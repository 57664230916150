import { Box } from "@mui/material";
import { TopNav_2 } from "../../../../components/nav/top_nav_2";
import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from "react";
import { SideNav_3 } from "../../../../components/nav/side_nav_3";
import { adminNavItms, adminRoutesMap, adminSideNav } from "../../../../utils/data/IndividualDashboard/Data";
import { Analytics } from "../../../../Analytics";



export const Main_3 = () => {
    const [nav, setNav] = useState(0);
    const [subNav, setSubNav] = useState(-1);
    const [minimize, setMinimize] = useState(false);
    const ut = localStorage.getItem('user_type');
      const navigate = useNavigate();
    const containerRef = useRef(null);
    const sideNavRef = useRef(null);
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100);
    const [mainContentWidth, setMainContentWidth] = useState('100%');
    const handleResize = () => {
        setMinimize(window.innerWidth < 1100);
        setSmallScreen(window.innerWidth < 1100);
    };
    useEffect(() => {
      const userRole = localStorage.getItem('user_role');
      if (ut === 'company' && userRole === 'Admin User') {
        navigate('/admin');
      } else if (ut === 'individual') {
        navigate('/dashboard/individual-dashboard');
      } else if (ut === 'company' && userRole === 'Normal User') {
        navigate('/user');
      }
    }, []);
    const smsc2 = window.innerWidth < 900
    const updateMainContentWidth = () => {
        if (!smsc2) {
            if (containerRef.current && sideNavRef.current) {
                const newWidth = `${containerRef.current.offsetWidth - sideNavRef.current.offsetWidth}px`;
                setMainContentWidth(newWidth);
            }
        } else {
            if (containerRef.current) {
                const newWidth = `${containerRef.current.offsetWidth}px`;
                setMainContentWidth(newWidth);
            }
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        // Initial calculation
        updateMainContentWidth();
        // Add event listener for window resize
        window.addEventListener('resize', updateMainContentWidth);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateMainContentWidth);
        };
    }, [minimize]);
    // console.log('ref main wdth', containerRef,'cont',containerRef.current.offsetWidth,'sid', sideNavRef.current.offsetWidth, mainContentWidth)
    return (
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }} ref={containerRef}>
            <SideNav_3 ref={sideNavRef} sref={sideNavRef} setSubNav={setSubNav} setNav={setNav} Data={adminSideNav} routesMap={adminRoutesMap} commonPath={'/admin/dashboard'} minimize={minimize} setMinimize={setMinimize} smallScreen={smallScreen} setSmallScreen={setSmallScreen} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: mainContentWidth }}>
                    <Analytics />
                <TopNav_2 setNav={setNav} Data={adminNavItms} minimize={minimize} setMinimize={setMinimize} />
                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}