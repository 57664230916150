import { useEffect, useState } from "react";
import http from "../../../../../utils/http-common";
import {
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import {
  ConfigStyleBar2,
  data,
} from "../../../../../utils/data/IndividualDashboard/Data";
import {
  CustomDatePicker,
  DateField,
} from "../../../../../components/Date-Picker/CustomDatePicker";
import { Tick } from "../../../../../utils/icon/IndividualPages/Icon";
import { TableComp6 } from "../../../../../components/table/type - 6";
import { generateTableData } from ".";
import { dateClnse2, dateClnse3, dateClnse7, StringToDate } from "../../../../../components/Public/DateClnse";
import { TableComp5 } from "../../../../../components/table/type - 5";
import { createExcelBlob, DownloadCSV } from "../../../../../components/Public/DownloadCSV";
import dayjs from "dayjs";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearBundle, updateBundle } from "../../../../../slice/bundle";
import DateRangePicker from "../../../../../components/Date-Picker/DatePicker";
import { useNavigate } from "react-router-dom";
import { DataG_T_1 } from "../../../../../components/table/Data_Grid/type - 1";
import { OrdinaryTable1 } from "../../../../../components/table/Ordinary Table/type - 1";
import SettingsIcon from "@mui/icons-material/Settings";
import { Model_1 } from "../../../../../components/@extended/Model";
import { Header } from "./Config-Components/Header";
import { Selection } from "./Config-Components/Selection";
import { Frequency } from "./Config-Components/Frequency";
import { Footer } from "./Config-Components/Footer";
import { Preview } from "./Config-Components/Preview";
import { DataNotFound } from "./Config-Components/DataNotFound";
import { ProperDate2 } from "../../../../../components/@extended/TimeRemaining";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { GetPriceWithSymbol } from "../../../../../components/@extended/CurrencyConversion";
import logoImg from '../../../../../assets/pdf/logo.png'
import linkedinImg from '../../../../../assets/pdf/1.png'
import xImg from '../../../../../assets/pdf/2.png'
import instagramImg from '../../../../../assets/pdf/3.png'
import { addStyledImage, sendData } from "../../../../../components/@extended/CustomFunctions";

export const Configuration = ({
  selectedData,
  setSelectedData,
  minimize,
  setMinimize,
  state,
  setState,
  addElement,
  removeElement,
  clearElement,
}) => {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState("");
  const navigate = useNavigate();
  const [ec, setEC] = useState(null);
  const [cal, setCal] = useState(false);
  const [dnf, setDnf] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [country_id, setCountry_id] = useState(0);
  const [name, setName] = useState("");
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [category_id, setCategory_id] = useState(0);
  const [ecommerceSites_id, setEcommerceSites_id] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [brand_id, setBrand_id] = useState([]);
  const [bundleId, setBundleId] = useState(null);
  const [tbl, setTbl] = useState({
    Category: "",
    Country: "",
    "E-Commerce Site": [],
    Brand: [],
    "Configured Date": "",
  });
  const [effect, setEffect] = useState({
    category: true,
    country: true,
    ecommerceSites: true,
    brand: true,
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [responseData, setResponseData] = useState({
    category: [],
    category_id: [],
    country: [],
    country_id: [],
    ecommerceSites: [],
    ecommerceSites_id: [],
    brand_id: [],
    brand: [],
  });
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenS(false);
  };

  const handleDateChange = (newValue, index) => {
    // const formattedValue = newValue.map((date) => {
    //   if (date) {
    //     return dayjs(date).toISOString();
    //   }
    //   return null;
    // });
    // setDateRange(formattedValue);
    // console.log('newV', newValue, formattedValue, dateRange)
    const updatedDateRange = [...dateRange];
    updatedDateRange[index] = newValue;
    setDateRange(updatedDateRange);
  };
  const handleButtonClick = (e, arrayName) => {
    if (selectedData[arrayName].includes(e)) {
      removeElement(e, arrayName);
    } else {
      addElement(e, arrayName);
      console.log("selectedData", selectedData);
    }
  };
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
    //setValue: setFormValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });


  // console.log('urllll',http?.defaults?.baseURL);

  const createPDF = async ({ prop, response }) => {
    const { Quotation_date, Quotation_id, Valid_until_date, Bundle_name, User_id, recharge_request_id, plan_name, User_name,
      Bundle_details, No_of_Admin, Valid_Until, Bundle_Cost,
      No_Of_User,
      credits_for_the_plan,
      Plan_cost, } = prop
    console.log('prop', prop, Quotation_date);

    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    // Add border around entire page content
    doc.setLineWidth(0.1);
    doc.setDrawColor(255, 255, 255);
    doc.rect(10, 10, 190, 267, "D");

    // Header
    doc.setFillColor(0, 0, 0); // Black fill
    // Draw top-left and top-right rounded corners
    doc.roundedRect(10, 10, 150, 15, 2, 5, "F");
    doc.roundedRect(10, 10, 95, 15, 2, 2, "F"); // Left part
    doc.roundedRect(105, 10, 95, 15, 2, 2, "F"); // Right part
    doc.rect(10, 22, 190, 3, "F"); // Bottom straight line to connect the two rounded parts

    doc.addImage(logoImg, "PNG", 165, 13, 30, 10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 255, 255); // White text
    doc.setFontSize(12);
    doc.text("Quotation", 15, 20);

    // Quotation Details
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    doc.text("Quotation Details", 15, 35); // Title
    doc.setFont("helvetica", "normal");

    doc.text(`Quotation Date: ${dateClnse7(StringToDate(Quotation_date))}`, 15, 43);
    doc.text(`Quotation ID: ${Quotation_id}`, 15, 48);
    doc.text(`Bundle Name: ${Bundle_name}`, 15, 53);

    doc.setFont("helvetica", "bold");
    // User Details
    doc.text("User Details", 100, 35); // Title (Adjust X-coordinate for side-by-side)
    doc.setFont("helvetica", "normal");

    doc.text(`User ID: ${User_id}`, 100, 43); // Adjust X-coordinate
    doc.text(`User Name: ${User_name}`, 100, 48); // Adjust X-coordinate

    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 255, 255); // White text
    doc.setFontSize(12);
    doc.text("Bundle Details", 100, 50);

    // Quotation Details
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    // Plan Details Table
    // console.log('response?.data?.estimated_credits', response?.data, response?.data?.estimated_credits);

    autoTable(doc, {
      startY: 80,
      head: [["Configurations", ""]],
      body: [
        ["No. of Categories", Bundle_details?.["No.Of.Categories"]],
        ["No. of Countries", Bundle_details?.["No.Of.Country"]],
        ["No. of Platforms", Bundle_details?.["No.Of.Platform"]],
        ["No. of Brands", Bundle_details?.["No.Of.Brand"]],
        ["No. of Products (SKU)", Bundle_details?.["No.Of.Product(SKU)"]],
        ["No. of Data Elements", Bundle_details?.["No.Of.Data Elements"]],
        ["Bundle setup cost", Math.round(response?.data?.estimated_credits) + ' Credits'],
        ["Cost of the bundle", GetPriceWithSymbol(Math.round(Bundle_details?.["Cost Of The Bundle"]))],
        ["Discount", Math.round(Bundle_details?.["Discount"]) + '%'],
        ["Bundle Cost", GetPriceWithSymbol(Math.round(Bundle_details?.["Bundle Cost"]))],
      ],
      theme: "grid",
      headStyles: {
        fillColor: [243, 231, 250],
        textColor: 0,
        lineWidth: 0.2, // Set border width to 1px
        lineColor: [174, 177, 176], // Set border color to #EAECF0 (RGB)
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: 0,
        lineWidth: 0.2, // Set border width to 1px
        lineColor: [174, 177, 176], // Set border color to #EAECF0 (RGB)
      },
      alternateRowStyles: {
        fillColor: [250, 250, 250],
        lineWidth: 0.2, // Set border width to 1px
        lineColor: [174, 177, 176], // Set border color to #EAECF0 (RGB)
      },
      didParseCell: (data) => {
        const row = data.row.index;
        if (row >= 6) { // Last 4 rows (index 6, 7, 8, 9)
          data.cell.styles.fontStyle = 'bold';
        }
        if (row >= 9) { // Last 4 rows (index 6, 7, 8, 9)
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = [243, 231, 250];
        }
      },
    });

    const termsY = 170; // Adjusted Y position
    const termsHeight = 60; // Height of the box
    const termsWidth = 170; // Width of the box
    const termsX = 15; // X position (left side)

    // // Set border thickness and color
    // doc.setLineWidth(0.5); // Thicker border
    // doc.setDrawColor(0, 0, 0); // Black border

    // Draw a properly aligned rectangle with padding
    doc.rect(termsX, termsY, termsWidth, termsHeight);
    doc.setFontSize(8);
    doc.text("(*Tax not included)", 140, termsY + 1);

    // Title for Terms & Conditions
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Terms & Conditions", termsX + 5, termsY + 8); // Title inside box with padding

    // Reset font to normal for terms text
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    // Terms list (Bullets properly aligned)
    const terms = [
      `<b>Validity of Quote</b> - This quotation is valid for 7 working days from ${dateClnse7(StringToDate(Quotation_date))} till ${dateClnse7(StringToDate(Valid_until_date))}.`,
      "<b>Payment Terms</b> - Payment to be done before the bundle is created.",
      "<b>Delivery</b> - Delivery will be completed within 72 hours, at which point the credits will be updated accordingly.",
      "<b>Changes and Revisions</b> - Changes and revisions after the quotation will lead to additional effort and cost.",
      "<b>Cancellation Policy</b> - Cancellations are not permitted once the service has been initiated.",
      "<b>Warranty/Guarantee</b> - The data provided is as-is basis only, and we do not offer any guarantee or warranty at this time.",
      "<b>Confidential</b> - All information shared between both parties will remain confidential.",
    ];

    let textY = termsY + 15; // Start position for text inside the box
    const lineSpacing = 5; // Space between each line

    // Wrap text inside the box and align properly
    terms.forEach((term, index) => {
      const bullet = `• `;
      const bulletWidth = doc.getTextWidth(bullet, { fontSize: 12 }); // Use correct fontSize

      // Split the term into bold and regular text parts
      const parts = term.split(/<b>(.*?)<\/b>/); // Split by <b>...</b> tags
      let currentX = termsX + 5 + bulletWidth;

      // Print bullet
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12);
      doc.setTextColor('#000');
      doc.text(bullet, termsX + 5, textY);

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (i % 2 === 1) { // Bold part
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(8);
          doc.setTextColor('#000');
          const wrappedBoldText = doc.splitTextToSize(part, termsWidth - currentX);
          wrappedBoldText.forEach((line, lineIndex) => {
            if (lineIndex > 0) {
              textY += lineSpacing;
              currentX = termsX + 5 + bulletWidth; // Reset X for new lines
            }
            doc.text(line, currentX, textY);
            currentX += doc.getTextWidth(line, { fontSize: 8, fontWeight: 'bold' }); // Adjust X for next part
          });

        } else { // Regular part
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(8);
          doc.setTextColor('#000');
          const wrappedRegularText = doc.splitTextToSize(part, termsWidth - currentX);
          wrappedRegularText.forEach((line, lineIndex) => {
            if (lineIndex > 0) {
              textY += lineSpacing;
              currentX = termsX + 5 + bulletWidth; // Reset X for new lines
            }
            doc.text(line, currentX, textY);
            currentX += doc.getTextWidth(line, { fontSize: 8, fontWeight: 'normal' }); // Adjust X for next part
          });
        }
      }
      textY += lineSpacing; // Space between terms
    });




    // Footer
    doc.setFillColor(0, 0, 0);
    // Draw bottom-left and bottom-right rounded corners
    doc.roundedRect(10, 265, 95, 12, 2, 2, "F"); // Left part
    doc.roundedRect(105, 265, 95, 12, 2, 2, "F"); // Right part
    doc.rect(10, 265, 190, 3, "F"); // Top straight line to connect the two rounded parts

    // Draw rounded rectangle in the middle
    const centerX = 10 + 190 / 2;
    const rectWidth = 50; // Adjust width as needed
    const rectHeight = 12; // Adjust height as needed
    const rectX = centerX - rectWidth / 2;
    const cornerRadiusX = 2; // Adjust corner radius X as needed
    const cornerRadiusY = 2; // Adjust corner radius Y as needed

    doc.roundedRect(rectX, 265, rectWidth, rectHeight, cornerRadiusX, cornerRadiusY, "F");

    doc.setFontSize(8);
    doc.setTextColor(255, 255, 255);

    doc.text("Follow us on:", 15, 270);
    addStyledImage(doc, linkedinImg, 35, 267, 6, 6, 1, 1);
    addStyledImage(doc, xImg, 45, 267, 6, 6, 1, 1);
    addStyledImage(doc, instagramImg, 55, 267, 6, 6, 1, 1);
    doc.setFontSize(13);
    doc.text("©", 96, 271);
    doc.setFontSize(8);
    doc.text("Copyright 2025, All Rights Reserved by Brand Aura.", 100, 270);

    // doc.save(`xxx.pdf`);
    // doc.save(`Quotation-${Quotation_id}.pdf`);
    const pdfBuffer = doc.output("blob");
    const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
    const Uint8Array2 = new Uint8Array(new ArrayBuffer(doc.output('arraybuffer')));
    const bloburl = URL.createObjectURL(blob)
    // console.log('bloburl', bloburl, blob, pdfBuffer, doc.output(), doc.output('arraybuffer'), doc.output('bloburl'), doc.output('bloburi'), doc.output('dataurl'), doc.output('datauri'));
    // const blobUrl1 = await uploadToAzureBlob(doc.output(), '007');
    // const blobUrl2 = await uploadToAzureBlob(doc.output('arraybuffer'), '007');
    // const blobUrl3 = await uploadToAzureBlob(pdfBuffer, '007');
    // const blobUrl4 = await uploadToAzureBlob(doc.output('bloburl'), '007');
    // const blobUrl5 = await uploadToAzureBlob(doc.output('bloburi'), '007');
    // const blobUrl6 = await uploadToAzureBlob(doc.output('dataurl'), '007');
    // const blobUrl7 = await uploadToAzureBlob(doc.output('datauri'), '007');
    // const blobUrl8 = await uploadToAzureBlob(bloburl, '007');
    // const blobUrl9 = await uploadToAzureBlob(Uint8Array2, '007');
    // const base64Image = extractBase64Data(pdfBuffer);
    // const blob2 = base64ToBlob(base64Image, mime);
    const mime = "application/pdf";
    const file = new File([blob], "abc.pdf", { type: mime });
    const d = { ...prop, paymentFor: 'custombundle' }
    let parmUrl = sendData(d)
    console.log('paramurl', parmUrl, d);
    const html = `
    <div class="container"
        style="max-width: 700px; margin: 0 auto; padding: 20px; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);">
        <p style="margin-bottom: 5px;">Hi ${User_name},</p>
        <p style="margin-bottom: 5px;">Thank you for raising a custom bundle.</p>
        <p style="margin-bottom: 5px;">We have attached the quotation for the custom bundle. To activate this bundle in your account, a
            payment of ${GetPriceWithSymbol(Math.round(Bundle_details?.["Bundle Cost"]))} is required.</p>

            <div class="header" style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 20px; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <table style="width: 100%; height: 60px; border-collapse: collapse;">
                    <tr>
                        <td style="text-align: left; vertical-align: middle;">
                            <div class="logo" style="font-size: 1.5em;">
                                <h2 style="font-size: 1.1em; color:#fff; margin-bottom: 5px;">Quotation</h2>
                            </div>
                        </td>
                        <td style="text-align: right; vertical-align: middle;">
                            <div class="brand">
                                <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png" alt="Brand Aura Logo" style="height: 40px; margin-right: 5px;">
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

    <div style="width: 100%; margin-bottom: 20px;">
    <table width="100%" style="border-collapse: collapse;">
        <tr>
            <td style="width: 50%; padding: 10px;">
            <h2 style="font-size: 1.1em; margin-bottom: 5px;">Quotation Details</h2>
            <p style="margin-bottom: 0;">Quotation Date: ${dateClnse7(StringToDate(Quotation_date))}</p>
            <p style="margin-bottom: 0;">Quotation ID: ${Quotation_id}</p>
            <p style="margin-bottom: 0;">Bundle Name: ${Bundle_name}</p>
            </td>
            <td style="width: 50%; padding: 10px;">
            <h2 style="font-size: 1.1em; margin-bottom: 5px;">User Details</h2>
            <p style="margin-bottom: 0;">User ID: ${User_id}</p>
            <p style="margin-bottom: 0;">User Name: ${User_name}</p>
            </td>
        </tr>
            </table>
        </div>

    <div class="plan-details" style="margin-bottom: 20px; border-radius: 10px; overflow: hidden;">
        <h2 style="font-size: 1.1em; margin-bottom: 10px;">Bundle Details</h2>
        <table style="width: 100%; border-collapse: collapse; border-radius: 8px;">
            <tr>
                <td style="padding: 8px; background-color: #8A18D01A; border-top-left-radius: 8px; text-align: left;"><strong>Configurations</strong></td>
                <td style="padding: 8px; background-color: #8A18D01A; border-top-right-radius: 8px; text-align: left;"></td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left;">No of Categories</td>
                <td style="padding: 8px; text-align: left;">${Bundle_details?.["No.Of.Categories"]}</td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">No of Countries</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">${Bundle_details?.["No.Of.Country"]}</td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left;">No of Platforms</td>
                <td style="padding: 8px; text-align: left;">${Bundle_details?.["No.Of.Platform"]}</td>
            </tr>
             <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">No of Brands</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">${Bundle_details?.["No.Of.Brand"]}</td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left;">No of Products (SKU)</td>
                <td style="padding: 8px; text-align: left;">${Bundle_details?.["No.Of.Product(SKU)"]}</td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">No of Data Elements</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">${Bundle_details?.["No.Of.Data Elements"]}</td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left; font-weight: 600;">Bundle setup cost</td>
                <td style="padding: 8px; text-align: left; font-weight: 600;">${Bundle_details?.["Bundle setup cost"]} Credits</td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left; font-weight: 600;">Cost of the bundle</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left; font-weight: 600;">${GetPriceWithSymbol(Math.round(Bundle_details?.["Cost Of The Bundle"]))}</td>
            </tr>
              <tr>
                <td style="padding: 8px; text-align: left; font-weight: 600;">Discount</td>
                <td style="padding: 8px; text-align: left; font-weight: 600;">${Bundle_details?.["Discount"]}%</td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #8A18D01A; border-bottom-left-radius: 8px; text-align: left; font-weight: 600;">Bundle Cost</td>
                <td style="padding: 8px; background-color: #8A18D01A; border-bottom-right-radius: 8px; text-align: left; font-weight: 600;">${GetPriceWithSymbol(Math.round(Bundle_details?.["Bundle Cost"]))}</td>
            </tr>
            <tr>
                <td style="font-weight: 600; font-size: 16px; color: #000;"></td>
                <td style="font-size: 11px; color: #000; text-align: end;">(*Tax not included)</td>
            </tr>
        </table>
    </div>
    <a href='${parmUrl}' style="font-weight: 600; font-size: 14px; color: #8A18D0; text-decoration: underline; cursor: pointer;">Payment Link</a>

    <div class="terms-conditions" style="margin-bottom: 20px;">
        <h2 style="font-size: 1.1em; margin-bottom: 10px;">Terms & Conditions</h2>
        <ul style="padding-left: 20px; list-style-type: disc; margin: 0;">
            <li style="margin-bottom: 5px;"><b>Validity of Quote</b> - This quotation is valid for 7 working days from ${dateClnse7(StringToDate(Quotation_date))} till ${dateClnse7(StringToDate(Valid_until_date))}</li>
            <li style="margin-bottom: 5px;"><b>Payment Terms</b> - Payment to be done before the bundle is created.</li>
            <li style="margin-bottom: 5px;"><b>Delivery</b> - Delivery will be completed within 72 hours, at which point the credits will be updated accordingly.</li>
            <li style="margin-bottom: 5px;"><b>Changes and Revisions</b> - Changes and revisions after the quotation will lead to additional effort and cost.</li>
            <li style="margin-bottom: 5px;"><b>Cancellation Policy</b> - Cancellations are not permitted once the service has been initiated.</li>
            <li style="margin-bottom: 5px;"><b>Warranty/Guarantee</b> - Please note that the data provided is as is basis only, and we do not offer any guaranty or warranty at this time.</li>
            <li style="margin-bottom: 5px;"><b>Confidential</b> - All information shared between both parties will remain confidential.</li>
        </ul>
    </div>

    <div class="footer" style="margin-top: 20px; background-color: #000000; color: #ffffff; padding: 10px; border-top: 1px solid #eee; font-size: 0.8em; height: 60px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
    <table style="width: 100%; height: 100%; border-collapse: collapse;">
        <tr>
            <td style="text-align: left; vertical-align: middle;">
                <div class="social-icons">
                    <span style="color: #ffffff; ">Follow us on:</span>
                    <!-- Replace with actual image URLs -->
                    <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image%20(1).png" alt="LinkedIn" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                    <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image%20(3).png" alt="X" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                    <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image.png" alt="Instagram" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                </div>
            </td>
            <td style="text-align: right; vertical-align: middle;">
                <div class="copyright">
                    <p style="margin: 0; color: #ffffff;">&copy; Copyright 2025, All Rights Reserved by Brand Aura.</p>
                </div>
            </td>
        </tr>
    </table>
</div>

        <div class="thank-you">
            <p>Thank you,<br>Brand Aura Team</p>
        </div>
    </div>
`
    await customBundleMailSending({
      pdf: file,
      message: `${name} Bundle has been created successfully`,
      filename: `Quotation-${Quotation_id}.pdf`,
      Quotation_Id: Quotation_id,
      quotationEmailSubject: `Quotation for Bundle Creation`,
      quotationHtml: html
    })
    // console.log('blobUrl', bloburl);
    // console.log('blobUrl1', blobUrl1);
    // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
    // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
    // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
    // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);


    // return blob;
    // return file;
  };
  const customBundleMailSending = async ({ pdf, message, filename, Quotation_Id, quotationEmailSubject, quotationHtml }) => {
    // const payload = {
    //     user_name: user?.user_first_name,
    //     plan_name: formData?.planName,
    //     No_of_Admin: formData?.noOfAdmin,
    //     No_Of_User: formData?.noOfUsers,
    //     credits_for_the_plan: formData?.creditsForThePlan,
    //     Plan_cost: formData?.planCost,
    //     pdf: pdf
    // }

    // const mime = "application/pdf";
    // const file = new File([pdf], "abc.pdf", { type: mime });
    const atcSheet = createExcelBlob(bundleData);
    const formData = new FormData();
    formData.append('mail', user?.user_email);
    formData.append('filename', filename);
    formData.append('message', message);
    formData.append('quotationEmailSubject', quotationEmailSubject);
    formData.append('quotationHtml', quotationHtml);
    formData.append('Quotation_Id', Quotation_Id);
    // formData.append('Plan_cost', formData?.planCost);
    formData.append('pdf', pdf);
    formData.append('xlName', name + '_sample_data.xlsx');
    formData.append('attachmentSheet', atcSheet);
    // formData.append('pdf', file);
    try {
      const response = await http.post("/master_service/admin_panel/custom_plan_mail_sending", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set the correct content type
        },
      });
      console.log("response customBundleMailSending:", response?.data, response?.data?.manage_user, response?.data?.user_table);
      console.log("response response?.data?.message:", response?.data?.message || 'Custom Plan, Created Successfully');
      // const pdfBuffer = createPDF({ prop: response?.data })
      // const blobUrl = await uploadToAzureBlob(pdfBuffer, '007');
      // dispatch(setAllUsers(response?.data?.user_table))
      // setTblData(response?.data?.user_table)
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
      setTimeout(() => {
        setOpenS(false); // Optionally hide the popup after navigation
        navigate("/admin/dashboard");
      }, 1900);
      setMessage(`${name} Bundle has been created successfully`);
      // dispatch(setMessage(`${name} Bundle has been created successfully`));
      // dispatch(setMessage('Custom Bundle, Created Successfully'));
      // dispatch(setMessage(response?.data?.message || 'Custom Bundle, Created Successfully'));
      // dispatch(setSeverity('success'));
      // dispatch(handleClick())
      setSeverity('success');
      handleClick()
      setLoad(false)
    }
    catch (error) {
      console.error("Error creating Custom Plan:", error)
      setLoad(false)
      console.log("response response?.data?.message:", error?.response?.data?.message || 'Error Creating Custom Plan');
      // dispatch(setMessage('Error Creating Custom Plan'));
      // dispatch(setMessage(error?.response?.data?.message || 'Error Creating Custom Plan'));
      // dispatch(setSeverity('error'));
      // dispatch(handleClick())
      setMessage(error?.response?.data?.message || 'Error Creating Custom Plan');
      setSeverity('error');
      handleClick()
    }
  }

  const Data_Fetching_ECS = async () => {
    try {
      console.log("1");
      const send = {
        country_id: country_id,
        category_id: category_id,
      };
      const EcommerceResponse = await http.post(
        "/master_service/ecommerce/filter",
        send
      );
      console.log("2", EcommerceResponse, EcommerceResponse.data);
      const refinedData = {
        ecommerce: EcommerceResponse.data
          .sort((a, b) => a.site_name.localeCompare(b.site_name))
          .map((e) => ({ name: e.site_name, available: e.available.includes(true) })),
        ecommerce_id: EcommerceResponse.data
          .sort((a, b) => a.site_name.localeCompare(b.site_name))
          .map((e) => e.site_id),
      };
      if (EcommerceResponse.data.length === 0) {
        setDnf(true);
      }
      console.log("response", refinedData.ecommerce);
      console.log("3");
      setResponseData({
        ...responseData,
        ecommerceSites: refinedData.ecommerce,
        ecommerceSites_id: refinedData.ecommerce_id,
      });
      console.log("responseData", responseData);
      setEffect({ ...effect, ecommerceSites: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
      console.log("4");
    }
  };

  const Data_Fetching_B = async () => {
    try {
      const send = {
        country_id: country_id,
        category_id: category_id,
        site_ids: ecommerceSites_id,
      };
      const BrandResponse = await http.post(
        "/master_service/category/brand/filter",
        send
      );
      console.log("BrandResponse", BrandResponse);
      const refinedData = {
        brand: BrandResponse.data
          .sort((a, b) => a.brand_name.localeCompare(b.brand_name))
          .map((e) => ({ name: e.brand_name, available: e.available })),
        brand_id: BrandResponse.data
          .sort((a, b) => a.brand_name.localeCompare(b.brand_name))
          .map((e) => e.brand_id),
      };
      console.log("response", refinedData.brand);
      setResponseData({
        ...responseData,
        brand: refinedData.brand,
        brand_id: refinedData.brand_id,
      });
      if (BrandResponse.data.length === 0) {
        setDnf(true);
      }
      console.log("responseData", responseData);
      setEffect({ ...effect, brand: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
    }
  };

  const Data_Fetching = async () => {
    try {
      console.log("1");
      const [categoryResponse, CountryResponse] = await Promise.all([
        http.get("/master_service/category_master"),
        http.get("/master_service/country_master"),
        // http.get('/master_service/powertools_ecommerce_sites'),
        // http.get('/master_service/india_brand_master')
      ]);
      console.log("2");
      const refinedData = {
        category: categoryResponse.data.data
          .sort((a, b) => a.category_name.localeCompare(b.category_name))
          .map((e) => ({ name: e.category_name, available: e.available })),
        category_id: categoryResponse.data.data
          .sort((a, b) => a.category_name.localeCompare(b.category_name))
          .map((e) => e.category_id),
        country: CountryResponse.data.data
          .filter((e) => e.country_name !== 'WorldWide')
          .sort((a, b) => a.country_name.localeCompare(b.country_name))
          .map((e) => ({ name: e.country_name, available: e.available })),
        country_id: CountryResponse.data.data
          .filter((e) => e.country_name !== 'WorldWide')
          .sort((a, b) => a.country_name.localeCompare(b.country_name))
          .map((e) => e.country_id),
        // ecommerce: EcommerceResponse.data.data.sort((a, b) => a.site_name.localeCompare(b.site_name)).map(e => e.site_name),
        // brand: BrandResponse.data.data.sort((a, b) => a.brand_name.localeCompare(b.brand_name)).map(e => e.brand_name)
      };
      console.log(
        "response",
        refinedData.category,
        refinedData.country,
        refinedData
      );
      console.log("3");
      setResponseData({
        ...responseData,
        category: refinedData.category,
        category_id: refinedData.category_id,
        country: refinedData.country,
        country_id: refinedData.country_id,
        // ecommerceSites: refinedData.ecommerce,
        // brand: refinedData.brand
      });
      console.log("responseData", responseData);
      setEffect({ ...effect, category: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
      console.log("4");
    }
  };

  const Bundle_Creation = async () => {
    setOpen(false)
    setLoad(true)
    try {
      // console.log('1')
      const sec =
        "8cf441d7c6a7394c46be9a7502f5a10194406d826383054ea04b0c9a33397567";
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        ind_bundle_country_id: country_id,
        ind_bundle_e_commerce_id: ecommerceSites_id,
        ind_bundle_category_id: category_id,
        ind_bundle_brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        ind_bundle_start_date: ProperDate2(dateRange.startDate),
        ind_bundle_end_date: ProperDate2(dateRange.endDate),
        // ind_bundle_start_date: dateRange.startDate,
        // ind_bundle_end_date: dateRange.endDate,
        // ind_bundle_start_date: dateRange[0],
        // ind_bundle_end_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        estimated_credits: ec,
        ind_bundle_category: selectedData.category,
        user_id: user?.user_id,
        discount: discount,
        ...Esti,
      };
      const res = await http.post(
        "/master_service/admin_panel/create_custom_bundle",
        payload_data
      );
      console.log("res", res);
      console.log("2");
      // setBundleData(res.data);
      // dispatch(updateBundle(res.data));
      // navigate("/dashboard/individual-dashboard/bundle-creation");
      // setBundleData(res.data);
      dispatch(updateBundle(res.data));
      // setOpenS(true);
      // setMessage(res.data.message);
      // setSeverity("success");
      // handleClick();
      const d = { ...res?.data?.data, ...res?.data?.data?.quotationDetails, ...res?.data?.data?.quotationDetails?.Quotation_details, ...res?.data?.data?.quotationDetails?.User_details }
      console.log('d', d);

      const crePdf = createPDF({ prop: d, response: res?.data })
      // setMessage(`${name} Bundle has been created successfully`);
      // setSeverity("success");
      // handleClick();

      // Navigate after showing the popup
      // setTimeout(() => {
      //   setOpenS(false); // Optionally hide the popup after navigation
      //   navigate("/admin/dashboard");
      // }, 1900); // Delay before navigation

      // localStorage.setItem("res_datas", JSON.stringify(res.data));
      // window.location.href = "/dashboard/individual-dashboard/bundle-creation";
      // localStorage.setItem('res_datas', CryptoJS.AES.encrypt(JSON.stringify(res), sec))
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false)
      console.log("4");
      setOpen(false);
      setState(4);
      setMessage(
        error?.response ? error?.response?.data?.message : "Error creating bundle."
      );
      console.log("error respon", error.response, error?.response?.data?.message);
      setSeverity("error");
      handleClick();
    }
  };

  const [Esti, setEsti] = useState({
    rows: 0,
    columns: 0,
    estimated_credit: 0,
  });
  const EstimatedCredits = async () => {
    try {
      console.log("dat007", dateRange, dateRange.startDate)
      const sd = `${new Date(dateRange.startDate).getFullYear()}-${String(
        new Date(dateRange.startDate).getMonth() + 1
      ).padStart(2, "0")}-${String(new Date(dateRange.startDate).getDate()).padStart(2, "0")}`
      const ed = `${new Date(dateRange.endDate).getFullYear()}-${String(
        new Date(dateRange.endDate).getMonth() + 1
      ).padStart(2, "0")}-${String(new Date(dateRange.endDate).getDate()).padStart(2, "0")}`
      // console.log('1')
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        country_id: country_id,
        site_id: ecommerceSites_id,
        category_id: category_id,
        brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        from_date: ProperDate2(dateRange.startDate),
        to_date: ProperDate2(dateRange.endDate),
        // from_date: sd,
        // to_date: ed,
        // from_date: dateRange.startDate,
        // to_date: dateRange.endDate,
        // from_date: dateRange[0],
        // to_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        ind_bundle_category: selectedData.category,
        user_id: localStorage.getItem("user_id"),
        discount: String(discount),
      };
      const url = userType === 'company' ? '/master_service/admin_panel/admin_calculation' : userType === 'individual' ? '/master_service/admin_panel/custuom_calculation' : null
      const res = await http.post(
        url,
        payload_data
      );
      console.log("res and dat", dateRange, dateRange.startDate, res, res.data, '\n\n\n',
        name.trim() !== "" &&
        cal &&
        (dateRange.startDate !== null && dateRange.endDate !== null && userType === 'individual')
          (dateRange.startDate !== null && dateRange.endDate !== null && userType === 'individual'),
        userType === 'individual'
      );
      setEC(res.data.estimated_credit);
      console.log("22222222222222");
      setEsti(res.data);

      if (res.data.estimated_credit === 0) {
        setOpenS(true);
        setMessage('Data not found');
        // setMessage(res.data.message);
        setSeverity("warning");
      }
      else if (res.data.estimated_credit !== 0) {
        setCal(true);
      } else {
        setCal(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log("4");
      // setOpenS(false);
      // setMessage(res.data.error);
      // setSeverity("false");
    }
  };

  // const startDate = useWatch({
  //   control,
  //   name: 'start_date',
  // });

  // const endDate = useWatch({
  //   control,
  //   name: 'end_date',
  // });

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     // Trigger API request here
  //     EstimatedCredits()
  //   }
  // }, [startDate, endDate]);
  const Bundle_Updation = async () => {
    try {
      // console.log('1')
      const sec =
        "8cf441d7c6a7394c46be9a7502f5a10194406d826383054ea04b0c9a33397567";
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        ind_bundle_country_id: country_id,
        ind_bundle_e_commerce_id: ecommerceSites_id,
        ind_bundle_category_id: category_id,
        ind_bundle_brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        ind_bundle_start_date: dateRange.startDate,
        ind_bundle_end_date: dateRange.endDate,
        // ind_bundle_start_date: dateRange[0],
        // ind_bundle_end_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        ind_bundle_category: selectedData.category,
        estimated_credits: ec,
        user_id: localStorage.getItem("user_id"),
        ind_bundle_id: bundleId,
      };
      const res = await http.post("/master_service/bundle/edit", payload_data);
      console.log("res", res);
      console.log("2");
      // setBundleData(res.data);
      dispatch(updateBundle(res.data));
      setOpenS(true);
      setMessage(res.data.message);
      setSeverity("success");
      handleClick();
      setTimeout(() => {
        navigate("/dashboard/individual-dashboard/bundle-creation");
        setOpenS(false); // Optionally hide the popup after navigation
      }, 500); // Delay before navigation

      // localStorage.setItem("res_datas", JSON.stringify(res.data));
      // window.location.href = "/dashboard/individual-dashboard/bundle-creation";
      // localStorage.setItem('res_datas', CryptoJS.AES.encrypt(JSON.stringify(res), sec))
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log("4");
      setOpen(false);
      setState(4);
      setMessage(
        error.response ? error.response.data.message : "Error updating bundle."
      );
      console.log("error respon", error.response, error.response.data.message);
      setSeverity("error");
      handleClick();
    }
  };

  const Sample_Data = async () => {
    try {
      // const f = dateRange[0];
      const f = dateClnse2(dateRange.startDate);
      // const f = dateClnse2(dateRange[0]);
      // const t = dateRange[1];
      const t = dateClnse2(dateRange.endDate);
      // const t = dateClnse2(dateRange[1]);
      console.log("ft", f, t);
      const payload = {
        category_id: category_id,
        site_id: ecommerceSites_id,
        brand_id: brand_id,
        country_id: country_id,
        from_date: f,
        to_date: t,
      };
      const url = userType === 'company' ? '/master_service/corporate_sample_data' : userType === 'individual' ? '/master_service/sample/bundle/data' : null
      const response = await http.post(
        url,
        payload
      );
      const resD = userType === 'company' ? response?.data?.top5Data : userType === 'individual' ? response?.data?.data : null
      setBundleData(resD);
      if (
        // response.data.data.product_id === "-" ||
        response.data.message === "No sellers found for the given criteria."
      ) {
        setDnf(true);
      }
      console.log(
        "res smpl data",
        response, resD
        // response.data.payload.ind_bundle_category
      );
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });

      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/dashboard/individual-dashboard/sample-data'
    } catch (error) {
      console.error("Error fetching sample data:", error);
      setDnf(true);
    }
  };

  const BtnAction = (i) => {
    if (state === 0) {
      setCategory_id(responseData.category_id[i].toString());
    } else if (state === 1) {
      setCountry_id(responseData.country_id[i].toString());
    } else if (state === 2) {
      if (
        !ecommerceSites_id.includes(
          responseData.ecommerceSites_id[i].toString()
        )
      ) {
        setEcommerceSites_id((prevEcommerceSites_id) => [
          ...prevEcommerceSites_id,
          responseData.ecommerceSites_id[i].toString(),
        ]);
      } else {
        setEcommerceSites_id((prevEcommerceSites_id) =>
          prevEcommerceSites_id.filter(
            (id) => id !== responseData.ecommerceSites_id[i].toString()
          )
        );
      }
    } else if (state === 3) {
      if (!brand_id.includes(responseData.brand_id[i])) {
        setBrand_id((prevBrand_id) => [
          ...prevBrand_id,
          responseData.brand_id[i].toString(),
        ]);
      } else {
        setBrand_id((prevBrand_id) =>
          prevBrand_id.filter(
            (id) => id !== responseData.brand_id[i].toString()
          )
        );
      }
    }
  };

  const ModelData2 = {
    title: "Bundle Creation",
    emoji: "",
    txt: ["Are you sure, you want to create the bundle?"],
    cl: "",
    btn_1: "Cancel",
    btn_1_cancel: true,
    btn_1_Link: "",
    btn_2: "Create Bundle",
    btn_2_cancel: "",
    // btn_2_Link: '/dashboard/individual-dashboard/bundle-creation',
    btn_2_fn: Bundle_Creation,
  };

  const ModelData = {
    title: bundleId ? "Update Bundle" : "Bundle Creation",
    emoji: "",
    txt: bundleId
      ? ["Are you sure, you want to update the bundle?"]
      : ["Are you sure, you want to create the bundle?"],
    cl: "",
    btn_1: "Cancel",
    btn_1_cancel: true,
    btn_1_Link: "",
    btn_2: bundleId ? "Update Bundle" : "Create Bundle",
    btn_2_cancel: "",
    // btn_2_Link: '/dashboard/individual-dashboard/bundle-creation',
    btn_2_fn: bundleId ? Bundle_Updation : Bundle_Creation,
  };

  const dispatch = useDispatch();
  const bundle = useSelector((state) => state.bundles.bundle);
  // console.log('bundle', bundle)

  useEffect(() => {
    console.log("bundle ue", bundle);
    if (bundle && bundle.SelectData) {
      console.log(
        "bundle",
        bundle.SelectData.brand_id,
        bundle,
        bundle.SelectData
      );
      Data_Fetching();
      setState(bundle.state);
      setSelectedData({
        category: bundle.SelectData.category,
        country: bundle.SelectData.country,
        ecommerceSites: bundle.SelectData.ecommerceSites,
        brand: bundle.SelectData.brand,
      });
      setCountry_id(bundle.SelectData.country_id);
      setBrand_id(bundle.SelectData.brand_id);
      setCategory_id(bundle.SelectData.category_id);
      setEcommerceSites_id(bundle.SelectData.ecommerceSites_id);
      setName(bundle.bundleName);
      setDateRange(bundle.daterange);
      setBundleId(bundle.ind_bundle_id);
      // handleDateChange(bundle.daterange);

      // Clear the bundle data after using it
      dispatch(clearBundle());
    }

    if (state === 0 && effect.category) {
      Data_Fetching();
    } else if (state === 2 && effect.ecommerceSites) {
      Data_Fetching_ECS();
    } else if (state === 3 && effect.brand) {
      Data_Fetching_B();
    }
  }, [bundle, dispatch, state, effect]);
  const btnStyle = {
    background: "#8A18D0",
    color: "#fff",
    px: 4,
    textTransform: "capitalize",
    "&:hover": { background: "#B37FFC" },
  };

  const handleNext = async () => {
    if (validateCurrentStep()) {
      setState(state + 1);
      if (state === 1) Data_Fetching_ECS();
      else if (state === 2) Data_Fetching_B();
      // else if (state === 3) EstimatedCredits();
      else if (state === 4) await Sample_Data();
    }
  };

  const handleBack = () => {
    clearCurrentStepData();
    setState(state - 1);
    if (dnf) setDnf(false);
  };

  const validateCurrentStep = () => {
    if (state <= 3) {
      const currentArrayName = data[state].arrayName;
      return selectedData[currentArrayName]?.length > 0;
    } else if (state === 4) {
      if (name.trim() === "" || !cal) {
        return false;
      }
      return userType === 'individual'
        ? (dateRange.startDate !== null && dateRange.endDate !== null)
        : true;
    }
    return true;
  };

  const clearCurrentStepData = () => {
    if (state <= 3) {
      const currentArrayName = data[state].arrayName;
      clearElement(currentArrayName);
      if (state === 0) {
        // setCategory_id(null)
        setCountry_id(null)
      } else if (state === 1) {
        setEcommerceSites_id([])
      } else if (state === 2) {
        setBrand_id([])
      }
    } else if (state === 4) {
      setName("");
      setDateRange([null, null]);
      setEsti({ rows: 0, columns: 0, estimated_credit: 0 });
      setCal(false);
    }
  };

  return (
    <Box sx={{ minHeight: "590px", width: "100%", height: "90%", borderRadius: "8px", py: 3, }}>
      {/* Header Section */}
      <Header
        prop={{
          data: data,
          state: state,
          minimize: minimize,
          setMinimize: setMinimize,
          bundleData: bundleData,
          bName: name,
          btnStyle: btnStyle,
        }}
      />

      <Model_1 open={open} setOpen={setOpen} data={ModelData} />

      {/* Step-specific Sections */}

      {dnf ? (
        <DataNotFound />
      ) : (
        <Box>
          {state <= 3 && (
            <Selection prop={{ data: data, state: state, responseData: responseData, selectedData: selectedData, handleButtonClick: handleButtonClick, BtnAction: BtnAction, }} />
          )}
          {state === 4 && (
            <Frequency
              prop={{
                name: name,
                setName: setName,
                dateRange: dateRange,
                setDateRange: setDateRange,
                setCal: setCal,
                IndividualEstimatedCredits: EstimatedCredits,
                CompanyEstimatedCredits: EstimatedCredits,
                setEsti: setEsti,
                Esti: Esti,
                userType: userType,
                setUserType: setUserType,
                user: user,
                setUser: setUser,
                discount: discount,
                setDiscount: setDiscount
              }}
            />
          )}

          {state === 5 && <Preview prop={{ bundleData: bundleData }} />}
        </Box>
      )}
      {/* <Button onClick={() => { createPDF({ prop: {}, response: {} }) }}>createPDF</Button> */}
      <Footer prop={{ state: state, data: data, setOpen: setOpen, handleBack: handleBack, handleNext: handleNext, load, setLoad, validateCurrentStep: validateCurrentStep, bundleId: bundleId, }} />
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ position: "absolute", mt: "38px" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
