import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import CountryContext from '../../context/CountryContext';
import { Link } from 'react-router-dom';
import { getParsedRating } from '../type - 2';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { snakeCaseToNormal } from '../../../utils/Utility';
import { ThreeDots } from '../../../utils/icon/IndividualPages/Icon';
import http from '../../../utils/http-common';
import { ThreeDot, ThreeDot2 } from '../../@extended/ThreeDots';

export const TableComp6 = ({
  Data = [],
  Style = {},
  bundle_id = "",
  rb,
  cb = true,
  btnData = [],
}) => {
  const tblref = useRef()
  const [maxWidth, setMaxWidth] = useState('100%');
  const sanitizedData = Array.isArray(Data) ? Data : [];
  const Head =
    sanitizedData && sanitizedData.length > 0
      ? Object.keys(sanitizedData[0])
      : [];
  const Row =
    Head && Head.length > 0
      ? Head.map((x) => ({
        title: snakeCaseToNormal(x),
        field: x,
      }))
      : [];

  // console.log('tbl datas', Data, sanitizedData, Head, Row)

  /*
    const Style = {
        table: {},
        head: {},
        headRow: {},
        headCell: {},
        body: {},
        row: {},
        cell: {},
    }
    
    */
  const updateMaxWidth = () => {
    if (tblref.current) {
      setMaxWidth(`${tblref.current.offsetWidth}px`);
    } else {
      setMaxWidth('100%');
    }
  };

  useEffect(() => {
    // Initial calculation
    updateMaxWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateMaxWidth);
    };
  }, []);
  // let maxWidth = tblref.current ? `${tblref.current.offsetWidth}px` : '100%'
  // console.log('tblref', tblref, maxWidth)

  return (
    <Box
      ref={tblref}
      sx={{
        overflowY: "auto",
        overflowX: "auto",
        // scrollbarWidth: 'none',
        border: "0.8px solid #797F8F80",
        borderRadius: "8px",
        width: "100%",
        maxWidth: Style.StrictMaxWidth && tblref.current ? maxWidth : '100%',
        ...(Style.OutBox ? { ...Style.OutBox } : {}),
      }}
    >
      <TableContainer
        component={Paper}
        sx={{ width: "100%", scrollbarWidth: 'thin', maxWidth: Style.StrictMaxWidth && tblref.current ? maxWidth : '100%', ...(Style.TableContainer ? { ...Style.TableContainer } : {}), }}
      >
        <Table
          aria-label="brand table"
          sx={{
            borderRadius: "25px",
            maxWidth: Style.StrictMaxWidth && tblref.current ? maxWidth : '100%',
            ...(Style.table ? { ...Style.table } : {}),
          }}
        >
          {/* <Table aria-label="brand table" sx={{ border: '1px solid #E1E1E1', borderRadius: '25px', ...(Style.table ? { ...Style.table } : {}) }}> */}
          <TableHead sx={{ ...(Style.head ? { ...Style.head } : {}) }}>
            <TableRow sx={{ ...(Style.headRow ? { ...Style.headRow } : {}) }}>
              {Row.map((row, i) => (
                <TableCell
                  key={i}
                  sx={{ ...(Style.headCell ? { ...Style.headCell } : {}) }}
                >
                  {row.title}
                </TableCell>
              ))}
              {Style.isThreeDot && (
                <TableCell
                  sx={{
                    ...(Style.headCell ? { ...Style.headCell } : {}),
                  }}
                ></TableCell>
              )}
              {Style.isThreeDot2 && (
                <TableCell
                  sx={{
                    ...(Style.headCell ? { ...Style.headCell } : {}),
                  }}
                ></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ overflow: "auto", ...(Style.body ? { ...Style.body } : {}) }}
          >
            {sanitizedData.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  ...(Style.oddEvenColor
                    ? { background: i % 2 !== 0 ? "#FAFAFA" : "#FFFFFF" }
                    : {}),
                  ...(Style.row ? { ...Style.row } : {}),
                }}
              >
                {Row.map((col, j) => (
                  <TableCell
                    key={j}
                    sx={{ ...(Style.cell ? { ...Style.cell } : {}) }}
                  >
                    {Array.isArray(row[col.field]) ? (
                      <Box>
                        {row[col.field].map((e, i) => (
                          <Box key={i} sx={{ my: 1 }}>
                            {e}
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                ))}
                {Style.isThreeDot && Row[0].field !== null && (
                  <TableCell
                    sx={{ ...(Style.threeDot ? { ...Style.threeDot } : {}) }}
                  >
                    <ThreeDot
                      bundle_id={bundle_id}
                      rb={rb}
                      cb={cb}
                      data={btnData}
                    />
                  </TableCell>
                )}
                {/* {console.log('Row[0].field', Row[0].field, row[Row[0].field])} */}
                {Style.isThreeDot2 && row[Row[0].field] !== null && (
                  <TableCell
                    sx={{ ...(Style.threeDot ? { ...Style.threeDot } : {}) }}
                  >
                    <ThreeDot2
                      bundle_id={bundle_id}
                      rb={rb}
                      cb={cb}
                      data={btnData}
                      rowData={row}
                      i={i}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
