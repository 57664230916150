import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminCreditPlanRequest, AdminDashboardCredit, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";
import { setRechargeCreditsDatas } from "../../../../slice/adminDashboard";
import { useDispatch, useSelector } from "react-redux";


export const NewCreditRequest = () => {
    const dispatch = useDispatch();
    const [searchTermCredit, setSearchTermCredit] = useState(null);
    const [searchTermPlan, setSearchTermPlan] = useState(null);
    const [filter1, setFilter1] = useState(null);
    const [filter2, setFilter2] = useState(null);
    // const [all1, setAll1] = useState(null);
    // const [completed1, setCompleted1] = useState(null);
    // const [pending1, setPending1] = useState(null);
    // const [all2, setAll2] = useState(null);
    // const [completed2, setCompleted2] = useState(null);
    // const [pending2, setPending2] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [tblDataC, setTblDataC] = useState([]);
    // const [tblDataP, setTblDataP] = useState([]);
    const tblDataP = useSelector((s) => s.adminDashboard.rawRechargeCreditsDatas)
    const [cardDatas, setCardDatas] = useState(
        {
            totalCreditRequest: 0,
            pendingCreditRequests: 0,
            completedCreditRequests: 0,
        }
    );
    const cardData = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/card_status");
            console.log("response card:", response?.data);
            setCardDatas((p) => ({ ...p, pendingCreditRequests: response?.data?.Pending_Credit_Requests, totalCreditRequest: response?.data?.Total_Credit_Requests, completedCreditRequests: response?.data?.Completed_Credit_Requests }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const fetchDatasP = async () => {
        try {
            const response = await http.post("/master_service/credit_request");
            // console.log("response tbl products:", response?.data?.data, response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending'));
            dispatch(setRechargeCreditsDatas(response?.data?.data))
            // setTblDataP(response?.data?.data)
            // setCardDatas((p) => ({ ...p, totalCreditRequest: response?.data?.data?.filter((e) => e?.plan_name === null)?.length, pendingCreditRequests: response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending')?.length, completedCreditRequests: response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status !== 'Pending')?.length }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            // setAll1(response?.data?.data?.filter((e) => e.plan_name === null))
            // setAll2(response?.data?.data?.filter((e) => e.plan_name !== null))
            // setCompleted1(response?.data?.data?.filter((e) => e.plan_name === null))
            // setCompleted2(response?.data?.data?.filter((e) => e.plan_name !== null))
            // setPending1(response?.data?.data?.filter((e) => e.plan_name === null))
            // setPending2(response?.data?.data?.filter((e) => e.plan_name !== null))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => {
        fetchDatasP()
        cardData()
    }, [])
    const { tblC } = useAdminCreditPlanRequest({ d: tblDataP })
    // const { tblC, tblP } = useAdminCreditPlanRequest({ d: tblDataP })
    // console.log('tbl', tblC, tblP)
    // const filteredDataC = tblC;
    const filteredDataC = tblC?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermCredit ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.request_id,
            k?.user_id,
            k?.user_name,
            k?.request_date,
            k?.payment_status,
            k?.payment_type?.props?.value,
            k?.transaction_number?.props?.value,
            k?.payment_date?.props?.value,
            k?.amount,
            k?.applicable_credits
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter1 === null || filter1 === 'all' || filter1 === k?.payment_status?.props?.txt
        console.log('filtMatch', filtMatch, k?.payment_status?.props?.txt, filter1 === k?.payment_status?.props?.txt)
        return matchesSearch && filtMatch;
    });
    // console.log('filteredDataC', filteredDataC)
    // const filteredDataP = tblP;
    // const filteredDataP = tblP?.filter((k) => {
    //     if (!k) return false;

    //     const searchTerm = String(searchTermPlan ?? '')?.toLowerCase();
    //     // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
    //     const matchesSearch = [
    //         k?.request_id,
    //         k?.user_id,
    //         k?.user_name,
    //         k?.request_date,
    //         k?.payment_status
    //     ].some(field =>
    //         String(field ?? '').toLowerCase().includes(searchTerm)
    //     );
    //     const filtMatch = filter2 === null || filter2 === 'all' || filter2 === k?.payment_status?.props?.txt
    //     return matchesSearch && filtMatch;
    // });
    console.log('filteredDataC', filteredDataC)
    const propsCredit = { filteredData: filteredDataC, rowsPerPage, page, setPage }
    // const propsPlan = { filteredData: filteredDataP, rowsPerPage, page, setPage }
    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2, gap: 1 }}>
                        <BasicCard title='Total Credit Request' count={cardDatas?.totalCreditRequest} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Pending Credit Request' count={cardDatas?.pendingCreditRequests} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Completed Credit Request' count={cardDatas?.completedCreditRequests} width={{ xs: '100%', md: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Credit Table</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermCredit} onChange={(e) => setSearchTermCredit(e.target.value)} />
                            </Box>
                            <Select
                                displayEmpty
                                size='small'
                                value={filter1}
                                onChange={(e) => setFilter1(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "170px",
                                    height: "40px",
                                    pr: '5px !important',
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter1 === null ? 'end' : 'center',
                                        pl: 0,
                                        pr: '5px !important',
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                        pr: 0
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Payment Status</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Paid</MenuItem>
                                <MenuItem value='Not Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Not Paid</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsCredit} />
                </Box>
            </Box>
        </Box>
    )
}
