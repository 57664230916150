import { Box, Typography } from "@mui/material";

export const Card = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#ffffff",
        px: 4,
        py: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", ml: "-35px" }}>
        {data.icon}
        <Typography
          sx={{
            pl: "7px",
            fontWeight: "700",
            fontSize: "24px",
            background: "#000000",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {data.title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "20px",
          color: "#474747",
          mt: 2,
        }}
      >
        {data.text}
      </Typography>
    </Box>
  );
};

export const Card2pO = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#FAFAFA",
        px: 2,
        py: 1, borderRadius: '16px', border: '1px solid #EAECF0',
      }}
    >
      <Typography
        sx={{
          pl: "7px",
          fontWeight: "600",
          fontSize: "24px",
          color: "#000000",
        }}
      >
        {data.title}
      </Typography>
      <Typography
        sx={{ fontWeight: "400", fontSize: "20px", color: "#474747" }}
      >
        {data.text}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>{data.icon}</Box>
    </Box>
  );
};

export const Card_2 = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background: "#FAFAFA",
        borderRadius:'8px',
        border:'1px solid #EAECF0',
        minHeight: '270px',
        px: 2,
        py: 1,
      }}
    >
      {data.icon}
      <Typography
        sx={{
          pl: "7px",
          py: 1,
          fontWeight: "600",
          fontSize: "20px",
          background: "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {data.title}
      </Typography>
      <Typography
        sx={{ fontWeight: "400", fontSize: "16px", color: "#000000" }}
      >
        {data.text}
      </Typography>
    </Box>
  );
};
