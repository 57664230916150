import { Box, Typography } from "@mui/material";
import { GradientBackground, GradientText } from "../../../../components/DesignedComponents/GradientHelper";
import { DataMartSearch } from ".";

export const DataMartHero = () => {
    return (
        <GradientBackground sx={{ minHeight: '500px', height: '100%', borderRadius: '24px', background: '#000', color: '#fff' }}>
            <Box sx={{ minHeight: '500px', width: '100%', color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', borderRadius: 'inherit', p: 2 }}>
                <DataMartSearch />
                <GradientText boxStyl={{ width: { xs: '95%', md: '75%' }, textAlign: 'center' }} txt1={'Unlock Our Preconfigured '} txt2={'Bundles'} txtStyl1={{ fontSize: { xs: '28px', md: '58px' } }} txtStyl2={{ fontSize: { xs: '28px', md: '58px' } }} />
                <Typography sx={{ width: { xs: '95%', md: '55%' }, textWrap: 'wrap', textAlign: 'center' }}>Explore Our Custom-Crafted Bundles, Tailored to Meet Your Unique Requirements.
                    Our carefully designed bundles offer a perfect blend of creativity, strategy, and innovation.</Typography>
            </Box>
        </GradientBackground>
    );
}