import { createSlice } from '@reduxjs/toolkit';

export const adminDashboardSlice = createSlice({
    name: 'adminDashboard',
    initialState: {
        rawRechargeCreditsDatas: [],
    },
    reducers: {
        setRechargeCreditsDatas: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.rawRechargeCreditsDatas = action.payload; // Store the new bundle data
        },
        clearRechargeCreditsDatas: (state) => {
            state.rawRechargeCreditsDatas = []; // Clear the bundle data
        },
    },
});

// Export the actions
export const { setRechargeCreditsDatas, clearRechargeCreditsDatas } = adminDashboardSlice.actions;

// Export the reducer
export default adminDashboardSlice.reducer;
