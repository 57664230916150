import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import DateRangePicker from '../../../../../../components/Date-Picker/DatePicker';
import { UserDropDown } from '../../../../../../components/@extended/UserTypeDropDown';
import { useEffect, useState } from 'react';
import http from '../../../../../../utils/http-common';
import { useDispatch } from 'react-redux';
import { setAllUsers } from '../../../../../../slice/allBundles';

export const Frequency = ({ prop }) => {
  const { name, setName, dateRange, setDateRange, setCal, IndividualEstimatedCredits, CompanyEstimatedCredits, setEsti, Esti, userType, setUserType, user, setUser, discount, setDiscount, } = prop
  const dispatch = useDispatch();
  const [disableUT, setDisableUT] = useState(user?.user_type !== null)
  const fetchDatas = async () => {
    try {
      const response = await http.get("/master_service/admin_panel/manage_user");
      console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
      dispatch(setAllUsers(response?.data?.user_table?.filter((k) => k?.user_type !== 'admin' && k?.user_role !== 'Normal User')))
      // setTblData(response?.data?.user_table)
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
    }
    catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  useEffect(() => {
    fetchDatas()
  }, [])
  return (
    <Box
      sx={{
        minHeight: '420px',
        width: '100%',
        border: '0.8px solid #797F8F80',
        borderRadius: '4px',
        p: 3,
      }}>
      <Box>
        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
          User Type*
        </Typography>
        <RadioGroup
          row
          value={userType}
          onChange={(e) => {
            setUserType(e.target.value);
          }}
        >
          <FormControlLabel
            disabled={disableUT}
            value="individual"
            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
            label="Individual"
          />
          <FormControlLabel
            disabled={disableUT}
            value="company"
            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
            label="Company"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 1,
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', md: '35%' },
            // width: "350px",
            // my: '5px',
            // mb: 5,
          }}>
          <FormLabel
            sx={{ fontSize: '12px', fontWeight: '600', color: '#000000' }}>
            User ID/Email
          </FormLabel>
          <UserDropDown value={user}
            onChange={(e) => {
              console.log('userss', e, e?.user_type, user, userType); setUser(e);
              setUserType(e?.user_type);
              if (e?.user_type !== null) { setDisableUT(true) } else { setDisableUT(false) }
            }}
            required={true} sx={{ height: "40px", }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', md: '35%' },
            // width: "350px",
            // my: '5px',
            // mb: 5,
          }}>
          <FormLabel
            sx={{ fontSize: '12px', fontWeight: '600', color: '#000000' }}>
            Bundle Name
          </FormLabel>
          <TextField
            placeholder='Enter Bundle Name'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{ color: '#AFAFAF', fontSize: '10px', fontWeight: '600' }}
            size='small'
            InputProps={{
              sx: {
                height: '40px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#47474799',
                },
              },
            }}
          />
        </Box>
        {/* {userType === 'company' ?
          <Button
            sx={{
              background: '#8A18D0',
              color: '#fff',
              px: 4,
              // mx: 1,
              marginTop: '15px', // Adjust this value as needed
              // marginLeft: '20px',
              height: 'min-content',
              textTransform: 'capitalize',
              '&:hover': { background: '#B37FFC' },
            }}
            onClick={() => CompanyEstimatedCredits()}>
            Calculate
          </Button>
          :
          null} */}
      </Box>
      <Box sx={{ width: '100%', my: '12px' }}>
        {/* <Box sx={{ width: "750px", my: "12px" }}> */}
        {userType === 'individual' ?
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
            }}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                setDateRange={(newDateRange) => {
                  setDateRange(newDateRange);
                  setCal(false);
                  setEsti({
                    rows: 0,
                    columns: 0,
                    estimated_credit: 0,
                  });
                }}
                // allowFutureDates={true}
                highlightColor='#8A18D0'
              />
            </Box>
            {/* <Button
              sx={{
                background: '#8A18D0',
                color: '#fff',
                px: 4,
                mx: 1,
                marginTop: '25px', // Adjust this value as needed
                marginLeft: '20px',
                height: 'min-content',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}
              onClick={() => IndividualEstimatedCredits()}>
              Calculate
            </Button> */}
          </Box>
          :
          null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            mt: 2,
          }}>
          <Box>
            <FormLabel
              sx={{ fontSize: '12px', fontWeight: '600', color: '#000000' }}>
              Discount
            </FormLabel>
            <TextField
              value={discount}
              id="discount"
              type="number"
              placeholder="Discount"
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
              onChange={(event) => {
                if (event.target.value >= 0) {
                  // handleFieldChange(event)
                  setDiscount(event.target.value)
                }
              }}
              InputProps={{ sx: { height: "36px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
              sx={{ width: '100%', color: "#727272", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", }, "& input[type=number]": { MozAppearance: "textfield", }, }}
            />
          </Box>
          <Button
            sx={{
              background: '#8A18D0',
              color: '#fff',
              px: 4,
              // mx: 1,
              marginTop: '25px', // Adjust this value as needed
              marginLeft: '8px',
              // marginLeft: '20px',
              height: 'min-content',
              textTransform: 'capitalize',
              '&:hover': { background: '#B37FFC' },
            }}
            onClick={() => IndividualEstimatedCredits()}>
            Calculate
          </Button>
        </Box>
        {/* <CustomDatePicker
              dateRange={dateRange}
              handleDateChange={handleDateChange}
            /> */}
        {/* <DateRange
              editableDateInputs={true}
              onChange={item => setD([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
            /> */}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #EAECF0',
        }}>
        <Box
          sx={{
            background: '#F3E7FA',
            p: 2,
            borderBottom: '1px solid #EAECF0',
            borderRadius: '8px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}>
          <Typography
            sx={{ fontWeight: '600', fontSize: '14px', color: '#000000' }}>
            Calculation
          </Typography>
        </Box>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            Total Credit Cost
          </Typography>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {Esti.estimated_credit ? Esti.estimated_credit : 0}
          </Typography>
        </Box>
        <Box
          sx={{
            // borderBottom: '1.13px solid #E7E9EB',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            Total BundleCost
          </Typography>
          {/* <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {Esti.rows * Esti.columns ? Esti.rows * Esti.columns : 0}
          </Typography> */}
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {Esti.setup_cost ? Esti.setup_cost : 0}
          </Typography>
        </Box>
        {/* <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ fontWeight: '600', fontSize: '12px', color: '#2B2B2B' }}>
            Credits needed to run the bundle
          </Typography>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {Esti.estimated_credit ? Esti.estimated_credit : 0}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};
