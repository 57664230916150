

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import PhoneInput from "react-phone-input-2";
import { NoteComponent } from "../../../../../components/@extended/Card";
import { Country } from "../../../../../components/@extended/Country";
import { CheckoutStatus } from "./CheckoutStatus";
import http from "../../../../../utils/http-common";
import { ConvertDataMartBundles, formatPhoneNumber } from "../../../../../components/@extended/CustomFunctions";
import { Tk2 } from "../../../../../components/@extended/ReturnToken";
import { handleClick, setMessage, setSeverity } from "../../../../../slice/SnackPop";
import { BtnLoad } from "../../../../../utils/data/LandingPage/Data";
import parsePhoneNumberFromString, { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import { useRazorpayPayment } from "../../../../../components/@extended/Payment";
import { returnInvoiceGeneratingPayload } from "../../../../../utils/data/IndividualDashboard/Data";
import { GetPriceWithSymbolNoDecimal } from "../../../../../components/@extended/CurrencyConversion";
import jsPDFInvoiceTemplate from "../../../../../components/pdf-generation/Invoice";

export const CheckoutForm = ({ activeStep, setActiveStep, handleNextStep }) => {
    const [userType, setUserType] = useState("individual");
    const [user_id, setUser_Id] = useState(localStorage.getItem("user_id") || null)
    const tkn = Tk2();
    const { error, isLoading, Razorpay } = useRazorpay();
    const RazorpayPayment = useRazorpayPayment()
    // console.log('error, isLoading, Razorpay', error, isLoading, Razorpay);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [bundleId, setBundleId] = useState(null);
    const Get = useSelector((s) => s?.allbundles?.datamartBundles?.allDataMartBundles);
    const tbl = ConvertDataMartBundles(Get);

    const data = useMemo(() => tbl?.find((e) => e?.bundle_id === bundleId), [tbl, bundleId]);
    console.log('tbll', tbl, data?.finalPrice, Number(data?.finalPrice), Number(data?.finalPrice) * 100);
    const [load, setLoad] = useState(false);
    const [paymentType, setPaymentType] = useState("upi");
    const [transactionNo, setTransactionNo] = useState(null);
    const [otpVerified, setOtpVerified] = useState(2);
    const [disableFields, setDisableFields] = useState({});
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState({ label: "", code: "us" });
    const [ph, setPh] = useState("");
    const [phnChk, setPhnChk] = useState(null);
    const [phnValid, setPhnValid] = useState(null);
    const pdfInputData = returnInvoiceGeneratingPayload({})
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company: "",
        usertype: "",
        otp: "",
        phone: "",
        gst: "",
        address: "",
        upiId: "",
        cardholderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        bankName: "",
        beneficiaryName: "",
        accountNumber: "",
        ifscCode: "",
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
        validateField(id, value);
    };

    const validateField = (id, value) => {
        let error = "";
        const validations = {
            name: () => (!value || !/^[a-zA-Z\s]+$/.test(value)) && "Name must contain only alphabets and spaces.",
            email: () => (!value || !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)) && "Invalid email format.",
            usertype: () => !value && "UserType is invalid.",
            country: () => !value && "Country is invalid.",
            cardNumber: () => (!value || !/^[0-9]{16}$/.test(value)) && "Card number must be 16 digits.",
            cvv: () => (!value || !/^[0-9]{3,4}$/.test(value)) && "CVV must be 3 or 4 digits.",
            ifscCode: () => (!value || !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) && "Invalid IFSC code.",
            gst: () => (!value || !/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/.test(value)) && "Invalid GST number format.",
            upiId: () => {
                if (!value) {
                    return "UPI ID is required.";
                }

                // Common UPI ID patterns (you might want to add more or refine)
                const upiRegexes = [
                    /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/, // VPA format (e.g., abc@bank)
                    /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}\.[a-zA-Z]{2,64}$/, // VPA format (e.g., abc@bank.com)
                    /^[0-9]{10}@[a-zA-Z]{2,64}$/, // Mobile number format
                    /^[0-9]{10}@[a-zA-Z]{2,64}\.[a-zA-Z]{2,64}$/ // Mobile number format with domain
                ];
                for (const regex of upiRegexes) {
                    if (regex.test(value)) {
                        return null; // Valid UPI ID
                    }
                }
                return "Invalid UPI ID format.";
            },
        };
        if (validations?.[id]) error = validations?.[id]();
        console.log('valida', id, value, error, errors);

        setErrors((prev) => ({ ...prev, [id]: error }));
    };

    const validateFieldOnSubmit = () => {
        const fieldErrors = {};
        let isValid = true;

        if (activeStep === 0) {
            if (otpVerified !== 0) {
                dispatch(setMessage("Please Verify the OTP to continue"));
                dispatch(setSeverity("warning"));
                dispatch(handleClick());
            }
            ["name", "email", "company", "usertype", "address"].forEach((key) => {
                if (!formData[key]) {
                    fieldErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
                    isValid = false;
                }
            });
            if (!ph || phnValid || Number(phnChk) === 0) fieldErrors.ph = "Phone number must be 10 digits.";
            if (!country.label && !country.phone) fieldErrors.country = "Invalid Country Field";
        } else if (activeStep === 1) {
            if (paymentType === "upi" && !formData.upiId) {
                fieldErrors.upiId = "UPI ID is required.";
            } else if (
                (paymentType === "credit_card" || paymentType === "debit_card") &&
                (!formData.cardholderName || !formData.cardNumber || !formData.expiryDate || !formData.cvv)
            ) {
                fieldErrors.cardholderName = !formData.cardholderName ? "Cardholder Name is required." : "";
                fieldErrors.cardNumber = !formData.cardNumber ? "Card Number is required." : "";
                fieldErrors.expiryDate = !formData.expiryDate ? "Expiry Date is required." : "";
                fieldErrors.cvv = !formData.cvv ? "CVV is required." : "";
            } else if (
                paymentType === "net_banking" &&
                (!formData.bankName || !formData.beneficiaryName || !formData.accountNumber || !formData.ifscCode)
            ) {
                fieldErrors.bankName = !formData.bankName ? "Bank Name is required." : "";
                fieldErrors.beneficiaryName = !formData.beneficiaryName ? "Beneficiary Name is required." : "";
                fieldErrors.accountNumber = !formData.accountNumber ? "Account Number is required." : "";
                fieldErrors.ifscCode = !formData.ifscCode ? "IFSC Code is required." : "";
            }
        }

        setErrors(fieldErrors);
        if (Object.keys(fieldErrors).length === 0) {
            if (activeStep === 0) UserDetails();
            else if (activeStep === 1) SaveBundle();
        }
    };
    const handleSubmit = () => {
        const fieldErrors = {};
        let isValid = true;

        if (activeStep === 0) {
            if (otpVerified !== 0) {
                fieldErrors.otpVerified = `OTP Verification is required.`;
                isValid = false;
                dispatch(setMessage("Please Verify the OTP to continue"));
                dispatch(setSeverity("warning"));
                dispatch(handleClick());
            }
            ["name", "email", "usertype", "address"].forEach((key) => {
                if (!formData[key]) {
                    fieldErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
                    isValid = false;
                }
            });
            console.log('sumb', formData.gst !== null, (formData.gst !== null || (formData.gst.length !== 0 && formData.gst.length !== undefined)));

            if (formData.usertype === 'company' && !formData.company) {
                fieldErrors.company = `Company is required.`;
                isValid = false;
            }
            if (
                formData.gst
                &&
                !/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/.test(formData.gst)
            ) {
                console.log('Invalid GST')
                fieldErrors.gst = "Invalid GST number format.";
                isValid = false;
            }
            if (!ph || phnValid || Number(phnChk) === 0) fieldErrors.ph = "Phone number must be 10 digits.";
            if (!country.label && !country.phone) fieldErrors.country = "Invalid Country Field";
        } else if (activeStep === 1) {
            if (paymentType === "upi") {
                if (!formData.upiId) {
                    fieldErrors.upiId = "UPI ID is required.";
                } else {
                    const upiRegexes = [
                        /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/, // VPA format (e.g., abc@bank)
                        /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}\.[a-zA-Z]{2,64}$/, // VPA format (e.g., abc@bank.com)
                        /^[0-9]{10}@[a-zA-Z]{2,64}$/, // Mobile number format
                        /^[0-9]{10}@[a-zA-Z]{2,64}\.[a-zA-Z]{2,64}$/ // Mobile number format with domain
                    ];
                    let isValid = false;
                    for (const regex of upiRegexes) {
                        if (regex.test(formData.upiId)) {
                            isValid = true; // Set flag to true if a match is found
                            break; // Exit the loop as soon as a match is found
                        }
                    }
                    if (!isValid) {
                        fieldErrors.upiId = "Invalid UPI ID format.";
                    }
                }
            } else if (
                (paymentType === "credit_card" || paymentType === "debit_card") &&
                (!formData.cardholderName || !formData.cardNumber || !formData.expiryDate || !formData.cvv)
            ) {
                fieldErrors.cardholderName = !formData.cardholderName ? "Cardholder Name is required." : "";
                fieldErrors.cardNumber = !formData.cardNumber ? "Card Number is required." : "";
                fieldErrors.expiryDate = !formData.expiryDate ? "Expiry Date is required." : "";
                fieldErrors.cvv = !formData.cvv ? "CVV is required." : "";
            } else if (
                paymentType === "net_banking" &&
                (!formData.bankName || !formData.beneficiaryName || !formData.accountNumber || !formData.ifscCode)
            ) {
                fieldErrors.bankName = !formData.bankName ? "Bank Name is required." : "";
                fieldErrors.beneficiaryName = !formData.beneficiaryName ? "Beneficiary Name is required." : "";
                fieldErrors.accountNumber = !formData.accountNumber ? "Account Number is required." : "";
                fieldErrors.ifscCode = !formData.ifscCode ? "IFSC Code is required." : "";
            }
        }

        setErrors(fieldErrors);
        if (Object.keys(fieldErrors).length === 0) {
            if (activeStep === 0) { UserDetails(); }
            // else if (activeStep === 1) SaveBundle();
        }
    };


    const UserDetails = async () => {
        setLoad(true);
        try {
            const response = await http.post(
                `master_service/profile_update/${user_id}`,
                JSON.stringify({
                    user_first_name: formData.name,
                    user_country: country.label,
                    user_phone_number: ph,
                    user_type: formData.usertype,
                    user_company_name: formData.company,
                    gst_number: formData.gst,
                    billing_adress: formData.address,
                })
            );
            SaveBundle()
            setLoad(false);
            // handleNextStep();
        } catch (error) {
            setLoad(false);
            dispatch(setMessage(error.response.data.message));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
        }
    };

    const ActivateBundle = async () => {
        try {
            const response = await http.post("/master_service/admin_panel/update_status", JSON.stringify({ ind_bundle_id: bundleId, bundle_status: 'Active' }));
            console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
            // setTblData(response?.data?.user_table)
            // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            // setTimeout(() => {
            //     navigate('/login')
            // }, 2000);
            setLoad(false);
            handleNextStep();
            handleNextStep();
        }
        catch (error) {
            console.error("Error changing status:", error)
            setLoad(false);
        }
    }
    const TransactionApiTrigger = async (k) => {
        try {
            // pdfInputData.outputType = 'save'
            const pdf = jsPDFInvoiceTemplate(pdfInputData)
            // return;
            const mime = "application/pdf";
            const file = new File([pdf?.blob], "abc.pdf", { type: mime });
            console.log('pdf', k, pdf);

            const formData = new FormData();
            formData.append('user_id', user_id);
            formData.append('ind_bundle_id', bundleId);
            // formData.append('plan_name', plan_name);
            formData.append('payment_id', k?.id);
            formData.append('invoice_no', k?.invoice_no);
            formData.append('payment_type', 'online');
            formData.append('payment_date', new Date());
            formData.append('bundle_cost', Number(data?.finalPrice));
            formData.append('payment_confirmation_status', k?.payment_confirmation_status)
            // formData.append('payment_confirmation_status', k?.payment_confirmation_status)
            formData.append('filename', data?.bundleName);
            if (k?.id !== null) { formData.append('pdf', file); }
            const response = await http.post(
                "master_service/payment/details", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the correct content type
                },
                // JSON.stringify({
                //     user_id: Number(user_id),
                //     ind_bundle_id: Number(bundleId),
                //     plan_name: Number(plan_name),
                //     // payment_type: paymentType,
                //     payment_id: k?.razorpay_payment_id,
                //     payment_date: new Date(),
                //     // bundle_cost: Number(data?.finalIndPrice),
                //     bundle_cost: Number(price),
                //     payment_confirmation_status: "success",
                // })
            });
            console.log('res', response, response?.data?.data?.payment?.order_number, response?.data?.data?.payment?.transaction_number);
            if (k?.payment_confirmation_status === 'success') {
                dispatch(setMessage(response.data.message || "Error with save bundle"));
                dispatch(setSeverity("success"));
                setTransactionNo(response?.data?.data?.payment?.transaction_number);
                // setTransaction_no(response?.data?.data?.payment?.transaction_number);
                ActivateBundle()
            } else {
                dispatch(setMessage(k?.errorMessage || "Error with purchasing bundle"));
                dispatch(setSeverity("error"));
            }
            dispatch(handleClick());
        } catch (error) {
            console.log('err', error);
            setLoad(false);
            dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle'));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
            // setTimeout(() => {
            //     navigate('/login')
            // }, 2000);
        }
    }
    const InvoiceNoTrigger = async (k) => {
        try {
            const response = await http.post("/master_service/invoice", JSON.stringify({ user_id: user_id }));
            console.log("response tbl manage_user:", response, response?.data, response?.data?.data?.invoice_no);
            // pdfInputData.invoice.additionalRows[0].col1 = undefined
            // pdfInputData.invoice.additionalRows[0].col2 = 'Total'
            pdfInputData.invoice.additionalRows[0].col3 = String(GetPriceWithSymbolNoDecimal(Math.round(data?.finalPrice)))
            pdfInputData.invoice.num = response?.data?.data?.invoice_no
            pdfInputData.contact.name = response?.data?.data?.user_details?.user_first_name
            pdfInputData.contact.address = response?.data?.data?.user_details?.billing_address
            pdfInputData.contact.phone = formatPhoneNumber(String(response?.data?.data?.user_details?.phone_number))
            pdfInputData.contact.email = response?.data?.data?.user_details?.email
            pdfInputData.invoice.header = [{ title: "Sl No", style: { width: 17, } }, { title: "Bundle Name", style: {} }, { title: "Bundle Setup Cost", style: { align: "right", } }, { title: "Bundle Cost", style: { align: "right", } },]
            pdfInputData.invoice.table = [[1, data?.bundleName, Number(Math.round(data?.estimated_credits)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(data?.finalPrice)))]]
            // setTblData(response?.data?.user_table)
            // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            TransactionApiTrigger({ ...k, invoice_no: response?.data?.data?.invoice_no })
        }
        catch (error) {
            console.error("Error changing status:", error)
        }
    }
    const SaveBundle = async () => {
        setLoad(true);
        // InvoiceNoTrigger()
        RazorpayPayment({ amount: data?.finalPrice, handler: (e) => { InvoiceNoTrigger({ id: e?.razorpay_payment_id, payment_confirmation_status: 'success' }); }, ondismiss: () => { setLoad(false); }, onError: () => { }, onPaymentFailed: ({ res, errorMessage }) => { TransactionApiTrigger({ id: null, payment_confirmation_status: 'failed', errorMessage: errorMessage }) }, })
    }
    const SaveBundle2 = async () => {
        const currency = localStorage.getItem('currency');
        setLoad(true);
        const razorpayOptions = {
            key: 'rzp_test_Ir3RqpkKv3LiIt', // Replace with your Razorpay Key ID
            // key_secret: 'O3nI73DRVT66p1CO3H0DttzQ',
            // amount: Number(data?.finalPriceWithSymbol) * 100, // Amount in paise
            amount: Number(data?.finalPrice) * 100, // Amount in paise
            currency: String(currency).toUpperCase(),
            name: 'Brand Aura',
            description: 'Test Transaction',
            // order_id: response?.data?.data?.order_number,
            handler: async function (k) {
                console.log('Payment successful:', k);
                // alert('Payment successful!');
                try {
                    const response = await http.post(
                        "master_service/payment/details",
                        JSON.stringify({
                            user_id: Number(user_id),
                            ind_bundle_id: Number(bundleId),
                            // payment_type: paymentType,
                            payment_id: k?.razorpay_payment_id,
                            payment_date: new Date(),
                            // bundle_cost: Number(data?.finalIndPrice),
                            bundle_cost: Number(data?.finalPrice),
                            payment_confirmation_status: "success",
                        })
                    );
                    console.log('res', response, response?.data?.data?.payment?.order_number, response?.data?.data?.payment?.transaction_number);

                    setLoad(false);
                    setTransactionNo(response?.data?.data?.payment?.transaction_number);
                    handleNextStep();
                    handleNextStep();
                    // Add specific configurations for certain methods
                    // if (paymentType === "credit_card" || paymentType === "debit_card") {
                    //     razorpayOptions.card = {
                    //         number: cardNumber,
                    //         expiry_month: expiryDate.split('/')[0],
                    //         expiry_year: expiryDate.split('/')[1],
                    //         cvv: cvv,
                    //     };
                    // } else if (paymentType === 'upi') {
                    //     razorpayOptions.upi = { upi: formData.upiId } // Example UPI ID
                    // } else if (paymentType === 'netbanking') {
                    //     razorpayOptions.netbanking = {
                    //         bankName: formData.bankName,
                    //         beneficiaryName: formData.beneficiaryName,
                    //         accountNumber: formData.accountNumber,
                    //         ifscCode: formData.ifscCode,
                    //     }
                    // }

                    // Open Razorpay's secure checkout
                    // const rzp = new window.Razorpay(razorpayOptions);
                    dispatch(setMessage(response.data.message || "Error with save bundle"));
                    dispatch(setSeverity("success"));
                    dispatch(handleClick());
                } catch (error) {
                    setLoad(false);
                    console.log('err', error);
                    dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle'));
                    dispatch(setSeverity("error"));
                    dispatch(handleClick());
                }
            },
            prefill: {
                // name: 'John Doe',
                // email: 'john@example.com',
                // contact: '9999999999',
            },
            notes: {
                address: 'Razorpay Corporate Office',
            },
            theme: {
                color: '#3399cc',
            },
            // method: paymentType === "credit_card" || paymentType === "debit_card" ? 'card' : paymentType === 'netbanking' ? 'netbanking' : paymentType, // Dynamically set the payment method
        };
        const rzp = new Razorpay(razorpayOptions);
        rzp.open()
        rzp.on('payment.failed', async function (res) {
            // Log the error details
            console.error('Payment failed:', res);

            // Extract error details from the response
            const { error } = res;
            const errorMessage = error?.description || 'Payment failed. Please try again.';

            // Display an error message to the user
            // alert(errorMessage);

            // Optionally, log the error for debugging
            // console.error('Error code:', error?.code);
            // console.error('Error source:', error?.source);
            // console.error('Error reason:', error?.reason);
            try {
                const response = await http.post(
                    "master_service/payment/details",
                    JSON.stringify({
                        user_id: Number(user_id),
                        ind_bundle_id: Number(bundleId),
                        // payment_type: paymentType,
                        payment_date: new Date(),
                        bundle_cost: Number(data?.finalPrice),
                        payment_confirmation_status: "failed",
                    })
                );
                console.log('res', response, response?.data?.data?.order_number);

                setLoad(false);
                // Add specific configurations for certain methods
                // if (paymentType === "credit_card" || paymentType === "debit_card") {
                //     razorpayOptions.card = {
                //         number: cardNumber,
                //         expiry_month: expiryDate.split('/')[0],
                //         expiry_year: expiryDate.split('/')[1],
                //         cvv: cvv,
                //     };
                // } else if (paymentType === 'upi') {
                //     razorpayOptions.upi = { upi: formData.upiId } // Example UPI ID
                // } else if (paymentType === 'netbanking') {
                //     razorpayOptions.netbanking = {
                //         bankName: formData.bankName,
                //         beneficiaryName: formData.beneficiaryName,
                //         accountNumber: formData.accountNumber,
                //         ifscCode: formData.ifscCode,
                //     }
                // }

                // Open Razorpay's secure checkout
                // const rzp = new window.Razorpay(razorpayOptions);
                dispatch(setMessage(errorMessage || "Error with purchasing bundle"));
                dispatch(setSeverity("failed"));
                dispatch(handleClick());
            } catch (error) {
                setLoad(false);
                console.log('err', error);
                dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle Saving api'));
                dispatch(setSeverity("error"));
                dispatch(handleClick());
            }
        });


    };

    const VerifyOtp = async () => {
        if (activeStep === 0) {
            const fieldErrors = {};
            let isValid = true;
            if (!formData.email || !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)) {
                fieldErrors.email = "Invalid email format.";
                isValid = false;
            }
            ["email", "otp"].forEach((key) => {
                if (!formData[key]) {
                    fieldErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
                    isValid = false;
                }
            });
            setErrors(fieldErrors);
            if (!isValid) return;
        }
        setLoad(true);
        try {
            const response = await http.post(
                "auth_service/otpverification",
                JSON.stringify({
                    user_email: formData.email,
                    otp: formData.otp,
                })
            );
            const datas = {
                user_id: response.data.user_id,
                user_type: response.data.user_details.user_type,
                user_name: response.data.user_details.user_first_name,
                user_company_name: response.data.user_details.user_company_name,
                email: response.data.user_details.user_email,
                phn: response.data.user_details.user_phone_number,
                country: response.data.user_details.user_country,
            };
            if (datas.user_id) {
                setUser_Id(datas.user_id);
            }
            if (datas.user_type) {
                setFormData((p) => ({ ...p, usertype: datas.user_type }));
                setDisableFields((p) => ({ ...p, usertype: true }));
            }
            if (datas.user_name) {
                setFormData((p) => ({ ...p, name: datas.user_name }));
                setDisableFields((p) => ({ ...p, name: true }));
            }
            if (datas.user_company_name) {
                setFormData((p) => ({ ...p, company: datas.user_company_name }));
                setDisableFields((p) => ({ ...p, company: true }));
            }
            if (datas.email) {
                setFormData((p) => ({ ...p, email: datas.email }));
                setDisableFields((p) => ({ ...p, email: true, otp: true }));
                setOtpVerified(0);
            }
            if (datas.phn) {
                setPh(datas.phn);
                setDisableFields((p) => ({ ...p, phn: true }));
                setPhnValid(false);
                setPhnChk(2);
            }
            if (datas.country) {
                setCountry({ label: datas.country });
                setDisableFields((p) => ({ ...p, country: true }));
            }
            setOtpVerified(0);
            setDisableFields((p) => ({ ...p, email: true, otp: true }));
            setLoad(false);
            dispatch(setMessage(response.data.message || "Otp Send to the user"));
            dispatch(setSeverity("success"));
            dispatch(handleClick());
            setFormData((p) => ({ ...p, otp: "" }));
            // localStorage.setItem("user_id", datas.user_id);
            // localStorage.setItem("user_type", datas.user_type);
            // localStorage.setItem("user_name", datas.user_name);
            // localStorage.setItem("user_company_name", datas.user_company_name);
            // localStorage.setItem("email", datas.email);
            // localStorage.setItem("phn", datas.phn);
            // localStorage.setItem("country", datas.country);
        } catch (error) {
            setLoad(false);
            dispatch(setMessage(error.response.data.message));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
        }
    };

    const ResendOtp = async () => {
        try {
            const response = await http.post(
                "auth_service/Resend-OTP",
                JSON.stringify({
                    user_email: formData.email,
                })
            );
            dispatch(setMessage(response.data.message));
            dispatch(setSeverity("success"));
            dispatch(handleClick());
            setFormData((p) => ({ ...p, otp: "" }));
        } catch (error) {
            dispatch(setMessage(error.response.data.message));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
        }
    };

    const SendMail = async () => {
        if (activeStep === 0) {
            const fieldErrors = {};
            let isValid = true;
            if (!formData.email || !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)) {
                fieldErrors.email = "Invalid email format.";
                isValid = false;
            }
            ["email"].forEach((key) => {
                if (!formData[key]) {
                    fieldErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
                    isValid = false;
                }
            });
            setErrors(fieldErrors);
            if (!isValid) return;
        }
        setLoad(true);
        try {
            const response = await http.post(
                "auth_service/Registeration",
                JSON.stringify({
                    user_email: formData.email,
                })
            );
            setOtpVerified(1);
            setLoad(false);
            dispatch(setMessage(response.data.message || "Otp Send to the user"));
            dispatch(setSeverity("success"));
            dispatch(handleClick());
            setFormData((p) => ({ ...p, otp: "" }));
        } catch (error) {
            setLoad(false);
            dispatch(setMessage(error.response.data.message));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
        }
    };

    const getPopulate = () => {
        const datas = {
            user_type: localStorage.getItem("user_type"),
            user_name: localStorage.getItem("user_name"),
            user_company_name: localStorage.getItem("user_company_name"),
            email: localStorage.getItem("email"),
            phn: localStorage.getItem("phn"),
            country: localStorage.getItem("country"),
        };
        if (datas.user_type === 'admin') {
            return;
        }
        if (datas.user_type) {
            setFormData((p) => ({ ...p, usertype: datas.user_type }));
            setDisableFields((p) => ({ ...p, usertype: true }));
        }
        if (datas.user_name) {
            setFormData((p) => ({ ...p, name: datas.user_name }));
            setDisableFields((p) => ({ ...p, name: true }));
        }
        if (datas.user_company_name && datas.user_company_name !== null && datas.user_company_name !== 'null') {
            setFormData((p) => ({ ...p, company: datas.user_company_name }));
            setDisableFields((p) => ({ ...p, company: true }));
        }
        if (datas.email) {
            setFormData((p) => ({ ...p, email: datas.email }));
            setDisableFields((p) => ({ ...p, email: true, otp: true }));
            setOtpVerified(0);
        }
        if (datas.phn) {
            setPh(datas.phn);
            setDisableFields((p) => ({ ...p, phn: true }));
            setPhnValid(false);
            setPhnChk(2);
        }
        if (datas.country) {
            setCountry({ label: datas.country });
            setDisableFields((p) => ({ ...p, country: true }));
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("bundle_id")) {
            setBundleId(params.get("bundle_id"));
        }
    }, [location]);

    useEffect(() => {
        if (tkn) {
            getPopulate();
        }
    }, [tkn]);

    return (
        <Box
            component="form"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 4,
                // border: "1px solid #ddd",
                borderRadius: 2,
                backgroundColor: "#fff",
                height: "100%",
            }}
        >
            <CheckoutStatus activeStep={activeStep} />

            {activeStep === 0 && (
                <>
                    <Box>
                        <NoteComponent prop={{ txt: "If you are not a brand aura user, we will register you automatically." }} />
                        <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, width: "100%", mt: 2, mb: 2 }}>
                            <Box sx={{ width: "100%", pt: { xs: 1, md: 0 }, }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Email ID*
                                </Typography>
                                <TextField
                                    disabled={disableFields.email}
                                    id="email"
                                    variant="outlined"
                                    placeholder="Enter Email ID"
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    InputProps={{ sx: { height: "36px" } }}
                                    sx={{ height: "36px", p: 0 }}
                                />
                            </Box>
                            <Box sx={{ px: { xs: 0, md: 1 }, pt: { xs: 1, md: 0 }, }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    OTP*
                                </Typography>
                                <TextField
                                    disabled={disableFields.otp}
                                    id="otp"
                                    variant="outlined"
                                    placeholder="Enter OTP"
                                    fullWidth
                                    value={formData.otp}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            handleInputChange(e);
                                        }
                                    }}
                                    error={!!errors.otp}
                                    helperText={errors.otp}
                                    InputProps={{ sx: { height: "36px" } }}
                                    sx={{ height: "36px", p: 0 }}
                                />
                            </Box>
                            <Box sx={{ pt: 0.5 }}>
                                <Button
                                    disabled={disableFields.otp}
                                    variant="contained"
                                    onClick={() => {
                                        if (otpVerified === 2) SendMail();
                                        else if (otpVerified === 1) VerifyOtp();
                                    }}
                                    sx={{
                                        mt: 3,
                                        backgroundColor: "#8A18D0",
                                        textTransform: "capitalize",
                                        minWidth: "max-content",
                                        minWidth: { xs: '100%', md: "max-content" },
                                        minHeight: "max-content",
                                        height: "36px",
                                        ":hover": { backgroundColor: "#6F13AA" },
                                    }}
                                >
                                    {otpVerified === 2 || otpVerified === 0 ? "Send OTP" : "Verify OTP"}
                                    {load && otpVerified !== 0 && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                bgcolor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "4px",
                                                zIndex: 1,
                                            }}
                                        >
                                            <Box sx={BtnLoad} />
                                        </Box>
                                    )}
                                </Button>
                                {otpVerified === 1 && (
                                    <Button
                                        disableFocusRipple
                                        disableTouchRipple
                                        sx={{
                                            textTransform: "capitalize",
                                            color: "#474747",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                        onClick={ResendOtp}
                                    >
                                        Resend Code?
                                    </Button>
                                )}
                            </Box>
                        </Box>

                        <Box>
                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                User Type*
                            </Typography>
                            <RadioGroup
                                row
                                value={formData.usertype}
                                onChange={(e) => {
                                    setFormData((prev) => ({ ...prev, usertype: e.target.value }));
                                    validateField("usertype", e.target.value);
                                }}
                            >
                                <FormControlLabel
                                    disabled={disableFields.usertype}
                                    value="individual"
                                    control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                                    label="Individual"
                                />
                                <FormControlLabel
                                    disabled={disableFields.usertype}
                                    value="company"
                                    control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                                    label="Company"
                                />
                            </RadioGroup>
                            {errors.usertype && (
                                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "12px" }}>
                                    {errors.usertype}
                                </Typography>
                            )}
                        </Box>

                        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                            Name*
                        </Typography>
                        <TextField
                            disabled={disableFields.name}
                            id="name"
                            variant="outlined"
                            placeholder="Enter Name"
                            fullWidth
                            value={formData.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            InputProps={{ sx: { height: "36px" } }}
                            sx={{ height: "36px", p: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
                        <Box sx={{ width: "100%", pr: { xs: 0, md: 1 } }}>
                            <FormLabel sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                Country*
                            </FormLabel>
                            <Country
                                country={country}
                                setCountry={setCountry}
                                required={true}
                                sx={{ height: "40px" }}
                                error={!!errors.country}
                                helperText={errors.country}
                                disabled={disableFields.country}
                            />
                            {errors.country && (
                                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "12px" }}>
                                    {errors.country}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <FormLabel sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                Phone Number*
                            </FormLabel>
                            <PhoneInput
                                disabled={disableFields.phn}
                                country={country?.code?.toLowerCase() || "us"}
                                value={ph}
                                onChange={(value, data, event, formattedValue) => {
                                    const ogphn = value.toString().substring(formattedValue.split(" ")[0].length - 1);
                                    const f = parsePhoneNumberFromString(String(event?.target?.value));
                                    const requiredLength = getExampleNumber(f?.country, examples)?.nationalNumber?.length;
                                    const currentLength = ogphn?.length;
                                    setPhnChk(value.toString().substring(formattedValue.split(" ")[0].length - 1));
                                    setPhnValid(requiredLength !== currentLength);
                                    setPh(value);
                                }}
                                countryCodeEditable={false}
                                containerStyle={{ height: "40px", padding: 0, borderColor: "#0000003b" }}
                                inputStyle={{
                                    width: "100%",
                                    height: "40px",
                                    color: "#000",
                                    border: `1px solid ${!errors.ph ? "#D0D5DD" : "#d32f2f"}`,
                                    background: "#fff",
                                }}
                                buttonStyle={{ background: "transparent" }}
                                isValid={!errors.ph}
                                inputProps={{ required: true }}
                            />
                            {errors.ph && (
                                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "12px" }}>
                                    {errors.ph}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                            Company{formData.usertype === 'company' ? '*' : ''}
                        </Typography>
                        <TextField
                            disabled={disableFields.company}
                            id="company"
                            variant="outlined"
                            placeholder="Enter Company Name"
                            fullWidth
                            value={formData.company}
                            onChange={handleInputChange}
                            error={!!errors.company}
                            helperText={errors.company}
                            InputProps={{ sx: { height: "36px" } }}
                            sx={{ height: "36px", p: 0 }}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                            GST Number
                        </Typography>
                        <TextField
                            id="gst"
                            variant="outlined"
                            placeholder="Enter GST Number"
                            fullWidth
                            value={formData.gst}
                            onChange={(e) => {
                                if (e.target.value?.length < 16) {
                                    handleInputChange(e)
                                }
                            }}
                            error={!!errors.gst}
                            helperText={errors.gst}
                            InputProps={{ sx: { height: "36px" } }}
                            sx={{ height: "36px", p: 0 }}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                            Billing Address*
                        </Typography>
                        <TextField
                            id="address"
                            variant="outlined"
                            placeholder="Enter Address"
                            fullWidth
                            multiline
                            rows={3}
                            value={formData.address}
                            onChange={handleInputChange}
                            error={!!errors.address}
                            helperText={errors.address}
                        />
                    </Box>
                </>
            )}

            {activeStep === 1 && (
                <>
                    <NoteComponent prop={{ txt: "After successful purchase, the transaction cannot be reversed." }} />
                    <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 500 }}>
                        Payment Type*
                    </Typography>
                    <RadioGroup row value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                        <FormControlLabel
                            value="upi"
                            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                            label="UPI"
                        />
                        <FormControlLabel
                            value="credit_card"
                            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                            label="Credit Card"
                        />
                        <FormControlLabel
                            value="debit_card"
                            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                            label="Debit Card"
                        />
                        <FormControlLabel
                            value="net_banking"
                            control={<Radio sx={{ color: "#8A18D0", "&.Mui-checked": { color: "#8A18D0" } }} />}
                            label="Net Banking"
                        />
                    </RadioGroup>

                    {paymentType === "upi" && (
                        <>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    UPI ID*
                                </Typography>
                                <TextField
                                    id="upiId"
                                    variant="outlined"
                                    placeholder="Enter UPI ID"
                                    fullWidth
                                    value={formData.upiId}
                                    onChange={handleInputChange}
                                    error={!!errors.upiId}
                                    helperText={errors.upiId}
                                />
                            </Box>
                            {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="body1" sx={{ fontWeight: 500, color: "#666" }}>
                                    or
                                </Typography>
                            </Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                Select payment option
                            </Typography>
                            <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "none",
                                        borderColor: "#797F8F",
                                        color: "#000000",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Google_Pay_Logo.svg"
                                        alt="Google Pay"
                                        style={{ width: 24, height: 24 }}
                                    />
                                    Google Pay
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "none",
                                        borderColor: "#797F8F",
                                        color: "#000000",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/PhonePe_Logo.png"
                                        alt="PhonePe"
                                        style={{ width: 24, height: 24 }}
                                    />
                                    PhonePe
                                </Button>
                            </Box> */}
                        </>
                    )}

                    {(paymentType === "credit_card" || paymentType === "debit_card") && (
                        <>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Cardholder Name*
                                </Typography>
                                <TextField
                                    id="cardholderName"
                                    variant="outlined"
                                    placeholder="Enter Cardholder Name"
                                    fullWidth
                                    value={formData.cardholderName}
                                    onChange={handleInputChange}
                                    error={!!errors.cardholderName}
                                    helperText={errors.cardholderName}
                                />
                            </Box>

                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Card Number*
                                </Typography>
                                <TextField
                                    id="cardNumber"
                                    variant="outlined"
                                    placeholder="Enter Card Number"
                                    fullWidth
                                    value={formData.cardNumber}
                                    onChange={handleInputChange}
                                    error={!!errors.cardNumber}
                                    helperText={errors.cardNumber}
                                />
                            </Box>

                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                        Expiry Date*
                                    </Typography>
                                    <TextField
                                        id="expiryDate"
                                        variant="outlined"
                                        placeholder="MM/YY"
                                        fullWidth
                                        value={formData.expiryDate}
                                        onChange={handleInputChange}
                                        error={!!errors.expiryDate}
                                        helperText={errors.expiryDate}
                                    />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                        CVV Number*
                                    </Typography>
                                    <TextField
                                        id="cvv"
                                        variant="outlined"
                                        placeholder="Enter CVV"
                                        type="password"
                                        fullWidth
                                        value={formData.cvv}
                                        onChange={(e) => {
                                            if (e.target.value?.length < 5) {
                                                handleInputChange(e)
                                            }
                                        }}
                                        error={!!errors.cvv}
                                        helperText={errors.cvv}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}

                    {paymentType === "net_banking" && (
                        <>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Bank Name*
                                </Typography>
                                <TextField
                                    id="bankName"
                                    variant="outlined"
                                    placeholder="Enter Bank Name"
                                    fullWidth
                                    value={formData.bankName}
                                    onChange={handleInputChange}
                                    error={!!errors.bankName}
                                    helperText={errors.bankName}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Beneficiary Name*
                                </Typography>
                                <TextField
                                    id="beneficiaryName"
                                    variant="outlined"
                                    placeholder="Enter Beneficiary Name"
                                    fullWidth
                                    value={formData.beneficiaryName}
                                    onChange={handleInputChange}
                                    error={!!errors.beneficiaryName}
                                    helperText={errors.beneficiaryName}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    Account Number*
                                </Typography>
                                <TextField
                                    id="accountNumber"
                                    variant="outlined"
                                    placeholder="Enter Account Number"
                                    fullWidth
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                    error={!!errors.accountNumber}
                                    helperText={errors.accountNumber}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#000", mb: 1 }}>
                                    IFSC Code*
                                </Typography>
                                <TextField
                                    id="ifscCode"
                                    variant="outlined"
                                    placeholder="Enter IFSC Code"
                                    fullWidth
                                    value={formData.ifscCode}
                                    onChange={handleInputChange}
                                    error={!!errors.ifscCode}
                                    helperText={errors.ifscCode}
                                />
                            </Box>
                        </>
                    )}
                </>
            )}

            {activeStep === 2 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
                    <Box sx={{ textAlign: "center", pt: 10 }}>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "28px",
                                fontWeight: 600,
                                lineHeight: "33.89px",
                                textAlign: "center",
                                textDecoration: "none",
                            }}
                        >
                            Thank you for purchase
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24.2px",
                                textAlign: "center",
                                textDecoration: "none",
                            }}
                        >
                            Transaction ID: {transactionNo}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24.2px",
                                textAlign: "center",
                                textDecoration: "none",
                            }}
                        >
                            You have successfully purchased the bundle
                        </Typography>
                    </Box>
                </Box>
            )}

            {activeStep < 2 && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                    <Button
                        variant="contained"
                        sx={{
                            width: "100%",
                            mr: 1,
                            backgroundColor: "transparent",
                            border: "1px solid #47474799",
                            textTransform: "capitalize",
                            color: "#47474799",
                            ":hover": { backgroundColor: "transparent" },
                        }}
                        fullWidth
                        onClick={() => setActiveStep((p) => (p >= 1 ? p - 1 : p))}
                        disabled={activeStep <= 1}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            width: "100%",
                            backgroundColor: "#8A18D0",
                            textTransform: "capitalize",
                            ":hover": { backgroundColor: "#6F13AA" },
                        }}
                        fullWidth
                        onClick={handleSubmit}
                    // disabled={activeStep === 1 && isLoading}
                    >
                        {activeStep === 0 ? "Next" : "Purchase Bundle"}
                        {load && otpVerified === 0 && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    bgcolor: "rgba(0, 0, 0, 0.5)",
                                    borderRadius: "4px",
                                    zIndex: 1,
                                }}
                            >
                                <Box sx={BtnLoad} />
                            </Box>
                        )}
                    </Button>
                </Box>
            )}
        </Box>
    );
};


