import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Snackbar,
    TextField,
    Typography,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../../../utils/http-common";
import { PasswordSvg, TickCircleSolid, } from "../../../../utils/icon/LandingPage/Icon";
import { Carousel_OG } from "../Components/Carousel";
import { PasswordModel } from "../Components/Model";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Logo } from "../../../../assets/logo";
import { BtnLoad } from "../../../../utils/data/LandingPage/Data";
import { useTimer } from "../../../../components/Timer";
import { Analytics } from "../../../../Analytics";

export const SetPassword = () => {
    const [userMail, setUserMail] = useState("");
    const [load, setLoad] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);
    const [otp, setOtp] = useState("");
    const [verified, setVerified] = useState(false);
    const [screen, setScreen] = useState(1);
    const [newPassword, setNewPassword] = useState("");
    const [showPassword1, setShowPassword1] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword2, setShowPassword2] = useState(false);
    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    const expiryTimestamp = time;
    const {
        seconds,
        minutes,
        hours,
        days,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => { console.warn('onExpire called'); setDisableRt(false) }, autoStart: false });
    // const [timBreak, setTimBreak] = useState(false);
    const intervalRef = useRef(null);
    const [disableRt, setDisableRt] = useState(true);
    const [timeProps, setTimeProps] = useState({
        initialMinute: 2,
        initialSeconds: 0,
        setDisableRt: setDisableRt,
    });
    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    // const Timer = async () => {
    //   if (intervalRef.current) {
    //     clearInterval(intervalRef.current); // Clear any existing interval
    //   }

    //   // Start a new countdown
    //   intervalRef.current = setInterval(() => {
    //     setSeconds((prevSeconds) => {
    //       if (prevSeconds === 0 && minutes > 0) {
    //         setMinutes((prevMinutes) => prevMinutes - 1);
    //         return 59;
    //       }

    //       if (prevSeconds === 0 && minutes === 0) {
    //         clearInterval(intervalRef.current); // Stop the timer
    //         setDisableRt(false); // Enable the Resend OTP button
    //         return 0;
    //       }
    //       if (prevSeconds > 0) return prevSeconds - 1;

    //       return prevSeconds;
    //     });
    //   }, 1000);

    //   // Clean up interval when component unmounts or when props change
    //   return () => {
    //     clearInterval(intervalRef.current);
    //   };
    // };
    const handleMailChange = (e) => {
        setUserMail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Email validation
        if (!userMail) {
            tempErrors.userMail = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(userMail)) {
            tempErrors.userMail = "Email address is invalid.";
            isValid = false;
        }
        setErrors(tempErrors);
        return isValid;
    };
    const handleSubmit = () => {
        if (validateForm()) {
            Forgot_Password_1();
        }
        // else {
        //   // setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };
    const OtpvalidateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Name validation
        if (!otp) {
            tempErrors.otp = "Enter Otp";
            isValid = false;
        }
        // Password validation
        if (
            confirmPassword.length < 8 ||
            !/[A-Z]/.test(confirmPassword) ||
            !/[a-z]/.test(confirmPassword) ||
            !/[0-9]/.test(confirmPassword) ||
            !/[!@#$%^&*]/.test(confirmPassword)
        ) {
            tempErrors.confirmPassword = "Enter valid password";
            isValid = false;
        }
        if (
            newPassword.length < 8 ||
            !/[A-Z]/.test(newPassword) ||
            !/[a-z]/.test(newPassword) ||
            !/[0-9]/.test(newPassword) ||
            !/[!@#$%^&*]/.test(newPassword)
        ) {
            tempErrors.newPassword = "Enter valid password";
            isValid = false;
        }

        if (newPassword !== confirmPassword) {
            tempErrors.confirmPassword = 'Enter the Same Password';
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const ChangePassword = () => {
        if (OtpvalidateForm()) {
            Forgot_Password_2();
        }
        // else {
        //   setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };
    const Forgot_Password_1 = async () => {
        setLoad(true)
        setOtpLoad(true)
        try {
            // const response = await http.post('/forgot/password', JSON.stringify({
            const response = await http.post(
                "auth_service/forgot/password",
                JSON.stringify({
                    user_email: userMail,
                    // newPassword: newPassword,
                    // confirmPassword: confirmPassword
                })
            );
            setLoad(false)
            setOtpLoad(false)

            console.log("res", response);

            if (response.status === 200) {
                // setScreen(2);
                setMessage(response.data.message);
                localStorage.setItem("token", response.data.token);
                setSeverity("success");
                handleClick();
                // setMinutes(2);
                // setSeconds(0);
                // Timer();
                setDisableRt(true)
                const t = new Date()
                t.setSeconds(t.getSeconds() + 120)
                restart(t)
            }
        } catch (error) {
            setLoad(false)
            setOtpLoad(false)
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            setSeverity("error");
            handleClick();
        }
    };

    const Forgot_Password_2 = async () => {
        setLoad(true)
        try {
            // const response = await http.post('/forgot/password', JSON.stringify({
            const response = await http.post(
                "auth_service/reset/password",
                JSON.stringify({
                    user_email: userMail,
                    otp: otp,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword,
                })
            );
            setLoad(false)

            console.log("res", response);

            if (response.data.message === "Password reset successfully!") {
                setMessage(response.data.message);
                setSeverity("success");
                handleClick();
                // setScreen(0);
                // setVerified(true)
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                setMessage(response.data.message);
                setSeverity("error");
                handleClick();
            }

            if (response.status === 200) {
                setOtp("");
                setMessage(response.data.message);
                // localStorage.setItem("token", response.data.token);
                setSeverity("success");
                handleClick();
            }
        } catch (error) {
            setLoad(false)
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            // setMessage("Error resetting password", error.message);
            setSeverity("error");
            handleClick();
        }
    };
    const ResendOtp = async () => {
        try {
            // const response = await http.post('/otpverification', JSON.stringify({
            const response = await http.post(
                "auth_service/Resend-OTP",
                JSON.stringify({
                    user_email: userMail,
                })
            );
            console.log("res", response);

            if (
                response.data.message ===
                "An Email sent to your account please verify" ||
                response.status === 201
            ) {
                setOtp("");
                // setTimeProps({
                //   initialMinute: 2,
                //   initialSeconds: 0,
                //   setDisableRt: setDisableRt,
                // });
                setMessage(response.data.message);
                setSeverity("success");
                handleClick();
                // setMinutes(2);
                // setSeconds(0);
                setDisableRt(true);
                // Timer();
                const t = new Date()
                t.setSeconds(t.getSeconds() + 120)
                restart(t)
            }
        } catch (error) {
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            // setMessage("Error resetting password", error.message);
            setSeverity("error");
            handleClick();
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ width: '50%', height: '100vh', display: { xs: 'none', sm: 'flex' }, background: '#000000', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', color: '#fff' }}>
                <Box sx={{ width: '100%', height: '100vh', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', py: 4, px: 5, background: "radial-gradient(circle farthest-side, #2C2C2C, #010101)", borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit', }}>
                    <Link to={'/'} style={{ width: 'max-content' }}>
                        <Logo cursor="pointer" />
                    </Link>
                    <Carousel_OG />
                    <Analytics />
                </Box>
            </Box>
            {/* {screen === 2 && (
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#ffffff",
                        color: "#474747",
                        py: 10,
                        px: 5,
                    }}
                >
                    <FormControl sx={{ width: { xs: "100%", sm: "75%" } }}>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                display: "flex",
                                mt: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "32px",
                                    background:
                                        "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Change Password
                            </Typography>
                            ✨
                        </Typography>
                        <Analytics />

                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <FormLabel>Email ID
                                <span style={{ position: "relative", top: "-4px", }}>*</span>
                            </FormLabel>
                            <TextField
                                placeholder="Enter Email ID"
                                type="email"
                                name="email"
                                autoComplete="off"
                                required
                                sx={{ color: "#AFAFAF", height: "43px", "& .MuiFormHelperText-root": { marginLeft: "0px" } }}
                                onChange={handleMailChange}
                                value={userMail}
                                error={!!errors.userMail}
                                helperText={errors.userMail}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Button disabled={load}
                                sx={{
                                    color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff',
                                    background: "#8A18D0",
                                    height: "43px",
                                    textTransform: "capitalize",
                                    my: "25px",
                                    "&:hover": { background: "#B37FFC" },
                                }}
                                onClick={handleSubmit}
                            >
                                Send Code
                                <Box>
                                    {load && (
                                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                                            <Box sx={BtnLoad} />
                                        </Box>
                                    )}
                                </Box>
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            // sx={{
                            //   position: "absolute",
                            //   mt: "80px",
                            //   width: "100%",
                            // }}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={severity}
                                    variant="filled"
                                    sx={{ width: "100%" }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </FormControl>
                </Box>
            )} */}
            {screen === 1 && (
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#ffffff",
                        color: "#474747",
                        py: 10,
                        px: 5,
                    }}
                >
                    <FormControl sx={{ width: { xs: "100%", sm: "75%" } }}>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                display: "flex",
                                mt: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "32px",
                                    background:
                                        "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Set Password
                            </Typography>
                            ✨
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <FormLabel>Enter Email
                                <span style={{ position: "relative", top: "-4px", }}>*</span>
                            </FormLabel>
                            <TextField
                                placeholder="Enter Email"
                                type="email"
                                name="emailid_unique"
                                // autoComplete="off"
                                required
                                sx={{ color: "#AFAFAF", height: "43px", "& .MuiOutlinedInput-root": { height: "43px", }, "& .MuiFormHelperText-root": { marginLeft: "0px" } }}
                                onChange={handleMailChange}
                                value={userMail}
                                error={!!errors.userMail}
                                helperText={errors.userMail}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <FormLabel>Enter Code*</FormLabel>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    placeholder="****"
                                    autoComplete="off"
                                    name="otp"
                                    type="text"
                                    sx={{ color: "#AFAFAF", height: "43px", width: '100%', mr: 1, "& .MuiOutlinedInput-root": { height: "43px", }, "& .MuiFormHelperText-root": { marginLeft: "0px", height: "43px", } }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setOtp(value);
                                        }
                                    }}

                                    value={otp}
                                    error={!!errors.otp}
                                    helperText={errors.otp}
                                />
                                <Button disabled={load} sx={{ color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff', background: "#8A18D0", height: "100%", textTransform: "capitalize", width: 'max-content', minWidth: 'max-content', "&:hover": { background: "#B37FFC" }, }} onClick={handleSubmit}>
                                    Send Code
                                    <Box>
                                        {load && otpLoad && (
                                            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                                                <Box sx={BtnLoad} />
                                            </Box>
                                        )}
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                my: { xs: "0", sm: "5px" },
                            }}
                        >
                            {/* <Timer props={timeProps} /> */}
                            <Typography sx={{}}>
                                Time Remaining {minutes < 10 ? `0${minutes}` : minutes} :{" "}
                                {seconds < 10 ? `0${seconds}` : seconds}{" "}
                            </Typography>
                            <Button
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#474747",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                                onClick={() => ResendOtp()}
                                disabled={disableRt}
                            >
                                Resend Code?
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                                <FormLabel sx={{ flexGrow: 1 }}>Password*</FormLabel>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                        "&:hover .model-1": {
                                            display: "block",
                                        },
                                    }}
                                >
                                    <PasswordSvg />
                                    <Box
                                        sx={{
                                            display: "none",
                                            position: "absolute",
                                            right: 0,
                                            top: "100%",
                                            zIndex: 1,
                                        }}
                                        className="model-1"
                                    >
                                        <PasswordModel />
                                    </Box>
                                </Box>
                            </Box>
                            {/* <TextField
                  placeholder='Enter password'
                  type='password'
                  sx={{ color: '#AFAFAF', height: '43px' }}
                  onChange={handlePasswordChange}
                  value={newPassword}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword || ''}
                /> */}
                            {/* <OutlinedInput
                  placeholder="Enter Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!errors.newPassword}
                  type={showPassword1 ? "text" : "password"}
                  sx={{
                    my: 1,
                    "& input": {
                      height: "25px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword1(!showPassword1)}
                        edge="end"
                      >
                        {showPassword1 ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.newPassword && (
                  <Typography variant="caption" color="error">
                    {errors.newPassword}
                  </Typography>
                )} */}
                            <TextField
                                placeholder="Enter Password"
                                name="passwordunique"
                                value={newPassword}
                                autoComplete="off"
                                onChange={(e) => setNewPassword(e.target.value)}
                                error={!!errors.newPassword}
                                sx={{
                                    "& input": {
                                        height: "25px",
                                    }, "& .MuiOutlinedInput-root": { height: "43px", }, height: "43px",
                                }}
                                type={showPassword1 ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword1(!showPassword1)}
                                                edge="end"
                                            >
                                                {showPassword1 ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.newPassword && (
                                <Typography variant="caption" color="error">
                                    {errors.newPassword}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", my: "25px" }}>
                            <FormLabel>Re-Enter Password*</FormLabel>
                            {/* <TextField
                  placeholder="Confirm password"
                  type="password"
                  sx={{ color: "#AFAFAF", height: "43px" }}
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword || ""}
                /> */}

                            <TextField
                                placeholder="Enter Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                error={!!errors.confirmPassword}
                                sx={{
                                    "& input": {
                                        height: "25px",
                                    }, "& .MuiOutlinedInput-root": { height: "43px", }, height: "43px",
                                }}
                                type={showPassword2 ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword2(!showPassword2)}
                                                edge="end"
                                            >
                                                {showPassword2 ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.confirmPassword && (
                                <Typography variant="caption" color="error">
                                    {errors.confirmPassword}
                                </Typography>
                            )}
                            {/* <OutlinedInput
                  placeholder="Re-Enter Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!errors.confirmPassword}
                  type={showPassword2 ? "text" : "password"}
                  sx={{
                    // my: 1,
                    "& input": {
                      height: "25px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword2(!showPassword2)}
                        edge="end"
                      >
                        {showPassword2 ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.confirmPassword && (
                  <Typography variant="caption" color="error">
                    {errors.confirmPassword}
                  </Typography>
                )} */}
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Button disabled={load} sx={{ color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff', background: "#8A18D0", height: "43px", textTransform: "capitalize", my: "15px", "&:hover": { background: "#B37FFC" }, }} onClick={ChangePassword}>
                                Set Password
                                <Box>
                                    {load && !otpLoad && (
                                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                                            <Box sx={BtnLoad} />
                                        </Box>
                                    )}
                                </Box>
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            // sx={{ position: "absolute", mt: "78px", width: "100%" }}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={severity}
                                    variant="filled"
                                    sx={{ width: "100%" }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </FormControl>
                </Box>
            )}
            {verified && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <TickCircleSolid />
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "32px",
                            display: "flex",
                            mt: "10px",
                            mb: "17px",
                            background:
                                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Verified
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            display: "flex",
                            mt: "10px",
                            mb: "17px",
                            color: "#000000",
                        }}
                    >
                        Your Account has been verified successfully.
                    </Typography>
                </Box>
            )}
            {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar> */}
        </Box>
    );
};
