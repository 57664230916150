import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: { isClicked: false, cartDatas: JSON.parse(localStorage.getItem('cartDatas')) || [] },
    },
    reducers: {
        updateCartDatas: (state, action) => {
            console.log('Redux updateBundle action:', state, action, [...(state.cart.cartDatas || []), action.payload]);
            // state.cart.cartDatas = [...(state.cart.cartDatas || []), action.payload];
            const newBundle = action.payload; // The new bundle to be added
            const existingBundles = state.cart.cartDatas || []; // Existing bundles in the cart

            // Check if the new bundle already exists in the cart
            const isDuplicate = existingBundles.some(bundle => bundle.bundle_id === newBundle.bundle_id);

            if (!isDuplicate) {
                // If it's not a duplicate, add it to the cart
                state.cart.cartDatas = [newBundle, ...existingBundles];
                localStorage.setItem('cartDatas', JSON.stringify(state.cart.cartDatas))
            } else {
                console.log('Duplicate bundle not added:', newBundle);
            }
        },
        removeCart: (state, action) => {
            const bundleIdToRemove = action.payload; // Extract the bundle_id from the action payload
            const existingBundles = state.cart.cartDatas || []; // Existing bundles in the cart
            const filtedBundles = existingBundles.filter(bundle => bundle.bundle_id !== bundleIdToRemove);

            // Filter out the bundle with the specified bundle_id
            state.cart.cartDatas = filtedBundles;
            localStorage.setItem('cartDatas', JSON.stringify(state.cart.cartDatas))

            console.log('Updated cart after removal:', state.cart.cartDatas, filtedBundles, action.payload, action.payload.bundle_id);
        },
        setCartDatas: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.cart.cartDatas = action.payload; // Store the new bundle data
            localStorage.setItem('cartDatas', JSON.stringify(state.cart.cartDatas))
        },
        clickCart: (state, action) => {
            state.cart.isClicked = !state.cart.isClicked
        },
    },
});

// Export the actions
export const { updateCartDatas, removeCart, setCartDatas, clickCart } = cartSlice.actions;

// Export the reducer
export default cartSlice.reducer;
