import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminDashboardCredit, ConvertAdminDataMart, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterList } from "@mui/icons-material";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";



export const BrandAuraAdminDataMart = () => {
    const [searchTermCredit, setSearchTermCredit] = useState(null);
    const [searchTermPlan, setSearchTermPlan] = useState(null);
    const [filter, setFilter] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [tblDataC, setTblDataC] = useState([]);
    const [tblDataP, setTblDataP] = useState([]);
    const [threedotss, setThreeDotss] = useState(false);
    const [cardDatas, setCardDatas] = useState(
        {
            totalDataMartBundlesCreated: 0,
            activeDataMartBundles: 0,
            draftDataMartBundles: 0,
        }
    );
    const Deactivate = async ({ uid, status, rowData }) => {
        try {
            const response = await http.post("/master_service/admin_panel/update_status", JSON.stringify({ ind_bundle_id: rowData?.bundle_id, bundle_status: status }));
            console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
            fetchDatasP()
            // setTblData(response?.data?.user_table)
            // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error changing status:", error)
        }
    }
    const fetchDatasP = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/data_mart");
            console.log("response tbl manage_user:", response?.data);
            setTblDataP(response?.data?.Data_mart?.sort((a, b) => b?.Bundle_id - a?.Bundle_id))
            setCardDatas((p) => ({ ...p, totalDataMartBundlesCreated: response?.data?.Total_Data_Mart_bundles_Created, activeDataMartBundles: response?.data?.Active_Data_Mart_bundles, draftDataMartBundles: response?.data?.Inactive_Data_Mart_bundles }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => {
        fetchDatasP()
    }, [])
    const btns = [
        {
            title: ({ i, rowData }) => {
                return rowData.status?.props?.txt !== "Active"
                    ? "Active"
                    : "Inactive";
            },
            fn: ({ index, i, rowData }) => {
                console.log(
                    "dec",
                    rowData.status?.props?.txt === "Active",
                    rowData.status,
                    rowData.status?.props?.txt,
                    rowData
                );
                Deactivate({
                    uid: rowData.user_id,
                    status:
                        rowData.status?.props?.txt !== "Active"
                            ? "Active"
                            : "Inactive",
                    rowData: rowData
                });
            },
            cb: true,
            link: "",
        },
    ];
    const tblP = ConvertAdminDataMart({ d: tblDataP })
    // console.log('tbl', tblC, tblP)
    // const filteredDataC = tblC;
    // console.log('filteredDataC', filteredDataC)
    // const filteredDataP = tblP;
    const filteredDataP = tblP?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermPlan ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.bundle_id,
            k?.bundle_name,
            k?.category,
            k?.country,
            k?.platform,
            k?.brand,
            k?.from_date,
            k?.to_date,
            k?.price,
            k?.discount,
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter === null || filter === 'all' || filter === k?.status?.props?.txt
        return matchesSearch && filtMatch;
    });
    useEffect(() => {
        // console.log('filterDatas', threedotss, filterDatas, fltData);
        setThreeDotss(filteredDataP?.[0]?.request_id !== null);
    }, [filteredDataP]);
    const propsPlan = { filteredData: filteredDataP, tableProps: { btnData: btns }, tableStyle: { Invoice: false, isThreeDot2: threedotss, OutBox: { borderRadius: "8px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }, threeDot2: { textAlign: "end" }, }, rowsPerPage, page, setPage }
    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2, gap: 1 }}>
                        <BasicCard title='Total Data Mart Bundles Created' count={cardDatas?.totalDataMartBundlesCreated} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Active Data Mart Bundles' count={cardDatas?.activeDataMartBundles} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Draft Data Mart Bundles' count={cardDatas?.draftDataMartBundles} width={{ xs: '100%', md: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Data Mart Bundles</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermPlan} onChange={(e) => setSearchTermPlan(e.target.value)} />
                            </Box>

                            <Select
                                displayEmpty
                                size='small'
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "100px",
                                    height: "40px",
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter === null ? 'end' : 'center',
                                        pr: '5px !important',
                                        pl: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Filter</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Inactive' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Inactive</MenuItem>
                                <MenuItem value='Active' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Active</MenuItem>
                            </Select>
                            <Link to={'/admin/dashboard/data-mart/upload-bundles'}><Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }}>Upload Bundle</Button></Link>
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsPlan} />
                </Box>
            </Box>
        </Box>
    )
}
