import { Box, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { handleClick, setMessage, setSeverity } from "../../../../slice/SnackPop"
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import http from "../../../../utils/http-common"
import logoImg from '../../../../assets/pdf/logo.png'
import jsPDFInvoiceTemplate from "../../../../components/pdf-generation/Invoice"
import { extractNumberFromString, formatPhoneNumber } from "../../../../components/@extended/CustomFunctions"
import { dateClnse8, dateClnse9 } from "../../../../components/Public/DateClnse"
import { GetPriceWithSymbolNoDecimal } from "../../../../components/@extended/CurrencyConversion"
import { useRazorpayPayment } from "../../../../components/@extended/Payment"

const extractAndSetParam = (params, paramName, setterFunction) => {
    if (params.get(paramName)) {
        setterFunction(params.get(paramName));
        params.delete(paramName);
        return true; // Indicate that a parameter was processed
    }
    return false; // Indicate that no parameter was processed
};

export const Payment = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const RazorpayPayment = useRazorpayPayment()
    const currency = 'usd';
    // const currency = localStorage.getItem('currency');
    const { error, isLoading, Razorpay } = useRazorpay();
    const [price, setPrice] = useState(null)
    const [bundleId, setBundleId] = useState('')
    const [bundleName, setBundleName] = useState(null)
    const [plan_name, setPlan_Name] = useState(null)
    const [user_name, setUser_Name] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [user_email, setUser_email] = useState(null)
    const [transaction_no, setTransaction_no] = useState(null)
    const [paymentType, setPaymentType] = useState('online')
    const [billing_adress, setBilling_Adress] = useState(null)
    const [user_phone_number, setUser_phone_number] = useState(null)
    const [user_id, setUser_id] = useState(null)
    const [receivedData, setReceivedData] = useState(null);
    const [tableHeader, setTableHeader] = useState([{ title: "Sl No", style: { width: 10 } }, { title: "Title", style: { width: 30 } }, { title: "Description", style: { width: 80 } }, { title: "Price", style: { align: "right", } }, { title: "Quantity", style: { align: "right", } }, { title: "Unit", style: { align: "right", } }, { title: "Total", style: { align: "right", } }]);
    const [tableData, setTableData] = useState([1, "name", "description", 200.5, 4.5, "m2", 400.5]);
    const samplePdfGenProp = {
        outputType: "blob",
        returnJsPDFDocObject: true,
        fileName: "Invoice 2022",
        orientationLandscape: false,
        compress: true,
        logo: {
            src: logoImg,
            // src: "https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png",
            width: 30, //aspect ratio = width/height
            height: 10,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        // stamp: {
        //     inAllPages: true,
        //     src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
        //     width: 20, //aspect ratio = width/height
        //     height: 20,
        //     margin: {
        //         top: 0, //negative or positive num, from the current position
        //         left: 0 //negative or positive num, from the current position
        //     }
        // },
        business: {
            name: "Brand Aura",
            address: "Bandapura Village Road, Bangalore,\nChandapura, Anekal Tk,\nBangalore - 562107, Karnataka, India.",
            // phone: "+91 069 11 11 111",
            email: "contact@brand-aura.com",
            // email_1: "info@example.al",
            website: "www.brand-aura.com",
        },
        contact: {
            label: "Invoice issued for:",
            name: user_name,
            address: billing_adress,
            // phone: "+91 069 11 11 111",
            phone: formatPhoneNumber(String(user_phone_number)),
            email: user_email,
            // otherInfo: "www.website.al",
        },
        invoice: {
            label: "Invoice No: ",
            num: 19,
            invDate: `Payment Date: ${dateClnse9(new Date())}`,
            invGenDate: `Invoice Date: ${dateClnse9(new Date())}`,
            headerBorder: true,
            tableBodyBorder: true,
            header: tableHeader,
            // header: [
            //     {
            //         title: "Sl No",
            //         style: {
            //             width: 10
            //         }
            //     },
            //     {
            //         title: "Title",
            //         style: {
            //             width: 30
            //         }
            //     },
            //     {
            //         title: "Description",
            //         style: {
            //             width: 80
            //         }
            //     },
            //     { title: "Price" },
            //     { title: "Quantity" },
            //     { title: "Unit" },
            //     { title: "Total" }
            // ],
            // table: [1,'','','',''],
            table: tableData,
            // table: Array.from(Array(1), (item, index) => ([
            //     index + 1,
            //     "name",
            //     "description",
            //     200.5,
            //     4.5,
            //     "m2",
            //     400.5
            // ])),
            additionalRowsBorder: false,
            additionalRows: [
                {
                    col1: 'Total:',
                    // col2: String(price),
                    col3: String(GetPriceWithSymbolNoDecimal(price)),
                    // col3: 'ALL',
                    style: {
                        fontSize: 14 //optional, default 12
                    }
                },
                // {
                //     col1: 'VAT:',
                //     col2: '20',
                //     col3: '%',
                //     style: {
                //         fontSize: 10 //optional, default 12
                //     }
                // },
                // {
                //     col1: 'SubTotal:',
                //     col2: '116,199.90',
                //     col3: 'ALL',
                //     style: {
                //         fontSize: 10 //optional, default 12
                //     }
                // }
            ],

            // invDescLabel: "Invoice Note",
            // invDesc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
        },
        footer: {
            text: "The invoice is created on a computer and is valid without the signature and stamp.",
        },
        pageEnable: true,
        pageLabel: "Page ",
    }

    const ProcessRecievedData = () => {
        if (receivedData?.paymentFor === 'customplan') {
            setPlan_Name(receivedData?.Plan_Name)
            setUser_id(receivedData?.User_id)
            setUser_email(receivedData?.User_email)
            setUser_Name(receivedData?.user_name)
            setBilling_Adress(receivedData?.billing_adress)
            setUser_phone_number(receivedData?.user_phone_number)
            setFileName(receivedData?.Plan_Name)
            setTableHeader([{ title: "Sl No", style: { width: 17, }, }, { title: "Plan Name", }, { title: "No Of Admin", }, { title: "No Of Users" }, { title: "Credits for the Plan (per month)", style: { align: "right", } }, { title: "Plan Cost", style: { align: "right", } },])
            setTableData([[1, receivedData?.Plan_Name, receivedData?.No_of_Admin, receivedData?.No_Of_User, receivedData?.credits_for_the_plan, GetPriceWithSymbolNoDecimal(Number(receivedData?.Plan_cost))]])
            console.log('tdd', [[1, receivedData?.Plan_Name, receivedData?.No_of_Admin, receivedData?.No_Of_User, receivedData?.credits_for_the_plan, GetPriceWithSymbolNoDecimal(Number(receivedData?.Plan_cost))]]);
            setPrice(Number(receivedData?.Plan_cost))
        } else if (receivedData?.paymentFor === 'selectplan') {
            setPlan_Name(receivedData?.plan_name)
            setUser_id(receivedData?.user_id)
            setUser_email(receivedData?.user_email)
            setUser_Name(receivedData?.user_first_name)
            setBilling_Adress(receivedData?.billing_adress)
            setUser_phone_number(receivedData?.user_phone_number)
            setFileName(receivedData?.plan_name)
            setTableHeader([{ title: "Sl No", style: { width: 17, }, }, { title: "Plan Name", }, { title: "No Of Admin", }, { title: "No Of Users" }, { title: "Credits for the Plan (per month)", style: { align: "right", } }, { title: "Plan Cost", style: { align: "right", } },])
            setTableData([[1, receivedData?.plan_name, receivedData?.admin_access, receivedData?.user_licenses, receivedData?.applicable_credit, GetPriceWithSymbolNoDecimal(Number(extractNumberFromString(receivedData?.price)))]])
            console.log('tdd', [[1, receivedData?.plan_name, receivedData?.admin_access, receivedData?.user_licenses, receivedData?.applicable_credit, GetPriceWithSymbolNoDecimal(Number(extractNumberFromString(receivedData?.price)))]]);
            setPrice(Number(extractNumberFromString(receivedData?.price)))
        } else if (receivedData?.paymentFor === 'custombundle') {
            setUser_id(receivedData?.User_id)
            setBundleId(receivedData?.ind_bundle_id)
            setBundleName(receivedData?.ind_bundle_name)
            setUser_email(receivedData?.user_email)
            setUser_Name(receivedData?.user_name)
            setBilling_Adress(receivedData?.billing_adress)
            setUser_phone_number(receivedData?.user_phone_number)
            setFileName(receivedData?.ind_bundle_name)
            setTableHeader([{ title: "Sl No", style: { width: 17, }, }, { title: "Bundle Name", }, { title: "Bundle Setup Cost", style: { align: "right", } }, { title: "Bundle Cost", style: { align: "right", } },])
            setTableData([[1, receivedData?.ind_bundle_name, Number(Math.round(receivedData?.estimated_credits)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(receivedData?.Bundle_details?.["Bundle Cost"])))]])
            console.log('tdd', [[1, receivedData?.ind_bundle_name, Number(Math.round(receivedData?.estimated_credits)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(receivedData?.Bundle_details?.["Bundle Cost"])))]]);
            setPrice(Number(Math.round(receivedData?.Bundle_details?.["Bundle Cost"])))
        } else if (receivedData?.paymentFor === 'adminSendCredit' || receivedData?.paymentFor === 'SendCredit') {
            setUser_id(receivedData?.user_id)
            setUser_email(receivedData?.user_email)
            setUser_Name(receivedData?.user_first_name)
            setBilling_Adress(receivedData?.billing_adress)
            setUser_phone_number(receivedData?.user_phone_number)
            setFileName('CreditRequest')
            setTableHeader([{ title: "Sl No", style: { width: 17, }, }, { title: "Name", }, { title: "Applicable Credits", style: { align: "right", } }, { title: "Credits Cost", style: { align: "right", } },])
            setTableData([[1, 'Credit Purchase', Number(Math.round(receivedData?.applicable_credit)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(receivedData?.request_amt)))]])
            console.log('tdd', [[1, 'Credit Purchase', Number(Math.round(receivedData?.applicable_credit)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(receivedData?.request_amt)))]]);
            setPrice(Number(Math.round(receivedData?.request_amt)))
        }
    }
    const getQueryParams = (search) => {
        const params = new URLSearchParams(search);
        let paramProcessed = false;

        // paramProcessed = extractAndSetParam(params, "price", setPrice) || paramProcessed;
        // paramProcessed = extractAndSetParam(params, "ind_bundle_id", setBundleId) || paramProcessed;
        // paramProcessed = extractAndSetParam(params, "plan_name", setPlan_Name) || paramProcessed;
        // paramProcessed = extractAndSetParam(params, "user_id", setUser_id) || paramProcessed;
        const decodedData = {};

        for (const [key, value] of params.entries()) {
            try {
                // Attempt to parse as JSON (arrays and objects)
                decodedData[key] = JSON.parse(value);
            } catch (e) {
                // If parsing fails, treat as a simple string
                decodedData[key] = value;
            }
        }

        setReceivedData(decodedData);
        console.log('received datas', decodedData?.paymentFor === 'customplan', decodedData, receivedData);


        console.log('paramProcessed', params, paramProcessed, params?.size);

        // if (paramProcessed) {
        //     const newSearch = params.toString();
        //     navigate({ search: newSearch }, { replace: true });
        // }

        // if (params?.size <= 0) { navigate('/login'); }
        // if (params.get("price")) {
        //     // console.log('price :', params.get("price"));
        //     setPrice(params.get("price"));
        //     params.delete("price");
        //     const newSearch = params.toString();
        //     navigate({ search: newSearch }, { replace: true });
        // }
        // if (params.get("ind_bundle_id")) {
        //     // console.log('price :', params.get("price"));
        //     setBundleId(params.get("ind_bundle_id"));
        //     params.delete("ind_bundle_id");
        //     const newSearch = params.toString();
        //     navigate({ search: newSearch }, { replace: true });
        // }
        // if (params.get("plan_id")) {
        //     // console.log('price :', params.get("price"));
        //     setPlanId(params.get("plan_id"));
        //     params.delete("plan_id");
        //     const newSearch = params.toString();
        //     navigate({ search: newSearch }, { replace: true });
        // }
        // else {
        //     if (search && search.length >= 1) { // Check if query parameters exist
        //         // If there are other query parameters, do nothing or handle them
        //         console.log("Other query parameters exist.");
        //     } else {
        //         console.log('redirecting to login');
        //         navigate('/login');
        //     }
        // }
        // return {
        //   firstName: params.get('firstName') || '',
        //   email: params.get('email') || '',
        //   phoneNumber: params.get('phoneNumber') || '',
        // };
    };


    // samplePdfGenProp.invoice.num = 335
    // console.log('sss', samplePdfGenProp);

    const ActivateBundle = async () => {
        try {
            const response = await http.post("/master_service/admin_panel/update_status", JSON.stringify({ ind_bundle_id: bundleId, bundle_status: 'Active' }));
            console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
            // setTblData(response?.data?.user_table)
            // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            setTimeout(() => {
                navigate('/login')
            }, 2000);
        }
        catch (error) {
            console.error("Error changing status:", error)
        }
    }
    const SendCredit = async (p) => {
        try {
            const response = await http.post(
                "/master_service/credit_request/update",
                JSON.stringify(p)
                // JSON.stringify({user_id:'', recharge_request_id, transaction_no, payment_date, payment_type})
            );
            const successMessage = response.data?.message;
            const errorMessage = response.data?.error;
            if (errorMessage) {
                console.warn("Warning:", errorMessage);
                dispatch(setMessage(errorMessage));
                dispatch(setSeverity("warning"));
            } else {
                //  console.log(successMessage);
                dispatch(setMessage(successMessage || "Data sent successfully."));
                dispatch(setSeverity("success"));
                // fetchDatasP()
            }
            dispatch(handleClick());
            setTimeout(() => {
                navigate('/login')
            }, 2000);
        } catch (error) {
            const errMsg = error?.response?.data?.error || "Error sending data.";
            console.error("Error sending data:", error, errMsg);
            // dispatch(setMessage(errMsg));
            // dispatch(setSeverity("error"));
            // dispatch(handleClick());
        }
    };
    const SecondaryApiCalls = (t) => {
        try {
            if (receivedData?.paymentFor === 'customplan' || receivedData?.paymentFor === 'adminSendCredit' || receivedData?.paymentFor === 'SendCredit' || receivedData?.paymentFor === 'selectplan' || receivedData?.paymentFor === 'customplan') {
                SendCredit({ user_id: user_id, recharge_request_id: receivedData?.recharge_request_id, transaction_no: t, payment_date: new Date(), payment_type: 'online' })
            } else if (receivedData?.paymentFor === 'custombundle') {
                ActivateBundle()
            }
            // else if(receivedData?.paymentFor === 'selectplan'||receivedData?.paymentFor === 'customplan'){}
        } catch (error) {
            console.log('error in secondary api calls', error);
        }
    }
    const TransactionApiTrigger = async (k) => {
        try {
            const pdf = jsPDFInvoiceTemplate(samplePdfGenProp)
            const mime = "application/pdf";
            const file = new File([pdf?.blob], "abc.pdf", { type: mime });
            console.log('pdf', pdf);

            const formData = new FormData();
            formData.append('user_id', user_id);
            formData.append('ind_bundle_id', bundleId);
            formData.append('plan_name', plan_name);
            formData.append('payment_id', k?.id);
            formData.append('invoice_no', k?.invoice_no);
            formData.append('payment_type', 'online');
            formData.append('payment_date', new Date());
            formData.append('bundle_cost', Number(price));
            formData.append('payment_confirmation_status', k?.payment_confirmation_status)
            // formData.append('payment_confirmation_status', k?.payment_confirmation_status)
            formData.append('filename', fileName);
            if (k?.id !== null) { formData.append('pdf', file); }
            const response = await http.post(
                "master_service/payment/details", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the correct content type
                },
                // JSON.stringify({
                //     user_id: Number(user_id),
                //     ind_bundle_id: Number(bundleId),
                //     plan_name: Number(plan_name),
                //     // payment_type: paymentType,
                //     payment_id: k?.razorpay_payment_id,
                //     payment_date: new Date(),
                //     // bundle_cost: Number(data?.finalIndPrice),
                //     bundle_cost: Number(price),
                //     payment_confirmation_status: "success",
                // })
            });
            console.log('res', response, response?.data?.data?.payment?.order_number, response?.data?.data?.payment?.transaction_number);
            if (k?.payment_confirmation_status === 'success') {
                dispatch(setMessage(response.data.message || "Error with save bundle"));
                dispatch(setSeverity("success"));
                setTransaction_no(response?.data?.data?.payment?.transaction_number);
                SecondaryApiCalls(response?.data?.data?.payment?.transaction_number)
            } else {
                dispatch(setMessage(k?.errorMessage || "Error with purchasing bundle"));
                dispatch(setSeverity("error"));
            }
            dispatch(handleClick());
        } catch (error) {
            console.log('err', error);
            dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle'));
            dispatch(setSeverity("error"));
            dispatch(handleClick());
            setTimeout(() => {
                navigate('/login')
            }, 2000);
        }
    }
    const InvoiceNoTrigger = async (k) => {
        try {
            const response = await http.post("/master_service/invoice", JSON.stringify({ user_id: user_id }));
            console.log("response tbl manage_user:", response, response?.data, response?.data?.data?.invoice_no);
            samplePdfGenProp.invoice.num = response?.data?.data?.invoice_no
            // setTblData(response?.data?.user_table)
            // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            TransactionApiTrigger({ ...k, invoice_no: response?.data?.data?.invoice_no })
        }
        catch (error) {
            console.error("Error changing status:", error)
        }
    }
    const Pay = () => {
        try {
            console.log('pri', price);

            const razorpayOptions = {
                key: 'rzp_test_Ir3RqpkKv3LiIt', // Replace with your Razorpay Key ID
                // key_secret: 'O3nI73DRVT66p1CO3H0DttzQ',
                // amount: Number(data?.finalPriceWithSymbol) * 100, // Amount in paise
                amount: Number(price) * 100, // Amount in paise
                currency: String(currency).toUpperCase(),
                name: 'Brand Aura',
                description: 'Test Transaction',
                // order_id: response?.data?.data?.order_number,
                handler: async function (k) {
                    console.log('Payment successful:', k);
                    // alert('Payment successful!');
                    console.log('received datas', receivedData);
                    TransactionApiTrigger({ id: k?.razorpay_payment_id, payment_confirmation_status: 'success' })
                    navigate('/login')
                },
                // prefill: {
                // name: 'John Doe',
                // email: 'john@example.com',
                // contact: '9999999999',
                // },
                notes: {
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#3399cc',
                },
                // method: paymentType === "credit_card" || paymentType === "debit_card" ? 'card' : paymentType === 'netbanking' ? 'netbanking' : paymentType, // Dynamically set the payment method
            };
            console.log('options', razorpayOptions);

            if (window.Razorpay) {
                const rzp = new Razorpay(razorpayOptions);
                rzp.open()
                rzp.on('payment.failed', async function (res) {
                    // Log the error details
                    console.error('Payment failed:', res);

                    // Extract error details from the response
                    const { error } = res;
                    const errorMessage = error?.description || 'Payment failed. Please try again.';

                    // Display an error message to the user
                    // alert(errorMessage);

                    // Optionally, log the error for debugging
                    // console.error('Error code:', error?.code);
                    // console.error('Error source:', error?.source);
                    // console.error('Error reason:', error?.reason);
                    TransactionApiTrigger({ id: null, payment_confirmation_status: 'failed', errorMessage: errorMessage })
                });
            }
        } catch (error) {
            console.log('error in payment', error)
            navigate('/login')
        }
    }

    useEffect(() => {
        const ogUser_Id = localStorage.getItem('user_id')
        console.log('uid', user_id, ogUser_Id);

        if (Number(user_id) !== Number(ogUser_Id) && user_id !== null) {
            sessionStorage.setItem('redirectUrl', window.location.origin + '/payment' + location.search);
            dispatch(setMessage('Please Login in the appropriate account'))
            dispatch(setSeverity('warning'));
            dispatch(handleClick())
            setTimeout(() => {
                navigate('/login')
            }, 3000);
        }
    }, [user_id]);
    useEffect(() => {
        ProcessRecievedData()
    }, [receivedData]);
    useEffect(() => {
        getQueryParams(location.search);
    }, [location.search]);
    useEffect(() => {
        const ogUser_Id = localStorage.getItem('user_id')
        // if (price !== null && price !== undefined) { Pay() }
        if (price !== null && price !== undefined && Number(user_id) === Number(ogUser_Id)) { RazorpayPayment({ amount: price, handler: (e) => { console.log('received datas', receivedData); InvoiceNoTrigger({ id: e?.razorpay_payment_id, payment_confirmation_status: 'success' }); }, ondismiss: () => { navigate('/login') }, onError: () => { navigate('/login') }, onPaymentFailed: ({ res, errorMessage }) => { TransactionApiTrigger({ id: null, payment_confirmation_status: 'failed', errorMessage: errorMessage }) }, }) }
        // if (price !== null && price !== undefined) { InvoiceNoTrigger() }
    }, [price, user_id, window.Razorpay]);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            {/* <Button onClick={() => Pay()}>Pay Now</Button> */}
        </Box>
    )
}
export const Pay = () => {
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        console.log('loc', location);
        sessionStorage.setItem('redirectUrl', window.location.origin + '/payment' + location.search);
        // navigate('/payment' + location.search)
        navigate('/login')
    }, [])
    return (
        <Box></Box>
    )
}