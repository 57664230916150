import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { ThreeDots } from '../../utils/icon/IndividualPages/Icon';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export const ThreeDot = ({ rb, data, bundle_id }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const lg = async () => {
    console.log('lg');
    localStorage.clear();
    navigate('/');
  };

  const handleMenuItemClick = (setting, index) => {
    console.log('clicked', bundle_id);
    if (setting.fn) {
      localStorage.setItem('bundle_id', bundle_id);
      setting.fn(index, rb);
    } else if (setting.link) {
      window.location.href = setting.link;
    }
    handleCloseUserMenu();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Open settings'>
        <IconButton
          disableRipple
          onClick={handleOpenUserMenu}
          sx={{ p: 2 }}>
          <ThreeDots />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{}}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        {data.map((setting, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(setting, index)}>
            <Link to={setting.link && setting.link}>
              <Typography textAlign='center'>{setting.title}</Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const ThreeDot2 = ({ data, i, rowData }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const lg = async () => {
    console.log('lg');
    localStorage.clear();
    navigate('/');
  };

  const handleMenuItemClick = (setting, index) => {
    if (setting.fn) {
      setting.fn({ index, i, rowData });
    }
    //   else if (setting.link) {
    //     window.location.href = setting.link;
    //   }
    handleCloseUserMenu();
  };

  // console.log('dat', data)
  return (
    <Box sx={{ flexGrow: 0, height: '100%', }}>
      <Tooltip title='Open settings' sx={{ height: '100%', }}>
        <IconButton
          disableRipple
          onClick={handleOpenUserMenu}
          sx={{ py: 0, height: '100%' }}>
          <ThreeDots />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ height: '100%', }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        {data.map((setting, index) => {
          // console.log('setting?.disabled', setting?.disabled);
          const disable = () => setting?.disabled ? setting.disabled({ i: i, rowData }) : false;
          const isDisable = disable();
          console.log('dis', disable());
          return (
            <MenuItem
              key={index}
              disabled={isDisable}
              // disabled={false}
              // disabled={() => setting?.disabled ? setting.disabled({ i: i, rowData }) : false}
              // disabled={() => { console.log('setting?.disabled', setting?.disabled); if (setting?.disabled !== undefined) { return setting?.disabled({ i: i, rowData }); } else { return false; } }}
              onClick={() => handleMenuItemClick(setting, index)}>
              <Link to={setting.link && setting.link}>
                <Typography textAlign='center'>
                  {setting.title({ i: i, rowData })}
                </Typography>
              </Link>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  );
};
