import { Box, Button, Typography } from "@mui/material"
import { Back } from "../../../../components/@extended/Button"
import { NoteComponent } from "../../../../components/@extended/Card"
import { DownloadVioletIkn, DragAndDropUploadIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon"
import { useRef, useState } from "react"
import { downloadCSV } from "../../../../components/Public/DownloadCSV"
import { BrandAuraDataMartTemplateData } from "../../../../utils/data/BrandAuraAdminPage/data"
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable"
import { checkDataMartUploadBundles, ConvertDataMartUploadBundles } from "../../../../components/@extended/CustomFunctions"
import { useDispatch } from "react-redux"
import { handleClick, setMessage, setSeverity } from "../../../../slice/SnackPop"
import http from "../../../../utils/http-common"
import { useNavigate } from "react-router-dom"
// import * as XLSX from 'xlsx';
var XLSX = require("xlsx");




export const UploadDataMartBundles = () => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tempTbl, setTempTbl] = useState(null)
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    const GetPrice = async (d) => {
        try {
            const response = await http.post("/master_service/admin_panel/calculate", JSON.stringify(d));
            console.log("response getPrice:", response, response?.data);

            const d2 = checkDataMartUploadBundles(response?.data);
            if (!d2?.success) { dispatch(setMessage(d2?.errors)); dispatch(setSeverity("error")); dispatch(handleClick()); console.log('issue', d2, response?.data); }
            setTempTbl(d2?.validData)
            console.log('tempTbl', tempTbl);
            // setCardDatas((p) => ({ ...p, users: response?.data?.Total_Brand_Aura_Users, creditRequests: response?.data?.Pending_Credit_Requests, planRequest: response?.data?.Pending_Plan_Request, dataScrapedToday: response?.data?.Data_Scraped_Today }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            dispatch(setMessage(response?.data?.message||'Bundle Uploaded Successfully'));
            dispatch(setSeverity('success'));
            dispatch(handleClick())
            // setTimeout(() => {
            //     navigate('/admin/dashboard/data-mart')
            // }, 1900);
        }
        catch (error) {
            console.error("Error Getting Bundle Price:", error)
            dispatch(setMessage(error?.response?.data?.message || 'Error Uploading Bundle'));
            dispatch(setSeverity('error'));
            dispatch(handleClick())
        }
    }
    const CreateDataMartBundles = async () => {
        // GetPrice()
        // return;
        try {
            const response = await http.post("/master_service/admin_panel/create_data_mart", JSON.stringify(tempTbl));
            // const response = await http.post("/master_service/admin_panel/create_data_mart", JSON.stringify(tempTbl?.[0]));
            console.log("response CreateDataMartBundles:", response,);
            // setCardDatas((p) => ({ ...p, users: response?.data?.Total_Brand_Aura_Users, creditRequests: response?.data?.Pending_Credit_Requests, planRequest: response?.data?.Pending_Plan_Request, dataScrapedToday: response?.data?.Data_Scraped_Today }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            dispatch(setMessage(response?.data?.message || 'Bundle Created Successfully'));
            dispatch(setSeverity('success'));
            dispatch(handleClick())
            setTimeout(() => {
                navigate('/admin/dashboard/data-mart')
            }, 1900);
        }
        catch (error) {
            console.error("Error fetching data:", error)
            dispatch(setMessage(error?.response?.data?.message || 'Error Creating Bundles'));
            dispatch(setSeverity('error'));
            dispatch(handleClick())
        }
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log('file', file);

        if (file) {
            readExcelFile(file);
            event.target.value = '';
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            readExcelFile(file);
        }
    };

    const readExcelFile = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'csv') {
            alert('Please upload an Excel (.xlsx, .xls) or CSV file (.csv)');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            if (fileExtension === 'csv') {
                const text = e.target.result;
                const jsonData = parseCSV(text);
                console.log('checkDataMartUploadBundles(jsonData)', jsonData, checkDataMartUploadBundles(jsonData))
                const d = checkDataMartUploadBundles(jsonData);
                if (!d?.success) { dispatch(setMessage(d?.errors)); dispatch(setSeverity("error")); dispatch(handleClick()); console.log('issue', d, jsonData); return; }
                GetPrice(d?.validData)
                // setTempTbl(d)
                console.log('CSV Data:', jsonData); // Use the JSON data as needed
            } else {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array',cellDates:true });
                const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                const d = checkDataMartUploadBundles(jsonData);
                if (!d?.success) { dispatch(setMessage(d?.errors)); dispatch(setSeverity("error")); dispatch(handleClick()); console.log('issue', d, jsonData); return; }
                console.log('d', d, jsonData);

                GetPrice(d?.validData)
                // setTempTbl(d)
                // console.log('Excel Data:', jsonData, workbook); // Use the JSON data as needed
            }
        };

        if (fileExtension === 'csv') {
            reader.readAsText(file); // Read CSV files as text
        } else {
            reader.readAsArrayBuffer(file); // Read Excel files as array buffer
        }
    };

    const parseCSV = (text, quoteChar = '"', delimiter = ",") => {
        console.log('csv raw', text);
        text = text.trim();

        // Split the text into rows and filter out any empty lines
        let rows = text.split("\n").map(row => row.trim()).filter(row => row.length > 0);

        // Extract headers from the first row and trim quotes
        let headers = rows[0].split(delimiter).map(header => header.replace(new RegExp(`^${quoteChar}|${quoteChar}$`, 'g'), '').trim());

        const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, "gs");

        const match = (line) => {
            const matches = [...line.matchAll(regex)].map((m) => m[2].replace(new RegExp(`^${quoteChar}|${quoteChar}$`, 'g'), '').trim());
            // Ensure matches length matches headers length by padding with null values
            const paddedMatches = Array.from({ length: headers.length }, (_, i) => matches[i] ?? null);
            return paddedMatches;
        };

        // Remove the header row from lines
        let lines = rows.slice(1);

        return lines.map((line) => {
            return match(line).reduce((acc, cur, i) => {
                // Replace blank matches with `null`
                const val = cur === null || cur.length <= 0 ? null : Number(cur) || cur;
                const key = headers[i] ?? `{${i}}`; // Use template literal for undefined keys
                return { ...acc, [key]: val };
            }, {});
        });
    };


    const convertCode = ConvertDataMartUploadBundles(tempTbl);
    console.log('convertCode', tempTbl, convertCode);


    const propsTempTbl = { title: 'Bundle Table', filteredData: convertCode, rowsPerPage, page, setPage }
    return (
        <Box sx={{ p: 2 }}>
            <Back prop={{ txt: 'Upload Bundle' }} />
            <NoteComponent prop={{ txt: 'Data Mart Bundles only can be created here.' }} />
            <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '12px', color: '#040815' }}>Upload File</Typography>
                    <Button disableElevation disableFocusRipple disableRipple sx={{ background: "transparent", color: "#8A18D0", fontSize: '10px', borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "transparent", }, }} onClick={() => { downloadCSV(BrandAuraDataMartTemplateData, 'Template') }}>
                        <DownloadVioletIkn />
                        Download Template
                    </Button>
                </Box>
                <Box sx={{ width: '100%', minHeight: '150px', border: '1px solid #E7E8EA', borderRadius: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => fileInputRef.current.click()} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <DragAndDropUploadIkn />
                    <Typography sx={{ color: '#474747CC', fontWeight: '500', fontSize: '12px' }}>Select a file or drag & drop the file</Typography>
                </Box>
                <input
                    type="file"
                    ref={fileInputRef}
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the file input
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, gap: 1 }}>
                {/* <Button disableElevation disableFocusRipple disableRipple variant='outlined' sx={{ color: '#474747', background: 'transparent', borderColor: '#474747', borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "transparent", borderColor: '#474747', }, }}>Back</Button> */}
                <Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }} onClick={() => fileInputRef.current.click()}>Upload</Button>
            </Box>
            {tempTbl ? <CorporateNormalUserTable prop={propsTempTbl} /> : null}
            {tempTbl ? <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, gap: 1 }}><Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }} onClick={() => { if (tempTbl !== null) { console.log('ent not null'); CreateDataMartBundles() } else if (tempTbl === null) { console.log('ent null'); dispatch(setMessage('Please Submit Proper data to create Datamart Bundles')); dispatch(setSeverity('warning')); dispatch(handleClick()) } }}>Create</Button></Box> : null}
        </Box>
    )
}