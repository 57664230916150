import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import http from '../../../../utils/http-common';
import { Carousel_OG } from '../Components/Carousel';
// import { Timer } from '../../../components/@extended/TimeRemaining';
import { Logo } from '../../../../assets/logo';
import { TickCircleSolid } from '../../../../utils/icon/LandingPage/Icon';
import { BtnLoad } from '../../../../utils/data/LandingPage/Data';
import { useTimer } from '../../../../components/Timer';
import { Analytics } from '../../../../Analytics';

export const Otp = () => {
  // const expiry = useSelector((state) => state.expiry.expiry);
  // const [timeRemaining, setTimeRemaining] = useState({ minutes: 0, seconds: 0 });
  const [otp, setOtp] = useState('');
  const [rtCode, setRtCode] = useState('login');
  const [load, setLoad] = useState(false);
  const [disableRt, setDisableRt] = useState(true);
  const [errors, setErrors] = useState({});
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);
  const expiryTimestamp = time;
  const {
    seconds,
    minutes,
    hours,
    days,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => { console.warn('onExpire called'); setDisableRt(false) } });
  // const [minutes, setMinutes] = useState(initialMinute);
  // const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef(null);
  const [timeProps, setTimeProps] = useState({
    initialMinute: 2,
    initialSeconds: 0,
    setDisableRt: setDisableRt,
  });
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [open, setOpen] = useState(false);
  const [mail, setMail] = useState(localStorage.getItem('email') || '');
  const navigate = useNavigate();

  // const Timer = async () => {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current); // Clear any existing interval
  //   }

  //   // Start a new countdown
  //   intervalRef.current = setInterval(() => {
  //     setSeconds((prevSeconds) => {
  //       if (prevSeconds === 0 && minutes > 0) {
  //         setMinutes((prevMinutes) => prevMinutes - 1);
  //         return 59;
  //       }

  //       if (prevSeconds === 0 && minutes === 0) {
  //         clearInterval(intervalRef.current); // Stop the timer
  //         setDisableRt(false); // Enable the Resend OTP button
  //         return 0;
  //       }
  //       if (prevSeconds > 0) return prevSeconds - 1;

  //       return prevSeconds;
  //     });
  //   }, 1000);

  //   // Clean up interval when component unmounts or when props change
  //   return () => {
  //     clearInterval(intervalRef.current);
  //   };
  // };
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
    if (!otp) {
      tempErrors.otp = "Enter code.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;

  }
  const handleOtp = () => {
    if (validateForm()) {
      Otp()
    }

  }
  useEffect(() => {
    const e = localStorage.getItem('email');
    const o = localStorage.getItem('otp');
    if (!e) navigate('/');
    // Timer();
  }, []);
  const maskedEmail = mail.replace(/^(.{3})(.*)(@.*)$/, (match, p1, p2, p3) => {
    return `${p1}${'*'.repeat(p2.length)}${p3}`;
  });

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }

  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const Otp = async () => {
    setLoad(true)
    try {
      // const response = await http.post('/otpverification', JSON.stringify({
      const response = await http.post(
        'auth_service/otpverification',
        JSON.stringify({
          otp: otp,
          user_email: localStorage.getItem('email'),
        })
      );
      console.log('res', response, response.user);

      if (response.status === 200) {
        setLoad(false)
        setMessage(
          response.data.message
            ? response.data.message
            : 'User is Verified with the Otp Successfully'
        );
        setSeverity('success');
        handleClick();
        setVerified(true);
        if (response.data.user_type === 'company') {
          localStorage.setItem('user_id', response.data.user_id);
        }
        localStorage.setItem('paymentType', response?.data?.paymentType);
        setRtCode(response.data.user_type === 'company' && response.data.user_role === 'Admin User' && !response.data.plan_status ? 'Pricing' : 'Login');
        setTimeout(() => {
          if (response.data.user_type === 'company' && response.data.user_role === 'Admin User' && !response.data.plan_status) {
            navigate('/pricing');
          } else {
            navigate('/login');
          }
        }, 4000); // execute after 4 seconds
        // setRtCode(response.data.user_type === 'company' && response.data.user_role === 'Admin User' ? 'Pricing' : 'Login');
        // setTimeout(() => {
        //   if (response.data.user_type === 'company' && response.data.user_role === 'Admin User') {
        //     navigate('/pricing');
        //   } else {
        //     navigate('/login');
        //   }
        // }, 4000); // execute after 4 seconds
        setOtp('');
      }
    } catch (error) {
      setLoad(false)
      console.error('Error verifying OTP', error);
      setMessage(error.response.data.message);
      setSeverity('error');
      handleClick();
    }
  };
  const ResendOtp = async () => {
    try {
      // const response = await http.post('/otpverification', JSON.stringify({
      const response = await http.post(
        'auth_service/Resend-OTP',
        JSON.stringify({
          user_email: localStorage.getItem('email'),
        })
      );
      console.log('res', response);

      if (
        response.data.message ===
        'An Email sent to your account please verify' ||
        response.status === 201
      ) {
        setOtp('');
        // setTimeProps({
        //   initialMinute: 0,
        //   initialSeconds: 20,
        //   setDisableRt: setDisableRt,
        // });
        // setMinutes(2);
        setMessage(response.data.message);
        setSeverity('success');
        handleClick();
        setDisableRt(true)
        const t = new Date()
        t.setSeconds(t.getSeconds() + 120)
        restart(t)
        // Timer();
      }
    } catch (error) {
      console.error('Error verifying OTP', error);
      setMessage(error.response.data.message);
      setSeverity('error');
      handleClick();
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '50%', height: '100vh', display: { xs: 'none', sm: 'flex' }, background: '#000000', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', color: '#fff' }}>
        <Box sx={{ width: '100%', height: '100vh', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', py: 4, px: 5, background: "radial-gradient(circle farthest-side, #2C2C2C, #010101)", borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit', }}>
          <Link to={'/'} style={{ width: 'max-content' }}>
            <Logo cursor="pointer" />
          </Link>
          <Carousel_OG />
          <Analytics />
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '50%' },
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#ffffff',
          color: '#474747',
          py: 10,
          px: 5,
        }}>
        {verified ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <TickCircleSolid />
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '32px',
                display: 'flex',
                mt: '10px',
                mb: '17px',
                background:
                  'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Verified
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '20px',
                display: 'flex',
                mt: '10px',
                mb: '17px',
                color: '#000000',
              }}>
              Your Account has been verified successfully.
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '20px',
                color: '#000000',
                mb: '0px',
              }}>
              Redirecting to the {rtCode} page.
            </Typography>
          </Box>
        ) : (
          <FormControl
            sx={{ width: { xs: '100%', sm: '75%', padding: '50px' } }}>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '32px',
                display: 'flex',
                mt: '15px',
              }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '32px',
                  background:
                    'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                Verify Your Account{' '}
              </Typography>{' '}
              🧑‍💻
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                display: 'flex',
                mt: '10px',
                mb: '17px',
                color: '#000000',
              }}>
              We have just sent a verification code to your mail {maskedEmail}.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: '15px' }}>
              <FormLabel
                sx={{ fontWeight: '500', fontSize: '14px', color: '#474747',mb:'6px' }}>
                Code*
              </FormLabel>
              <TextField
                placeholder='Enter code'
                // type='text'
                size='small'
                sx={{ color: '#AFAFAF', height: '20px', mb: 2 }}
                value={otp}
                onChange={handleOtpChange}
                error={!!errors.otp}
                helperText={errors.otp || ""}
              ></TextField>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                my: { xs: '0', sm: '5px' },
              }}>
              {/* <Timer props={timeProps} /> */}
              <Typography sx={{}}>
                Time Remaining {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                {seconds < 10 ? `0${seconds}` : seconds}{' '}
              </Typography>
              <Button
                disableFocusRipple
                disableTouchRipple
                sx={{
                  textTransform: 'capitalize',
                  color: '#474747',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                onClick={() => ResendOtp()}
                disabled={disableRt}
              >
                Resend Code?
              </Button>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Button
                disabled={load}
                sx={{
                  color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff',
                  background: '#8A18D0',
                  height: '43px',
                  textTransform: 'capitalize',
                  my: '5px',
                  '&:hover': { background: '#B37FFC' },
                }}
                onClick={handleOtp}>
                Verify
                <Box>
                  {load && (
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                      <Box sx={BtnLoad} />
                    </Box>
                  )}
                </Box>
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              // sx={{
              //   position: 'absolute',
              //   // top: "60px",
              //   marginTop: '50px',
              //   width: '100%',
              //   left: 0,
              //   zIndex: 1300,
              // }}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  variant='filled'
                  sx={{ width: '100%' }}>
                  {message}
                </Alert>
              </Snackbar>
            </Box>
          </FormControl>
        )}
      </Box>
    </Box>
  );
};
