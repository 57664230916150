import { createSlice } from '@reduxjs/toolkit';

export const bundleSlice = createSlice({
    name: 'allbundle',
    initialState: {
        allbundle: null, // Initial state as an object
        datamartBundles: { allDataMartBundles: JSON.parse(localStorage.getItem('DataMartBundles')) || [], },
        allUsers: null,
        type: null,
    },
    reducers: {
        setAllUsers: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.allUsers = action.payload; // Store the new bundle data
        },
        setType: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.type = action.payload; // Store the new bundle data
        },
        setDataMartBundles: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.datamartBundles.allDataMartBundles = action.payload; // Store the new bundle data
        },
        updateBundles: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.allbundle = action.payload; // Store the new bundle data
        },
        clearBundles: (state) => {
            state.allbundle = []; // Clear the bundle data
        },
    },
});

// Export the actions
export const { setAllUsers,setType, setDataMartBundles, updateBundles, clearBundles } = bundleSlice.actions;

// Export the reducer
export default bundleSlice.reducer;
