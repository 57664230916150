import { Avatar, Box, Button, MenuItem, Select, Slider, Typography } from "@mui/material";
import { filterDataByTime, modifyUserProductTable, ProductNotification, productWordCount, Ratings } from "../../../../../../components/@extended/CustomFunctions";
import { SimpleCard2 } from "../../../../../../components/card/Plan";
import { NotificationData, SampleDescription } from "../../../../../../utils/data/CorporateNormalUserDatas/Data";
import { useEffect, useState } from "react";
import { ComponentTopBtn } from "../../../../../../components/@extended/Button";
import { DataG_T_1 } from "../../../../../../components/table/Data_Grid/type - 1";
import { ConfigStyleBar2, ConfigStyleBarProductPageForSellerTable } from "../../../../../../utils/data/IndividualDashboard/Data";
import { useSelector } from "react-redux";
import { SellerData } from "../../../../../../utils/data/SellerData";
import { CorporateNormalUserTable, TablePagination } from "../../../../../../components/@extended/ModifiedTable";
import { WordCloudChart } from "../../../../../../components/word-cloud/WordCloudChart";
import { TextDescription } from "../../../../../../components/@extended/Textarea";
import { useLocation } from "react-router-dom";
import http from '../../../../../../utils/http-common'
import { GetPriceWithOutSymbol } from "../../../../../../components/@extended/CurrencyConversion";


export const ProductHeader = ({ prop }) => {
    return (
        <Box>
            <Typography sx={{ fontWeight: '600', fontSize: '20px', color: '#000' }}>{prop?.productData?.product_name}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                <Ratings prop={{ value: prop?.productData?.ratings }} />
                <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#2B2B2B', ml: 1 }}>{prop?.productData?.no_of_ratings} Ratings</Typography>
            </Box>
        </Box>
    );
}

export const ProductImgs = ({ prop }) => {
    const [currImg, setCurrImg] = useState(0)
    const Pimgs = Array.isArray(prop?.product_image?.image_url) ? prop?.product_image?.image_url : [prop?.product_image?.image_url]
    const [value1, setValue1] = useState([0, 100]);
    const minPrice = prop?.sellerDetails && prop?.sellerDetails?.length > 0
        ? Math.min(...prop?.sellerDetails?.map((e) => e?.seller_price))
        : prop?.productData?.price;
    // const handleChange1 = (event, newValue, activeThumb) => {
    //     if (!Array.isArray(newValue)) {
    //         return;
    //     }

    //     if (activeThumb === 0) {
    //         setValue1([Math.min(newValue[0], value1[1]), value1[1]]);
    //     } else {
    //         setValue1([value1[0], Math.max(newValue[1], value1[0])]);
    //     }
    // };
    // console.log('Math.min(prop?.sellerDetails?.map((e, i) => (e.seller_price))', prop?.sellerDetails, prop?.sellerDetails?.map((e, i) => (e.seller_price)), Math.min(...prop?.sellerDetails?.map((e, i) => (e.seller_price)), 0));

    console.log('minprice', minPrice);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '350px', mt: 2, gap: 1 }}>
            <Box sx={{ width: '100%' }}>
                <img src={Pimgs[currImg]} style={{ border: '1px solid #797F8F80', borderRadius: '6px', width: '100%', minHeight: '320px', maxHeight: '400px', height: 'auto', objectFit: 'contain' }} alt="" />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
                {Pimgs?.map((e, i) => {
                    return (
                        <Button disableTouchRipple disableFocusRipple disableGutters key={i} onClick={() => setCurrImg(i)} sx={{ height: '110px', width: '110px' }}><img src={e} style={{ border: '1.5px solid #BCBFC7', borderRadius: '4px', width: '100%', minHeight: '70%', maxHeight: '90px', height: 'auto', objectFit: 'contain' }} alt="" /></Button>
                    )
                })}
            </Box>
            <Typography sx={{ fontWeight: '600', fontSize: '12px', color: '#25252D' }}>Product Price Details</Typography>
            {/* <Slider value={value1} sx={{ color: '#8A18D0', height: '14px', width: '100%', '& .MuiSlider-thumb': { border: '2px solid #fff', }, }} valueLabelDisplay="auto" aria-label="prettified" /> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SimpleCard2 x={{ title: GetPriceWithOutSymbol(Number(minPrice)), value: 'Minimum price' }} wd={'49'} />
                <SimpleCard2 x={{ title: GetPriceWithOutSymbol(Number(prop?.productData?.price)), value: 'Maximum Price' }} wd={'49'} />
            </Box>
        </Box>
    );
}
export const NotificationCard = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: '', border: '1px solid #0000001A', borderRadius: '10px', my: '5px', p: '10px' }}>
            <Box sx={{ display: 'flex' }}>
                {data.img && <Avatar src={data.img} alt="" sx={{ borderRadius: 0, height: '40px', pr: '15px', width: 'auto' }} />}
                <Box>
                    <Typography sx={{ color: '#191919', fontWeight: '600', fontSize: '15px' }}>{data.title}</Typography>
                    <Typography sx={{ color: '#000000', fontWeight: '400', fontSize: '13px' }}>{data.description}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                {/* <Button sx={{ fontWeight: "500", fontSize: "12px", color: "#8A18D0", px: "17px", py: 0, height: "25px", width: "min-content", display: "flex", alignItems: "center", ml: "5px", background: "#F3E7FA", borderRadius: "16px", }}>
                    Details
                </Button> */}
                <Typography sx={{ color: '#8A18D0', fontWeight: '400', fontSize: '12px' }}>{data.date}</Typography>
            </Box>
        </Box>
    );
}
function filterSpecificSymbols(text, symbolsToRemove) {
    let filteredText = '';
    for (let char of text) {
        if (!symbolsToRemove.includes(char)) {
            filteredText += char;
        }
    }
    return filteredText;
}

export const Description = ({ prop }) => {
    const props = { datas: [{ content: [typeof prop?.productData?.product_description === 'string' && prop.productData.product_description.trim() !== '' ? filterSpecificSymbols(prop.productData.product_description, ['\u2013', '\u2014', '\u00A0', '']) : ''] }], style: { box1: { width: '100%', px: 5 }, box2: { width: '100%' }, title: { fontSize: '18px', fontWeight: '600' }, description: { fontSize: '16px', fontWeight: '400', color: '#2B2B2B' } } }
    // console.log('tblll', prop, prop?.sellerDetails)
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <TextDescription prop={props} />
        </Box>
    )
}

export const Sellers = ({ prop }) => {
    const subnav = useSelector((state) => state.navs.sideNav.subNav);
    const [subNav, setSubNav] = useState(subnav !== -1 ? subnav : 0);
    const tbl = modifyUserProductTable(prop?.sellerDetails || []);
    console.log('tblll', prop?.sellerDetails)
    const [page, setPage] = useState(1);
    const rowsPerPage = 8;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };
    const filteredData = tbl;
    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
    const paginatedData = filteredData.slice(startIndex, endIndex);
    const props = { filteredData: filteredData, rowsPerPage, page, setPage }
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#000' }}>Sellers List</Typography>
            </Box>
            <DataG_T_1 Data={paginatedData} Style={ConfigStyleBarProductPageForSellerTable} sorting={true} />
            <TablePagination prop={{ filteredData: filteredData, rowsPerPage: rowsPerPage, page: page, setPage: setPage, handlePreviousPage: handlePreviousPage, handleChangePage: handleChangePage, totalPages: totalPages }} />
            {/* <CorporateNormalUserTable prop={props} /> */}
        </Box>
    )
}

export const Reviews = ({ prop }) => {
    const [selectedReview, setSelectedReview] = useState(0);
    const prop2 = [
        { text: 'great', value: 20 },
        { text: 'product', value: 21 },
        { text: 'durable', value: 20 },
        { text: 'long-lasting', value: 20 },
        { text: 'easy', value: 92 },
        { text: 'use', value: 85 },
        { text: 'powerful', value: 20 },
        { text: 'versatile', value: 21 },
        { text: 'tough', value: 20 },
        { text: 'jobs', value: 20 },
        { text: 'accessories', value: 20 },
        { text: 'price', value: 20 },
        { text: 'high', value: 20 },
        { text: 'worth', value: 20 },
        { text: 'recommend', value: 20 },
        { text: 'customer', value: 20 },
        { text: 'service', value: 20 },
        { text: 'perfect', value: 20 },
        { text: 'condition', value: 20 },
        { text: 'issues', value: 20 },
        { text: 'resolved', value: 20 },
        { text: 'heavy', value: 20 },
        { text: 'handle', value: 27 },
        { text: 'loud', value: 20 },
        { text: 'issue', value: 20 },
        { text: 'assemble', value: 20 },
        { text: 'well-built', value: 20 },
        { text: 'sturdy', value: 20 },
        { text: 'maintain', value: 26 },
        { text: 'efficient', value: 20 },
        { text: 'effective', value: 20 },
        { text: 'clean', value: 20 },
        { text: 'store', value: 26 },
        { text: 'variety', value: 20 },
        { text: 'tasks', value: 20 },
        { text: 'adjust', value: 26 },
        { text: 'customize', value: 26 },
        { text: 'transport', value: 26 },
        { text: 'move', value: 20 },
        { text: 'operate', value: 28 },
        { text: 'control', value: 26 },
        { text: 'read', value: 26 },
        { text: 'understand', value: 20 },
        { text: 'set', value: 26 }
    ]

    // const productReviews = [
    //     {
    //         text: 'Great product, very durable and long-lasting',
    //         words: ['great', 'product', 'durable', 'long-lasting'],
    //     },
    // ]

    // productReviews.forEach((review) => {
    //     review.words.forEach((word) => {
    //         if (!data[word]) {
    //             data[word] = 20;
    //         } else {
    //             data[word]++;
    //         }
    //     });
    // });
    // Like this it is used to generate the cloud words
    const filteredDatas = filterDataByTime(prop?.reviews, selectedReview)
    const word = productWordCount(filteredDatas)
    console.log('filteredDatas', filteredDatas, word)
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', userSelect: "none", flexDirection: 'column' }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#000000" }}>Product Reviews</Typography>
                <Select
                    value={selectedReview}
                    onChange={(e) => setSelectedReview(e.target.value)}
                    displayEmpty
                    sx={{ p: 0, height: '36px', width: "140px", }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={0}>All Review</MenuItem>
                    <MenuItem value={1}>Last Month</MenuItem>
                    <MenuItem value={2}>Last Year</MenuItem>
                </Select>
            </Box>
            {word?.length === 0 || !word ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography>Reviews Unavailable</Typography>
                </Box>
                :
                <WordCloudChart data={word} />
            }
        </Box>
    )
}

export const Notifications = () => {
    const [product_id_seq, setProduct_id_seq] = useState(null);
    const [res, setRes] = useState(null);
    const location = useLocation();
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);
        if (params.get("product_id_seq")) {
            const rawProductId = params.get("product_id_seq");
            const decodedProductId = decodeURIComponent(rawProductId);
            setProduct_id_seq(decodedProductId);
            console.log('Decoded product_id:', decodedProductId, rawProductId);
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    const Get = async () => {
        try {
            const response = await http.get(`master_service/product_notification/page/${product_id_seq}`, JSON.stringify({
                // const response = await http.get(`master_service/product_page_info/${product_id}`, JSON.stringify({
                product_id_seq: product_id_seq,
            }))
            console.log("response noti:", response?.data?.data);
            setRes(ProductNotification(response?.data?.data))
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => { if (product_id_seq !== null) { Get() } }, [product_id_seq])
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', p: 2, pr: 0 }}>
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#000' }}>Product Reviews</Typography>
            </Box>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', pr: 2, maxHeight: '53vh' }}>
                {/* {NotificationData?.map((e, i) => <NotificationCard data={e} key={i} />)} */}
                {!res ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography>Notifications Unavailable</Typography>
                    </Box>
                    :
                    res?.map((e, i) => <NotificationCard data={e} key={i} />)
                }
            </Box>
        </Box>
    )
}

export const ProductDetails = ({ prop }) => {
    const [tab, setTab] = useState(0);
    // const Btns = prop?.sellerDetails ? [{ name: 'Description', i: 0, }, { name: 'Sellers', i: 1, }, { name: 'Reviews', i: 2, }, { name: 'Notifications', i: 3, },] : [{ name: 'Description', i: 0, }, { name: 'Reviews', i: 2, }, { name: 'Notifications', i: 3, },]
    const Btns = [{ name: 'Description', i: 0, }, ...(prop?.sellerDetails ? [{ name: 'Sellers', i: 1 }] : []), { name: 'Reviews', i: 2, }, { name: 'Notifications', i: 3, },]
    // console.log('btn', Btns)
    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                {Btns?.map((e, i) => (
                    <ComponentTopBtn btn={{ ...e, i: e.i, tab: tab, setTab: setTab, first: i === 0, last: i === Btns.length - 1 }} key={i} />
                ))}
            </Box>
            <Box sx={{ border: '1px solid #797F8F80', borderRadius: '8px', borderTopLeftRadius: '0px', minHeight: '550px', height: '100%', width: '100%' }}>
                {tab === 0 && <Description prop={prop} />}
                {tab === 1 && <Sellers prop={prop} />}
                {tab === 2 && <Reviews prop={prop} />}
                {tab === 3 && <Notifications />}
            </Box>
        </Box>
    )
}