import { Element } from "react-scroll";
import { DataMartHero, DataMartMainContent } from "./components";
import { HeaderFooter } from "../../../components/nav/NormalHeaderFooter";
import { SampleDataMartDatas } from "../../../utils/data/LandingPage/Data";
import { Box } from "@mui/material";


export const DataMart = () => {
    return (
        <HeaderFooter width={{ xs: '85%', md: '85%' }}>
            <Element name="Datamart_Hero">
                <Box sx={{ pt: 3 }}>
                    <DataMartHero />
                </Box>
            </Element>
            <Element name="Datamart_Content">
                <DataMartMainContent />
            </Element>
        </HeaderFooter>
    );
};