import { Box, Button, Rating, TextField, Typography } from '@mui/material';
import {
  BrandsIcon,
  CategoriesChoosenIcon,
  CountriesAddedIcon,
  EcommerceIcon,
  TotalCreditCostIcon,
} from '../../utils/icon/IndividualPages/Icon';
import StarIcon from '@mui/icons-material/Star';
import { dateClnse, RailwayTimeToNormalTime, timeAgo } from '../Public/DateClnse';
import { Link } from 'react-router-dom';
import { RoundedBtn } from './Button';
import { ActiveBundle } from './ApiCall';
import { ThreeDot2 } from './ThreeDots';
import { NotiIcons } from '../../utils/icon/CorporateNormalUserDatas/Icon';
import { countries, DataMartCapitalBrands, StaticImgExport } from '../../utils/data/LandingPage/Data';
import http from '../../utils/http-common';
import { useDispatch } from 'react-redux';
import { handleClick, setMessage, setSeverity } from '../../slice/SnackPop';
import { useEffect, useState } from 'react';
import { setRechargeCreditsDatas } from '../../slice/adminDashboard';
import { GetPriceWithOutFunctionalPrice, GetPriceWithOutSymbol, GetPriceWithSymbol, GetPriceWithSymbolNoDecimal } from './CurrencyConversion';

export const modifyDataIDP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: item.estimated_credits.toString().split('.')[0],
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(item.ind_bundle_config_date),
      },
    };
  });
};

export const modifyDataSelectionSampleDataTable = (d) => {
  if (d.length === 0 || !d) {
    return [{
      product: '',
      category: '',
      country: '',
      'E-Commerce Site': '',
      brand: '',
      seller: '',
      product_price: '',
      discount: '',
      seller_price: '',
      review: '',
      rating: '',
      stock_status: '',
    }]
  }
  return d?.map((e, i) => {
    // const formattedDate = e.bundle_date ? dateClnse(new Date(e.bundle_date)) : '-';
    // console.log('e?.product_id', e?.product_id, e?.product_id?.length)
    return {
      product: e?.product_name ? (
        <Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', minWidth: '300px' }}>{e?.product_name}</Typography>
      ) : '',
      category: e?.category_name || '',
      country: e?.country_name || '',
      'E-Commerce Site': e?.site_name || '',
      brand: e?.brand_name || '',
      seller: e?.seller_name ? (
        <Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', minWidth: '100px' }}>{e?.seller_name}</Typography>
      ) : '',
      product_price: e?.product_price || '',
      discount: e?.discount || '',
      seller_price: e?.seller_price || '',
      // review: '',
      rating: e?.ratings || e?.ratings === 0 ? (
        <Rating
          name='rating'
          value={parseFloat(e?.ratings)}
          precision={0.5}
          readOnly
          emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />}
          icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />}
        />
      ) : '',
      stock_status: e?.stock_available || '',
    }
  })
}


export const modifyBundleRunLogTable = (d) => {
  if (d.length === 0) {
    return [{
      "S.No": '',
      Bundle_Name: '',
      Date: '',
      Time: '',
      Credit_Cost: ''
    }]
  }
  return d.map((e, i) => {
    const formattedDate = e.bundle_date ? dateClnse(new Date(e.bundle_date)) : '-';
    return {
      "S.No": d?.length - i,
      Bundle_Name: e?.bundle_name,
      Date: formattedDate,
      Time: RailwayTimeToNormalTime(e?.bundle_time),
      Credit_Cost: Number(e?.credit_cost) === 0 ? 'No Credits' : `${e?.credit_cost} Credits`,
    }
  })
}


export const modifyRechargeCregitTable = (d) => {
  if (d.length === 0) {
    return [{
      user_name: '',
      email: '',
      "Transaction No.": '',
      amount: '',
      Credits: '',
      Payment_Type: '',
      date: '',
      // Invoice: ''
    }]
  }
  return d?.sort((a, b) => new Date(b?.payment_date) - new Date(a?.payment_date))?.map((e, i) => {
    const formattedPaymentDate = e.payment_date ? dateClnse(new Date(e.payment_date)) : '-';
    return {
      user_name: e.name || '-',
      email: e.user_email || '-',
      "Transaction No.": e.transaction_no || '-',
      amount: GetPriceWithSymbolNoDecimal(Number(e.request_amt)) || '-',
      Credits: e.applicable_credit || '-',
      Payment_Type: e.payment_type || '-',
      date: formattedPaymentDate,
      // Invoice: e.Invoice
    }
  })
}
export const modifyDataABLP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: Math.round(item.estimated_credits),
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(new Date(item.ind_bundle_config_date)),
        // Frequency_of_data: item.frequency,
        'Active / Inactive': <ActiveBundle i={item} />,
      },
    };
  });
};

export const modifyUserLandingTable = ({ d, ee, data }) => {
  if (d.length === 0) {
    return [{
      'E-Commerce Site': '',
      minimum_price: '',
      maximum_price: '',
      sentiment: '',
      Average_Rating: '',
      No_Of_Sellers: '',
      No_Of_Products: '',
    }];
  }

  const getSentiment = (rating) => {
    if (rating >= 4.5 && rating <= 5) return 'Excellent';
    if (rating >= 3.5 && rating < 4.5) return 'Very Good';
    if (rating >= 2.5 && rating < 3.5) return 'Good';
    if (rating >= 1.5 && rating < 2.5) return 'Bad';
    if (rating >= 1 && rating < 1.5) return 'Worst';
    return 'N/A';
  };


  return d.map((e, i) => ({
    'E-Commerce Site': e.site_name,
    minimum_price: GetPriceWithOutSymbol(Number(e.min_price)),
    maximum_price: GetPriceWithOutSymbol(Number(e.max_price)),
    sentiment: getSentiment(parseFloat(e.average_rating)),
    Average_Rating: e.average_rating &&
      (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Rating
          name='rating'
          value={parseFloat(e.average_rating)}
          precision={0.5}
          readOnly
          emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />}
          icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />}
        />
        &nbsp;({e.ratings_count})
      </Box>),
    No_Of_Sellers: <Link to={`/user/brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(1)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.seller_count}</Link>,
    No_Of_Products: <Link to={`/user/brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(0)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.product_count}</Link>,
  }));
};


export const modifyAdminLandingTable = ({ d, btnD }) => {
  return d.length === 0 || (d.length === 1 && d[0].user_id === '') ?
    ([{
      user_id: '-',
      user_first_name: '-',
      user_email: '-',
      Name: null,
      user_role: null,
      joined_on: null,
      invite_status: null,
      user_status: null,
    }])
    :
    (d.map((e, i) => {
      const formattedJoinedOn = e.joined_on ? new Date(e.joined_on).toLocaleDateString() : '-';
      return {
        user_id: e.user_id,
        user_first_name: e.user_first_name,
        user_email: e.user_email,
        user_status_og: e.user_status,
        invite_status_og: e.invite_status,
        Name: (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Box sx={{ height: "40px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "#474747", color: "#fff", fontSize: "20px", fontWeight: "bold", marginRight: "8px" }}>
              {e.user_first_name.charAt(0).toUpperCase()}
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#2B2B2B' }}>{e.user_first_name}</Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2B', pb: 1 }}>{e.user_email}</Typography>
            </Box>
          </Box >
        ),
        user_role: e.user_role,
        joined_on: formattedJoinedOn,
        invite_status: e.invite_status === 'Pending' ? (<RoundedBtn col='#FF974C' txt={e.invite_status} />) : (<RoundedBtn col='#008000' txt={e.invite_status} />),
        user_status: e.user_status === 'Deactivate' ? (<RoundedBtn col='#FF974C' txt={e.user_status} />) : (<RoundedBtn col='#008000' txt={e.user_status} />),
        // '': <ThreeDot2 data={btnD} i={i} />
      }
    }))
}

export const modifyUserAllSellerTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      No_Of_Products: '',
      'Reviews': '',
      'Ratings': '',
      '': '',
    }]
  }
  // console.log('d', d)
  return d?.map((e, i) => {
    // console.log('sell', e?.seller_ratings_count !== 0, e?.seller_ratings_count, e.seller_name)
    return {
      Seller_Name: e.seller_name,
      No_Of_Products: e.no_of_products,
      'Reviews': e.seller_reviews_count,
      'Ratings': e.seller_ratings ? (<Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Rating name='rating' value={parseFloat(e.seller_ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />} icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />} />
        &nbsp;{e?.seller_ratings_count !== 0 ? `(${e?.seller_ratings_count})` : null}
      </Box>) : null,
      '': (
        <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}&country_id=${encodeURIComponent(e?.country_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserAllProductTable = (d) => {
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      '': '',
    }]
  }
  return d.map((e, i) => {
    return {
      product_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': GetPriceWithOutSymbol(Number(e.max_price)),
      'Min Price (₹)': GetPriceWithOutSymbol(Number(e.min_price)),
      'MRP (₹)': GetPriceWithOutSymbol(Number(e.mrp)),
      '': (
        <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}&country_id=${encodeURIComponent(e?.country_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserBrandProductTable = ({ d }) => {
  // console.log('fn', d)
  const btn = localStorage.getItem('user_role') === 'Admin User' ? false : true
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      ...(btn && {
        '': '',
      })
    }]
  }



  return d.map((e, i) => {
    // console.log('GetPriceWithOutSymbol(Number(e.max_price))', e?.max_price,Number(e.max_price),GetPriceWithOutSymbol(Number(e.max_price)));
    // console.log('GetPriceWithOutSymbol(Number(e.max_price))2', e.max_price, Number(e.max_price), GetPriceWithOutSymbol(Number(e.max_price)));
    return {
      produce_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': GetPriceWithOutSymbol(Number(e.max_price)),
      'Min Price (₹)': GetPriceWithOutSymbol(Number(e.min_price)),
      'MRP (₹)': GetPriceWithOutSymbol(Number(e.mrp)),
      ...(btn && {
        '': (
          <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}&country_id=${encodeURIComponent(e?.country_id)}`}>
            <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
          </Link>
        )
      }),
    }
  })
}

export const modifyUserBrandSellerTable = ({ d }) => {
  const btn = localStorage.getItem('user_role') === 'Admin User' ? false : true
  if (d.length === 0) {
    return [{
      seller_name: '',
      No_Of_Products: '',
      Reviews: '',
      Ratings: '',
      ...(btn && {
        '': '',
      })
    }]
  }

  return d.map((e, i) => {
    return {
      seller_name: e.seller_name,
      No_Of_Products: e.no_of_products,
      Reviews: e?.seller_reviews_count && e?.seller_reviews_count !== 0 ? (<Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', textWrap: 'wrap', maxWidth: '500px' }}>{e?.seller_reviews_count}</Typography>) : null,
      Ratings: e?.seller_ratings ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}><Ratings prop={{ value: e.seller_ratings }} />{e?.seller_ratings_count && e?.seller_ratings_count !== 0 ? `(${e?.seller_ratings_count})` : ''}</Box>
      ) : '0',
      ...(btn && {
        '': (
          <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}&country_id=${encodeURIComponent(e?.country_id)}`}>
            <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
          </Link>
        ),
      }),
    }
  })
}

export const modifyUserSellerProductTable = (d) => {
  if (d.length === 0) {
    return [{
      brand: '',
      product_list: '',
      MRP: '',
      discount: '',
      final_price: '',
      Link: '',
    }]
  }

  return d.map((e, i) => {
    return {
      brand: e.brand_name,
      product_list: e.product_name,
      MRP: GetPriceWithOutSymbol(Number(e.seller_price)),
      discount: Math.round(e.discount) + '%',
      final_price: GetPriceWithOutSymbol(Number(e?.product_price)),
      Link: (
        <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}&country_id=${encodeURIComponent(e?.country_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserSellerEcormTable = (d) => {
  if (d.length === 0) {
    return [{
      'E-Commerce': '',
      Products: '',
      Total_Reviews: '',
      Total_Ratings: '',
    }]
  }

  return d.map((e, i) => {
    return {
      'E-Commerce': e.site_name,
      Products: e.no_of_products,
      Total_Reviews: e.total_reviews,
      Total_Ratings: e.total_rating && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}><Ratings prop={{ value: e.total_rating }} />({e?.rating_count})</Box>
      ),
    }
  })
}
export const modifyUserProductTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      Price: '',
      Discount: '',
      Stock: '',
      Platform: '',
      Average_Rating: '',
    }]
  }

  return d.map((e, i) => {
    return {
      Seller_Name: e.seller_name,
      Price: GetPriceWithOutSymbol(Number(e.seller_price)),
      Discount: Number(e.discount),
      Stock: e.stock,
      Platform: e.site_name,
      Average_Rating: e?.seller_ratings && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Ratings prop={{ value: e?.seller_ratings }} />
          {/* {e?.seller_ratings_count && `(${e?.seller_ratings_count})`} */}
        </Box>
      ),
      // Average_Rating: (
      //   <Box><Ratings prop={{ value: e.seller_ratings }} /></Box>
      // ),
    }
  })
}

export function base64ToBlob(base64, mime) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}
export function extractBase64Data(base64String) {
  const base64Data = base64String.split(",")[1];
  return base64Data;
}
export const Ratings = ({ prop }) => {
  return (
    <Rating name="rating" value={parseFloat(prop?.value)} precision={0.1} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
  );
}

export const parseProperly = (d) => {
  if (d === undefined || d === null) {
    return []
  } else {
    // return d;
    try {
      return JSON.parse(d);
    } catch (error) {
      console.error("Error parsing data:", error);
      return [];
    }

    // return JSON.parse(d)
  }
}

/**
 * Function to calculate the percentage difference between two numbers
 * @param {number} value1 - The first number
 * @param {number} value2 - The second number
 * @returns {number} - The percentage difference
 */
export const calculatePercentageDifference = (value1, value2) => {
  if (value1 === 0 && value2 === 0) {
    return 0; // No difference if both values are zero
  }

  const difference = Math.abs(value1 - value2);
  const average = (value1 + value2) / 2;
  const percentageDifference = (difference / average) * 100;

  return percentageDifference.toFixed(2);
  // return percentageDifference;
}

// export const NotificationFilter = (d) => {
//   return d?.map((e) => {
//     let cf = -1;
//     let st = ''
//     // if (e?.old_price < e?.current_price) {
//     //   cf = 0;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // } else if (e?.old_price > e?.current_price) {
//     //   cf = 1;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // } else if (e?.created_at === e?.updated_at || null === e?.updated_at) {
//     //   cf = 3;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // }
//     if (e?.price_status?.status === 'price_rocked') {
//       cf = 0;
//       st = `Price rocked by ${calculatePercentageDifference(e?.old_price, e?.current_price)}% for your subscribed product`
//     } else if (e?.price_status?.status === 'price_dropped') {
//       cf = 1
//       st = `${calculatePercentageDifference(e?.current_price, e?.old_price)}% Price droped for your subscribed product`
//       // st = `Price rocked by ${calculatePercentageDifference(e?.current_price, e?.current_price)}% for your subscribed product`
//     } else if (e?.seller_check?.new_seller) {
//       cf = 2
//       st = `New Seller Found !!!`
//     } else if (e?.new_product) {
//       cf = 2
//       st = `New Product from ${e?.brand_name} !!!`
//     }
//    console.log('cf', cf)
//     return {
//       ...e,
//       prodName: e?.product_name,
//       text: e?.brand_name,
//       subText: st,
//       // time: '30 Min Ago',
//       EcommName: e?.site_name,
//       Seller: e?.seller_name,
//       prodPrice: `₹ ${e?.price_status?.current_price}`,
//       time: timeAgo(cf === 3 ? e?.created_at : e?.updated_at),
//       categoryFilter: cf
//     }
//   });
// }

export const NotificationFilter = (notifications) => {
  return notifications?.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at))?.map((notification) => {
    let categoryFilter = -1;
    let notificationMessage = '';

    // Check price status and set category and message accordingly
    if (notification?.price_status?.status === 'price_rocked') {
      categoryFilter = 0;
      notificationMessage = `Price rocked by ${calculatePercentageDifference(notification?.old_price, notification?.current_price)}% for your subscribed product`;
    } else if (notification?.price_status?.status === 'price_dropped') {
      categoryFilter = 1;
      notificationMessage = `${calculatePercentageDifference(notification?.current_price, notification?.old_price)}% price dropped for your subscribed product`;
    } else if (notification?.seller_check?.new_seller) {
      categoryFilter = 2;
      notificationMessage = `New seller found!`;
    } else if (notification?.new_product) {
      categoryFilter = 2;
      notificationMessage = `New product from ${notification?.brand_name}!`;
    }

    // console.log('Category Filter:', categoryFilter);

    return {
      ...notification,
      prodName: notification?.product_name,
      text: notification?.brand_name,
      subText: notificationMessage,
      EcommName: notification?.site_name,
      Seller: notification?.seller_name,
      prodPrice: `₹ ${notification?.price_status?.current_price}`,
      time: timeAgo(categoryFilter === 3 ? notification?.created_at : notification?.updated_at),
      categoryFilter: categoryFilter
    };
  });
};
export const ProductNotification = (notifications) => {
  return notifications?.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at))?.map((notification) => {
    let categoryFilter = -1;
    let notificationTitle = '';
    let notificationMessage = '';
    let notificationIcon = null;

    // Check price status and set category and message accordingly
    if (notification?.price_status?.status === 'price_rocked') {
      categoryFilter = 0;
      notificationTitle = 'Price Rocketed ...🚀'
      notificationIcon = NotiIcons.PriceUp
      notificationMessage = `The price has been increased ${calculatePercentageDifference(notification?.old_price, notification?.current_price)}%.`;
    } else if (notification?.price_status?.status === 'price_dropped') {
      categoryFilter = 1;
      notificationTitle = 'Price Drop'
      notificationIcon = NotiIcons.PriceDrop
      notificationMessage = `${calculatePercentageDifference(notification?.current_price, notification?.old_price)}%  Price drop on your subscribed product. Grab it now!`;
    } else if (notification?.seller_check?.new_seller) {
      categoryFilter = 2;
      notificationTitle = 'New Seller'
      notificationIcon = NotiIcons.NewSeller
      notificationMessage = `Your subscribed product has a new seller, do check it now!!!`;
    }

    // console.log('Category Filter:', categoryFilter);

    return {
      ...notification,
      prodName: notification?.product_name,
      text: notification?.brand_name,
      subText: notificationMessage,
      EcommName: notification?.site_name,
      Seller: notification?.seller_name,
      prodPrice: `₹ ${notification?.price_status?.current_price}`,
      time: timeAgo(categoryFilter === 3 ? notification?.created_at : notification?.updated_at),
      categoryFilter: categoryFilter,
      img: notificationIcon,
      title: notificationTitle,
      description: notificationMessage,
      date: dateClnse(notification?.updated_at),
    };
  });
}

export const filterDataByTime = (d, time) => {
  const currentDate = new Date();
  const filteredData = d?.filter(entry => {
    const entryDate = new Date(entry.date);
    if (time === 0) {
      // Return all data
      return true;
    } else if (time === 1) {
      // Return data from the last month
      const lastMonth = new Date();
      lastMonth.setMonth(currentDate.getMonth() - 1);
      return entryDate >= lastMonth;
    } else if (time === 2) {
      // Return data from the last year
      const lastYear = new Date();
      lastYear.setFullYear(currentDate.getFullYear() - 1);
      return entryDate >= lastYear;
    }
  });

  return filteredData;
};

export const productWordCount = (reviews) => {
  const stopWords = ["i", "a", "am", "the", "is", "in", "on", "of", "for", "to", "and", "it", "this", "as", "so", "we", "had", "that", "with", "at", "by", "an", "be", "are", "from", "or", "not", "can", "if", "but", "your", "you", "my", "have", "was", "do", "does", "did", "all", "our", "will", "they", "them", "there", "their", "been", "being", "would", "could", "should", "because", "about", "us", "more", "very", "any", "these", "other", "some", "such", "than", "then", "just", "like"];
  const data = {};
  reviews?.forEach(review => {
    const words = review?.review?.replace(/[^\w\s]/g, '')?.split(' ')?.filter(word => !stopWords?.includes(word.toLowerCase()) && isNaN(word));
    words?.forEach((word) => {
      if (!data[word]) { data[word] = 1; } else { data[word]++; }
    });
  });

  const result = Object.keys(data).map((key) => {
    return { text: key, value: data[key] };
  });

  return result;
};

export const CountryFlagEmoji = ({ countryCode }) => {
  if (!countryCode || countryCode.length !== 2) {
    return null; // Or display a default placeholder
  }

  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  return (
    <span role="img" aria-label={`${countryCode} flag`}>
      {String.fromCodePoint(...codePoints)}
    </span>
  );
};
export function getFlag(country) {
  const countryCode = countries?.find((e) => e.label === country)?.code?.toLowerCase()
  // console.log('contry', country, countryCode);

  return <img
    loading="lazy"
    // width="20"
    srcSet={`https://flagcdn.com/24x18/${countryCode}.webp`}
    src={`https://flagcdn.com/24x18/${countryCode}.webp`}
    alt=""
  />
}

export const ConvertDataMartBundles = (d) => {
  if (d?.length === 0) {
    return null;
    return [
      {
        gradientTxt: { txt1: null, txt2: null },
        bundleName: null,
        bundle_id: null,
        contryCode: null,
        bundleDescrp: null,
        bundlePrice: null,
        category: null,
        country: null,
        platform: null,
        brand: null,
        dateFrom: null,
        dateTo: null,
        noOfProducts: null,
        dataPoints: null,
        lineItems: null,
        bg: StaticImgExport?.datamart?.[1],
        cardImg: null,
        overviewImg: null
      },
    ]
  }

  return d?.map((e, i) => {
    // const disc = Math.floor(Math.random() * 101)
    const usd_currendyData = JSON.parse(localStorage.getItem('usd_currendyData'))
    const currency = localStorage.getItem('currency');
    const disc = Math.floor(e?.discount)
    const finalPrice = e?.discount ? Math.round(e?.setup_cost - (e?.setup_cost * (disc / 100))) : e?.setup_cost;
    // const finalIndPrice = finalPrice;
    const finalIndPrice = finalPrice * usd_currendyData?.[currency];
    // console.log('GetPriceWithSymbol', GetPriceWithSymbolNoDecimal(finalPrice),GetPriceWithSymbol(Math.round(e?.setup_cost)),finalIndPrice, usd_currendyData, currency, usd_currendyData?.[currency]);

    // const finalPrice = e?.discount ? Math.round(e?.estimated_credits - (e?.estimated_credits * (disc / 100))) : e?.estimated_credits;
    const discReducedPrice = Math.floor(e?.setup_cost - finalPrice)
    // const discReducedPrice = Math.floor(e?.estimated_credits - finalPrice)
    // console.log('upc', DataMartCapitalBrands.some(brand =>
    //   String(e?.ind_bundle_brand)?.toLowerCase() === String(brand).toLowerCase()
    // ), e?.ind_bundle_brand, DataMartCapitalBrands);

    return {
      // ogDatas:{
      //   ...e,
      // },
      ...e,
      gradientTxt: { txt1: null, txt2: null },
      bundleName: e?.ind_bundle_name,
      bundle_id: e?.ind_bundle_id,
      contryCode: null,
      bundleDescrp: e?.bundle_description,
      finalPrice: GetPriceWithOutFunctionalPrice(finalPrice),
      finalPriceWithSymbol: GetPriceWithSymbolNoDecimal(finalPrice),
      bundlePrice: GetPriceWithSymbolNoDecimal(Math.round(e?.setup_cost)),
      // bundlePrice: '$ ' + Math.round(e?.estimated_credits),
      discount: disc,
      discountReducedPrice: GetPriceWithSymbolNoDecimal(discReducedPrice),
      discountedPrice: GetPriceWithSymbolNoDecimal(finalPrice),
      category: e?.ind_bundle_category,
      country: e?.ind_bundle_country,
      platform: e?.ind_bundle_e_commerce,
      // brand: e?.ind_bundle_brand,
      brand: e.ind_bundle_brand.map((brand) => {
        // console.log('upc 2', DataMartCapitalBrands.some(capitalBrand => String(brand)?.toLowerCase() === String(capitalBrand)?.toLowerCase()), brand, DataMartCapitalBrands);

        if (DataMartCapitalBrands.some(capitalBrand =>
          brand?.toLowerCase().includes(capitalBrand?.toLowerCase())
        )) {
          return brand?.toUpperCase();
        } else {
          return brand;
        }
      }),
      dateFrom: e?.ind_bundle_start_date,
      dateTo: e?.ind_bundle_end_date,
      noOfProducts: e?.no_of_products,
      dataPoints: e?.data_points,
      lineItems: e?.line_items,
      bg: StaticImgExport?.datamart?.[1],
      cardImg: e?.card_picture,
      overviewImg: e?.overview_picture,
      // overviewImg: StaticImgExport.datamart.bndle1,
      // cardImg: StaticImgExport.datamart.bndle1,
      // overviewImg: StaticImgExport.datamart.BundleOverview,
    }
  })
}


export const AdminDashboardCredit = ({ d }) => {
  const dispatch = useDispatch()
  // const btn = localStorage.getItem('user_role') === 'Admin User' ? false : true 
  if (d.length === 0 || d === undefined || d === null) {
    return {
      tblC: [{
        request_id: '',
        user_id: '',
        user_name: '',
        request_date: '',
        payment_status: '',
        payment_status: '',
      }],
      tblP: [{
        request_id: '',
        user_id: '',
        user_name: '',
        request_date: '',
        payment_status: '',
        payment_status: '',
      }]
    }
  }
  const SendCredit = async ({ recharge_request_id, user_id }) => {
    const entry = d?.find(e => e?.recharge_request_id === recharge_request_id && e.user_id === user_id);
    try {
      const response = await http.post(
        '/master_service/credit_request/update',
        JSON.stringify(entry)
      );

      const successMessage = response.data?.message;
      const errorMessage = response.data?.error;

      if (errorMessage) {
        console.warn('Warning:', errorMessage);
        dispatch(setMessage(errorMessage));
        dispatch(setSeverity('warning'));
      } else {
        //  console.log(successMessage);
        dispatch(setMessage(successMessage || 'Data sent successfully.'));
        dispatch(setSeverity('success'));
      }
      dispatch(handleClick());
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Error sending data.';
      console.error('Error sending data:', errorMessage);
      dispatch(setMessage(errorMessage));
      dispatch(setSeverity('error'));
      dispatch(handleClick());
    }
  }

  return {
    tblC: d?.filter((e) => e?.plan_name === null)?.map((e, i) => {
      return {
        request_id: e?.recharge_request_id,
        user_id: e?.user_id,
        user_name: e?.user?.user_first_name,
        request_date: e?.recharge_request_date,
        payment_status: e?.payment_status && <RoundedBtn txt={e?.payment_status} col={e?.payment_status === 'completed' ? '#008000' : '#FF4C51'} />,
        '': (
          <Button disableElevation disableFocusRipple disableRipple sx={{ background: e?.payment_status === 'completed' ? '#727272' : '#8A18D0', height: '20px', color: '#fff', borderRadius: '2px', textWrap: 'nowrap', textTransform: 'capitalize', '&:hover': { background: e?.payment_status === 'completed' ? '#727272' : '#8A18D0' } }} onClick={() => { if (e?.payment_status !== 'completed') { SendCredit({ recharge_request_id: e?.recharge_request_id, user_id: e?.user_id }) } }}>Send Credit</Button>
        ),
      }
    }),
    tblP: d?.filter((e) => e?.plan_name !== null)?.map((e, i) => {
      return {
        request_id: e?.recharge_request_id,
        user_id: e?.user_id,
        user_name: e?.user?.user_first_name,
        request_date: e?.recharge_request_date,
        payment_status: e?.payment_status && <RoundedBtn txt={e?.payment_status} col={e?.payment_status === 'completed' ? '#008000' : '#FF4C51'} />,
        '': (
          <Button disableElevation disableFocusRipple disableRipple sx={{ background: e?.payment_status === 'completed' ? '#727272' : '#8A18D0', height: '20px', color: '#fff', borderRadius: '2px', textWrap: 'nowrap', textTransform: 'capitalize', '&:hover': { background: e?.payment_status === 'completed' ? '#727272' : '#8A18D0' } }} onClick={() => { if (e?.payment_status !== 'completed') { SendCredit({ recharge_request_id: e?.recharge_request_id, user_id: e?.user_id }) } }}>Send Credit</Button>
        ),
      }
    })
  }
}


export const useAdminCreditPlanRequest = ({ d }) => {
  const dispatch = useDispatch();
  const [fmDatas, setFmDatas] = useState([]);
  const [tblC, setTblC] = useState([]);
  const [tblP, setTblP] = useState([]);

  // Ensure that d is an array
  const getEntryKey = (recharge_request_id, user_id) =>
    `${recharge_request_id}-${user_id}`;
  const dataArray = Array.isArray(d) ? d : [];
  // console.log('trg', dataArray, d);


  // Default values if dataArray is empty
  const defaultTblC = [
    {
      request_id: "",
      request_date: "",
      user_id: "",
      user_name: "",
      amount: "",
      applicable_credits: "",
      payment_type: "",
      transaction_number: "",
      payment_date: "",
      payment_status: "",
      action: "",
    },
  ];
  const defaultTblP = [
    {
      request_id: "",
      request_date: "",
      user_id: "",
      user_name: "",
      plan_name: "",
      amount: "",
      payment_type: "",
      transaction_number: "",
      payment_date: "",
      payment_status: "",
      action: "",
    },
  ];


  const fetchDatasP = async () => {
    try {
      const response = await http.post("/master_service/credit_request");
      // console.log("response tbl products:", response?.data?.data, response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending'));
      dispatch(setRechargeCreditsDatas(response?.data?.data))
      // setTblDataP(response?.data?.data)
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
    }
    catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  // If no valid data, return default table arrays
  // Function to send a credit request
  const SendCredit = async ({ recharge_request_id, user_id }) => {
    //  console.log('dataArray', dataArray);

    const key = getEntryKey(recharge_request_id, user_id);
    const entry = {
      ...dataArray.find(e => e.recharge_request_id === recharge_request_id && e.user_id === user_id),
      ...fmDatas[key]
    };


    // const entry = { ...aRest, ...b };
    try {
      const response = await http.post(
        "/master_service/credit_request/update",
        JSON.stringify(entry)
      );
      const successMessage = response.data?.message;
      const errorMessage = response.data?.error;
      if (errorMessage) {
        console.warn("Warning:", errorMessage);
        dispatch(setMessage(errorMessage));
        dispatch(setSeverity("warning"));
      } else {
        //  console.log(successMessage);
        dispatch(setMessage(successMessage || "Data sent successfully."));
        dispatch(setSeverity("success"));
        fetchDatasP()
      }
      dispatch(handleClick());
    } catch (error) {
      const errMsg = error.response?.data?.error || "Error sending data.";
      console.error("Error sending data:", errMsg);
      dispatch(setMessage(errMsg));
      dispatch(setSeverity("error"));
      dispatch(handleClick());
    }
  };

  // Function to handle field changes for form data
  const handleFieldChange = (fieldName, value, reqId, userId) => {
    const key = getEntryKey(reqId, userId);
    setFmDatas(prev => ({
      ...prev,
      [key]: {
        ...(prev[key] || {}),
        [fieldName]: value
      }
    }));
  };

  // Compute tblC for credit requests (where plan_name is null)
  useEffect(() => {
    if (dataArray.length === 0) {
      setTblC(defaultTblC);
      setTblP(defaultTblP);
      return;
    }

    const computedTblC = dataArray
      .filter((e) => e.plan_name === null)
      .map((e, i) => {
        const key = getEntryKey(e.recharge_request_id, e.user_id);
        const fmData = fmDatas[key] || {};
        // console.log('fmDatas', fmDatas.find((entry) => entry.recharge_request_id === e.recharge_request_id && entry.user_id === e.user_id)?.payment_date || e.payment_date, fmDatas.find((entry) => entry.recharge_request_id === e.recharge_request_id && entry.user_id === e.user_id)?.payment_date, e.payment_date, e?.user_id, e.recharge_request_id, e.payment_status);

        return {
          request_id: e?.recharge_request_id,
          request_date: e?.recharge_request_date,
          user_id: e?.user_id,
          user_name: e?.user?.user_first_name,
          amount: e?.request_amt,
          applicable_credits: e?.applicable_credit,
          payment_type: (
            <TextField
              value={fmData.payment_type || e.payment_type || ''}
              // value={fmData.payment_type ?? e.payment_type}
              onChange={(event) => handleFieldChange("payment_type", event.target.value, e.recharge_request_id, e.user_id)}
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          transaction_number: (
            <TextField
              value={fmData.transaction_no || e.transaction_no || ''}
              // value={fmData.transaction_no ?? e.transaction_no}
              onChange={(event) =>
                handleFieldChange("transaction_no", event.target.value, e.recharge_request_id, e.user_id)
              }
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          payment_date: (
            <TextField
              name='payment_date'
              autoComplete="off"
              value={fmData.payment_date || e.payment_date || ''}
              // value={fmData.payment_date ?? e.payment_date}
              onChange={(event) => handleFieldChange("payment_date", event.target.value, e.recharge_request_id, e.user_id)}
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          payment_status:
            e.payment_status && (
              <RoundedBtn
                txt={e.payment_status === 'completed' ? 'Paid' : 'Not Paid'}
                col={e.payment_status === "completed" ? "#008000" : "#FF4C51"}
              />
            ),
          action: (
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              sx={{
                background: "#727272",
                // background: e.payment_status === "completed" ? "#727272" : "#8A18D0",
                height: "20px",
                color: "#fff",
                borderRadius: "2px",
                textWrap: "nowrap",
                textTransform: "capitalize",
                "&:hover": {
                  // background: e.payment_status === "completed" ? "#727272" : "#8A18D0",
                  background: "#727272",
                },
              }}
              onClick={() => {
                if (e.payment_status !== "completed") {
                  // SendCredit({ recharge_request_id: e.recharge_request_id, user_id: e.user_id });
                }
              }}
            >
              Send Credit
            </Button>
          ),
        }
      });

    // Compute tblP for plan requests (where plan_name is not null)
    const computedTblP = dataArray
      .filter((e) => e.plan_name !== null)
      .map((e, i) => {
        const key = getEntryKey(e.recharge_request_id, e.user_id);
        const fmData = fmDatas[key] || {};
        // console.log('fmData', key, fmData.payment_date ?? e.payment_date, e.payment_date, fmData);

        return {
          request_id: e.recharge_request_id,
          request_date: e?.recharge_request_date, // or use e.recharge_request_date if needed
          user_id: e.user_id,
          user_name: e.user?.user_first_name,
          plan_name: e?.plan_name,
          amount: e?.request_amt,
          payment_type: (
            <TextField
              name='payment_type'
              value={fmData.payment_type || e.payment_type || ''}
              // value={fmData.payment_type ?? e.payment_type}
              onChange={(event) => handleFieldChange("payment_type", event.target.value, e.recharge_request_id, e.user_id)}
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          transaction_number: (
            <TextField
              name='transaction_no'
              value={fmData.transaction_no || e.transaction_no || ''}
              // value={fmData.transaction_no ?? e.transaction_no}
              onChange={(event) =>
                handleFieldChange("transaction_no", event.target.value, e.recharge_request_id, e.user_id)
              }
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          payment_date: (
            <TextField
              name='payment_date'
              autoComplete="off"
              // value={null}
              value={fmData.payment_date || e.payment_date || ''}
              // value={fmData.payment_date ?? e.payment_date}
              onChange={(event) => handleFieldChange("payment_date", event.target.value, e.recharge_request_id, e.user_id)}
              InputProps={{ sx: { height: "20px", borderColor: "#727272" } }}
              sx={{ color: "#727272" }}
              disabled={e.payment_status === 'completed'}
            />
          ),
          payment_status:
            e.payment_status && (
              <RoundedBtn
                txt={e.payment_status === 'completed' ? 'Paid' : 'Not Paid'}
                col={e.payment_status === "completed" ? "#008000" : "#FF4C51"}
              />
            ),
          action: (
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              sx={{
                background: "#727272",
                // background: e.payment_status === "completed" ? "#727272" : "#8A18D0",
                height: "20px",
                color: "#fff",
                borderRadius: "2px",
                textWrap: "nowrap",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#727272",
                  // background: e.payment_status === "completed" ? "#727272" : "#8A18D0",
                },
              }}
              onClick={() => {
                if (e.payment_status !== "completed") {
                  // SendCredit({ recharge_request_id: e.recharge_request_id, user_id: e.user_id });
                }
              }}
            >
              Activate Plan
            </Button>
          ),
        }
      });
    setTblC(computedTblC);
    setTblP(computedTblP);
  }, [dataArray, fmDatas]);

  return { tblC, tblP };
};



export const checkDataMartUploadBundles = (d) => {
  // Check if the input array is empty
  if (!d || d.length === 0) {
    return {
      validData: [],
      success: false,
      errors: 'Input data is empty or undefined.'
    };
  }

  // Define the required fields
  const requiredFields = [
    'ind_bundle_name',
    'ind_bundle_category',
    'ind_bundle_country',
    'ind_bundle_e_commerce',
    'ind_bundle_brand',
    'ind_bundle_start_date',
    'ind_bundle_end_date',
    'overview_picture',
    'card_picture',
    'occation',
    'bundle_description',
    'discount'
  ];
  let validData = [];
  let errors = [];
  let allValid = true;

  // Map and filter the data
  const ret = d
    // .map((e) => {
    //   // Create the object with the required fields
    //   return {
    //     name: e?.ind_bundle_name,
    //     category: e?.ind_bundle_category,
    //     country: e?.ind_bundle_country,
    //     platform: e?.ind_bundle_e_commerce,
    //     brand: e?.ind_bundle_brand,
    //     from_date: e?.ind_bundle_start_date,
    //     to_date: e?.ind_bundle_end_date,
    //     overview_picture: e?.overview_picture,
    //     card_picture: e?.card_picture,
    //     Occation: e?.Occation,
    //     bundle_description: e?.bundle_description,
    //     price: '', // Assuming price is optional or can be set to an empty string
    //     discount: e?.Discount,
    //   };
    // })
    ?.filter((d) => {
      const isSuccess = d?.success !== false
      if (allValid && !isSuccess) {
        allValid = false;
        errors.push({
          bundleId: d?.ind_bundle_name || `Bundle ${index + 1}`,
          message: `${d?.ind_bundle_name} is not a valid Bundle, ${d?.message}, Please modify the configurations`
        });
      }
      return isSuccess
    })
    ?.filter((item) => {
      // Check if all required fields are present
      const fltItm = requiredFields.every(field => item[field] !== undefined && item[field] !== null);
      // console.log('fltItm', fltItm, item);

      if (!fltItm) {
        allValid = false;
        errors.push({
          bundleId: item.ind_bundle_name || `Bundle ${index + 1}`,
          message: `${item.ind_bundle_name + ', ' || `Bundle ${index + 1}`} Missing required fields: ${requiredFields.filter(field => item[field] === undefined || item[field] === null).join(', ')}.`
        });
        return; // Skip further processing for this bundle
      }
      return fltItm;
    });
  // console.log('aaa', allValid, errors);

  // Return null if no valid data is found, otherwise return the array
  // return ret.length > 0 ? ret : null;
  // return ret.length === d.length ? d : null;
  return ret.length === d.length ?
    {
      validData: d?.map((e) => {
        let { ind_bundle_e_commerce, ind_bundle_brand, discount, ...rest } = e
        ind_bundle_brand =
          typeof ind_bundle_brand === 'string'
            ? ind_bundle_brand.split(",").map(item => item.trim())
            : ind_bundle_brand;

        ind_bundle_e_commerce =
          typeof ind_bundle_e_commerce === 'string'
            ? ind_bundle_e_commerce.split(",").map(item => item.trim())
            : ind_bundle_e_commerce;
        let parsedDiscount = null;
        if (typeof discount === 'string') {
          // Remove any % or spaces and try to parse as a number
          const cleanedDiscount = discount.replace(/[%\s]/g, '');
          const numericValue = parseFloat(cleanedDiscount);
          parsedDiscount = isNaN(numericValue) ? 0 : numericValue; // Return 0 if parsing fails
        } else if (typeof discount === 'number') {
          parsedDiscount = discount; // If already a number, use it directly
        } else {
          parsedDiscount = 0; // Default to 0 for invalid or missing values
        }
        return { ...rest, ind_bundle_e_commerce, ind_bundle_brand, discount: parsedDiscount }
        // return { ...rest, ind_bundle_e_commerce, ind_bundle_brand, Discount: String(parsedDiscount) }
      }),
      success: allValid && errors.length === 0,
      errors: errors.map(error => error.message).join('\n')
    }
    :
    {
      validData: null,
      success: false,
      errors: errors.map(error => error.message).join('\n')
    };
};



export const ConvertDataMartUploadBundles = (d) => {
  if (d === null || d?.length === 0) {
    return null;
  }

  return d?.map((e, i) => {
    return {
      name: e?.ind_bundle_name,
      category: e?.ind_bundle_category,
      country: e?.ind_bundle_country,
      platform: e?.ind_bundle_e_commerce,
      brand: e?.ind_bundle_brand,
      from_date: e?.ind_bundle_start_date,
      to_date: e?.ind_bundle_end_date,
      price: GetPriceWithSymbolNoDecimal(Number(e?.setup_cost)),
      discount: `${e?.discount} %`,
    }
  })
}



export const ConvertManageUsers = ({ d }) => {
  if (d === null || d?.length === 0) {
    return [{
      user_id: null,
      name: null,
      email: null,
      user_type: null,
      country: null,
      phone_number: null,
      company: null,
      verified_status: null,
      user_status: null,
    }];
  }

  return d?.map((e, i) => {
    return {
      user_id: e?.user_id,
      name: e?.user_first_name,
      email: e?.user_email,
      user_type: e?.user_type,
      country: e?.user_country,
      phone_number: e?.user_phone_number,
      company: e?.user_company_name,
      verified_status: e?.verified !== undefined ? <RoundedBtn txt={e?.verified ? 'Verified' : 'Not Verified'} col={e?.verified ? '#008000' : '#FF4C51'} /> : null,
      user_status: e?.user_status ? <RoundedBtn txt={e?.user_status} col={e?.user_status === 'Active' ? '#008000' : '#FF4C51'} /> : null,
    }
  })
}


export const ConvertWebScrapping = ({ d }) => {
  if (d === null || d?.length === 0) {
    return [{
      sl_no: null,
      script_run_date: null,
      country: null,
      script_name: null,
      data_inserted_into_DB: null,
      scrapping_status: null,
    }];
  }

  return d?.map((e, i) => {
    return {
      sl_no: i + 1,
      script_run_date: e?.script_run_date,
      country: e?.country,
      script_name: e?.script_name,
      data_inserted_into_DB: e?.data_inserted_db,
      scrapping_status: e?.scraping_status ? <RoundedBtn txt={e?.scraping_status} col={e?.scraping_status === 'Success' ? '#008000' : '#FF4C51'} /> : null,
    }
  })
}



export const ConvertAdminDataMart = ({ d }) => {
  if (d === null || d?.length === 0) {
    return [{
      bundle_id: null,
      bundle_name: null,
      category: null,
      country: null,
      platform: null,
      brand: null,
      from_date: null,
      to_date: null,
      price: null,
      discount: null,
      status: null,
    }];
  }

  return d?.map((e, i) => {
    return {
      bundle_id: e?.Bundle_id,
      bundle_name: e?.Bundle_name,
      category: e?.Category,
      country: e?.Country,
      platform: e?.Platform,
      brand: e?.Brand,
      // platform: e?.Platform?.join(', '),
      // brand: e?.Brand?.join(', '),
      from_date: e?.From_date,
      to_date: e?.To_date,
      price: e?.Price,
      discount: e?.Discount,
      status: e?.Status ? <RoundedBtn txt={e?.Status} col={e?.Status === 'Active' ? '#008000' : '#FF4C51'} /> : null,
    }
  })
}



export const ConvertAdminCustomBundles = ({ d }) => {
  if (d === null || d?.length === 0) {
    return [{
      bundle_id: null,
      bundle_name: null,
      user_id: null,
      user_name: null,
      user_type: null,
      category: null,
      country: null,
      platform: null,
      brand: null,
      from_date: null,
      to_date: null,
      price: null,
      discount: null,
      status: null,
    }];
  }

  return d?.map((e, i) => {
    return {
      bundle_id: e?.Bundle_id,
      bundle_name: e?.Bundle_name,
      user_id: e?.User_id,
      user_name: e?.User_name,
      user_type: e?.User_type,
      category: e?.Category,
      country: e?.Country,
      platform: e?.Platform,
      // platform: e?.Platform?.join(', '),
      brand: e?.Brand,
      // from_date: e?.From_date,
      // to_date: e?.To_date,
      // price: e?.Price,
      // discount: e?.Discount,
      status: e?.Bundle_status ? <RoundedBtn txt={e?.Bundle_status} col={e?.Bundle_status === 'Active' ? '#008000' : '#FF4C51'} /> : null,
      payment_status: e?.Payment_status ? <RoundedBtn txt={e?.Payment_status === 'Paid' ? e?.Payment_status : 'Not Paid'} col={e?.Payment_status === 'Paid' ? '#008000' : '#FF4C51'} /> : null,
    }
  })
}

export const isObject = (o) => {
  return (typeof o === "object" && !Array.isArray(o)) && (o !== null)
}

export const loadImage = async (url) => {
  const img = new Image();
  img.src = url;
  return img;
  // return new Promise((resolve) => {
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.onload = () => {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     canvas.getContext("2d").drawImage(img, 0, 0);
  //     resolve(canvas.toDataURL("image/png"));
  //   };
  //   img.src = url;
  // });
};


export const addStyledImage = (doc, img, x, y, width, height, padding, borderRadius) => {
  // 1. Draw White Background
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(x - padding, y - padding, width + 2 * padding, height + 2 * padding, borderRadius, borderRadius, 'F');
  // doc.rect(x - padding, y - padding, width + 2 * padding, height + 2 * padding, 'F');

  // 2. Approximate Border Radius (Simple Rectangle)
  // If you want actual rounded corners, implement arcs or circles here.

  // 3. Add Padding
  const imageX = x;
  const imageY = y;

  // 4. Add Image
  doc.addImage(img, 'PNG', imageX, imageY, width, height);
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ""; // Handle empty input
  }

  // Remove any non-digit characters except the leading '+'
  let cleanedNumber = phoneNumber?.replace(/[^+\d]/g, '');

  // Check if the number starts with '+' (country code)
  // const hasCountryCode = cleanedNumber?.startsWith('+');

  // if (hasCountryCode) {
  //   // Remove the '+' for processing, but keep it for the output
  //   cleanedNumber = cleanedNumber?.substring(1);
  // }

  let formattedNumber = '';
  let groupSize = 2; // Default group size

  // Adjust group size for the last group
  if (cleanedNumber.length % 2 !== 0) {
    groupSize = 3;
  }

  for (let i = 0; i < cleanedNumber.length; i++) {
    formattedNumber += cleanedNumber[i];
    if ((i + 1) % groupSize === 0 && i !== cleanedNumber.length - 1) {
      formattedNumber += ' ';
      groupSize = 2; //Reset group size after the first group.
    }
  }

  // if (hasCountryCode) {
  //   return `+${formattedNumber}`;
  // } else {
  //   return `+${formattedNumber}`;
  // }
  return `+${formattedNumber}`;
}

export const sendData = (data) => {
  const params = new URLSearchParams();

  for (const key in data) {
    if (typeof data[key] === 'object' && data[key] !== null) {
      // Stringify arrays and objects
      params.set(key, JSON.stringify(data[key]));
    } else {
      // Handle simple properties (strings, numbers, booleans)
      params.set(key, data[key]);
    }
  }

  // navigate(`/receiving?${params.toString()}`);
  return window.location.origin + `/click-pay?${params.toString()}`
};

export const extractNumberFromString = (str) => {
  if (typeof str !== 'string' || !str) {
    return null; // Handle non-string or empty input
  }

  const matches = str.match(/\d+(\.\d+)?/); // Regular expression to find numbers (including decimals)

  if (matches) {
    return parseFloat(matches[0]); // Convert the matched string to a number
  } else {
    return null; // No number found
  }
}
