import { Box } from "@mui/material";
import TopNav from "../../../pages/public/home/private-components/nav/TopNav";
import { Analytics } from "../../../Analytics";
import { Element } from "react-scroll";
import Footer from "../../../pages/public/home/private-components/screens/Footer";



export const HeaderFooter = ({ children, width= { xs: "100%", md: "85%" } }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                color: "#000",
                fontFamily: "Inter",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: { xs: "85%", md: "85%" },
                        height: '100%',
                        position: "sticky",
                        top: 0,
                        zIndex: 999,
                    }}
                >
                    <TopNav />
                    <Analytics />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "calc(100vh - 344px)",
                        height: '100%',
                        alignItems: "center",
                        width: "100%",
                        // mt: 2,
                    }}
                >
                    <Box sx={{ width: width, height: '100%', }}>
                        {children}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#000000",
                    mt: 4,
                    width: "100%",
                }}
            >
                <Box sx={{ width: { xs: "100%", md: "80%" } }}>
                    {/* <Element name="about-us"> */}
                    <Footer />
                    {/* </Element> */}
                </Box>
            </Box>
        </Box>
    );
};