import { Box, Typography } from "@mui/material";
import TopNav from "../home/private-components/nav/TopNav.jsx";
import Footer from "../home/private-components/screens/Footer.jsx";
import { Element } from "react-scroll";
import { PricingSvg } from "../../../utils/icon/LandingPage/Icon.jsx";
import { Link } from "react-router-dom";
import { Analytics } from "../../../Analytics.jsx";

export const TempPricing = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        color: "#000",
        fontFamily: "Inter",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "85%", md: "85%" },
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <TopNav />
          <Analytics />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Element name="TempPricing">
            <FirstPage />
          </Element>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000000",
          mt: 4,
          width: "100%",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Element name="about-us">
            <Footer />
          </Element>
        </Box>
      </Box>
    </Box>
  );
};

export const FirstPage = () => {
  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "70vh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <PricingSvg />
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "24px",
            color: "#000000",
            mt: 2,
            mb: 2,
          }}
        >
          We are currently in beta.
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "20px",
            color: "#000000",
            textAlign: "center",
          }}
        >
          Brand Aura is free to use during this phase.
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "20px",
            color: "#000000",
            textAlign: "center",
          }}
        >
          Please note that we do not offer any guaranty or warranty for the data
          at this time.
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "20px",
            color: "#000000",
            textAlign: "center",
          }}
        >
          In case you have any suggestions, feel free to contact our support
          team at {""}
          <Link
            to={"mailto:contact@brand-aura.com"}
            style={{
              color: "#8A18D0",
              textDecoration: "underline",
            }}
          >
            contact@brand-aura.com
          </Link>
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "20px",
            color: "#000000",
            mt: 2,
            textAlign: "center",
          }}
        >
          Pricing will be introduced in the future, and we reserve the right to
          update our terms accordingly.
        </Typography>
      </Box>
    </Box>
  );
};
