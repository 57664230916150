import { Box, Button, Typography } from '@mui/material';
import { dateClnse } from '../Public/DateClnse';
import { ConfigStyleBar } from '../../utils/data/IndividualDashboard/Data';
import { generateTableData } from '../../pages/private/individual/DataSelection/Private';
import { TableComp6 } from '../table/type - 6';
import {
  DownloadIcn,
  DownloadSvg,
  PlaySvg2,
} from '../../utils/icon/IndividualPages/Icon';
import { Card_2 } from '../card/Bundle-Card';
import { generateTableData2 } from './TableEssentials';

export const Bundle = ({ e, ChangeModelData, BndlePops, BtnDatas }) => {
  // console.log('bdl', e);
  return (
    <Box sx={{ mb: 10 }}>
      {e.ind_bundle_name && (
        <Box
          sx={{
            width: '100%',
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                mr: 3,
                color: '#000',
              }}>
              {e.ind_bundle_name}
            </Typography>
            {e.ind_bundle_start_date && (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: { xs: 'column', sm: 'row' },
                }}>
                <Typography
                  sx={{
                    p: 1,
                    border: '0.8px solid #474747CC',
                    borderRadius: '20px',
                    px: 1,
                    color: '#474747CC',
                    minWidth: '200px',
                    mr: { xs: 0, sm: 1 },
                    my: { xs: 1, sm: 0 },
                  }}>
                  Data From : {dateClnse(e.ind_bundle_start_date)}
                </Typography>
                <Typography
                  sx={{
                    p: 1,
                    border: '0.8px solid #474747CC',
                    color: '#474747CC',
                    borderRadius: '20px',
                    minWidth: '200px',
                    ml: { xs: 0, sm: 1 },
                    my: { xs: 1, sm: 0 },
                  }}>
                  Data Till : {dateClnse(e.ind_bundle_end_date)}
                </Typography>
              </Box>
            )}
          </Box>
          {e.bundle_status ? (
            <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', px: 2, color: '#fff', textTransform: 'capitalize', '&:hover': { background: '#B37FFC' }, }}
              onClick={() => {
                BndlePops({
                  x: 0,
                  ec: e.card_datas[0].value,
                  id: e.ind_bundle_id,
                });
              }}>
              <DownloadIcn />
              <Typography sx={{ ml: 2, fontWeight: '600', fontSize: '10px' }}>
                Sample Data
              </Typography>
            </Button>
          ) : !e.ind_bundle_run ? (
            <Button
              sx={{
                background: '#8A18D0',
                px: 2,
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}
              // onClick={() => RunBundle(e.ind_bundle_id)}
              onClick={() => {
                BndlePops({
                  x: 0,
                  ec: e.card_datas[0].value,
                  id: e.ind_bundle_id,
                });
              }}>
              <PlaySvg2 />
              <Typography sx={{ ml: 2, fontWeight: '600', fontSize: '10px' }}>
                Run Bundle
              </Typography>
            </Button>
          ) : (
            <Button
              sx={{
                background: '#8A18D0',
                px: 2,
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}
              onClick={() => {
                // if (e.ind_bundle_download_limit === 3) {
                if (Number(e.ind_bundle_download_limit) === 0) {
                  BndlePops({ x: 1, ec: 0, id: e.ind_bundle_id });
                } else {
                  // console.log('dl', e.ind_bundle_download_limit);
                  BndlePops({ x: 2, ec: 0, id: e.ind_bundle_id });
                }
              }}>
              <DownloadIcn />
              <Typography
                sx={{ ml: 2, fontWeight: '600', fontSize: '10px' }}
              // onClick={() => DownloadBundle(e.ind_bundle_id)}
              >
                {e.ind_bundle_download_limit ? 'Download' : 'Sample Data'}
              </Typography>
            </Button>
          )}
        </Box>
      )}
      {e.ind_bundle_download_limit && !e.bundle_status && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            my: 1,
            color: '#474747CC', textAlign: 'end',
          }}>
          {e.ind_bundle_run && e.ind_bundle_start_date !== null
            ? (Number(e.ind_bundle_download_limit) === 0 ? null : `${e.ind_bundle_download_limit} free downloads left`)
            // ? (Number(e.ind_bundle_download_limit) === 0 ? `Downloading bundle costs credits. You need 250 Credits to download this bundle with 2 free downloads.` : `${e.ind_bundle_download_limit} free downloads left`)
            // ? `Click "Download" to get the bundle data`
            // ? `Note: You have ${e.ind_bundle_download_limit} free downloads left`
            : null}
          {/* : e.ind_bundle_start_date ? 'Please Run the Bundle' : null} */}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'space-between' },
          width: '100%',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          my: 2,
        }}>
        {e.card_datas &&
          e.card_datas.map((f, j) => (
            <Card_2
              key={j}
              data={{
                icon: f.icon,
                title: f.title,
                value: f.value,
                // wd: ``,
                wd: `${100 / e.card_datas.length - 0.8}%`,
              }}
            />
          ))}
      </Box>
      {/* {console.log('gene tb', generateTableData2(e.table))} */}
      {e.table && (
        <TableComp6
          // Data={generateTableData(
          //   e.table.Category,
          //   e.table.Country,
          //   e.table['E-Commerce Site'],
          //   e.table.Brand,
          //   e.table['Configured Date']
          // )}
          Data={generateTableData2(e.table)}
          Style={ConfigStyleBar}
          bundle_id={e.ind_bundle_id}
          rb={e.ind_bundle_run}
          ChangeModelData={ChangeModelData}
          btnData={BtnDatas}
        // btnData={e.ind_bundle_run ? settingsForNot : settingsForRb}
        />
      )}
    </Box>
  );
};
