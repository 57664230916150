import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { HeaderContents } from "./utils/data/Analytics/data";
import Axios from "axios";

export const Analytics = () => {
    const location = useLocation();
    const defDescrip = 'Price monitoring on ecommerce platforms and portfolio assessment services. Optimize website performance with advanced data analysis and actionable insights.'
    const [hel, setHel] = useState(null);
    ReactGA.initialize('G-WGPGZK2ZCT');
    // console.log('enteredddddd analy')
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    useEffect(() => {
        // console.log('cookies', 'working', hel, location, canonicalUrl, window.location, location.pathname === HeaderContents[2].path, HeaderContents?.filter((e) => e.path === location.pathname)?.[0]);
        setHel(HeaderContents?.filter((e) => e.path === location.pathname)?.[0]);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname });

        // Axios.get(
        //     `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`)
        //     .then((res) => {
        //         localStorage.setItem('usd_currendyData', JSON.stringify(res.data['usd']))
        //     })

    }, [location]);

    useEffect(() => {
        const fetchCurrencyData = () => {
            fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json')
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem('usd_currendyData', JSON.stringify(data?.usd));
                    localStorage.setItem('lastfetchCurrencyData', new Date().toDateString()); // Store the fetch date
                    console.log(data);
                })
                .catch((error) => {
                    console.error('Error fetching currency data:', error);
                });
        };

        const lastFetchDate = localStorage.getItem('lastfetchCurrencyData');
        const currentDate = new Date().toDateString();

        if (lastFetchDate !== currentDate) {
            fetchCurrencyData();
        }
    }, []);


    useEffect(() => {
        const getUserCountryAndCurrency = async () => {
            localStorage.setItem('country_code', 'us')
            localStorage.setItem('currency', String('usd').toLowerCase())
            // console.log('usd');
            // localStorage.setItem('country_code', 'in')
            // localStorage.setItem('currency', String('inr').toLowerCase())
            return;
            try {
                const response = await Axios.get('https://ipapi.co/json/'); // Free API to get user's location
                const { country_code, currency } = response.data;
                // return { countryCode: country_code, currency };
                localStorage.setItem('country_code', country_code)
                localStorage.setItem('currency', String(currency).toLowerCase())
            } catch (error) {
                console.error('Error fetching user location:', error);
                return { countryCode: 'US', currency: 'USD' }; // Default to US and USD
            }
        };
        getUserCountryAndCurrency()
    }, [])

    return (
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            {/* <link rel="canonical" href={window.location.href} /> */}
            {hel?.title ? <title>{hel?.title}</title> : <title>Brand Aura</title>}
            {hel?.description ? <meta name="description" content={hel?.description} /> : <meta name="description" content={defDescrip} />}
        </Helmet>
    );
}
