import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { BtnLoad } from '../../../../../../utils/data/LandingPage/Data';

export const Footer = ({ prop }) => {
  const ut = localStorage.getItem('user_type');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        my: 3,
      }}>
      <Typography
        sx={{
          color: '#000',
          fontSize: '12px',
          fontWeight: '600',
          textWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }}>
        Are you missing data combinations,
        <br /> we are ready to help {''}
        <Link
          to={
            ut === 'company'
              ? '/admin/custom-bundle'
              : '/dashboard/individual-dashboard/custom-bundle'
          }
          style={{
            display: 'inline',
            color: '#8A18D0',
            fontSize: '12px',
            fontWeight: '600',
            textDecoration: 'underline',
            // minWidth: 'max-content',
          }}>
          Click here.
        </Link>
      </Typography>
      <Box sx={{ display: 'flex' }}>
        {prop.state > 0 && (
          <Button
            variant='outlined'
            sx={{
              color: '#8A18D0',
              borderColor: '#8A18D0',
              textTransform: 'capitalize',
              px: 4,
              mx: 1,
            }}
            onClick={prop.handleBack}>
            Back
          </Button>
        )}
        {prop.state < prop.data.length - 1 && (
          <Button
            sx={{
              background: !prop.validateCurrentStep() ? '#765987' : '#8A18D0',
              color: !prop.validateCurrentStep() ? '#fff' : '#fff',
              px: 4,
              mx: 1,
              textTransform: 'capitalize',
              '&:hover': { background: '#B37FFC' },
            }}
            onClick={prop.handleNext}
            disabled={!prop.validateCurrentStep()}
          >
            Next
          </Button>
        )}
        {prop.state === prop.data.length - 1 && (
          <Button
            sx={{
              background: '#8A18D0',
              color: prop?.load ? 'rgba(0, 0, 0, 0.5)' : '#fff',
              px: 4,
              mx: 1,
              textTransform: 'capitalize',
              '&:hover': { background: '#B37FFC' },
            }}
            disabled={prop?.load}
            onClick={() => prop.setOpen(true)}>
            {prop.bundleId ? 'Update' : 'Confirm'}
            <Box>
              {prop?.load && (
                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                  <Box sx={BtnLoad} />
                </Box>
              )}
            </Box>
          </Button>
        )}
      </Box>
    </Box>
  );
};
