

export const dateClnse = (date) => {// 31 March 2025
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const year = dateObj.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse2 = (date) => {//2025-03-31
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse3 = (date) => {// 31-03-2025
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    console.log('raw dat', dateObj)
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}


export const dateClnse4 = (date) => {// 31-Mar-25
    // console.log('dateClnse4 check', !date, isNaN(date), !date || isNaN(date));
    if (!date) {
        return 'dd'; // Return an error message for invalid input
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dateObj = new Date(date);
    let day = dateObj.getDate().toString().padStart(2, '0');
    let month = months[dateObj.getMonth()];
    let year = dateObj.getFullYear().toString().slice(-2);
    // console.log('dateClnse4', `${day}-${month}-${year}`);


    return `${day}-${month}-${year}`;
}


export const dateClnse5 = (date) => {// 31 MAR 25
    // console.log('dateClnse4 check', !date, isNaN(date), !date || isNaN(date));
    if (!date) {
        return 'dd'; // Return an error message for invalid input
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dateObj = new Date(date);
    let day = dateObj.getDate().toString().padStart(2, '0');
    let month = String(months[dateObj.getMonth()]).toUpperCase();
    let year = dateObj.getFullYear().toString().slice(-2);
    // console.log('dateClnse4', `${day}-${month}-${year}`);


    return `${day} ${month} ${year}`;
}
export const dateClnse6 = (date) => {// 31 Mar 25
    // console.log('dateClnse4 check', !date, date, isNaN(date), !date || isNaN(date));
    if (!date) {
        return 'dd'; // Return an error message for invalid input
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dateObj = new Date(date);
    let day = dateObj.getDate().toString().padStart(2, '0');
    let month = String(months[dateObj.getMonth()]);
    let year = dateObj.getFullYear().toString().slice(-2);
    // console.log('dateClnse4', `${day}-${month}-${year}`);


    return `${day} ${month} ${year}`;
}


export const dateClnse7 = (date) => {// 31 Mar 2025
    // console.log('dateClnse4 check', !date, date, isNaN(date), !date || isNaN(date));
    if (!date) {
        return 'dd'; // Return an error message for invalid input
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dateObj = new Date(date);
    let day = dateObj.getDate().toString().padStart(2, '0');
    let month = String(months[dateObj.getMonth()]);
    let year = dateObj.getFullYear().toString();
    // console.log('dateClnse4', `${day}-${month}-${year}`);


    return `${day} ${month} ${year}`;
}


export const dateClnse8 = (date) => {// 31/03/2025 12:44
    if (!date) return '';
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`; //01/04/2025 18:12
}


export const dateClnse9 = (date) => {// 31/03/2025
    if (!date) return '';
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`; //01/04/2025 18:12
}



export const RailwayTimeToNormalTime = (time) => {
    // Split the time string into components
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    const normalHours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Format the normal time string
    const NormalTime = `${String(normalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${period}`;

    return NormalTime;
}




export const RawDate = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    return dateObj;
}

export const timeAgo = (isoString) => {// Just now, 3 mins ago
    const date = new Date(isoString);
    if (isNaN(date)) {
        return 'Invalid date';
    }

    const now = new Date();
    const diffInMs = now - date;
    if (diffInMs < 0) { return 'It is a Future Date'; }

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds > 0) {
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    } else {
        return 'Just now';
    }
};
export const formatDate = (dateString) => { if (!dateString) return null; const date = new Date(dateString); return date.toISOString().split('T')[0]; }; //2025-03-31
export const StringToDate = (d) => {
    if (!d) return null;
    const k = d?.split("/");
    return new Date(k[2], k[1] - 1, k[0]);
}