import { Box } from "@mui/material";
import { CorporateNormalUserTable } from "../../../../../../components/@extended/ModifiedTable"
import { modifyUserBrandProductTable, modifyUserBrandSellerTable } from "../../../../../../components/@extended/CustomFunctions"
import { AllProductData, AllSellerData, ReportColor } from "../../../../../../utils/data/CorporateNormalUserDatas/Data"
import { useEffect, useState } from 'react';
import { Pie } from "./Pie";
import { DownloadIcn } from "../../../../../../utils/icon/IndividualPages/Icon";
import { HorizontalBar } from "./HorizontalBar";
import { MultiBarChart } from "./MultiBarChart";
import http from '../../../../../../utils/http-common'
import { useLocation, useSearchParams } from "react-router-dom";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";
import { BtnLoad } from "../../../../../../utils/data/LandingPage/Data";
import { downloadCSV } from "../../../../../../components/Public/DownloadCSV";
import { useDispatch, useSelector } from "react-redux";
import { handleClick, setMessage, setSeverity } from "../../../../../../slice/SnackPop";
import { SingleBarChart } from "./SingleBarChart";


export const PlatformDistribution = () => {
    const [indBundleId, setIndBundleId] = useState(null);
    const [load, setLoad] = useState(false);
    // const [d, setD] = useState([])
    const [d, setD] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    const [rawTbl, setRawTbl] = useState(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    // const [d, setD] = useState([
    //     { id: 0, value: '40', color: '#2BA1DB', label: 'Ibell (40%)', text: 'Ibell (40%) - 1,254', },
    //     { id: 1, value: '40', color: '#8A18D0', label: 'Makita (40%)', text: 'Makita (40%) - 1,254', },
    //     { id: 2, value: '20', color: '#F68D2B', label: 'Dewalt (20%)', text: 'Dewalt (20%) - 365', },
    // ])
    const [md, setMd] = useState(['', 0])
    const [sd, setSd] = useState([{ color: '#2BA1DB', dataKey: 'Makita', label: 'Makita', text: 'Makita' }, { color: '#8A18D0', dataKey: 'Ibell', label: 'Ibell', text: 'Ibell' }, { color: '#F68D2B', dataKey: 'Dewalt', label: 'Dewalt', text: 'Dewalt' },])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                `/master_service/reports/platform/distribution`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            searchParams.set('category', response?.data?.category?.category_name);
            setSearchParams(searchParams);
            const total = response?.data?.brands?.reduce((acc, curr) => acc + curr?.ecommerce?.reduce((e, i) => e + i?.no_of_products, 0), 0);
            // console.log('response product', response?.data);
            let dupmd = ['', 0];
            const data = response?.data?.brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))?.map((site, i) => {
                const bnd = site?.ecommerce?.reduce((e, i) => e + i?.no_of_products, 0)
                if (bnd > dupmd[1]) {
                    dupmd = [site?.brand_name, bnd];
                    // setMd([site?.brand_name, bnd]);
                }
                const percentage = Math.round((bnd / total) * 100);
                // console.log('per', site, total, percentage)

                // const siteData = { id: i, value: percentage, color: ReportColor[i], label: `${site?.brand_name} (${percentage}%)`, text: `${site?.brand_name} (${percentage}%) - ${bnd}`, count: bnd };
                const siteData = { id: i, value: bnd, color: ReportColor[i], label: `${site?.brand_name} (${percentage}%)`, text: `${site?.brand_name} (${percentage}%) - ${bnd}`, count: bnd };

                return siteData;
            });

            const allAreZeroOrNot = data.every(site => {
                // Check if all brand.no_of_sellers are 0 for the current site
                return Object.values(site).every(value => {
                    // Ignore the 'id' and 'site' properties
                    if (typeof value === 'number') {
                        return value === 0;
                    }
                    return true; // For non-numeric properties
                });
            });
            setIsEmpty(allAreZeroOrNot)

            const excelData = response?.data?.brands
                ?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))
                ?.map((brand, i) => {
                    // console.log('brand.ecommerce', brand.ecommerce)
                    const bnd = brand?.ecommerce?.reduce((e, i) => e + i?.no_of_products, 0)
                    const ret = {
                        Brand: brand.brand_name,
                        'Product Count': bnd,
                        Percentage: Math.round((brand?.ecommerce?.reduce((e, i) => e + i?.no_of_products, 0) / total) * 100)
                    };
                    return ret;
                });

            // console.log(excelData);

            setMd(dupmd);
            // console.log('products data', data)
            setD(data);
            setRawTbl(excelData);
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
            setLoad(false)
        }
        catch (error) {
            console.error('Error fetching data:', error);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => {
        if (bndlId !== null) {
            setIndBundleId(bndlId)
            searchParams.set('ind_bundle_id', bndlId);
            setSearchParams(searchParams);
        }
    }, [bndlId])
    // console.log('bndl', bndlId)

    // const d = [
    //     { id: 0, value: '40', color: '#2BA1DB', label: 'Ibell (40%)', text: 'Ibell (40%) - 1,254', },
    //     { id: 1, value: '40', color: '#8A18D0', label: 'Makita (40%)', text: 'Makita (40%) - 1,254', },
    //     { id: 2, value: '20', color: '#F68D2B', label: 'Dewalt (20%)', text: 'Dewalt (20%) - 365', },
    // ];
    const props = {
        data: d, load: load, title: md[0], num: md[1], sideTitle: 'Based on Products', sideDatas: d, isEmpty: isEmpty,
        msg: load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available', name: 'Platform Distribution', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnLnk: '', fn: () => {
            if (!load) {
                if (rawTbl !== null && rawTbl?.length !== 0) {
                    downloadCSV(rawTbl, `Platform Distribution ${indBundleId}`);
                    dispatch(setMessage('Platform Distribution CSV Data Downloaded Successfully'));
                    dispatch(setSeverity('success'));
                    dispatch(handleClick());
                } else {
                    // console.log('enttt');
                    dispatch(setMessage('No data found for Platform Distribution. CSV download is not possible.'));
                    dispatch(setSeverity('error'));
                    dispatch(handleClick());
                }
            } else {
                dispatch(setMessage('Loading...'));
                dispatch(setSeverity('warning'));
                dispatch(handleClick());
            }
        }
    }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                <Pie prop={props} />
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
export const SellerDistribution = () => {
    const [indBundleId, setIndBundleId] = useState(null);
    const [load, setLoad] = useState(false);
    const [md, setMd] = useState(['', 0])
    // const [d, setD] = useState([])
    const [d, setD] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    const [rawTbl, setRawTbl] = useState(null);
    const dispatch = useDispatch()
    const m = useSelector((s) => s?.reportFilter?.reportFilter)


    // const [d, setD] = useState([
    //     { id: 0, value: '45', color: '#2BA1DB', label: 'Makita (45%)', text: 'Makita (45%) - 54', },
    //     { id: 1, value: '30', color: '#8A18D0', label: 'Ibell (30%)', text: 'Ibell (30%) - 34', },
    //     { id: 2, value: '25', color: '#F68D2B', label: 'Dewalt (25%)', text: 'Dewalt (25%) - 28', },
    // ])
    // const [sd, setSd] = useState([])
    const [sd, setSd] = useState([{ color: '#2BA1DB', dataKey: 'Makita', label: 'Makita', text: 'Makita' }, { color: '#8A18D0', dataKey: 'Ibell', label: 'Ibell', text: 'Ibell' }, { color: '#F68D2B', dataKey: 'Dewalt', label: 'Dewalt', text: 'Dewalt' },])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                `/master_service/reports/seller/distribution`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );

            const total = response?.data?.brands?.reduce((acc, curr) => acc + curr?.ecommerce?.reduce((e, i) => e + i?.no_of_sellers, 0), 0);
            // console.log('response seller', response?.data);
            let dupmd = ['', 0];
            const data = response?.data?.brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))?.map((site, i) => {
                const bnd = site?.ecommerce?.reduce((e, i) => e + i?.no_of_sellers, 0)
                if (bnd > dupmd[1]) {
                    dupmd = [site?.brand_name, bnd];
                    // setMd([site?.brand_name, bnd]);
                }
                const percentage = Math.round((bnd / total) * 100);
                // console.log('sel', bnd > md[1], md, bnd, site?.brand_name)

                // const siteData = { id: i, value: percentage, color: ReportColor[i], label: `${site?.brand_name} (${percentage}%)`, text: `${site?.brand_name} (${percentage}%) - ${bnd}`, count: bnd };
                const siteData = { id: i, value: bnd, color: ReportColor[i], label: `${site?.brand_name} (${percentage}%)`, text: `${site?.brand_name} (${percentage}%) - ${bnd}`, count: bnd };

                return siteData;
            });

            const allAreZeroOrNot = data.every(site => {
                // Check if all brand.no_of_sellers are 0 for the current site
                return Object.values(site).every(value => {
                    // Ignore the 'id' and 'site' properties
                    if (typeof value === 'number') {
                        return value === 0;
                    }
                    return true; // For non-numeric properties
                });
            });
            setIsEmpty(allAreZeroOrNot)
            const excelData = response?.data?.brands
                ?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))
                ?.map((brand, i) => {
                    const bnd = brand?.ecommerce?.reduce((e, i) => e + i?.no_of_sellers, 0)
                    const ret = {
                        brand: brand.brand_name,
                        sellerCount: bnd,
                        percentage: Math.round((brand?.ecommerce?.reduce((e, i) => e + i?.no_of_sellers, 0) / total) * 100),
                        // siteName: brand.ecommerce?.[0]?.site_name ?? "N/A"
                    };
                    return ret;
                });
            // console.log(excelData);

            setMd(dupmd);
            // console.log('seller data', data)
            setD(data);
            setRawTbl(excelData);
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
            setLoad(false)
        }
        catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)

    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    // const d = [
    // { id: 0, value: '45', color: '#2BA1DB', label: 'Makita (45%)', text: 'Makita (45%) - 54', },
    // { id: 1, value: '30', color: '#8A18D0', label: 'Ibell (30%)', text: 'Ibell (30%) - 34', },
    // { id: 2, value: '25', color: '#F68D2B', label: 'Dewalt (25%)', text: 'Dewalt (25%) - 28', },
    // ];
    const props = {
        data: d, load: load, title: md[0], num: md[1], sideTitle: 'Based on Platform', isEmpty: isEmpty,
        msg: load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available', sideDatas: d, name: 'Seller Distribution', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnLnk: '', fn: () => {
            if (!load) {
                if (rawTbl !== null && rawTbl?.length !== 0) {
                    downloadCSV(rawTbl, `Seller Distribution ${indBundleId}`);
                    dispatch(setMessage('Seller Distribution CSV Data Downloaded Successfully'));
                    dispatch(setSeverity('success'));
                    dispatch(handleClick());
                } else {
                    // console.log('enttt');
                    dispatch(setMessage('No data found for Seller Distribution. CSV download is not possible.'));
                    dispatch(setSeverity('error'));
                    dispatch(handleClick());
                }
            } else {
                dispatch(setMessage('Loading...'));
                dispatch(setSeverity('warning'));
                dispatch(handleClick());
            }

        }
    }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                <Pie prop={props} />
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
export const ProductRating = () => {
    const [indBundleId, setIndBundleId] = useState(null);
    const [load, setLoad] = useState(false);
    // const [d, setD] = useState([])
    const [rawTbl, setRawTbl] = useState(null)
    const dispatch = useDispatch()
    const [d, setD] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    // const tempd = [
    //     {
    //         "id": 0,
    //         "value": 0,
    //         "color": "#2BA1DB",
    //         "label": "Bosch k",
    //         "brand": "Bosch",
    //         "text": "Bosch",
    //         "totalReview": 74,
    //         "totalRatings": 90
    //     }
    // ]
    // const [d, setD] = useState([
    //     { id: 0, value: '5', color: '#2BA1DB', label: 'Makita (45%)', brand: 'Makita', text: 'Makita (45%) - 54', totalReview: '466', totalRatings: '6885', },
    //     { id: 1, value: '3', color: '#8A18D0', label: 'Ibell (30%)', brand: 'Ibell', text: 'Ibell (30%) - 34', totalReview: '156', totalRatings: '6868', },
    //     { id: 2, value: '1', color: '#F68D2B', label: 'Dewalt (25%)', brand: 'Dewalt', text: 'Dewalt (25%) - 28', totalReview: '5756', totalRatings: '284', },
    // ])
    // const [sd, setSd] = useState([])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                `/master_service/reports/product_rating`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            // console.log('response ratings', response?.data);
            const data = response?.data?.brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))?.map((site, i) => {
                // const total = response?.data?.reduce((acc, curr) => acc + curr?.no_of_sellers, 0);
                // const percentage = Math.round((site?.no_of_sellers / total) * 100);

                const siteData = { id: i, value: site?.total_count?.average_rating, color: ReportColor[i], label: `${site?.brand_name} k`, brand: site?.brand_name, text: `${site?.brand_name}`, totalReview: site?.total_count?.no_of_reviews, totalRatings: site?.total_count?.no_of_ratings };

                return siteData;
            });

            const allAreZeroOrNot = data.every(site => {
                // Check if all brand.no_of_sellers are 0 for the current site
                return Object.values(site).every(value => {
                    // Ignore the 'id' and 'site' properties
                    if (typeof value === 'number') {
                        return value === 0;
                    }
                    return true; // For non-numeric properties
                });
            });
            setIsEmpty(allAreZeroOrNot)
            const excelData = response?.data?.brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))?.map((site, i) => {
                const siteData = {
                    brand: site?.brand_name,
                    averageRating: site?.total_count?.average_rating,
                    totalReview: site?.total_count?.no_of_reviews,
                    totalRatings: site?.total_count?.no_of_ratings,
                };

                return siteData;
            });


            // console.log('ratings data', excelData, data);

            setD(data);
            setRawTbl(excelData);
            // setRawTbl(response?.data?.brands)
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
            setLoad(false)
        }
        catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    // const d = [
    //     { id: 0, value: '5', color: '#2BA1DB', label: 'Makita (45%)', brand: 'Makita', text: 'Makita (45%) - 54', totalReview: '466', totalRatings: '6885', },
    //     { id: 1, value: '3', color: '#8A18D0', label: 'Ibell (30%)', brand: 'Ibell', text: 'Ibell (30%) - 34', totalReview: '156', totalRatings: '6868', },
    //     { id: 2, value: '1', color: '#F68D2B', label: 'Dewalt (25%)', brand: 'Dewalt', text: 'Dewalt (25%) - 28', totalReview: '5756', totalRatings: '284', },
    // ];
    const props = {
        // data: tempd, title: 'Makita', load: load, num: '54', sideTitle: 'Based on Platform', sideDatas: d, name: 'Product Rating', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnLnk: '', fn: () => {
        data: d, title: 'Makita', load: load, num: '54', isEmpty: isEmpty,
        msg: load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available', sideTitle: 'Based on Platform', sideDatas: d, name: 'Product Rating', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnLnk: '', fn: () => {
            // console.log('ent csv fn', rawTbl);
            if (!load) {
                if (rawTbl !== null && rawTbl?.length !== 0) {
                    downloadCSV(rawTbl, `Product Rating ${indBundleId}`);
                    dispatch(setMessage('Product Rating CSV Data Downloaded Successfully'));
                    dispatch(setSeverity('success'));
                    dispatch(handleClick());
                } else {
                    // console.log('enttt');
                    dispatch(setMessage('No data found for Product Rating. CSV download is not possible.'));
                    dispatch(setSeverity('error'));
                    dispatch(handleClick());
                }
            } else {
                dispatch(setMessage('Loading...'));
                dispatch(setSeverity('warning'));
                dispatch(handleClick());
            }
        }
    }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                {/* <SingleBarChart prop={props} /> */}
                <HorizontalBar prop={props} />
                {/* <Pie prop={props} /> */}
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export const ProductListTable = () => {
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(1);
    // const [rawTbl, setRawTbl] = useState([])
    const [rawTbl, setRawTbl] = useState(null)
    // const [tbl, setTbl] = useState([]);
    const [tbl, setTbl] = useState(modifyUserBrandProductTable({ d: [] }));
    const location = useLocation();
    const [indBundleId, setIndBundleId] = useState(null);
    const m = useSelector((s) => s?.reportFilter?.reportFilter)

    const [rowsPerPage] = useState(8);
    const dispatch = useDispatch()
    // const tbl = modifyUserBrandProductTable({ d: AllProductData || [] });
    const filteredData = tbl

    const Fetch = async () => {
        setLoad(true)
        try {
            const response = await http.post(
                '/master_service/reports/product_list',
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            console.log('response product', response?.data);
            const data = response?.data?.bundle?.Products
            console.log('products data', data)
            setRawTbl(data?.map((e) => {
                const { product_id, site_id, ...rest } = e;
                return rest;
            }))
            setTbl(modifyUserBrandProductTable({ d: data || [] }));
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
            setLoad(false)
        }
        catch (error) {
            console.error('Error fetching data:', error, error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    if (filteredData.length === 0) {
        filteredData.push({
            produce_name: '',
            No_Of_Sellers: '',
            'Max Price (₹)': '',
            'Min Price (₹)': '',
            'MRP (₹)': '',
            '': '',
        });
    }
    const props = {
        // searchTerm,
        // setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };
    // console.log('filteredData prod', filteredData, filteredData.length !== 0)
    return (
        filteredData.length !== 0 ?
            <Box>
                <ComboTxtBtn prop={{
                    name: 'Product List', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnTxtStyl: { fontSize: '10px' }, fn: () => { // console.log('ent csv fn');
                        if (!load) {
                            if (rawTbl !== null && rawTbl?.length !== 0) {
                                downloadCSV(rawTbl, `Product List ${indBundleId}`);
                                dispatch(setMessage('Product List CSV Data Downloaded Successfully'));
                                dispatch(setSeverity('success'));
                                dispatch(handleClick());
                            } else {
                                // console.log('enttt');
                                dispatch(setMessage('No data found for Product List. CSV download is not possible.'));
                                dispatch(setSeverity('error'));
                                dispatch(handleClick());
                            }
                        } else {
                            dispatch(setMessage('Loading...'));
                            dispatch(setSeverity('warning'));
                            dispatch(handleClick());
                        }
                    }
                }} />
                <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', p: 0, my: 1, }}>
                    <CorporateNormalUserTable prop={props} />
                    <Box>
                        {load && (
                            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                                <Box sx={BtnLoad} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            :
            null
    )
}
export const ProductPerBrand = () => {
    const [indBundleId, setIndBundleId] = useState(null);
    const [load, setLoad] = useState(false);
    const [rawTbl, setRawTbl] = useState(null)
    const dispatch = useDispatch()
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    // const [d, setD] = useState([])
    const [d, setD] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    // const [d, setD] = useState([
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ])
    const [sd, setSd] = useState(null)
    // const [sd, setSd] = useState([{ color: '#2BA1DB', dataKey: 'Makita', label: 'Makita', text: 'Makita' }, { color: '#8A18D0', dataKey: 'Ibell', label: 'Ibell', text: 'Ibell' }, { color: '#F68D2B', dataKey: 'Dewalt', label: 'Dewalt', text: 'Dewalt' },])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                `/master_service/reports/product_per_brand/`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            // console.log('response product', response?.data);
            const data = response?.data?.Reports?.ecommerce_sites?.sort((a, b) => a.site_name.localeCompare(b.site_name))?.map(site => {
                const siteData = { id: site.site_id, site: site.site_name };

                // Add product counts for each brand
                site.brand.forEach(brand => {
                    siteData[brand.brand_name] = brand.no_of_products;
                });

                return siteData;
            });
            const uniqueBrands = new Map(); // To store unique brands and their counts

            response?.data?.Reports?.ecommerce_sites?.forEach(site => {
                site.brand?.sort((a, b) => a.brand_name.localeCompare(b.brand_name)).forEach(brand => {
                    if (!uniqueBrands.has(brand.brand_name)) {
                        uniqueBrands.set(brand.brand_name, brand.brand_id); // Store brand name and id
                    }
                });
            });

            const allAreZeroOrNot = data.every(site => {
                // Check if all brand.no_of_sellers are 0 for the current site
                return Object.values(site).every(value => {
                    // Ignore the 'id' and 'site' properties
                    if (typeof value === 'number') {
                        return value === 0;
                    }
                    return true; // For non-numeric properties
                });
            });
            setIsEmpty(allAreZeroOrNot)
            const sdata = Array.from(uniqueBrands.keys()).map((brandName, index) => {
                return {
                    color: ReportColor[index], // Use modulo to cycle through colors
                    text: brandName,
                    dataKey: brandName,
                    label: brandName
                };
            });
            // console.log('products per data', data, sdata, data.map((e) => { const { id, ...rest } = e; return rest; }))
            setD(data);
            setSd(sdata)
            setLoad(false)
            setRawTbl(data?.map((e) => { const { id, ...rest } = e; return rest; }))
            // setRawTbl(response?.data?.Reports)
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
        }
        catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    const props = {
        data: d,
        title: 'Makita',
        num: '54',
        sideTitle: 'Based on Platform',
        sideDatas: sd,
        sd: sd,
        load: load,
        isEmpty: isEmpty,
        msg: load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available',
        name: 'Products Per Brand ',
        txtStyl: {},
        btnIkn: <DownloadIcn />,
        btnLnk: '',
        btnTxt: 'Report',
        fn: () => {
            if (!load) {
                if (rawTbl !== null && rawTbl?.length !== 0) {
                    downloadCSV(rawTbl, `Product Per Brand ${indBundleId}`)
                    dispatch(setMessage('Product Per Brand CSV Data Downloaded Successfully'));
                    dispatch(setSeverity('success'));
                    dispatch(handleClick());
                } else {
                    // console.log('enttt');
                    dispatch(setMessage('No data found for Product Per Brand. CSV download is not possible.'));
                    dispatch(setSeverity('error'));
                    dispatch(handleClick());
                }
            } else {
                dispatch(setMessage('Loading...'));
                dispatch(setSeverity('warning'));
                dispatch(handleClick());
            }
        }
    }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '99%' }}>
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                <MultiBarChart prop={props} />
                {/* <Pie prop={props} /> */}
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export const SellerListTable = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(8);
    const [tbl, setTbl] = useState(modifyUserBrandSellerTable({ d: [] }));
    const [rawTbl, setRawTbl] = useState(null)
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    const location = useLocation();
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch()
    const [indBundleId, setIndBundleId] = useState(null);
    // const tbl = modifyUserBrandSellerTable({ d: AllSellerData || [] });
    const filteredData = tbl?.map((e) => {
        if (!e?.Reviews) {
            const { Reviews, ...rest } = e;
            return rest;
        }
        return e;
    })

    // console.log('filt', filteredData)

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                '/master_service/reports/seller_list',
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            // console.log('response', response?.data);
            // const dataArray = [];
            const data = response?.data?.bundle?.sellers
            setRawTbl(data)
            const datam = modifyUserBrandSellerTable({ d: data || [] })
            // const data = response?.data?.bundles?.[0]?.brands?.map((e) => (e?.ecommerce?.map((r) => (dataArray.push(r)))))
            // console.log('sellers data', data, datam)
            setTbl(datam);
            setLoad(false)
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
        }
        catch (error) {
            console.error('Error fetching data:', error, error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])


    if (filteredData.length === 0) {
        filteredData.push({
            seller_name: '',
            No_Of_Products: '',
            Reviews: '',
            Ratings: '',
            '': '',
        });
    }
    const props = {
        // searchTerm,
        // setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };
    return (
        <Box>
            <ComboTxtBtn prop={{
                name: 'Sellers List', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => {
                    if (!load) {
                        if (rawTbl !== null && rawTbl?.length !== 0) {
                            downloadCSV(rawTbl, `Seller Table ${indBundleId}`)
                            dispatch(setMessage('Seller Table CSV Data Downloaded Successfully'));
                            dispatch(setSeverity('success'));
                            dispatch(handleClick());
                        } else {
                            // console.log('enttt');
                            dispatch(setMessage('No data found for Seller Table. CSV download is not possible.'));
                            dispatch(setSeverity('error'));
                            dispatch(handleClick());
                        }
                    } else {
                        dispatch(setMessage('Loading...'));
                        dispatch(setSeverity('warning'));
                        dispatch(handleClick());
                    }
                }
            }} />
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', p: 0, my: 1, }}>
                <CorporateNormalUserTable prop={props} />
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
export const SellerPerBrand = () => {
    const [indBundleId, setIndBundleId] = useState(null);
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch()
    const [rawTbl, setRawTbl] = useState(null)
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    // const [d, setD] = useState([])
    const [d, setD] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    // const [d, setD] = useState([
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ])
    const [sd, setSd] = useState(null)
    // const [sd, setSd] = useState([])
    // const [sd, setSd] = useState([{ color: '#2BA1DB', dataKey: 'Makita', label: 'Makita', text: 'Makita' }, { color: '#8A18D0', dataKey: 'Ibell', label: 'Ibell', text: 'Ibell' }, { color: '#F68D2B', dataKey: 'Dewalt', label: 'Dewalt', text: 'Dewalt' },])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true)
            const response = await http.post(
                `/master_service/reports/seller_per_brand`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            // console.log('response product', response?.data);
            const data = response?.data?.Reports?.ecommerce_sites?.sort((a, b) => a.site_name.localeCompare(b.site_name))?.map(site => {
                const siteData = { id: site.site_id, site: site.site_name };

                // Add product counts for each brand
                site.brand.forEach(brand => {
                    siteData[brand.brand_name] = brand.no_of_sellers;
                });

                return siteData;
            });
            const allAreZeroOrNot = data.every(site => {
                // Check if all brand.no_of_sellers are 0 for the current site
                return Object.values(site).every(value => {
                    // Ignore the 'id' and 'site' properties
                    if (typeof value === 'number') {
                        return value === 0;
                    }
                    return true; // For non-numeric properties
                });
            });
            setIsEmpty(allAreZeroOrNot)
            const uniqueBrands = new Map(); // To store unique brands and their counts

            response?.data?.Reports?.ecommerce_sites?.forEach(site => {
                site.brand?.sort((a, b) => a.brand_name.localeCompare(b.brand_name)).forEach(brand => {
                    if (!uniqueBrands.has(brand.brand_name)) {
                        uniqueBrands.set(brand.brand_name, brand.brand_id); // Store brand name and id
                    }
                });
            });

            const sdata = Array.from(uniqueBrands.keys()).map((brandName, index) => {
                return {
                    color: ReportColor[index], // Use modulo to cycle through colors
                    text: brandName,
                    dataKey: brandName,
                    label: brandName
                };
            });
            console.log('products data', data, sdata, allAreZeroOrNot)
            console.log('allAreZeroOrNot', load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available', allAreZeroOrNot)
            setD(data);
            setSd(sdata)
            setLoad(false)
            setRawTbl(data?.map((e) => { const { id, ...rest } = e; return rest; }))
            // setRawTbl(response?.data?.Reports?.ecommerce_sites)
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
        }
        catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            setLoad(false)
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    const props = {
        data: d,
        title: 'Makita',
        load: load,
        isEmpty: isEmpty,
        msg: load ? 'Loading...' : isEmpty ? 'Data is Empty' : 'Data Not Available',
        num: '54',
        sideTitle: 'Based on Platform',
        sideDatas: sd,
        sd: sd,
        name: 'Sellers Per Brand ',
        txtStyl: {},
        btnIkn: <DownloadIcn />,
        btnLnk: '',
        btnTxt: 'Report',
        fn: () => {
            if (!load) {
                console.log('rawTbl', rawTbl);

                // console.log('enttt', rawTbl);
                if (rawTbl !== null && rawTbl?.length !== 0) {
                    downloadCSV(rawTbl, `Seller Per Brand ${indBundleId}`)
                    dispatch(setMessage('Seller Per Brand CSV Data Downloaded Successfully'));
                    dispatch(setSeverity('success'));
                    dispatch(handleClick());
                } else {
                    // console.log('enttt');
                    dispatch(setMessage('No data found for Seller Per Brand. CSV download is not possible.'));
                    dispatch(setSeverity('error'));
                    dispatch(handleClick());
                }
            } else {
                dispatch(setMessage('Loading...'));
                dispatch(setSeverity('warning'));
                dispatch(handleClick());
            }
        }
    }
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    // ];
    // const props = { data: d, title: 'Makita', num: '54', sideTitle: 'Based on Platform', sideDatas: [{ color: '#2BA1DB', text: 'Makita (154)' }, { color: '#8A18D0', text: 'Ibell (101)' }, { color: '#F68D2B', text: 'Dewalt (54)' },], name: 'Sellers Per Brand ', txtStyl: {}, btnIkn: <DownloadIcn />, btnLnk: '', btnTxt: 'Report', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '99%' }}>
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                <MultiBarChart prop={props} />
                {/* <Pie prop={props} /> */}
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}