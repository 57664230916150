import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { BundleOverviewStyles, SampleDataMartDatas } from "../../../../utils/data/LandingPage/Data"
import { DataMartBundleCard } from "../../DataMart/components"
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ConvertDataMartBundles, getFlag, getFlagEmoji } from "../../../../components/@extended/CustomFunctions";
import { CartSvg, DataPointsSvg, DateSvg, GrayCartSvg, LineItemsSvg, NoOfProductsSvg, NoteVioletIkn } from "../../../../utils/icon/LandingPage/Icon";
import { DataSvg } from "../../../../utils/icon/IndividualPages/Icon";
import { clickCart, updateCartDatas } from "../../../../slice/cart";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../../utils/http-common";
import { downloadCSV } from "../../../../components/Public/DownloadCSV";
import { handleClick, setMessage, setSeverity } from "../../../../slice/SnackPop";
import { useMemo } from "react";
import { dateClnse4 } from "../../../../components/Public/DateClnse";
import { useAddCartDatas } from "../../../../components/@extended/ApiCall";

export const BundleConfigurations = () => { }

// Helper component for data metrics
const DataMetric = ({ icon, text }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>
            {text}
        </Typography>
    </Box>
);

export const BundleDetailedOverview = () => {
    const user_id = localStorage.getItem('user_id');
    const location = useLocation();
    const dispatch = useDispatch();
    const [bundle_id, setBundle_Id] = useState(null)
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("bundle_id")) {
            setBundle_Id(params.get("bundle_id"));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);

    const bundlesData = useSelector((s) => s?.allbundles?.datamartBundles?.allDataMartBundles)
    // const tbl = ConvertDataMartBundles(Get)
    const { data } = useMemo(() => {
        const tbl = ConvertDataMartBundles(bundlesData);
        return {
            data: tbl?.find((e) => e?.bundle_id === bundle_id),
            bundle_id
        };
    }, [bundlesData, bundle_id]);
    const addCart = useAddCartDatas({
        prop: {
            individual_bundle_id: data?.bundle_id,
            individual_bundle_name: data?.bundleName,
            bundle_image: data?.cardImg,
            bundle_cost: data?.finalPrice,
            quantity: 0,
            session_id: 0,
        },
    });
    const Sample_Data = async () => {
        try {
            const bundleData = { data: data };
            if (bundleData.data.ind_bundle_start_date === null || bundleData.data.ind_bundle_end_date === null) {
                setMessage('There is some error running the Sample data, Edit this bundle and select from and to date to get the sample data');
                setSeverity('error');
                handleClick();
                return;
            }
            const payload = {
                category_id: bundleData.data.ind_bundle_category_id,
                site_id: bundleData.data.ind_bundle_e_commerce_id,
                brand_id: bundleData.data.ind_bundle_brand_id,
                country_id: bundleData.data.ind_bundle_country_id,
                from_date: bundleData.data.ind_bundle_start_date,
                to_date: bundleData.data.ind_bundle_end_date,
            };
            const response = await http.post(
                'master_service/sample/bundle/data',
                payload
            );
            if (response.data.data.length === 0) {
                dispatch(setMessage('There is no sample datas found in this bundle'));
                dispatch(setSeverity('success'));
                dispatch(handleClick());
                return;
            }
            downloadCSV(
                response.data.data,
                bundleData.data.ind_bundle_name + '_Sample_Data'
            );
            // setBundleData(response.data.data);
            // setTbl({
            //   Category: response.data.payload.ind_bundle_category,
            //   Country: response.data.payload.ind_bundle_country,
            //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
            //   Brand: response.data.payload.ind_bundle_brand,
            //   "Configured Date": response.data.payload.ind_bundle_config_date,
            // });
            // console.log(
            //     'res smpl data',
            //     response,
            //     response.data.payload.ind_bundle_category
            // );
            dispatch(setMessage('The Sample data is Downloaded Successfully'));
            dispatch(setSeverity('success'));
            dispatch(handleClick());
            // localStorage.setItem('sample_datas', JSON.stringify(response.data))
            // window.location.href = '/dashboard/individual-dashboard/sample-data'
        } catch (error) {
            console.log('Error fetching sample data:', error);
            dispatch(setMessage('There is some error running the Sample data'));
            dispatch(setSeverity('error'));
            dispatch(handleClick());
        }
    };

    // console.log('data', Get);
    // console.log('data', data, bundlesData);

    // const data = SampleDataMartDatas?.[bundle_id]
    // const platform = `Platform (${data?.platform?.length}): ${data?.platform?.length > 1 ? data?.platform?.slice(0, -1)?.join(', ') + ' & ' + data?.platform[data?.platform?.length - 1] : data?.platform[0]}`
    // const brand = `Brand (${data?.brand?.length}): ${data?.brand?.length > 1 ? data?.brand?.slice(0, -1)?.join(', ') + ' & ' + data?.brand[data?.brand?.length - 1] : data?.brand[0]}`
    const formatList = (items, label) => {
        if (!items?.length) return `${label} (0):`;
        const formatted = items.length > 1
            ? `${items.slice(0, -1).join(', ')} & ${items[items.length - 1]}`
            : items[0];
        return `${label} (${items.length}): ${formatted}`;
    };

    const platform = formatList(data?.platform, 'Platform');

    const brand = formatList(data?.brand, 'Brand');

    const style = BundleOverviewStyles;

    // console.log('data', data, data?.platform, data?.platform?.length)
    return (
        <Box sx={style.container}>
            <Box sx={style.imageContainer}>
                <img alt={data?.bundleName} src={data?.overviewImg} style={{ borderRadius: 'inherit', width: '100%', objectFit: 'cover', height: '100%' }} />
            </Box>
            <Box sx={style.contentContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', mb: 1 }}>
                    <Typography sx={style.sectionTitle}>{data?.bundleName}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', "&:hover .noteModel": { opacity: 1, visibility: 'visible', }, }}>
                        {getFlag(data?.country)}
                        <Box
                            className="noteModel"
                            sx={{
                                position: 'absolute',
                                top: '100%', // Position below the flag
                                right: 0,
                                left: 0,
                                mt: 1, // Add some margin for spacing
                                opacity: 0,
                                visibility: 'hidden',
                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                zIndex: 1,
                                background: '#F3E8FA',
                                borderRadius: '4px',
                                padding: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                textAlign: 'center',
                                display: 'flex',
                                // alignItems: 'center',
                            }}
                        >
                            <Box sx={{ width: '20px' }}>
                                <NoteVioletIkn />
                            </Box>
                            <Typography sx={{ color: '#474747CC', fontSize: '12px', fontWeight: 500 }}>
                                <span style={{ color: '#000000CC', fontWeight: 600 }}>Note:</span> This Bundle contains data from {data?.country}
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Typography><CountryFlagEmoji countryCode={'us'} /></Typography> */}
                </Box>
                <Typography sx={{ fontSize: '14px', mb: 1, fontWeight: '400', color: '#474747' }}>{data?.bundleDescrp}</Typography>
                <Box>
                    <Typography sx={style.sectionTitle}>Configurations</Typography>
                    <Typography sx={style.detailItem}>This bundle includes:</Typography>
                    <List disablePadding sx={{ listStyleType: 'disc', pl: 4, color: '#474747' }}>
                        <ListItem disablePadding sx={{ display: 'list-item', color: '#474747' }}>
                            <Typography sx={style.detailItem} >Category (1) : {data?.category}</Typography>
                            {/* <ListItemText sx={style.detailItem} primary={`Category (1) : ${data?.category}`} /> */}
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item', color: '#474747' }}>
                            <Typography sx={style.detailItem}>Country (1) : {data?.country}</Typography>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item' }}>
                            <Typography sx={style.detailItem}>{platform}</Typography>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item' }}>
                            <Typography sx={style.detailItem}>{brand}</Typography>
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, }}>
                    <Typography sx={style.sectionTitle}>Data Description</Typography>
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ fontSize: '10px', fontWeight: '500', color: '#8A18D0', textTransform: 'capitalize', textDecorationLine: 'underline', '&:hover': { background: 'transparent', textDecorationLine: 'underline', } }} onClick={() => Sample_Data()}>(download sample)</Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, mb: 2, }}>
                    {data?.dateFrom && data?.dateTo ?
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                                <DateSvg />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#474747', ml: 1 }}>Date Range: {dateClnse4(data?.dateFrom)} till {dateClnse4(data?.dateTo)}</Typography>
                            </Box>
                        </>
                        :
                        null
                    }
                    {data?.noOfProducts ?
                        <>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                            <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                                <NoOfProductsSvg />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#474747', ml: 1 }}>No. of products: {data?.noOfProducts}</Typography>
                            </Box>
                        </>
                        :
                        null
                    }
                    {data?.dataPoints ?
                        <>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                            <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                                <DataPointsSvg />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#474747', ml: 1 }}>Data Points: {data?.dataPoints}</Typography>
                            </Box>
                        </>
                        :
                        null
                    }
                    {data?.lineItems ?
                        <>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                            <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                                <LineItemsSvg />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#474747', ml: 1 }}>Line Items: {data?.lineItems}</Typography>
                            </Box>
                        </>
                        :
                        null
                    }
                </Box>
                {data?.discount ?
                    <Box sx={{ display: 'flex', alignItems: 'end' }}>
                        <Typography sx={style.sectionTitle}>{data?.discountedPrice}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '16px', mx: 1, fontWeight: '500', textDecoration: 'line-through', color: '#474747CC' }}>{data?.bundlePrice}</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#008000' }}>({data?.discount} % off)</Typography>
                        </Box>
                    </Box>
                    :
                    <Typography sx={{ ...style.sectionTitle, mb: 3, }}>{data?.bundlePrice}</Typography>
                }
                <Box sx={{ display: 'flex', fontSize: { xs: '10px', md: '16px' }, justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    <Button disableTouchRipple disableFocusRipple disableGutters onClick={() => { dispatch(updateCartDatas(data)); dispatch(clickCart()); if (user_id) { addCart(); } }} sx={{ background: 'transparent', border: '1px solid #47474733', color: '#000', minWidth: '100px', width: '49%', textTransform: 'capitalize', '&:hover': { background: 'transparent' } }}>
                        <Typography sx={{ pr: 1, fontSize: { xs: '10px', md: '16px' }, }}>Add to Cart</Typography>
                        <GrayCartSvg />
                    </Button>
                    <Link style={{ width: '49%' }} to={`/data-mart/checkout?bundle_id=${encodeURIComponent(bundle_id)}`}>
                        <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', color: '#fff', minWidth: '100px', width: '100%', textTransform: 'capitalize', '&:hover': { background: '#8A18D0' } }}>
                            <Typography sx={{ pl: 1, fontSize: { xs: '10px', md: '16px' }, }}>Request Bundle</Typography>
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}

export const SimilarBundles = () => {
    const containerRef = useRef(null);
    const theme = useTheme();
    const [cardsToShow, setCardsToShow] = useState(3);
    const [showArrows, setShowArrows] = useState(false);

    // Responsive breakpoints
    const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    // Data setup
    const Get = useSelector((s) => s?.allbundles?.datamartBundles?.allDataMartBundles);
    const tbl = ConvertDataMartBundles(Get);

    const location = useLocation();
    const dispatch = useDispatch();
    const [bundle_id, setBundle_Id] = useState(null)
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("bundle_id")) {
            setBundle_Id(params.get("bundle_id"));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);

    // Responsive card count
    useEffect(() => {
        if (isXSmall) setCardsToShow(1);
        else if (isSmall) setCardsToShow(2);
        else if (isMedium) setCardsToShow(3);
        else if (isLarge) setCardsToShow(3);
        // else if (isLarge) setCardsToShow(4);
    }, [isXSmall, isSmall, isMedium, isLarge]);

    // Scroll handlers
    const handleScroll = (direction) => {
        const container = containerRef.current;
        if (!container) return;

        const scrollAmount = container.clientWidth * 0.8;
        container.scrollBy({
            left: direction === 'next' ? scrollAmount : -scrollAmount,
            behavior: 'smooth'
        });
    };

    // Scrollbar styling
    const scrollbarStyles = {
        '&::-webkit-scrollbar': {
            height: '0px',
            // height: '6px',
            backgroundColor: '#f5f5f5',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'linear-gradient(45deg, #8A18D0 30%, #CA3FC4 90%)',
            '&:hover': {
                background: '#8A18D0',
            },
        },
    };

    return (
        <Box
            sx={{
                position: 'relative',
                mt: 6,
                '&:hover .scroll-arrow': {
                    opacity: 1
                }
            }}
            onMouseEnter={() => setShowArrows(true)}
            onMouseLeave={() => setShowArrows(false)}
        >
            <Typography variant="h4" sx={{ mb: 3 }}>
                Similar Bundles
            </Typography>

            {/* Carousel Container */}
            <Box
                ref={containerRef}
                sx={{
                    display: 'flex',
                    gap: 4,
                    overflowX: 'auto',
                    scrollSnapType: 'x mandatory',
                    p: 1,
                    ...scrollbarStyles,
                    '& > *': {
                        scrollSnapAlign: 'start'
                    }
                }}
            >
                {tbl?.filter((k) => k?.bundle_id !== bundle_id)?.map((e, i) => (
                    <Box
                        key={i}
                        sx={{
                            flex: `0 0 calc(${100 / cardsToShow}% - ${theme.spacing(4)})`,
                            minWidth: 300,
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'translateY(-4px)'
                            }
                        }}
                    >
                        <DataMartBundleCard
                            e={{ ...e, style: { outerBox: { width: '100%', minWidth: 'auto' } } }}
                        />
                    </Box>
                ))}
            </Box>

            {/* Navigation Arrows */}
            {showArrows && (
                <>
                    <IconButton
                        className="scroll-arrow"
                        onClick={() => handleScroll('prev')}
                        sx={{
                            position: 'absolute',
                            left: -20,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            bgcolor: '#000',
                            '& svg': { color: 'white' },
                            boxShadow: 3,
                            opacity: 0,
                            transition: 'opacity 0.3s, left 0.3s',
                            '&:hover': {
                                left: -24,
                                bgcolor: '#000',
                                '& svg': { color: 'white' }
                            }
                        }}
                    >
                        <ChevronLeft sx={{ color: 'text.primary' }} />
                    </IconButton>

                    <IconButton
                        className="scroll-arrow"
                        onClick={() => handleScroll('next')}
                        sx={{
                            position: 'absolute',
                            right: -20,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            bgcolor: '#000',
                            '& svg': { color: 'white' },
                            boxShadow: 3,
                            opacity: 0,
                            transition: 'opacity 0.3s, right 0.3s',
                            '&:hover': {
                                right: -24,
                                bgcolor: '#000',
                                '& svg': { color: 'white' }
                            }
                        }}
                    >
                        <ChevronRight sx={{ color: 'text.primary' }} />
                    </IconButton>
                </>
            )}
        </Box>
    );
};

