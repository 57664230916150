import { Box, Divider } from "@mui/material"
import { Back } from "../../../../../components/@extended/Button"
import { OverallRating, PRSS, Reviews, SellerProfile, SellerTbl } from "./components"
import http from '../../../../../utils/http-common'
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"


export const Seller = () => {
    const [seller_id, setSeller_id] = useState(null);
    const [country_id, setCountry_id] = useState(null);
    const [res, setRes] = useState(null);
    const location = useLocation();
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("seller_id")) {
            setSeller_id(params.get("seller_id"));
        }
        if (params.get("country_id")) {
            setCountry_id(params.get("country_id"));
        }
        if (params.get("card")) {
            // setCard(Number(params.get("card")));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    const Get = async () => {
        try {
            const response = await http.post('master_service/seller_details', JSON.stringify({
                seller_id: seller_id,
                country_id: country_id,
            }))
            console.log("response tbl:", response.data);
            setRes(response.data)
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => { if (seller_id !== null) { Get() } }, [seller_id])
    return (
        <Box sx={{ p: 2, px: 3, pr: 0 }}>
            <Back prop={{ txt: 'Back' }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%', maxHeight: '83vh', overflowY: 'auto', gap: 1 }}>
                    <SellerProfile prop={res} />
                    <SellerTbl />
                </Box>
                <Divider sx={{ width: '0.5px' }} orientation="vertical" flexItem />
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '35%', maxHeight: '83vh', overflowY: 'auto', height: '100%', px: 2, gap: 1 }}>
                    <PRSS prop={res} />
                    <OverallRating prop={res} />
                    <Reviews prop={res} />
                </Box>
            </Box>
        </Box>
    )
}