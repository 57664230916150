import { useRazorpay } from "react-razorpay";
import { useNavigate } from "react-router-dom";


export const useRazorpayPayment = () => {
    const { error, isLoading, Razorpay } = useRazorpay();
    const RazorpayPayment = async ({
        amount,
        handler = () => { },
        onPaymentFailed = () => { },
        onError = () => { },
        ondismiss = () => { },
        razorpayKey = 'rzp_test_Ir3RqpkKv3LiIt',
        name = 'Brand Aura',
        description = 'Test Transaction',
        notes = { address: 'Razorpay Corporate Office' },
        theme = { color: '#3399cc' },
    }) => {
        try {
            const currency = 'usd';
            //   const currency = localStorage.getItem('currency');

            if (error) {
                console.error('Razorpay error:', error);
                onError(error); // Call onError prop if provided
                return;
            }

            if (isLoading) {
                console.log("Razorpay is still loading");
                // return;
            }

            const razorpayOptions = {
                key: razorpayKey,
                amount: Number(amount) * 100, // Amount in paise
                currency: String(currency).toUpperCase(),
                name: name,
                description: description,
                handler: async function (k) {
                    console.log('Payment successful:', k);
                    handler(k); // Call the provided handler prop
                },
                modal: {
                    ondismiss: (e) => { ondismiss(e) }
                },
                notes: notes,
                theme: theme,
            };

            console.log('options', razorpayOptions);

            if (window.Razorpay) {
                const rzp = new Razorpay(razorpayOptions);
                rzp.open();
                rzp.on('payment.failed', async function (res) {
                    console.error('Payment failed:', res);
                    const { error } = res;
                    const errorMessage = error?.description || 'Payment failed. Please try again.';
                    onPaymentFailed({ res, errorMessage }); // Call onPaymentFailed prop
                });
            }
        } catch (err) {
            console.error('Error in payment initiation:', err);
            onError(err); // Call onError prop if provided
        }
    };
    return RazorpayPayment
}


export const RazorpayPayment3 = async ({
    amount,
    handler = () => { },
    onPaymentFailed = () => { },
    // onError = () => { },
    razorpayKey = 'rzp_test_Ir3RqpkKv3LiIt',
    name = 'Brand Aura',
    description = 'Test Transaction',
    notes = { address: 'Razorpay Corporate Office' },
    theme = { color: '#3399cc' },
}) => {
    try {
        const { error, isLoading, Razorpay } = useRazorpay();
        const currency = 'usd';
        //   const currency = localStorage.getItem('currency');

        if (error) {
            console.error('Razorpay error:', error);
            // onError(error); // Call onError prop if provided
            return;
        }

        if (isLoading) {
            console.log("Razorpay is still loading");
            return;
        }

        const razorpayOptions = {
            key: razorpayKey,
            amount: Number(amount) * 100, // Amount in paise
            currency: String(currency).toUpperCase(),
            name: name,
            description: description,
            handler: async function (k) {
                console.log('Payment successful:', k);
                handler(k); // Call the provided handler prop
            },
            notes: notes,
            theme: theme,
        };

        console.log('options', razorpayOptions);

        if (window.Razorpay) {
            const rzp = new Razorpay(razorpayOptions);
            rzp.open();
            rzp.on('payment.failed', async function (res) {
                console.error('Payment failed:', res);
                const { error } = res;
                const errorMessage = error?.description || 'Payment failed. Please try again.';
                onPaymentFailed({ res, errorMessage }); // Call onPaymentFailed prop
            });
        }
    } catch (err) {
        console.error('Error in payment initiation:', err);
        // onError(err); // Call onError prop if provided
    }
};


export const RazorpayPayment2 = () => {
    try {
        console.log('pri', price);

        const razorpayOptions = {
            key: 'rzp_test_Ir3RqpkKv3LiIt', // Replace with your Razorpay Key ID
            // key_secret: 'O3nI73DRVT66p1CO3H0DttzQ',
            // amount: Number(data?.finalPriceWithSymbol) * 100, // Amount in paise
            amount: Number(price) * 100, // Amount in paise
            currency: String(currency).toUpperCase(),
            name: 'Brand Aura',
            description: 'Test Transaction',
            // order_id: response?.data?.data?.order_number,
            handler: async function (k) {
                console.log('Payment successful:', k);
                // alert('Payment successful!');
                console.log('received datas', receivedData);
                TransactionApiTrigger({ id: k?.razorpay_payment_id, payment_confirmation_status: 'success' })
                navigate('/login')
            },
            // prefill: {
            // name: 'John Doe',
            // email: 'john@example.com',
            // contact: '9999999999',
            // },
            notes: {
                address: 'Razorpay Corporate Office',
            },
            theme: {
                color: '#3399cc',
            },
            // method: paymentType === "credit_card" || paymentType === "debit_card" ? 'card' : paymentType === 'netbanking' ? 'netbanking' : paymentType, // Dynamically set the payment method
        };
        console.log('options', razorpayOptions);

        if (window.Razorpay) {
            const rzp = new Razorpay(razorpayOptions);
            rzp.open()
            rzp.on('payment.failed', async function (res) {
                // Log the error details
                console.error('Payment failed:', res);

                // Extract error details from the response
                const { error } = res;
                const errorMessage = error?.description || 'Payment failed. Please try again.';

                // Display an error message to the user
                // alert(errorMessage);

                // Optionally, log the error for debugging
                // console.error('Error code:', error?.code);
                // console.error('Error source:', error?.source);
                // console.error('Error reason:', error?.reason);
                TransactionApiTrigger({ id: null, payment_confirmation_status: 'failed', errorMessage: errorMessage })
            });
        }
    } catch (error) {
        console.log('error in payment', error)
    }
}