import { Box, Button, Typography } from "@mui/material";
import { Tabs } from "../../../../../utils/data/LandingPage/Data";
import { useState } from "react";



const TabComponent = () => {
    const [tab, setTab] = useState(0)
    const tabBtns = [
        {
            icon: (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_50_728)">
                    <path d="M19.5938 2.88748C19.3574 2.73455 19.087 2.64204 18.8065 2.61806C18.526 2.59408 18.2439 2.63938 17.985 2.74998L9.50125 6.09123C9.3004 6.17297 9.0856 6.215 8.86875 6.21498H3.4375C2.98166 6.21498 2.54449 6.39606 2.22216 6.71839C1.89983 7.04072 1.71875 7.47789 1.71875 7.93373V8.07123H0V12.1962H1.71875V12.375C1.72952 12.8236 1.91535 13.2503 2.23651 13.5637C2.55768 13.8772 2.98872 14.0526 3.4375 14.0525L5.5 18.425C5.63966 18.7194 5.85953 18.9685 6.13437 19.1436C6.40921 19.3187 6.72787 19.4128 7.05375 19.415H7.92C8.37345 19.4114 8.8071 19.2287 9.12646 18.9067C9.44582 18.5848 9.62501 18.1497 9.625 17.6962V14.2175L17.985 17.5587C18.1906 17.6406 18.4099 17.6826 18.6313 17.6825C18.9746 17.6769 19.309 17.5719 19.5938 17.38C19.8199 17.2273 20.0065 17.0229 20.138 16.7838C20.2695 16.5447 20.3421 16.2777 20.35 16.005V4.30373C20.3487 4.02394 20.2792 3.74868 20.1474 3.50187C20.0156 3.25506 19.8256 3.04416 19.5938 2.88748ZM7.90625 7.93373V12.375H3.4375V7.93373H7.90625ZM7.90625 17.6962H7.04L5.34875 14.0525H7.90625V17.6962ZM10.1337 12.5812C9.97049 12.4978 9.80026 12.4288 9.625 12.375V7.83748C9.79859 7.80166 9.96879 7.75106 10.1337 7.68623L18.6313 4.30373V15.9637L10.1337 12.5812ZM20.3913 8.41498V11.8525C20.8471 11.8525 21.2843 11.6714 21.6066 11.3491C21.9289 11.0267 22.11 10.5896 22.11 10.1337C22.11 9.67789 21.9289 9.24072 21.6066 8.91839C21.2843 8.59606 20.8471 8.41498 20.3913 8.41498Z" fill={tab === 0 ? '#8A18D0' : '#ffffff'} />
                </g>
                <defs>
                    <clipPath id="clip0_50_728">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                </defs>
            </svg>),
            text: 'Marketing'
        },
        {
            icon: (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.98996 9.97145L10.3233 18.028C10.69 18.4314 11.3234 18.4314 11.69 18.028L19.0234 9.97054C19.3103 9.6552 19.3433 9.18312 19.1031 8.83112L15.8609 4.06995C15.777 3.94713 15.6645 3.84656 15.533 3.77693C15.4016 3.7073 15.2552 3.6707 15.1065 3.67029H6.9243C6.77556 3.6707 6.62915 3.7073 6.49772 3.77693C6.36628 3.84656 6.25377 3.94713 6.16988 4.06995L2.92488 8.82929C2.6838 9.1822 2.7168 9.6552 3.00463 9.97145H2.98996Z" stroke={tab === 1 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            ),
            text: 'Branding'
        },
        {
            icon: (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4151 9.58475C11.6643 8.83422 10.6461 8.4126 9.58445 8.4126C8.52281 8.4126 7.50463 8.83422 6.75379 9.58475L3.9222 12.4154C3.17134 13.1663 2.74951 14.1847 2.74951 15.2465C2.74951 16.3084 3.17134 17.3268 3.9222 18.0777C4.67306 18.8285 5.69145 19.2504 6.75333 19.2504C7.8152 19.2504 8.83359 18.8285 9.58445 18.0777L10.9998 16.6623" stroke={tab === 2 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.58447 12.4154C10.3353 13.1659 11.3535 13.5875 12.4151 13.5875C13.4768 13.5875 14.495 13.1659 15.2458 12.4154L18.0774 9.5847C18.8283 8.83384 19.2501 7.81545 19.2501 6.75357C19.2501 5.69169 18.8283 4.67331 18.0774 3.92245C17.3265 3.17158 16.3081 2.74976 15.2463 2.74976C14.1844 2.74976 13.166 3.17158 12.4151 3.92245L10.9998 5.33778" stroke={tab === 2 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            ),
            text: 'Supply Chain'
        },
        {
            icon: (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_50_745)">
                        <path d="M19.2498 7.33328C19.7361 7.33328 20.2024 7.52644 20.5462 7.87025C20.89 8.21407 21.0832 8.68039 21.0832 9.16662V12.8333C21.0832 13.3195 20.89 13.7858 20.5462 14.1296C20.2024 14.4735 19.7361 14.6666 19.2498 14.6666H18.2763C18.0529 16.4388 17.1903 18.0685 15.8506 19.2499C14.5109 20.4314 12.7861 21.0833 10.9998 21.0833V19.2499C12.4585 19.2499 13.8575 18.6705 14.8889 17.639C15.9204 16.6076 16.4998 15.2086 16.4998 13.7499V8.24995C16.4998 6.79126 15.9204 5.39231 14.8889 4.36086C13.8575 3.32941 12.4585 2.74995 10.9998 2.74995C9.54115 2.74995 8.1422 3.32941 7.11075 4.36086C6.0793 5.39231 5.49984 6.79126 5.49984 8.24995V14.6666H2.74984C2.26361 14.6666 1.79729 14.4735 1.45347 14.1296C1.10966 13.7858 0.916504 13.3195 0.916504 12.8333V9.16662C0.916504 8.68039 1.10966 8.21407 1.45347 7.87025C1.79729 7.52644 2.26361 7.33328 2.74984 7.33328H3.72334C3.94703 5.56127 4.80966 3.93178 6.14936 2.75057C7.48906 1.56936 9.21376 0.917603 10.9998 0.917603C12.7859 0.917603 14.5106 1.56936 15.8503 2.75057C17.19 3.93178 18.0526 5.56127 18.2763 7.33328H19.2498ZM7.11317 14.4695L8.08484 12.9149C8.95849 13.4621 9.96892 13.7516 10.9998 13.7499C12.0308 13.7516 13.0412 13.4621 13.9148 12.9149L14.8865 14.4695C13.7217 15.1993 12.3744 15.5854 10.9998 15.5833C9.62525 15.5854 8.278 15.1994 7.11317 14.4695Z" fill={tab === 3 ? '#8A18D0' : '#ffffff'} />
                    </g>
                    <defs>
                        <clipPath id="clip0_50_745">
                            <rect width="22" height="22" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ),
            text: 'CRM'
        },
        {
            icon: (
                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.7918 6.41671L12.0002 1.83337L4.2085 6.41671V15.5834L12.0002 20.1667L19.7918 15.5834V6.41671Z" stroke={tab === 4 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linejoin="round" />
                    <path d="M12.0002 10.0833V13.75M15.6668 8.25V13.75M8.3335 11.9167V13.75" stroke={tab === 4 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            ),
            text: 'Sales'
        },
        {
            icon: (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.1664 16.8337C4.40031 16.0676 3.79261 15.1582 3.378 14.1572C2.9634 13.1563 2.75 12.0835 2.75 11C2.75 9.91663 2.9634 8.84383 3.378 7.84289C3.79261 6.84194 4.40031 5.93247 5.1664 5.16638M16.8337 5.16638C17.5998 5.93247 18.2075 6.84194 18.6221 7.84289C19.0367 8.84383 19.2501 9.91663 19.2501 11C19.2501 12.0835 19.0367 13.1563 18.6221 14.1572C18.2075 15.1582 17.5998 16.0676 16.8337 16.8337M7.75874 14.2414C7.33301 13.8158 6.99529 13.3104 6.76488 12.7543C6.53447 12.1981 6.41588 11.602 6.41588 11C6.41588 10.3981 6.53447 9.80196 6.76488 9.2458C6.99529 8.68965 7.33301 8.18433 7.75874 7.75871M14.2414 7.75871C14.6671 8.18433 15.0048 8.68965 15.2353 9.2458C15.4657 9.80196 15.5843 10.3981 15.5843 11C15.5843 11.602 15.4657 12.1981 15.2353 12.7543C15.0048 13.3104 14.6671 13.8158 14.2414 14.2414M11.9167 11C11.9167 11.2432 11.8202 11.4763 11.6483 11.6482C11.4763 11.8201 11.2432 11.9167 11.0001 11.9167C10.757 11.9167 10.5238 11.8201 10.3519 11.6482C10.18 11.4763 10.0834 11.2432 10.0834 11C10.0834 10.7569 10.18 10.5238 10.3519 10.3519C10.5238 10.18 10.757 10.0834 11.0001 10.0834C11.2432 10.0834 11.4763 10.18 11.6483 10.3519C11.8202 10.5238 11.9167 10.7569 11.9167 11Z" stroke={tab === 5 ? '#8A18D0' : '#ffffff'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            ),
            text: 'Digitalization'
        },
    ];
    return (
        <Box sx={{ width: { xs: '100%', md: '80%' } }}>
            <Box sx={{ px: { xs: '16px' } }}>
                {tabBtns.map((e, i) => (
                    <Button
                        key={i}
                        onClick={() => setTab(i)}
                        sx={{
                            background: tab === i ? '#fff' : '#000',
                            color: tab === i ? '#8A18D0' : '#fff',
                            px: '18px',
                            py: '15px',
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px',
                            mx: { xs: '5px', md: 0 },
                            my: { xs: 1, md: 0 },
                            textTransform: 'capitalize',
                            borderBottomLeftRadius: { xs: '16px', md: 0 },
                            borderBottomRightRadius: { xs: '16px', md: 0 },
                            '&:hover': { background: tab === i ? '#EAF6F1' : '' },
                        }}
                    >
                        {e.icon}
                        <Typography sx={{ pl: '6px', fontWeight: '500', fontSize: '14px' }}>{e.text}</Typography>
                    </Button>
                ))}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-evenly',
                        alignItems: 'stretch',
                        py: { xs: 3, md: 5 },
                        // pb: 0,
                        px: { xs: '18px', md: 0 },
                        borderTopLeftRadius: { xs: '16px', md: 0 },
                        borderTopRightRadius: { xs: '16px', md: 0 },
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                        // height: { xs: 'auto', lg: '550px' },
                        background: '#fff',
                        color: '#000000',
                        gap: '20px',
                    }}
                >
                    <Box sx={{ width: { xs: '100%', md: '100%' }, px: { xs: '', md: 5 }, display: 'flex', flexDirection: 'column', }}>
                        <Typography sx={{ color: '#8A18D0', fontWeight: '500', fontSize: { xs: '16px', md: '34.59px' }, pb: 0, textAlign: { xs: 'center', md: 'left' } }}>{Tabs[tab].title}</Typography>
                        <Box>
                            <Typography sx={{ color: '#000000', borderBottom: '1px solid #8A18D0', fontWeight: '500', textTransform:'uppercase', my: { xs: '5px', md: 2 }, mt: { xs: '15px', md: 2 }, mb: 5, fontSize: '16px' }}>{Tabs[tab].subTopic}</Typography>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
                                <Box sx={{ width: { xs: '100%', md: '45%' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                    {Tabs[tab].subTopicDatas.map((e, i) => (
                                        <Box key={i} sx={{ display: 'flex', py: 2 }}>
                                            <Box sx={{ flex: '1' }}>
                                                <Typography sx={{ color: '#000000', fontWeight: '600', fontSize: '14px' }}>{e.title}</Typography>
                                                <Typography sx={{ color: '#474747', fontWeight: '400', fontSize: '14px' ,textAlign: {xs: 'justify' ,md: 'justify' ,hyphens: 'none'}}}>{e.text}</Typography>
                                            </Box>
                                            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.18066 12.6445L9.43066 7.39446L4.18066 2.14443" stroke="black" strokeWidth="2.33333" strokeLinecap="round" />
                                        </svg>
                                    </Box> */}
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ width: { xs: '100%', md: '45%' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={Tabs[tab].img} alt="" className="h-[auto] w-[100%]" style={{ objectFit: 'contain', borderRadius: '8px' }} />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Button disableRipple sx={{ display: 'flex', width: 'max-content', textTransform: 'capitalize', '&:hover': { background: 'inherit' } }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ pr: '7px', color: '#000000', fontWeight: '500', fontSize: '13.89px' }}>
                                    {Tabs[tab].btn}
                                </Typography>
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.18066 12.6445L9.43066 7.39446L4.18066 2.14443" stroke="black" strokeWidth="2.33333" strokeLinecap="round" />
                                </svg>
                            </Box>
                        </Button> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default TabComponent;