

export const FilterIkn = () => (
    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 4.08333C1.75 3.92862 1.81146 3.78025 1.92085 3.67085C2.03025 3.56146 2.17862 3.5 2.33333 3.5H11.6667C11.8214 3.5 11.9697 3.56146 12.0791 3.67085C12.1885 3.78025 12.25 3.92862 12.25 4.08333C12.25 4.23804 12.1885 4.38642 12.0791 4.49581C11.9697 4.60521 11.8214 4.66667 11.6667 4.66667H2.33333C2.17862 4.66667 2.03025 4.60521 1.92085 4.49581C1.81146 4.38642 1.75 4.23804 1.75 4.08333ZM2.91667 6.70833C2.91667 6.55362 2.97812 6.40525 3.08752 6.29585C3.19692 6.18646 3.34529 6.125 3.5 6.125H10.5C10.6547 6.125 10.8031 6.18646 10.9125 6.29585C11.0219 6.40525 11.0833 6.55362 11.0833 6.70833C11.0833 6.86304 11.0219 7.01142 10.9125 7.12081C10.8031 7.23021 10.6547 7.29167 10.5 7.29167H3.5C3.34529 7.29167 3.19692 7.23021 3.08752 7.12081C2.97812 7.01142 2.91667 6.86304 2.91667 6.70833ZM4.66667 9.33333C4.66667 9.17862 4.72812 9.03025 4.83752 8.92085C4.94692 8.81146 5.09529 8.75 5.25 8.75H8.75C8.90471 8.75 9.05308 8.81146 9.16248 8.92085C9.27187 9.03025 9.33333 9.17862 9.33333 9.33333C9.33333 9.48804 9.27187 9.63642 9.16248 9.74581C9.05308 9.85521 8.90471 9.91667 8.75 9.91667H5.25C5.09529 9.91667 4.94692 9.85521 4.83752 9.74581C4.72812 9.63642 4.66667 9.48804 4.66667 9.33333Z" fill="#797F8F" />
    </svg>
)

export const DownloadVioletIkn = () => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 8.2875C5.93333 8.2875 5.87083 8.27717 5.8125 8.2565C5.75417 8.23584 5.7 8.20034 5.65 8.15L3.85 6.35C3.75 6.25 3.702 6.13334 3.706 6C3.71 5.86667 3.758 5.75 3.85 5.65C3.95 5.55 4.06883 5.498 4.2065 5.494C4.34417 5.49 4.46283 5.53783 4.5625 5.6375L5.5 6.575V3C5.5 2.85834 5.548 2.73967 5.644 2.644C5.74 2.54834 5.85867 2.50034 6 2.5C6.14133 2.49967 6.26017 2.54767 6.3565 2.644C6.45283 2.74034 6.50067 2.859 6.5 3V6.575L7.4375 5.6375C7.5375 5.5375 7.65633 5.4895 7.794 5.4935C7.93167 5.4975 8.05033 5.54967 8.15 5.65C8.24167 5.75 8.28967 5.86667 8.294 6C8.29833 6.13334 8.25033 6.25 8.15 6.35L6.35 8.15C6.3 8.2 6.24583 8.2355 6.1875 8.2565C6.12917 8.2775 6.06667 8.28784 6 8.2875ZM3 10.5C2.725 10.5 2.48967 10.4022 2.294 10.2065C2.09833 10.0108 2.00033 9.77534 2 9.5V8.5C2 8.35834 2.048 8.23967 2.144 8.144C2.24 8.04834 2.35867 8.00034 2.5 8C2.64133 7.99967 2.76017 8.04767 2.8565 8.144C2.95283 8.24034 3.00067 8.359 3 8.5V9.5H9V8.5C9 8.35834 9.048 8.23967 9.144 8.144C9.24 8.04834 9.35867 8.00034 9.5 8C9.64133 7.99967 9.76017 8.04767 9.8565 8.144C9.95283 8.24034 10.0007 8.359 10 8.5V9.5C10 9.775 9.90217 10.0105 9.7065 10.2065C9.51083 10.4025 9.27533 10.5003 9 10.5H3Z" fill="#8A18D0" />
    </svg>
)

export const DragAndDropUploadIkn = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.75 20V9.8125L10.5 13.0625L8.75 11.25L15 5L21.25 11.25L19.5 13.0625L16.25 9.8125V20H13.75ZM7.5 25C6.8125 25 6.22417 24.7554 5.735 24.2663C5.24583 23.7771 5.00083 23.1883 5 22.5V18.75H7.5V22.5H22.5V18.75H25V22.5C25 23.1875 24.7554 23.7763 24.2663 24.2663C23.7771 24.7563 23.1883 25.0008 22.5 25H7.5Z" fill="#474747" fill-opacity="0.8" />
    </svg>
)
export const CreateBundleTickIkn = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.75 8.75L6.25 12.25L13.25 4.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)