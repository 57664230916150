import { RoundedBtn } from "./Button"
import http from '../../utils/http-common'
import { modifyDataABLP } from "./CustomFunctions";
import { updateCredit } from "../../slice/credits";
import { useDispatch, useSelector } from "react-redux";
import { updateBundles } from "../../slice/allBundles";
import { useCallback } from "react";
import { setCartDatas } from "../../slice/cart";
import { handleClick, setMessage, setSeverity } from "../../slice/SnackPop";
import { DataMartCapitalBrands } from "../../utils/data/LandingPage/Data";
import { GetPriceWithSymbolNoDecimal } from "./CurrencyConversion";


export const useFetchDatas = () => {
  const dispatch = useDispatch();

  const FetchDatas = useCallback(async () => {
    try {
      const response = await http.post(
        '/master_service/corporate_bundle_list',
        JSON.stringify({ user_id: localStorage.getItem('user_id'), admin_id: localStorage.getItem('admin_id') })
      );
      if (response.status === 500) { return; }
      if (response.data.data.length !== 0) {
        const modifiedData = modifyDataABLP(response.data.data);
        dispatch(updateBundles(modifiedData)); // Update allBundles state
        dispatch(
          updateCredit({
            available_credit: response.data.available_credits,
            used_credit: response.data.used_credits,
          })
        );
      }
    } catch (error) {
      console.log('fetching error', error);
    }
  }, [dispatch]);

  return FetchDatas;
};

export const useCartDatas = () => {
  const dispatch = useDispatch();

  const FetchCart = useCallback(async () => {
    try {
      const response = await http.get(
        `/master_service/cart/items?user_id=${localStorage.getItem('user_id')}`,
        JSON.stringify({ user_id: localStorage.getItem('user_id'), admin_id: localStorage.getItem('admin_id') })
      );
      // console.log('Cartdatas', response, response?.data?.data)
      const modifyData = response?.data?.data?.sort((a, b) => b.id - a.id)?.map((e) => ({
        ...e,
        bundle_id: e?.individual_bundle_id,
        bundleName: e?.individual_bundle_name,
        cardImg: e?.bundle_image,
        bundlePrice: GetPriceWithSymbolNoDecimal(Number(e?.bundle_cost)),
      }))?.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.bundle_id === value.bundle_id
        ))
      );
      console.log('Cartdatas', response, response?.data?.data, modifyData)
      dispatch(setCartDatas(modifyData))
    } catch (error) {
      console.log('fetching error', error);
    }
  }, [dispatch]);

  return FetchCart;
};

export const useAddCartDatas = ({ prop }) => {
  const dispatch = useDispatch();
  const cart = useSelector((s) => s?.cart?.cart?.cartDatas);

  const AddCart = useCallback(async () => {
    try {
      const isBundleThere = cart?.some((ss) => {
        // console.log('ss?.bundle_id === prop?.individual_bundle_id', ss?.bundle_id, prop?.individual_bundle_id, ss?.bundle_id === prop?.individual_bundle_id, Number(ss?.bundle_id) === Number(prop?.individual_bundle_id));

        return Number(ss?.bundle_id) === Number(prop?.individual_bundle_id);
      });
      if (isBundleThere) return;
      console.log('isBundleThere', isBundleThere, cart, prop?.individual_bundle_id);

      const response = await http.post(
        `/master_service/add/cart`,
        JSON.stringify({
          user_id: localStorage.getItem('user_id'),
          individual_bundle_id: prop?.individual_bundle_id,
          individual_bundle_name: prop?.individual_bundle_name,
          bundle_image: prop?.bundle_image,
          bundle_cost: prop?.bundle_cost,
          quantity: prop?.quantity,
          session_id: prop?.session_id
        })
      );
      console.log('add cart', response)
      // const modifyData = response?.data?.data?.map((e) => ({
      //   bundle_id: e?.individual_bundle_id,
      //   bundleName: e?.individual_bundle_name,
      //   cardImg: e?.bundle_image,
      //   bundlePrice: e?.bundle_cost,
      // }))
      // dispatch(setCartDatas())
    } catch (error) {
      console.log('fetching error', error);
    }
  }, [dispatch, prop]);

  return AddCart;
};

export const useDeleteCartData = ({ prop }) => {
  const dispatch = useDispatch();

  const DeleteCart = useCallback(async () => {
    try {
      const response = await http.delete(
        `/master_service/cart/${localStorage.getItem('user_id')}/${prop?.individual_bundle_id}`
      );
      console.log('add cart', response)
      const modifyData = response?.data?.data?.map((e) => ({
        bundle_id: e?.individual_bundle_id,
        bundleName: e?.individual_bundle_name,
        cardImg: e?.bundle_image,
        bundlePrice: e?.bundle_cost,
      }))
      // dispatch(setCartDatas())
    } catch (error) {
      console.log('fetching error', error);
    }
  }, [dispatch, prop]);

  return DeleteCart;
};

// API handlers

// export const handleApiRequest = async (apiCall, successMessage, errorMessage) => {
export const useApiRequest = () => {
  const dispatch = useDispatch();

  const handleApiRequest = async ({ path, requestType = "post", pop = true, input = {}, successMessage, errorMessage }) => {
    try {
      const response = await http[requestType](path, JSON.stringify(input));
      if (pop) {
        dispatch(setMessage(response?.data?.message || successMessage || "Success"));
        dispatch(setSeverity("success"));
        dispatch(handleClick());
      }
      return response;
    } catch (error) {
      if (pop) {
        dispatch(setMessage(error.response?.data?.message || errorMessage || "An error occurred"));
        dispatch(setSeverity("error"));
        dispatch(handleClick());
      }
      throw error;
    }
  };

  return { handleApiRequest };
};



export const ActiveBundle = ({ i }) => {
  const FetchDatas = useFetchDatas();
  const Api = async () => {
    const pay = {
      ind_bundle_id: i.ind_bundle_id,
      user_id: localStorage.getItem('user_id'),
      bundle_status: i.bundle_status === 'Active' ? 'Inactive' : 'Active'
    }
    try {
      const response = http.post('master_service/bundle_status', pay)
      console.log(response, 'response')
      console.log('FetchDatas()', FetchDatas())
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <RoundedBtn txt={i.bundle_status} col={i.bundle_status === 'Active' ? '#008000' : '#FF4C51'} fn={Api} />
  )
}