import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminDashboardCredit, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";
import { store } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setRechargeCreditsDatas } from "../../../../slice/adminDashboard";


export const BrandAuraAdminDashboard = () => {
    const dispatch = useDispatch();
    const [searchTermCredit, setSearchTermCredit] = useState(null);
    const [searchTermPlan, setSearchTermPlan] = useState(null);
    const [filter1, setFilter1] = useState(null);
    const [filter2, setFilter2] = useState(null);
    const [page1, setPage1] = useState(1);
    const [rowsPerPage1] = useState(5);
    const [page2, setPage2] = useState(1);
    const [rowsPerPage2] = useState(5);
    const [tblDataC, setTblDataC] = useState([]);
    const tblDataP = useSelector((s) => s.adminDashboard.rawRechargeCreditsDatas)
    // const [tblDataP, setTblDataP] = useState([]);
    const [cardDatas, setCardDatas] = useState(
        {
            users: 0,
            creditRequests: 0,
            planRequest: 0,
            dataScrapedToday: 0
        }
    );
    console.log('store?.getState()', store?.getState(), tblDataP);

    const fetchDatasP = async () => {
        try {
            const response = await http.post("/master_service/credit_request");
            // console.log("response tbl products:", response?.data?.data, response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending'));
            dispatch(setRechargeCreditsDatas(response?.data?.data))
            // setTblDataP(response?.data?.data)
            setCardDatas((p) => ({ ...p, creditRequests: response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending')?.length, planRequest: response?.data?.data?.filter((e) => e?.plan_name !== null && e?.payment_status === 'Pending')?.length }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const cardData = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/card_status");
            // console.log("response card:", response?.data);
            setCardDatas((p) => ({ ...p, users: response?.data?.Total_Brand_Aura_Users, creditRequests: response?.data?.Pending_Credit_Requests, planRequest: response?.data?.Pending_Plan_Request, dataScrapedToday: response?.data?.Data_Scraped_Today }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => {
        fetchDatasP()
        cardData()
    }, [])
    // const { tblC, tblP } = AdminDashboardCredit({ d: tblDataP })
    const { tblC, tblP } = useAdminCreditPlanRequest({ d: tblDataP })
    // console.log('tbl', tblC, tblP)
    // const filteredDataC = tblC;
    const filteredDataC = tblC?.map((e) => { const { request_date, user_name, amount, applicable_credits, ...rest } = e; return rest; })?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermCredit ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.request_id,
            k?.user_id,
            k?.payment_type?.props?.value,
            k?.transaction_number?.props?.value,
            k?.payment_date?.props?.value,
            // k?.user_name,
            // k?.request_date,
            k?.payment_status
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter1 === null || filter1 === 'all' || filter1 === k?.payment_status?.props?.txt
        return matchesSearch && filtMatch;
    });
    // console.log('filteredDataC', filteredDataC)
    // const filteredDataP = tblP;
    const filteredDataP = tblP?.map((e) => { const { request_date, user_name, plan_name, amount, applicable_credits, ...rest } = e; return rest; })?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermPlan ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.request_id,
            k?.user_id,
            k?.payment_type?.props?.value,
            k?.transaction_number?.props?.value,
            k?.payment_date?.props?.value,
            // k?.user_name,
            // k?.request_date,
            k?.payment_status
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter2 === null || filter2 === 'all' || filter2 === k?.payment_status?.props?.txt
        return matchesSearch && filtMatch;
    });
    // console.log('tblll', tblC, filteredDataC);

    const propsCredit = { filteredData: filteredDataC, tableStyle: { OutBox: { borderRadius: "8px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }, }, rowsPerPage: rowsPerPage1, page: page1, setPage: setPage1 }
    const propsPlan = { filteredData: filteredDataP, tableStyle: { OutBox: { borderRadius: "8px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }, }, rowsPerPage: rowsPerPage2, page: page2, setPage: setPage2 }
    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column' }, justifyContent: 'space-between', alignItems: 'normal', width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <BasicCard title='Total Brand Aura Users' count={cardDatas?.users} width={'24.5%'} />
                        <BasicCard title='Pending Credit Request' count={cardDatas?.creditRequests} width={'24.5%'} />
                        <BasicCard title='Pending Plan Request' count={cardDatas?.planRequest} width={'24.5%'} />
                        <BasicCard title='Data Scrapped Today' count={cardDatas?.dataScrapedToday} width={'24.5%'} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Credit Table</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermCredit} onChange={(e) => setSearchTermCredit(e.target.value)} />
                            </Box>
                            <Select
                                displayEmpty
                                size='small'
                                value={filter1}
                                onChange={(e) => setFilter1(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "132px",
                                    height: "40px",
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter1 === null ? 'end' : 'center',
                                        pr: '5px !important',
                                        pl: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Payment Status</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Paid</MenuItem>
                                <MenuItem value='Not Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Not Paid</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsCredit} />
                </Box>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <BasicCard title='Pending Plan Request' count={cardDatas?.planRequest} width={'49%'} />
                        <BasicCard title='Data Scrapped Today' count={cardDatas?.dataScrapedToday} width={'49%'} />
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Plan Table</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermPlan} onChange={(e) => setSearchTermPlan(e.target.value)} />
                            </Box>
                            <Select
                                displayEmpty
                                size='small'
                                value={filter2}
                                onChange={(e) => setFilter2(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "132px",
                                    // minWidth:'132px',
                                    height: "40px",
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter2 === null ? 'end' : 'center',
                                        pr: '5px !important',
                                        pl: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '12px', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Payment Status</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Paid</MenuItem>
                                <MenuItem value='Not Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Not Paid</MenuItem>
                            </Select>
                            <Link to={'/admin/dashboard/custom-plan'}><Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }}>+ Custom Plan</Button></Link>
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsPlan} />
                </Box>
            </Box>
        </Box>
    )
}
