import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminDashboardCredit, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setRechargeCreditsDatas } from "../../../../slice/adminDashboard";


export const PlanRequest = () => {
    const dispatch = useDispatch();
    const tblDataP = useSelector((s) => s.adminDashboard.rawRechargeCreditsDatas)
    const [searchTermCredit, setSearchTermCredit] = useState(null);
    const [searchTermPlan, setSearchTermPlan] = useState(null);
    const [filter1, setFilter1] = useState(null);
    const [filter2, setFilter2] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [tblDataC, setTblDataC] = useState([]);
    // const [tblDataP, setTblDataP] = useState([]);
    const [cardDatas, setCardDatas] = useState(
        {
            totalPlanRequest: 0,
            pendingPlanRequest: 0,
            completedPlanRequest: 0,
        }
    );
    const cardData = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/card_status");
            console.log("response card:", response?.data);
            setCardDatas((p) => ({ ...p, totalPlanRequest: response?.data?.Total_Plan_Requests, pendingPlanRequest: response?.data?.Pending_Plan_Request, completedPlanRequest: response?.data?.Completed_Plan_Requests }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const fetchDatasP = async () => {
        try {
            const response = await http.post("/master_service/credit_request");
            // console.log("response tbl products:", response?.data?.data, response?.data?.data?.filter((e) => e?.plan_name === null && e?.payment_status === 'Pending'));
            dispatch(setRechargeCreditsDatas(response?.data?.data))
            // setTblDataP(response?.data?.data)
            // setCardDatas((p) => ({ ...p, totalPlanRequest: response?.data?.data?.filter((e) => e?.plan_name !== null)?.length, pendingPlanRequest: response?.data?.data?.filter((e) => e?.plan_name !== null && e?.payment_status === 'Pending')?.length, completedPlanRequest: response?.data?.data?.filter((e) => e?.plan_name !== null && e?.payment_status !== 'Pending')?.length }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => {
        fetchDatasP()
        cardData()
    }, [])
    const { tblP } = useAdminCreditPlanRequest({ d: tblDataP })
    // const { tblC, tblP } = useAdminCreditPlanRequest({ d: tblDataP })
    // console.log('tbl', tblC, tblP)
    // const filteredDataC = tblC;
    // const filteredDataC = tblC?.filter((k) => {
    //     if (!k) return false;

    //     const searchTerm = String(searchTermCredit ?? '')?.toLowerCase();
    //     // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
    //     const matchesSearch = [
    //         k?.request_id,
    //         k?.user_id,
    //         k?.user_name,
    //         k?.request_date,
    //         k?.payment_status
    //     ].some(field =>
    //         String(field ?? '').toLowerCase().includes(searchTerm)
    //     );
    //     const filtMatch = filter1 === null || filter1 === 'all' || filter1 === k?.payment_status?.props?.txt
    //     return matchesSearch && filtMatch;
    // });
    // console.log('filteredDataC', filteredDataC)
    // const filteredDataP = tblP;
    const filteredDataP = tblP?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermPlan ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.request_id,
            k?.user_id,
            k?.user_name,
            k?.request_date,
            k?.payment_type?.props?.value,
            k?.transaction_number?.props?.value,
            k?.payment_date?.props?.value,
            k?.payment_status,
            k?.amount
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter2 === null || filter2 === 'all' || filter2 === k?.payment_status?.props?.txt
        return matchesSearch && filtMatch;
    });
    // const propsCredit = { filteredData: filteredDataC, rowsPerPage, page, setPage }
    const propsPlan = { filteredData: filteredDataP, rowsPerPage, page, setPage }
    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2, gap: 1 }}>
                        <BasicCard title='Total Plan Request' count={cardDatas?.totalPlanRequest} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Pending Plan Request' count={cardDatas?.pendingPlanRequest} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Completed Plan Request' count={cardDatas?.completedPlanRequest} width={{ xs: '100%', md: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Plan Table</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermPlan} onChange={(e) => setSearchTermPlan(e.target.value)} />
                            </Box>
                            <Select
                                displayEmpty
                                size='small'
                                value={filter2}
                                onChange={(e) => setFilter2(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "170px",
                                    height: "40px",
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter2 === null ? 'end' : 'center',
                                        pr: '5px !important',
                                        pl: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Payment Status</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Paid</MenuItem>
                                <MenuItem value='Not Paid' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Not Paid</MenuItem>
                            </Select>
                            <Link to={'/admin/dashboard/custom-plan'}><Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }}>+ Custom Plan</Button></Link>
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsPlan} />
                </Box>
            </Box>
        </Box>
    )
}
