import { Box } from "@mui/material";
import { TopNav_2 } from "../../../../components/nav/top_nav_2";
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { SideNav_3 } from "../../../../components/nav/side_nav_3";
import { adminNavItms, navItms, routesMap, shortSideNav, sideNav } from "../../../../utils/data/IndividualDashboard/Data";
import { Analytics } from "../../../../Analytics";


export const Main_2 = () => {
    const [nav, setNav] = useState(-1);
    const [subNav, setSubNav] = useState(-1);
    const containerRef = useRef(null);
    const sideNavRef = useRef(null);
    const [mainContentWidth, setMainContentWidth] = useState('100%');
    const [minimize, setMinimize] = useState(false)
    const ut = localStorage.getItem('user_type');
    const navigate = useNavigate();
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100)
    const handleResize = () => {
        setMinimize(window.innerWidth < 1100);
        setSmallScreen(window.innerWidth < 1100);
    };

    useEffect(() => {
        const userRole = localStorage.getItem('user_role');
        if (ut === 'company' && userRole === 'Admin User') {
            navigate('/admin');
        } else if (ut === 'company' && userRole === 'Normal User') {
            navigate('/user');
        } else if (ut === 'admin') {
            navigate('/admin/dashboard')
        }
    }, []);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [minimize]);
    const smsc2 = window.innerWidth < 900
    const updateMainContentWidth = () => {
        if (!smsc2) {
            if (containerRef.current && sideNavRef.current) {
                const newWidth = `${containerRef.current.offsetWidth - sideNavRef.current.offsetWidth}px`;
                setMainContentWidth(newWidth);
            }
        } else {
            if (containerRef.current) {
                const newWidth = `${containerRef.current.offsetWidth}px`;
                setMainContentWidth(newWidth);
            }
        }
    };

    useEffect(() => {
        // Initial calculation
        updateMainContentWidth();

        // Add event listener for window resize
        window.addEventListener('resize', updateMainContentWidth);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateMainContentWidth);
        };
    }, [minimize]);
    console.log('mainContentWidth', mainContentWidth, containerRef, containerRef.current, sideNavRef)
    return (
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }} ref={containerRef}>
            <SideNav_3 ref={sideNavRef} sref={sideNavRef} nav={nav} subNav={subNav} minimize={minimize} smallScreen={smallScreen} setSmallScreen={setSmallScreen} setMinimize={setMinimize} setSubNav={setSubNav} setNav={setNav} Data={!minimize ? sideNav : shortSideNav} routesMap={routesMap} commonPath={'/dashboard/individual-dashboard'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: mainContentWidth, maxWidth: mainContentWidth }}>
                <Analytics />
                <TopNav_2 setNav={setNav} minimize={minimize} setMinimize={setMinimize} Data={!smallScreen ? navItms : adminNavItms} />
                <Box sx={{ height: '100%', overflowY: 'auto', scrollbarWidth: 'none' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}