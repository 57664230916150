import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clickCart, removeCart } from "../../slice/cart";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { NoBundlesFoundIcn } from "../../utils/icon/LandingPage/Icon";
import { NoteComponent } from "../@extended/Card";
import { BackIcon } from "../../utils/icon/AdminPage/Icon";
import { useCartDatas, useDeleteCartData } from "../@extended/ApiCall";
import { GetPriceWithSymbolNoDecimal } from "../@extended/CurrencyConversion";

export const CartCard = ({ e }) => {
    const dispatch = useDispatch()
    const user_id = localStorage.getItem('user_id')
    const deleteCart = useDeleteCartData({ prop: { individual_bundle_id: e?.bundle_id } })
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '140px', width: '100%', p: 2, my: 1, borderRadius: '8px', border: '1px solid #EAECF0' }}>
            <Box sx={{ borderRadius: 'inherit', display: { xs: 'none', md: 'flex' }, height: '100%', pr: 1, }}>
                <img alt="" src={e?.cardImg} style={{ borderRadius: 'inherit', width: 'auto', height: '100%' }} />
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: '500', color: '#000' }}>{e?.bundleName}</Typography>
                    <IconButton sx={{}} onClick={() => {
                        dispatch(removeCart(e?.bundle_id));
                        if (user_id) { deleteCart() }
                    }}>
                        <DeleteIcon sx={{ color: '#000' }} />
                    </IconButton>
                </Box>
                <Typography sx={{ fontSize: { xs: '20px', md: '24px' }, fontWeight: '600', color: '#000' }}>{e?.bundlePrice}</Typography>
                {e?.bundle_status === 'Inactive' ?
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', color: '#fff', minWidth: '100px', width: '100%', textTransform: 'capitalize', '&:hover': { background: '#8A18D0' } }} disabled={e?.bundle_status === 'Inactive'}>
                        <Typography sx={{ pl: 1 }}>Request Bundle</Typography>
                    </Button>
                    :
                    <Link onClick={() => dispatch(clickCart())} style={{ width: '100%' }} to={`/data-mart/checkout?bundle_id=${encodeURIComponent(e?.bundle_id)}`}>
                        <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', color: '#fff', minWidth: '100px', width: '100%', textTransform: 'capitalize', '&:hover': { background: '#8A18D0' } }} disabled={e?.bundle_status === 'Inactive'}>
                            <Typography sx={{ pl: 1 }}>Request Bundle</Typography>
                        </Button>
                    </Link>}
            </Box>
        </Box>
    );
}

export const Cart = () => {
    const cart = useSelector((s) => s?.cart?.cart?.cartDatas);
    const cartOpen = useSelector((s) => s?.cart?.cart?.isClicked);
    const tkn = useSelector((s) => s?.token?.token)
    const dispatch = useDispatch()
    const user_id = localStorage.getItem('user_id')
    const FetchCartDatas = useCartDatas();
    console.log('cart', cart);


    const handleOverlayClick = (e) => {
        console.log('e', e.target.id, e.target.id === 'cart-overlay', e)
        if (e.target.id === 'cart-overlay') {
            console.log('ent');
            dispatch(clickCart());
        }
    };
    useEffect(() => {
        if (tkn) { FetchCartDatas() }
    }, [])
    return (
        <Box
            id="cart-overlay"
            onClick={handleOverlayClick}
            sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                zIndex: 999,
                display: cartOpen ? 'flex' : 'none',
                justifyContent: 'flex-end',
                transition: 'all 3s ease-in-out',
            }}
        >
            <Box
                sx={{
                    width: { xs: '80%', md: '600px' },
                    height: '100%',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    padding: 2,
                    color: '#000',
                    overflowY: 'auto',
                    transform: cartOpen ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 3s ease-in-out',
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click propagation to the overlay
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Button disableRipple sx={{ textTransform: 'capitalize', '&:hover': { background: 'transparent' }, display: 'flex', alignItems: 'center' }} onClick={() => dispatch(clickCart())}>
                        <BackIcon />
                        <Typography sx={{ color: '#000', pl: 1 }}>My Cart</Typography>
                    </Button>

                    <IconButton sx={{}} onClick={() => { dispatch(clickCart()) }}>
                        <CancelIcon sx={{ color: '#000' }} />
                    </IconButton>
                </Box>
                <Box sx={{ height: '90%' }}>
                    {cart?.length !== 0 ?
                        <Box>
                            <NoteComponent prop={{ txt: ' Only 1 bundle can be purchased at a time' }} />
                            <Box>
                                {cart?.map((e, i) => (<CartCard key={i} e={e} />))}
                            </Box>
                        </Box>
                        :
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <NoBundlesFoundIcn />
                            <Typography>No Bundles Found</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}