import { Box } from '@mui/material';
import { CorporateNormalUsersSideNav } from '../../../../../components/nav/corporate_normal_user_side_nav';
import { CorporateNormalUsersTopNav } from '../../../../../components/nav/corporate_normal_user_top_nav';
import { Outlet, useNavigate } from 'react-router-dom';
import { store } from '../../../../../store';
import { useEffect, useRef, useState } from 'react';
import { NavItmsCorporateNormal, routesMap, SideNavCorporateNormal, SideNavCorporateNormalAE } from '../../../../../utils/data/CorporateNormalUserDatas/Data';
import { useSelector } from 'react-redux';
import { Analytics } from '../../../../../Analytics';

export const CorporateNormalUsersMain = () => {
  const [minimize, setMinimize] = useState(false);
  const sub = useSelector((state) => state.subscribe.subscribe);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100);
  const [mainContentWidth, setMainContentWidth] = useState('100%');
  const ut = localStorage.getItem('user_type');
  const containerRef = useRef(null);
  const sideNavRef = useRef(null);
  const navigate = useNavigate();
  const handleResize = () => {
    setMinimize(window.innerWidth < 1100);
    setSmallScreen(window.innerWidth < 1100);
  };

  useEffect(() => {
    const userRole = localStorage.getItem('user_role');
    if (ut === 'company' && userRole === 'Admin User') {
      navigate('/admin');
    } else if (ut === 'individual') {
      navigate('/dashboard/individual-dashboard');
    } else if (ut === 'admin') {
      navigate('/admin/dashboard')
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const smsc2 = window.innerWidth < 900
  const updateMainContentWidth = () => {
    if (!smsc2) {
      if (containerRef.current && sideNavRef.current) {
        const newWidth = `${containerRef.current.offsetWidth - sideNavRef.current.offsetWidth}px`;
        setMainContentWidth(newWidth);
      }
    } else {
      if (containerRef.current) {
        const newWidth = `${containerRef.current.offsetWidth}px`;
        setMainContentWidth(newWidth);
      }
    }
  };


  useEffect(() => {
    // Initial calculation
    updateMainContentWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateMainContentWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateMainContentWidth);
    };
  }, [minimize]);
  // console.log('nor use rf', containerRef, sideNavRef)
  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }} ref={containerRef}>
      <CorporateNormalUsersSideNav
        ref={sideNavRef}
        sref={sideNavRef}
        minimize={minimize}
        smallScreen={smallScreen}
        setSmallScreen={setSmallScreen}
        setMinimize={setMinimize}
        Data={SideNavCorporateNormal}
        routesMap={routesMap}
        commonPath={'/user'}
        sx={{ width: minimize ? '60px' : '250px' }}
      />
      {/* <CorporateNormalUsersSideNav ref={sideNavRef} minimize={minimize} smallScreen={smallScreen} setSmallScreen={setSmallScreen} setMinimize={setMinimize} Data={!sub ? SideNavCorporateNormal : SideNavCorporateNormalAE} routesMap={routesMap} commonPath={'/user'} sx={{ width: minimize ? '60px' : '250px' }} /> */}
      {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '100%' }, }}> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: mainContentWidth }}>
        <Analytics />
        <CorporateNormalUsersTopNav minimize={minimize} setMinimize={setMinimize} Data={NavItmsCorporateNormal} sx={{ width: '100%' }} />
        <Box sx={{ height: 'calc(100% - 64px)', overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
