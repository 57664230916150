

export const BrandAuraAdminDashboardStyles = {}

export const BrandAuraDataMartTemplateData = [
    {
        ind_bundle_name: "Air Purifiers on Black Friday 2024",
        card_picture: "Bundle Images",
        overview_picture: "Bundle Images",
        bundle_description: "This is a Sample Bundle Data, You can start editing from here.",
        occation: "Black Friday Sale",
        ind_bundle_category: "Air Purifiers",
        ind_bundle_country: "India",
        ind_bundle_e_commerce: "Amazon, Flipkart, Jiomart, Croma, Reliance Digital",
        ind_bundle_brand: "Eureka Forbes, Honeywell, Mi, Philips, Sharp",
        ind_bundle_start_date: "24-Nov-24",
        ind_bundle_end_date: "27-Nov-24",
        discount: "0"
        // Discount: "0%"
    }
]