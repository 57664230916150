import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { GradientBackground, GradientText } from "../../../../components/DesignedComponents/GradientHelper";
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { useEffect, useState } from "react";
import { DataMartHero } from "./DataMartHero";
import { DataMartMainContent } from "./DataMartMainContent";
import { BackgroundPic } from "../../../../components/DesignedComponents/BackgroundPic";
import { StaticImgExport } from "../../../../utils/data/LandingPage/Data";
import { CartSvg, NoteVioletIkn } from "../../../../utils/icon/LandingPage/Icon";
import { CountryFlagEmoji, getFlag, getFlagEmoji } from "../../../../components/@extended/CustomFunctions";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { clickCart, setCartDatas, updateCartDatas } from "../../../../slice/cart";
import http from '../../../../utils/http-common'
import { setDataMartBundles, setType } from "../../../../slice/allBundles";
import { useAddCartDatas, useCartDatas } from "../../../../components/@extended/ApiCall";


export const DataMartSearch = () => {
    const [searchTerm, setSearchTerm] = useState(null);
    // const [type, setType] = useState(null)
    const [wholeData, setWholeData] = useState(null);
    const FetchCartDatas = useCartDatas();
    const tkn = useSelector((s) => s?.token?.token)
    const type = useSelector((s) => s?.allbundles?.type)
    const user_id = localStorage.getItem('user_id')
    const [isChanged, setIsChanged] = useState(false);
    const dispatch = useDispatch()
    const Get = async () => {
        try {
            const baseUrl = '/master_service/search/bundle';
            const params = [];

            // Check if type is valid and add to params
            // if (type) {
            // params.push(`keyword=${encodeURIComponent(type)}`);
            // }

            // Check if searchTerm is valid and add to params
            if (searchTerm) {
                //     params.push(`category=${encodeURIComponent(searchTerm)}`);
                params.push(`keyword=${encodeURIComponent(searchTerm)}`);
            }

            // Construct the final URL
            const url = params.length > 0 ? `${baseUrl}?${params.join('&')}` : baseUrl;
            const response = await http.get(url)
            const data = response?.data?.data?.sort((a, b) => a?.ind_bundle_name?.localeCompare(b?.ind_bundle_name))
            // const response = await http.get(`/master_service/search/bundle`)
            // const response = await http.get(`/master_service/search/bundle?keyword=${encodeURIComponent(type)}?category=${encodeURIComponent(searchTerm)}`)
            console.log('DataMart Get Response', data, response, response?.data?.data, response?.data?.data !== undefined)
            if (type) {
                console.log('true');
                dispatch(setDataMartBundles(data?.filter((k) => { return k?.occation?.includes(type) })))
            } else {
                console.log('false');
                dispatch(setDataMartBundles(data))
            }
            setWholeData(data)
            if (data !== undefined) {
                console.log('undefined');
                localStorage.setItem('DataMartBundles', JSON.stringify(data))
            }
        } catch (error) {
            console.log('DataMart Get Error', error);
            localStorage.removeItem('DataMartBundles')
            dispatch(setDataMartBundles([]))
        }
    }
    useEffect(() => {
        Get();
        if (tkn) { FetchCartDatas() }
    }, [])
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '4px', width: { xs: '100%', md: '80%' }, mb: 10 }}>
            <Select
                value={type}
                onChange={(e) => { dispatch(setType(e.target.value)); if (e.target.value === null) { dispatch(setDataMartBundles(wholeData)) } else { dispatch(setDataMartBundles(wholeData?.filter((k) => { return k?.occation?.includes(e.target.value) }))) } }}
                onKeyDown={(e) => {
                    // console.log('ent sel', e, e.key); 
                    if (e.key === 'Enter') { Get() }
                }}
                displayEmpty
                sx={{
                    p: 0, height: '36px', width: { xs: '60px', sm: "150px" }, fontSize: { xs: '10px', sm: "16px" }, background: '#fff', color: '#000', borderRadius: 0, borderTopLeftRadius: 'inherit', borderBottomLeftRadius: 'inherit',
                    '&.Mui-focused fieldset': {
                        border: 'none',
                        // borderRadius: '15px',
                    },
                }}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value={null}>All Bundles</MenuItem>
                <MenuItem value='Prime Day'>Prime Day</MenuItem>
                <MenuItem value='Black Friday'>Black Friday</MenuItem>
                <MenuItem value='Christmas'>Christmas</MenuItem>
                <MenuItem value='End of Year'>End of Year</MenuItem>
                <MenuItem value='Republic Day'>Republic Day</MenuItem>
            </Select>
            <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #797F8F', padding: '0.5rem 1rem', width: '100%', color: '#fff', my: { xs: 0, sm: 1 }, height: '35px', }}>
                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { color: '#FAFAFA80', fontSize: { xs: '10px', md: '14px' }, fontWeight: '500', '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search bundles here...' variant='outlined' value={searchTerm} onKeyDown={(e) => {
                    console.log('txt ent', e, e.key); if (e.key === 'Enter') {
                        // console.log('txt ent inss', e, e.key); 
                        Get()
                    }
                }} onChange={(e) => setSearchTerm(e.target.value)} />
            </Box>
            <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', color: '#fff', minWidth: { xs: '10px', sm: '100px' }, width: { xs: '40%', sm: 'auto' }, fontSize: { xs: '10px', sm: '16px' }, height: '35px', borderRadius: 0, borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit', textTransform: 'capitalize', '&:hover': { background: '#8A18D0' } }} onKeyDown={(e) => {
                // console.log('ent', e, e.key); 
                if (e.key === 'Enter') { Get() }
            }} onClick={() => Get()}>
                <SearchIcon prop={{ style: {} }} />
                <Typography sx={{ pl: 1, fontSize: { xs: '10px', md: '16px' }, }}>Search</Typography>
            </Button>
        </Box>
    )
}

export const DataMartBundleCard = ({ e }) => {
    const user_id = localStorage.getItem('user_id');
    const addCart = useAddCartDatas({
        prop: {
            individual_bundle_id: e?.bundle_id,
            individual_bundle_name: e?.bundleName,
            bundle_image: e?.cardImg,
            bundle_cost: e?.finalPrice,
            quantity: 0,
            session_id: 0,
        },
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const width = () => {
    //     if (window.innerWidth > 1300) {
    //         return '20%'
    //     } else if (window.innerWidth < 500) {
    //         return '100%'
    //     } else if (window.innerWidth < 900) {
    //         return '49%'
    //     } else if (window.innerWidth < 1100) {
    //         return '30%'
    //     } else if (window.innerWidth < 1300) {
    //         return '25%'
    //     }
    // }
    // console.log('wdd', width())

    return (
        <Box
            sx={{
                width: {
                    // xs: '100%',
                    // sm: '49%',
                    // md: '35%',
                    // lg: '30%',
                    // xl: '25%',
                    xs: '100%',
                    sm: '49%',
                    md: '30%',
                    lg: '25%',
                    xl: '20%',
                },
                minWidth: { xs: 'auto', lg: '380px' },
                // width: window.innerWidth > 1300 ? '20%' : window.innerWidth < 500 ? '100%' : window.innerWidth < 900 ? '49%' : window.innerWidth < 1100 ? '30%' : window.innerWidth < 1300 ? '25%' : '100%', // Full width on small screens
                cursor: 'pointer',
                height: '100%',
                mb: 2,
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                border: '1px solid #EAECF0', // Add border to the outer box
                ...(e?.style?.outerBox ? { ...e?.style?.outerBox } : {}),
            }}
            onClick={() => {
                navigate(`/data-mart/overview?bundle_id=${encodeURIComponent(e?.bundle_id)}`);
            }}
        >
            {/* Image Section */}
            <Box sx={{ borderRadius: '8px 8px 0 0', overflow: 'hidden' }}>
                <img alt="" src={e?.cardImg} style={{ width: '100%', height: 'auto' }} />
            </Box>

            {/* Content Section */}
            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0 0 8px 8px',
                }}
            >
                {/* Bundle Name and Flag */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', mb: 1 }}>
                    <Typography
                        sx={{
                            fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
                            fontWeight: '600',
                            color: '#000',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '80%', // Adjust max width for better responsiveness
                        }}
                    >
                        {e?.bundleName}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            "&:hover .noteModel": {
                                opacity: 1,
                                visibility: 'visible',
                            },
                        }}
                    >
                        {getFlag(e?.country)}
                        {/* Note Model */}
                        <Box
                            className="noteModel"
                            sx={{
                                position: 'absolute',
                                top: '100%', // Position below the flag
                                right: 0,
                                left: 0,
                                mt: 1, // Add some margin for spacing
                                opacity: 0,
                                visibility: 'hidden',
                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                zIndex: 1,
                                background: '#F3E8FA',
                                borderRadius: '4px',
                                padding: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                textAlign: 'center',
                                display: 'flex',
                                // alignItems: 'center',
                            }}
                        >
                            <Box sx={{ width: '20px' }}>
                                <NoteVioletIkn />
                            </Box>
                            <Typography sx={{ color: '#474747CC', fontSize: '12px', fontWeight: 500 }}>
                                <span style={{ color: '#000000CC', fontWeight: 600 }}>Note:</span> This Bundle contains data from {e?.country}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Bundle Description */}
                <Typography
                    sx={{
                        fontSize: { xs: '12px', sm: '14px' }, // Responsive font size
                        mb: 1,
                        fontWeight: '400',
                        color: '#474747',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                        maxWidth: '80%',
                    }}
                >
                    {e?.bundleDescrp}
                </Typography>

                {/* Pricing Section */}
                {e?.discount ? (
                    <Box sx={{ display: 'flex', alignItems: 'end' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#000' }}>
                            {e?.discountedPrice}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    mx: 1,
                                    fontWeight: '500',
                                    textDecoration: 'line-through',
                                    color: '#474747CC',
                                }}
                            >
                                {e?.bundlePrice}
                            </Typography>
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#008000' }}>
                                ({e?.discount} % off)
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography sx={{ fontSize: '20px', mb: 1, fontWeight: '600', color: '#000' }}>
                        {e?.bundlePrice}
                    </Typography>
                )}

                {/* Add to Cart Button */}
                <Button
                    disableTouchRipple
                    disableFocusRipple
                    disableGutters
                    onClick={(k) => {
                        k.preventDefault(); // Prevent routing on button click
                        k.stopPropagation(); // Stop event propagation
                        dispatch(updateCartDatas(e));
                        dispatch(clickCart());
                        if (user_id) {
                            addCart();
                        }
                    }}
                    sx={{
                        background: '#8A18D0',
                        color: '#fff',
                        minWidth: '100px',
                        width: '100%',
                        textTransform: 'capitalize',
                        '&:hover': { background: '#8A18D0' },
                        mt: 2, // Add margin top for spacing
                    }}
                >
                    <CartSvg />
                    <Typography sx={{ pl: 1 }}>Add to Cart</Typography>
                </Button>
            </Box>
        </Box>
    );
};


export const DataMartBundleCard2 = ({ e, i }) => {
    return (
        <Box sx={{ width: '380px', height: '100%', mb: 1, borderRadius: '8px', mr: { xs: 0, md: 1 }, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', }}>
            <GradientBackground sx={{ height: '100%', width: '100%', borderRadius: '0px', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', background: '#000', color: '#fff' }}>
                <BackgroundPic prop={{ bg: e?.bg }} sx={{ height: '100%', width: '100%', borderTopRightRadius: 'inherit', borderTopLeftRadius: 'inherit', color: '#fff' }}>
                    <Box sx={{ width: '100%', height: '100%', p: 2 }}>
                        <GradientText txt1={e?.gradientTxt?.txt1} txt2={e?.gradientTxt?.txt2} txtStyl1={{ fontSize: '24px' }} txtStyl2={{ fontSize: '24px' }} boxStyl={{ flexWrap: 'nowrap', flexDirection: 'column', }} />
                    </Box>
                </BackgroundPic>
            </GradientBackground>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', border: '1px solid #EAECF0', borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px', }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{e?.bundleName}</Typography>
                    <Typography>{getFlag(e?.contryCode)}</Typography>
                    {/* <Typography><CountryFlagEmoji countryCode={'us'} /></Typography> */}
                </Box>
                <Typography sx={{ fontSize: '12px', mb: 1, fontWeight: '400', color: '#474747' }}>{e?.bundleDescrp}</Typography>
                <Typography sx={{ fontSize: '20px', mb: 1, fontWeight: '600', color: '#000' }}>{e?.bundlePrice}</Typography>
                <Link to={`/data-mart/overview?bundle_id=${encodeURIComponent(i)}`}>
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', color: '#fff', minWidth: '100px', width: '100%', textTransform: 'capitalize', '&:hover': { background: '#8A18D0' } }}>
                        <CartSvg />
                        <Typography sx={{ pl: 1 }}>Purchase Bundle</Typography>
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}

export { DataMartHero, DataMartMainContent }