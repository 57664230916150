import { Autocomplete, Box, TextField } from "@mui/material";
import { countries } from "../../utils/data/LandingPage/Data";
import { useState } from "react";
import { useSelector } from "react-redux";


export const UserDropDown = ({ value, disabled = false, setCountry, onChange, required = false, sx = {}, error, helperText }) => {
    // console.log('Selected country:', country);
    const fetchedUsers = useSelector((s) => s?.allbundles?.allUsers);
    // console.log('fetchedUsers', fetchedUsers);

    // const [inp, setInp] = useState(country?.label || '');
    const [inp, setInp] = useState(value?.user_first_name || '');

    return (
        <Autocomplete
            disabled={disabled}
            // id="country-select"
            value={value} // Set the entire country object as the value
            onChange={(event, newValue) => {
                // console.log('users:', newValue, event);
                if (onChange) {
                    onChange(newValue)
                }
            }}
            required={required}
            options={fetchedUsers}
            inputValue={inp}
            filterOptions={(options, params) => {
                const inputValue = params.inputValue.toLowerCase(); // Case-insensitive search
                const filter = (option) => {
                    // Customize these conditions to match your data keys and search logic
                    return (
                        option?.user_first_name?.toLowerCase().includes(inputValue) ||
                        (option?.user_id && option.user_id.toString().toLowerCase().includes(inputValue)) ||
                        option?.user_company_name?.toLowerCase().includes(inputValue) ||
                        // (option?.user_phone_number && option.user_phone_number.toString().toLowerCase().includes(inputValue))||
                        option?.user_email?.toLowerCase().includes(inputValue)
                        // Add more conditions for other fields as needed
                        // (option?.other_field && option.other_field.toString().toLowerCase().includes(inputValue)) // Example with type conversion
                    );
                };
                return options.filter(filter);
            }}
            onInputChange={(event, newValue) => {
                setInp(newValue);
            }}
            autoHighlight
            getOptionLabel={(option) => option?.user_first_name} // Display the country label
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    {/* <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    /> */}
                    {option?.user_email}
                </Box>
            )}
            renderInput={(params) => {
                // console.log('parms', params)
                // if (/^[A-Za-z ]*$/.test(value)) {
                //     params.onChange(event);
                // }
                return (
                    <TextField
                        {...params}
                        placeholder="Select User"
                        InputProps={{ ...params.InputProps, sx: { ...sx } }}
                        required={required}
                        error={error}
                    />)
            }
            }
        />
    );
};

