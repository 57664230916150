import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";
import { BarChart } from "@mui/x-charts";



export const MultiBarChart2 = ({ prop }) => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400); // Default width in pixels

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                // Convert percentage to pixels (e.g., 100% of container width)
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);
    const data = prop?.data || [];
    const xAxisData = data.map((e) => ({
        scaleType: 'band',
        dataKey: 'site',
        colorMap: {
            type: 'ordinal',
            colors: e.brands.map((brand) => brand.color),
        },
    }));

    const seriesData = data.map((e) => ({
        dataKey: 'value',
    }));
    // console.log('multibar', data, xAxisData, seriesData)

    return (
        <Box sx={{ width: '100%' }} ref={chartContainerRef}>
            <ComboTxtBtn prop={prop} />
            <Box sx={{ px: 2, border: '1px solid #EAECF0', borderRadius: '8px' }}>
                <BarChart
                    dataset={data}
                    xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                    series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                    width={500}
                    height={300}
                />
            </Box>
        </Box>
    );
}
export const MultiBarChart = ({ prop }) => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400); // Default width in pixels

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                // Convert percentage to pixels (e.g., 100% of container width)
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [prop]);
    const data = prop?.data || [];
    const seriesData = prop?.sd || [];
    const xAxisData = {
        scaleType: 'band',
        dataKey: 'site',
        // colorMap: { type: 'ordinal', colors: data[0]?.map((e)=>({})) },
        // colorMap: { type: 'ordinal', colors: data[0]?.color?.map((e) => e) },
    };
    // const seriesData = [
    //     { dataKey: 'Makita', label: 'Makita', color: '#2BA1DB' },
    //     { dataKey: 'Ibell', label: 'Ibell', color: '#8A18D0' },
    //     { dataKey: 'Dewalt', label: 'Dewalt', color: '#F68D2B' },
    // ];
    // console.log('multibar', data, xAxisData, seriesData)

    return (
        <Box sx={{ width: '100%' }}>
            <ComboTxtBtn prop={prop} />
            {prop?.data && !prop?.isEmpty ?
                <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #EAECF0', borderRadius: '8px', width: '100%' }}>
                    <Box sx={{ px: 2, width: '80%' }} ref={chartContainerRef}>
                        <BarChart
                            dataset={data}
                            series={seriesData}
                            // yAxis={[{
                            //     scaleType: "band",
                            //     dataKey: "site",
                            //     label: "Brand",
                            //     tickStyle: { fontSize: 12 },
                            // }]}
                            xAxis={[xAxisData]}
                            slotProps={{ legend: { hidden: true } }}
                            width={chartWidth}
                            height={215}
                            cx={98}
                            cy={100}
                        />
                    </Box>
                    <Box sx={{ width: '19%', px: 1 }}>
                        <Box>
                            <Box sx={{ height: '28px', background: '#F3E7FA', borderRadius: '4px', p: 2, my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '600', }}>{prop?.sideTitle}</Typography>
                            </Box>
                            <Box sx={{}}>
                                {prop?.sideDatas?.map((e, i) => (
                                    <Box key={i} sx={{ display: 'flex', alignItems: 'center', my: 1, }}>
                                        <Box sx={{ background: e.color, height: '20px', width: '20px', borderRadius: '2px' }}></Box>
                                        <Typography sx={{ fontSize: '10px', ml: 1, color: '#4F4F4F', fontWeight: '500' }}>{e.text}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                :
                <Box sx={{ height: '215px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #EAECF0', borderRadius: '8px', }}>
                    <Typography>{prop?.msg}</Typography>
                </Box>
            }
        </Box>
    );
}