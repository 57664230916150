import { useDispatch } from "react-redux";
import { handleClick, setMessage, setSeverity } from "../../slice/SnackPop";

export const GetPriceWithSymbolNoDecimal = (p) => {
    const currency = localStorage.getItem('currency');
    const usd_currendyData = JSON.parse(localStorage.getItem('usd_currendyData'));
  
    if (!currency) {
      console.log('Currency is not available', currency);
      return p;
    }
  
    if (!usd_currendyData) {
      console.log('usd_currendyData is not available', usd_currendyData);
      return p;
    }
  
    const price = p * usd_currendyData?.[currency];
  
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0, // Remove decimal places
      maximumFractionDigits: 0, // Remove decimal places
    });
  
    return formatter.format(price);
  };
  
export const GetPriceWithSymbol = (p) => {
    const currency = localStorage.getItem('currency');
    const usd_currendyData = JSON.parse(localStorage.getItem('usd_currendyData'))
    if (!currency) {
        console.log('Currency is not available', currency);
        return p;
    }
    if (!usd_currendyData) {
        console.log('usd_currendyData is not available', usd_currendyData);
        return p;
    }
    const price = p * usd_currendyData?.[currency]
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency });
    return formatter.format(price);
}
export const GetPriceWithOutSymbol = (p) => {
    // const dispatch = useDispatch();
    if (typeof p !== 'number' || p === null || p === undefined) {
        // if (typeof p !== 'number') {
        //     dispatch(setMessage('GetPriceWithOutSymbol should be only number'))
        //     dispatch(setSeverity('error'))
        //     dispatch(handleClick())
        // }
        return 0
    }
    const currency = localStorage.getItem('currency');
    const usd_currendyData = JSON.parse(localStorage.getItem('usd_currendyData'))
    if (!currency) {
        console.log('Currency is not available', currency);
        return p;
    }
    if (!usd_currendyData) {
        console.log('usd_currendyData is not available', usd_currendyData);
        return p;
    }
    const price = p * usd_currendyData?.[currency]
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });
    return formatter.format(price);
}
export const GetPriceWithOutFunctionalPrice = (p) => {
    const currency = localStorage.getItem('currency');
    const usd_currendyData = JSON.parse(localStorage.getItem('usd_currendyData'))
    if (!currency) {
        console.log('Currency is not available', currency);
        return p;
    }
    if (!usd_currendyData) {
        console.log('usd_currendyData is not available', usd_currendyData);
        return p;
    }
    const price = (p * usd_currendyData?.[currency]).toFixed(2);
    return price;
}