import { Box, Typography } from "@mui/material"
import { StaticImgExport } from "../../utils/data/LandingPage/Data"

export const GradientBackground = ({ children, sx = {} }) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 'inherit',
                minHeight: '230px',
                height: '100%',
                width: "100%",
                ...(sx ? { ...sx } : {}),
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    // top: 0,
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: `url(${StaticImgExport?.gradientBg})`,
                    backgroundSize: "cover",
                    backgroundPositionY: "bottom",
                    backgroundPositionX: "center",
                    transform: "scaleY(-1)",
                    borderRadius: 'inherit',
                    zIndex: 1
                }}
            />
            <Box
                sx={{
                    position: "relative", width: '100%', height: '100%', zIndex: 2,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

export const GradientText = ({
    txt1,
    txt2,
    txtStyl1 = {},
    txtStyl2 = {},
    boxStyl = {},
    col1 = '#fff',
    col2 = {
        direction: 'right',
        colors: [
            { col: '#8A17D0', percent: '60%' },
            { col: '#CA3FC4', percent: '100%' },
        ],
    },
}) => {
    const { direction, colors } = col2;
    const [color1, color2] = colors;
    const gradient = `linear-gradient(to ${direction}, ${color1.col} ${color1.percent}, ${color2.col} ${color2.percent})`;

    return (
        <Typography
            component="span" // Use span for inline behavior
            sx={{
                display: 'inline', // Use inline to wrap like normal text
                whiteSpace: 'normal', // Allow text to wrap naturally
                lineHeight: 1.2, // Consistent line height
                ...boxStyl,
            }}
        >
            <Typography
                component="span" // Use span for inline behavior
                sx={{
                    fontSize: { xs: 28, md: 36, lg: 40 },
                    fontFamily: "Inter Variable",
                    fontWeight: 700,
                    color: col1,
                    whiteSpace: 'normal', // Allow wrapping
                    display: 'inline', // Inline display for natural wrapping
                    ...txtStyl1,
                }}
            >
                {txt1}
            </Typography>
            <Typography
                component="span" // Use span for inline behavior
                sx={{
                    background: gradient,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: { xs: 28, md: 36, lg: 40 },
                    fontFamily: "Inter Variable",
                    fontWeight: 700,
                    whiteSpace: 'normal', // Allow wrapping
                    display: 'inline', // Inline display for natural wrapping
                    ...txtStyl2,
                }}
            >
                {txt2}
            </Typography>
        </Typography>
    );
};
