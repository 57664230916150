import { Box, Typography } from "@mui/material";
import React from "react";


export const CheckoutStatus = ({ activeStep }) => {
    const steps = ["User  Details", "Payment Details", "Completed"];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens, row on larger
                alignItems: 'center',
                mb: 4,
            }}
        >
            {steps.map((step, index) => {
                const isCompleted = activeStep > index;
                const isActive = activeStep === index;

                return (
                    <React.Fragment key={index}>
                        <Box sx={{ textAlign: 'center', flex: 1, mb: { xs: 2, sm: 0 } }}>
                            <Box
                                sx={{
                                    width: { xs: 30, sm: 40 }, // Smaller circle on mobile
                                    height: { xs: 30, sm: 40 },
                                    borderRadius: '50%',
                                    backgroundColor: isActive || isCompleted ? '#8A18D0' : 'grey.300',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
                                    margin: '0 auto',
                                }}
                            >
                                {index + 1}
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: 1,
                                    color: '#000',
                                    fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
                                    fontWeight: 600,
                                    lineHeight: '19.36px',
                                }}
                            >
                                {step}
                            </Typography>
                        </Box>

                        {index < steps.length - 1 && (
                            <Box
                                sx={{
                                    flex: 1,
                                    height: { xs: 2, sm: 4 }, // Thinner line on mobile
                                    backgroundColor: isCompleted ? '#8A18D0' : 'grey.300',
                                    display: { xs: 'none', sm: 'block' }, // Hide line on mobile
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};
