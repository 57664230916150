import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import http from '../../../../utils/http-common';
import { dateClnse3, RawDate } from '../../../../components/Public/DateClnse';
import { downloadCSV } from '../../../../components/Public/DownloadCSV';
import { useDispatch, useSelector } from 'react-redux';
import { updateBundle } from '../../../../slice/bundle';
import { Bundle } from '../../../../components/@extended/Bundle';
import { Model_1 } from '../../../../components/@extended/Model';
import { modifyDataIDP } from '../../../../components/@extended/CustomFunctions';
import { SearchIcon } from '../../../../utils/icon/IndividualPages/Icon';
import { updateCredit } from '../../../../slice/credits';

const IndividualUserLandingPage = () => {
  const [open, setOpen] = useState(false);
  const [notMt, setNotMt] = useState(true);
  const [respData, setRespData] = useState([]);
  const [cred, setCred] = useState([0, 0]);
  const credi = useSelector((state) => state.credit.credit);
  const [modelData, setModelData] = useState({
    title: 'Welcome to Brand Aura',
    emoji: '🎉',
    txt: [
      'Thank you for registering into our product.',
      'If you have any questions, feel free to reach out to our support team.',
      'Lets get started!!!',
    ],
    cl: '',
    btn_1: 'Add Credits',
    btn_1_Link: '/dashboard/individual-dashboard/recharge',
    btn_2: 'Select Data',
    btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
  });
  // const [bundleData, setBundleData] = useState([])
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');

  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenS(false);
  };

  const dispatch = useDispatch();
  const navi = useNavigate();
  const bundle = useSelector((state) => state.bundles.bundle);
  console.log('bundle data', bundle);

  const filterBundles = respData.filter((bundle) =>
    bundle.ind_bundle_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const Credit = async () => {
    try {
      const res = await http.post(
        '/master_service/credit',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      console.log('resp', res, res.data);
      setCred([res.data.data.used_credit, res.data.data.balance_credit]);
      dispatch(
        updateCredit({
          available_credit: res.data.data.available_credit,
          used_credit: res.data.data.used_credit,
        })
      );
    } catch (error) {
      console.log('credits err', error);
    }
  };
  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      console.log('respData', dr, respData, filteredData, filteredData[0]);
      const set = {
        SelectData: {
          category: selectedData2.table.Category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.table.Country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.table['E-Commerce Site'],
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.table.Brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log('set', set);
      await dispatch(updateBundle(set));
      console.log('bundle data', bundle);
      navi('/dashboard/individual-dashboard/user-wizard');
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/dashboard/individual-dashboard/user-wizard'
    } catch (error) {
      console.log('Edit bundle error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Set the Edit Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          'user_id'
        )}/${bundle_id}`
      );
      await FetchDatas();
      console.log('respon', response);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      setModelData({
        title: 'Welcome to Brand Aura',
        emoji: '🎉',
        txt: [
          'Thank you for registering into our product.',
          'If you have any questions, feel free to reach out to our support team.',
          'Lets get started!!!',
        ],
        cl: '',
        btn_1: 'Add Credits',
        btn_1_Link: '/dashboard/individual-dashboard/recharge',
        btn_2: 'Select Data',
        btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
      });
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const ChangeDate = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.post(
        `/master_service/bundle/delete/${localStorage.getItem(
          'user_id'
        )}/${bundle_id}`
      );
      await FetchDatas();
      console.log('respon', response);
      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const CopyBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.post(
        `/master_service/duplicate_bundle`,
        JSON.stringify({
          user_id: localStorage.getItem('user_id'),
          ind_bundle_id: bundle_id,
        })
      );
      await FetchDatas();
      console.log('respon', response);
      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };

  const FetchDatas = async () => {
    try {
      const response = await http.post(
        '/master_service/bundle/log/list',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      if (response.data.data.length === 0) {
        setNotMt(false);
        setOpen(true);
      } else {
        setNotMt(true);
        const modifiedData = modifyDataIDP(response.data.data);
        setRespData(modifiedData);
        console.log('respon', response, response.data.data, modifiedData);
        // setMessage(response.data.message);
        // setSeverity("success");
        // handleClick();
      }
      await Credit();
      console.log('respon', response, response.data.bundles);
    } catch (error) {
      console.log('fetching error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  const RunBundle = async (bundle_id) => {
    const filteredData = respData.filter(
      (data) => data.ind_bundle_id === bundle_id
    );
    const selectedData2 = filteredData[0];
    console.log('selec', selectedData2, selectedData2.ind_bundle_start_date)
    if (selectedData2.ind_bundle_start_date === null || selectedData2.ind_bundle_end_date === null) {
      // if (selectedData2.data.ind_bundle_start_date === null || selectedData2.data.ind_bundle_end_date === null) {
      setMessage('Edit the bundle to get the data.');
      setSeverity('error');
      handleClick();
      return;
    }
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const response = await http.post('master_service/run/bundle', payload);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(
          new Date()
        )}_${new Date().toLocaleTimeString()}`
      );
      await FetchDatas();

      setMessage('Bundle run sucessfully');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Run the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  const DownloadBundle = async (bundle_id) => {
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const response = await http.post(
        'master_service/download_bundle',
        payload
      );
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const time = new Date().toLocaleTimeString();
      console.log('tm', time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Run the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  const Sample_Data = async () => {
    try {
      // const f = dateRange[0];
      // const f = dateClnse2(dateRange.startDate);
      // const f = dateClnse2(dateRange[0]);
      // const t = dateRange[1];
      // const t = dateClnse2(dateRange.endDate);
      // const t = dateClnse2(dateRange[1]);
      // console.log("ft", f, t);
      const bd = filterBundles.filter(
        (data) =>
          data.ind_bundle_id === (localStorage.getItem('bundle_id') || 0)
      );
      const bundleData = { data: bd[0] };
      if (bundleData.data.ind_bundle_start_date === null || bundleData.data.ind_bundle_end_date === null) {
        setMessage('There is some error running the Sample data, Edit this bundle and select from and to date to get the sample data');
        setSeverity('error');
        handleClick();
        return;
      }
      const payload = {
        category_id: bundleData.data.ind_bundle_category_id,
        site_id: bundleData.data.ind_bundle_e_commerce_id,
        brand_id: bundleData.data.ind_bundle_brand_id,
        country_id: bundleData.data.ind_bundle_country_id,
        from_date: bundleData.data.ind_bundle_start_date,
        to_date: bundleData.data.ind_bundle_end_date,
      };
      const response = await http.post(
        'master_service/sample/bundle/data',
        payload
      );
      downloadCSV(
        response.data.data,
        bundleData.data.ind_bundle_name + '_Sample_Data'
      );
      // setBundleData(response.data.data);
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });
      console.log(
        'res smpl data',
        response
        // response.data.payload.ind_bundle_category
      );
      setMessage('The Sample data is Downloaded Successfully');
      setSeverity('success');
      handleClick();
      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/dashboard/individual-dashboard/sample-data'
    } catch (error) {
      console.error('Error fetching sample data:', error);
      setMessage('There is some error running the Sample data');
      setSeverity('error');
      handleClick();
    }
  };
  const ChangeModelData = (itm, rb) => {
    console.log('clk');
    if (itm === 0) {
      if (!rb) {
        // EditBundle();
        Sample_Data();
        // console.log('Not yet implemented');
        // setMessage(
        //   'The Feature is not yet implemented, will be Launching Soon'
        // );
        // setSeverity('success');
        // handleClick();
      } else {
        Sample_Data();
      }
    } else if (itm === 1) {
      if (!rb) {
        EditBundle();
      } else {
        // CopyBundle();
        setModelData({
          title: 'Copy Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to copy the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Copy',
          btn_2_fn: CopyBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      }
    } else if (itm === 2) {
      if (!rb) {
        setModelData({
          title: 'Delete Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to delete the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Delete',
          btn_2_fn: DeleteBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      } else {
        setModelData({
          title: 'Delete Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to delete the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Delete',
          btn_2_fn: DeleteBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    FetchDatas();
  }, []);
  const runbndl = {
    title: 'Run Bundle',
    // emoji: "🎉",
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/dashboard/individual-dashboard/recharge',
    btn_2: 'Proceed',
    btn_2_cancel: true,
    // btn_2_Link: "/dashboard/individual-dashboard/user-wizard",
    btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: '/dashboard/individual-dashboard/recharge',
    // btn_2_fn_props: {},
  };
  const dnbndl = {
    title: 'Download Bundle',
    // emoji: "🎉",
    txt: [
      'You have used the free downloads.',
      [
        { text: 'You need ' },
        { text: `${250} Credits`, isBold: true },
        { text: ' to download this bundle.' },
      ],
      // "You need 2 Credits to download this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/dashboard/individual-dashboard/recharge',
    btn_2: 'Proceed',
    btn_2_cancel: true,
    Credits: true,
    CreditsLnk: '/dashboard/individual-dashboard/recharge',
    // btn_2_Link: "/dashboard/individual-dashboard/user-wizard",
    btn_2_fn: DownloadBundle,
  };
  const BndlePops = ({ x, ec, id }) => {
    console.log('BndlePops', x, id);
    if (x === 0) {
      console.log('ent', x, id);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === id
      );
      const selectedData2 = filteredData[0];
      console.log('filteredData', filteredData, selectedData2);
      if (selectedData2.ind_bundle_start_date === null || selectedData2.ind_bundle_end_date === null) {
        setMessage('Edit the bundle to get the data.');
        setSeverity('error');
        handleClick();
        return;
      }
      setModelData({
        ...runbndl,
        txt: [
          'Running the bundle costs credits.',
          [
            { text: 'You need ' },
            { text: `${ec} Credits`, isBold: true },
            { text: ' to run this bundle.' },
          ],
          // `You need ${ec} Credits to run this bundle.`,
        ],
        btn_2_fn_props: id,
      });
      setOpen(true);
    } else if (x === 1) {
      setModelData({ ...dnbndl, btn_2_fn_props: id });
      setOpen(true);
    } else if (x === 2) {
      DownloadBundle(id);
    }
  };
  const settingsForRb = [
    { title: 'Sample Data', fn: ChangeModelData, link: '' },
    { title: 'Edit Bundle', fn: ChangeModelData, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  const settingsForNot = [
    { title: 'Sample Data', fn: ChangeModelData, link: '' },
    { title: 'Copy Bundle', fn: ChangeModelData, cb: true, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  const settingsForcopy = [
    { title: 'Edit Bundle', fn: () => ChangeModelData(1, false), link: '' },
    { title: 'Delete Bundle', fn: () => ChangeModelData(2, true), link: '' }
  ];
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <Model_1
        open={open}
        setOpen={setOpen}
        data={modelData}
      />
      {!notMt && (
        <Box
          sx={{
            width: '100%',
            borderRight: '0.8px solid #797F8F80',
            display: 'flex',
            flexDirection: 'column',
            py: 3,
            px: 5,
          }}>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '20px',
                py: 3,
                background:
                  'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Your Bundle
            </Typography>
          </Box>
          <Box
            sx={{
              border: 'dashed 1px #BCBFC7',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              py: 15,
            }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: '600', color: '#8A18D0' }}>
              No configurations found!!!
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: '400', color: '#000' }}>
              Please configure to view the bundles
            </Typography>
          </Box>
        </Box>
      )}
      {notMt && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            borderRight: '0.8px solid #797F8F80',
            display: 'flex',
            flexDirection: 'column',
            py: 3,
            px: { xs: 1, md: 5 },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '20px',
                py: 3,
                background:
                  'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Your Bundle
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #797F8F',
                borderRadius: '4px',
                padding: '0.5rem 1rem',
                // backgroundColor: '#f9f9f9',
                width: '100%',
                height: '35px',
                maxWidth: '250px',
              }}>
              <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
              <TextField
                sx={{
                  flex: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                      height: '35px',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                }}
                placeholder='Search bundle...'
                variant='outlined'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          </Box>
          {filterBundles.map((e, i) => (
            <Bundle
              key={i}
              e={e}
              RunBundle={RunBundle}
              DownloadBundle={DownloadBundle}
              ChangeModelData={ChangeModelData}
              BndlePops={BndlePops}
              BtnDatas={
                !e.ind_bundle_start_date
                  ? settingsForcopy
                  : e.ind_bundle_run
                    ? settingsForNot
                    : settingsForRb
              }
            />
          ))}
        </Box>
      )}
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ position: 'absolute', mt: '38px' }}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const generateTableData = (
  category,
  countries,
  ecommerces,
  brands,
  cf,
  tds
) => {
  const tableData = [];

  // If any field is empty, add a default row with placeholders
  if (!category || !countries.length || !ecommerces.length || !brands.length) {
    tableData.push({
      Category: category || '-',
      Country: countries.length ? countries[0] : '-',
      'E-Commerce': ecommerces.length ? ecommerces[0] : '-',
      Brand: brands.length ? brands[0] : '-',
    });
    return tableData;
  }
  tableData.push({
    Category: category,
    Country: countries,
    'E-Commerce': ecommerces,
    Brand: brands,
    'Configured Date': cf,
    // tds
  });

  // ecommerces.forEach(ecommerce => {
  //     brands.forEach(brand => {
  //     });
  // });

  // Ensure at least 7 rows in the table by adding empty rows if needed
  // while (tableData.length < 7) {
  //     tableData.push({
  //         Category: '',
  //         Country: '',
  //         'E-Commerce': '',
  //         Brand: '',
  //     });
  // }

  return tableData;
};

const Card = ({ data }) => {
  return (
    <Box
      sx={{
        background: data.bg,
        display: 'flex',
        width: data.width,
        flexDirection: 'column',
        px: 2,
        borderRadius: '7px',
        py: 2,
      }}>
      <Typography
        sx={{ fontSize: '13px', fontWeight: '500', color: '#474747' }}>
        {data.title}
      </Typography>
      <Typography sx={{ fontSize: '24px', fontWeight: '500', color: '#000' }}>
        {data.value}
      </Typography>
    </Box>
  );
};

export default IndividualUserLandingPage;
