import { Avatar, TextField, Box, Typography, FormControlLabel, RadioGroup, Radio, Button, InputLabel, FormControl, Grid, Divider, IconButton, List, ListItem, FormLabel, styled } from '@mui/material';
import { useMemo, useState } from "react";
import React from "react";
import { Back } from "../../../../../components/@extended/Button";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import http from '../../../../../utils/http-common'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { BtnLoad, CheckoutSummaryStyles, SampleDataMartDatas } from '../../../../../utils/data/LandingPage/Data';
import { useDispatch, useSelector } from 'react-redux';
import { ConvertDataMartBundles } from '../../../../../components/@extended/CustomFunctions';
import { NoteComponent } from '../../../../../components/@extended/Card';
import PhoneInput from 'react-phone-input-2';
import { Country } from '../../../../../components/@extended/Country';
import parsePhoneNumberFromString, { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { handleClick, setMessage, setSeverity } from '../../../../../slice/SnackPop';
import { Tk2 } from '../../../../../components/@extended/ReturnToken';
import { useFetchDatas } from '../../../../../components/@extended/ApiCall';
import { DataPointsSvg, DateSvg, LineItemsSvg, NoOfProductsSvg } from '../../../../../utils/icon/LandingPage/Icon';
import { CheckoutStatus } from './CheckoutStatus';
import { CheckoutForm } from './CheckoutForm';
import { dateClnse4 } from '../../../../../components/Public/DateClnse';





export const CheckoutSummary = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [bundleId, setBundleId] = useState(null);
    const styles = CheckoutSummaryStyles;
    const Get = useSelector((s) => s?.allbundles?.datamartBundles?.allDataMartBundles);
    const tbl = ConvertDataMartBundles(Get);
    const data = useMemo(() => tbl?.find((e) => e?.bundle_id === bundleId), [tbl, bundleId]);

    // Extract query parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("bundle_id")) {
            setBundleId(params.get("bundle_id"));
        }
    }, [location]);

    // Memoized platform and brand strings
    const platform = useMemo(() => {
        if (!data?.platform) return 'Platform () :';
        return `Platform (${data.platform.length}): ${data.platform.length > 1
            ? data.platform.slice(0, -1).join(', ') + ' & ' + data.platform[data.platform.length - 1]
            : data.platform[0]
            }`;
    }, [data]);

    const brand = useMemo(() => {
        if (!data?.brand) return 'Brand () :';
        return `Brand (${data.brand.length}): ${data.brand.length > 1
            ? data.brand.slice(0, -1).join(', ') + ' & ' + data.brand[data.brand.length - 1]
            : data.brand[0]
            }`;
    }, [data]);

    // Memoized data description items
    const dataDescriptionItems = useMemo(() => [
        { icon: DateSvg, label: 'Date Range', value: `${dateClnse4(data?.dateFrom)} till ${dateClnse4(data?.dateTo)}` },
        { icon: NoOfProductsSvg, label: 'No. of products', value: data?.noOfProducts },
        { icon: DataPointsSvg, label: 'Data Points', value: data?.dataPoints },
        { icon: LineItemsSvg, label: 'Line Items', value: data?.lineItems },
    ], [data]);

    // Memoized cost items
    const costItems = useMemo(() => [
        { label: 'Cost of the bundle', value: data?.bundlePrice },
        { label: 'Discount', value: `- ${data?.discountReducedPrice}` },
    ], [data]);

    if (!data) { navigate('/data-mart'); return null };

    return (
        <Box sx={styles.summaryContainer}>
            <Typography sx={styles.summaryTitle}>Summary</Typography>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, marginBottom: 2 }}>
                <Box component="img" src={data?.cardImg} alt="Bundle Image" sx={styles.bundleImage} />

                <Box sx={{ flex: 1, mt: { xs: 2, md: 0 }, }}>
                    <Typography variant="body1" fontWeight="bold" sx={{ fontFamily: 'Inter', fontSize: '16px', mb: 1, fontWeight: 600, lineHeight: '19.36px', letterSpacing: '-0.02em', textAlign: 'left' }}>
                        {data?.bundleName}
                    </Typography>
                    <Typography variant="body2" sx={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, mb: 1, lineHeight: '14.52px', textAlign: 'left', color: '#474747CC', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, textOverflow: 'ellipsis', }}>
                        {data?.bundleDescrp}
                    </Typography>

                    <Typography variant="body2" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '14.52px', textAlign: 'left', color: '#000000', marginBottom: 1 }}>
                        Configurations
                    </Typography>
                    <Typography variant="body2" sx={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 500, lineHeight: '14.52px', textAlign: 'left', marginBottom: 1, color: '#474747CC' }}>
                        This bundle includes:
                    </Typography>

                    <List disablePadding sx={{ listStyleType: 'disc', fontSize: '10px', paddingLeft: 4, color: '#474747CC' }}>
                        <ListItem disablePadding sx={{ display: 'list-item', color: '#474747CC' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: '500' }}>Category (1) : {data?.category}</Typography>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item', color: '#474747CC' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#474747CC' }}>Country (1) : {data?.country}</Typography>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#474747CC' }}>{platform}</Typography>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'list-item' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#474747CC' }}>{brand}</Typography>
                        </ListItem>
                    </List>

                    <Typography variant="body2" sx={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 600, lineHeight: '14.52px', textAlign: 'left', color: '#000000', marginTop: 2, marginBottom: 1 }}>
                        Data Description
                    </Typography>

                    {dataDescriptionItems.map((item, index) => (
                        <Box key={index} sx={styles.summaryItem}>
                            <Box sx={styles.summabryIconLabel}>
                                <item.icon />
                                <Typography sx={styles.summaryLabelText}>{item.label}</Typography>
                            </Box>
                            <Typography sx={styles.summaryText}>{item.value}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Divider sx={{ my: 2, backgroundColor: 'black' }} />

            {costItems.map((item, index) => (
                <Box key={index} sx={styles.summaryItem}>
                    <Typography sx={styles.bundleCost}>{item.label}</Typography>
                    <Typography sx={styles.bundleCost}>{item.value}</Typography>
                </Box>
            ))}

            <Divider sx={{ marginBottom: 2, backgroundColor: 'black' }} />

            <Box sx={styles.summaryItem}>
                <Typography sx={styles.bundleCost}>Bundle Cost</Typography>
                <Typography sx={styles.bundleCost}>{data?.discountedPrice}</Typography>
            </Box>
        </Box>
    );
};


export const Checkout = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate()

    const handleNextStep = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };
    const handleBackFn = () => {
        if (activeStep === 0) {
            navigate(-1);
            return;
        }
        setActiveStep((prevStep) => prevStep - 1);
    }

    return (
        <Box>
            <Box>
                {/* <Back prop={{ txt: 'Checkout', fn: handleBackFn, dontBack: true }} /> */}
                <Back prop={{ txt: 'Checkout', url: '/data-mart' }} />
            </Box>
            <Box
                sx={{
                    // p: 4,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#FAFAFA',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                        <CheckoutForm activeStep={activeStep} setActiveStep={setActiveStep} handleNextStep={handleNextStep} />
                    </Grid>

                    <Grid item xs={12} md={4.6} sx={{ m: 2, p: 0 }}>
                        <CheckoutSummary />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};


