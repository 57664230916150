import { Box, Typography } from "@mui/material";
import { Card } from "../components";
import Mission_Vision from "../../../../../assets/about/mission-vision.png";
import Our_Story from "../../../../../assets/about/our-story.png";
import { I3 } from "../../../../../utils/icon/IndividualPages/Icon";

export const SecondPage = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", background: "#fff", color: "#000", px: { xs: 0, md: 5 }, py: { xs: 2, md: 5 }, }}>
      <Box sx={{ my: { xs: 2, md: 2 } }}>
        {" "}
        {/* Adjusted margin for mobile */}
        <Typography sx={{ textAlign: "center", fontWeight: "600", fontSize: { xs: "32px", md: "58px" }, }}>
          Mission & Vision
        </Typography>
        <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", gap: { xs: 2, md: 4 }, p: { xs: 2, md: 10 }, px: { xs: 1, md: 10 }, }}>
          <Box sx={{ width: { xs: "100%", md: "45%" }, display: "flex", alignItems: "center", justifyContent: "center", mb: { xs: 2, md: 0 }, }}>
            <Box sx={{ width: { xs: "100%", md: "95%" }, border: "1px solid #EAECF0", background: "#FAFAFA", borderRadius: "16px", py: 2, px: 2, display: "flex", alignItems: "center", justifyContent: "center", }}>
              <img src={Mission_Vision} alt="Mission and Vision" style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: "12px", mt: 15 }} sx={{ height: { xs: "auto", md: "1600px" }, }} />
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "45%" }, display: "flex", flexDirection: "column", my: { xs: 2, md: 0 }, py: 2, textAlign: { xs: "justify", md: "justify" }, hyphens: "none", }}>
            <Card data={{ icon: <I3 />, title: "Our Mission", text: "Empowering businesses through comprehensive Ecommerce Data Analysis and uniting data teams with actionable insights to drive transformative outcomes.", }} />
            <Box sx={{ my: 2 }} />
            <Card data={{ icon: <I3 />, title: "Our Vision", text: "Revolutionizing how businesses understand Ecommerce Product Analysis, offering unparalleled insights into digital touchpoints, market sentiments, and strategic growth opportunities. ", }} />
          </Box>
        </Box>
      </Box>

      <Box sx={{ my: { xs: 2, md: 2 }, }}>
        {" "}
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "32px", md: "58px" }, // Responsive font size

          }}
        >
          Our Story
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
            justifyContent: { xs: "center", md: "space-evenly" }, // Align items
            gap: { xs: 2, md: 2 }, // Add gap between elements
            px: { xs: 2, md: 5 }, // Responsive padding
            my: { xs: 2, md: 4 }, // Margin adjustments

          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              flexDirection: "column",
              my: { xs: 2, md: 0 }, // Margin for mobile
              py: 2,
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none",

            }}>
            <Card data={{ icon: <I3 />, title: "Data-Driven Success: The Brand Aura Story", text: "Brand Aura began with a single mission: to simplify the complexities of Ecommerce Data Analysis for businesses. Recognizing the challenges of shifting technologies, consumer behaviors, and vast data pools, we developed innovative Data Crawling Services to turn raw data into powerful insights. ", }} />
            <Box sx={{ my: 2 }} /> {/* Spacer */}
            <Card
              data={{
                text: "Fueled by innovation, passion, and expertise, we help organizations leverage Analytics for Ecommerce Websites to thrive in the digital era. Our dedicated team delivers unmatched service to support your journey toward growth. ",
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 2, md: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "95%" },
                border: "1px solid #EAECF0",
                background: "#FAFAFA",
                borderRadius: "16px",
                py: 2,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Our_Story}
                alt="Our Story"
                style={{
                  width: "100%",
                  height: "auto", // Maintain aspect ratio
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
