import { Element } from "react-scroll";
import { Box } from "@mui/material"
import { HeaderFooter } from "../../../components/nav/NormalHeaderFooter"
import { Back } from "../../../components/@extended/Button"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BundleDetailedOverview, SimilarBundles } from "./components";


export const BundleOverview = () => {
    return (
        <HeaderFooter width={{ xs: '85%', md: '85%' }}>
            <Box sx={{ pt: 3 }}>
                <Back prop={{ txt: 'Bundle', url: '/data-mart' }} />
                <Element name="Datamart_Overview_Hero">
                    <BundleDetailedOverview />
                </Element>
                <SimilarBundles />
            </Box>
        </HeaderFooter>
    )
}