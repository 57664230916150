import {
  Box,
  Button,
  FormLabel,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { I_Icon } from "../../../../../../utils/icon/IndividualPages/Icon";
import { Model_1 } from "../../../../../../components/@extended/Model";
import http from "../../../../../../utils/http-common";
import { useDispatch } from "react-redux";
import { notSubscribed, subscribed } from "../../../../../../slice/subscribed";
import { useNavigate } from "react-router-dom";
import { setNav } from "../../../../../../slice/sideNav";
import { dateClnse } from "../../../../../../components/Public/DateClnse";
import { useRazorpayPayment } from "../../../../../../components/@extended/Payment";
import { updateCredit } from "../../../../../../slice/credits";
import { BtnLoad } from "../../../../../../utils/data/LandingPage/Data";
import jsPDFInvoiceTemplate from "../../../../../../components/pdf-generation/Invoice";
import { returnInvoiceGeneratingPayload } from "../../../../../../utils/data/IndividualDashboard/Data";
import { GetPriceWithOutSymbol, GetPriceWithSymbolNoDecimal } from "../../../../../../components/@extended/CurrencyConversion";
import { formatPhoneNumber } from "../../../../../../components/@extended/CustomFunctions";

export const Plan = ({ setCp }) => {
  const [open, setOpen] = useState(false);
  const seq = localStorage.getItem("seq");
  const [plan, setPlan] = useState([]);
  const [endsIn, setEndsIn] = useState(0);
  const [nextP, setNextP] = useState(0);
  const [bar, setBar] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [modelData, setModelData] = useState({
    title: "Change Plan",
    emoji: "",
    txt: ["Are you sure, you want to change the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Change Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: () => setCp(true),
  });
  const Cancel = async () => {
    try {
      // const response = await http.post(`/master_service/billing_history/${3}`);
      const response = await http.delete(
        `/master_service/cancel_plan/${userId}`
      );
      console.log("cancel", response);
      // await Get();
      navigate("/admin");
      dispatch(setNav(-1));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Get = async () => {
    try {
      const response = await http.get(
        `/master_service/subscription_details/${userId}`
      );
      console.log("resi", response.data);
      setPlan(response.data.planDetails);
      setNextP(response.data.paymentDetails[0].next_payment_date);
      localStorage.setItem("seq", response.data.planDetails.sequence - 1);
      dispatch(subscribed());
      const fd = new Date(response.data.paymentDetails[0].payment_date);
      const td = new Date();
      const nd = new Date(response.data.paymentDetails[0].next_payment_date);
      const diffInMs = nd.getTime() - td.getTime();
      // const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
      const diffInDays = Math.max(0, Math.floor((diffInMs / (1000 * 3600 * 24) + 1)));
      setEndsIn(diffInDays);
      const totalDuration = nd.getTime() - fd.getTime();
      const remainingDuration = nd.getTime() - td.getTime();
      const percentageRemaining = (remainingDuration / totalDuration) * 100;
      const perValue = Math.max(0, 100 - percentageRemaining);
      console.log("per", percentageRemaining, perValue);
      setBar(perValue);

      // await tD();
      // setMessage(response.data.message);
      // setSeverity('success');
      // handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.data.error === "Plan data not found") {
        console.log("yes not found");
        localStorage.removeItem("seq");
        dispatch(notSubscribed());
      }
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : 'Unable to Fetch the table data.'
      // );
      // setSeverity('error');
      // handleClick();
    }
  };
  useEffect(() => {
    Get();
  }, []);

  const cancel = {
    title: "Cancel Plan",
    emoji: "",
    txt: ["Are you sure, you want to cancel the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Cancel Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: Cancel,
  };
  const Change = {
    title: "Change Plan",
    emoji: "",
    txt: ["Are you sure, you want to change the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Change Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: () => setCp(true),
  };

  return (
    plan && (
      <Box
        sx={{
          border: "1px solid #797F8F80",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Model_1 open={open} setOpen={setOpen} data={modelData} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#000",
            }}
          >
            {seq ? plan.title : "Purchase Plan"}
          </Typography>
          {/* <Button
            disableRipple
            sx={{ minWidth: 'max-content' }}>
            <I_Icon />
          </Button> */}
        </Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#000",
          }}
        >
          {seq && GetPriceWithSymbolNoDecimal(plan.price)}
        </Typography>
        <Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "12px",
              py: 1,
              color: "#000",
            }}
          >
            {seq && `Ends in ${endsIn} Days`}
          </Typography>
          {seq && (
            <LinearProgress
              variant="determinate"
              sx={{
                height: "8px",
                borderRadius: "5px",
                backgroundColor: "#E4E4E480",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#8A18D0",
                },
              }}
              value={seq ? bar : 100}
            // value={seq ? 40 : 100}
            />
          )}
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              py: 1,
              color: "#000",
            }}
          >
            {seq && `Next Payment : ${dateClnse(nextP)}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            sx={{
              height: "32px",
              width: "49%",
              borderRadius: "4px",
              color: "#8A18D0",
              borderColor: "#8A18D0",
              textTransform: "capitalize",
              textWrap: "nowrap",
            }}
            disabled={!seq}
            onClick={() => {
              setModelData(cancel);
              setOpen(true);
            }}
          >
            Cancel Plan
          </Button>
          <Button
            sx={{
              height: "32px",
              width: "49%",
              borderRadius: "4px",
              background: "#8A18D0",
              color: "#fff",
              textTransform: "capitalize",
              textWrap: "nowrap",
              "&:hover": { background: "#B37FFC" },
            }}
            onClick={() => {
              setModelData(Change);
              setOpen(true);
            }}
          >
            Change Plan
          </Button>
        </Box>
      </Box>
    )
  );
};

export const Pay = ({ setMessage, setSeverity, handleClick, setShowPaymentBanner, Get }) => {
  const [price, setPrice] = useState("");
  const [load, setLoad] = useState(false);
  const RazorpayPayment = useRazorpayPayment()
  const [credits, setCredits] = useState("");
  // const [paymentMode, setPaymentMode] = useState('online');
  const paymentMode = localStorage.getItem('paymentType')
  const [errors, setErrors] = useState({});
  const userId = localStorage.getItem("user_id");
  const dispatch = useDispatch()
  const pdfInputData = returnInvoiceGeneratingPayload({})
  // const pdfInputData = returnInvoiceGeneratingPayload({ user_name: user_name, billing_adress: billing_adress, user_phone_number: user_phone_number, tableHeader: tableHeader, tableData: tableData, price: price })
  const ValidatePayment = () => {
    const fieldErrors = {};
    let isValid = true;
    if (!price) {
      fieldErrors.price = 'Price is required'
      isValid = false;
    }
    setErrors(fieldErrors);
    return isValid;
  }
  const GenerateInvoice = async (k) => {
    try {
      const pdf = jsPDFInvoiceTemplate(pdfInputData)
      const mime = "application/pdf";
      const file = new File([pdf?.blob], "abc.pdf", { type: mime });
      console.log('pdf', k, pdf);

      const formData = new FormData();
      formData.append('user_id', Number(userId));
      formData.append('ind_bundle_id', '');
      formData.append('plan_name', '');
      formData.append('payment_id', k?.id);
      formData.append('invoice_no', k?.invoice_no);
      formData.append('payment_type', 'online');
      formData.append('payment_date', new Date());
      formData.append('bundle_cost', Number(price));
      formData.append('payment_confirmation_status', 'success')
      // formData.append('payment_confirmation_status', k?.payment_confirmation_status)
      formData.append('filename', 'Credit Purchase');
      formData.append('pdf', file);
      const response = await http.post(
        "master_service/payment/details", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set the correct content type
        },
        // JSON.stringify({
        //     user_id: Number(user_id),
        //     ind_bundle_id: Number(bundleId),
        //     plan_name: Number(plan_name),
        //     // payment_type: paymentType,
        //     payment_id: k?.razorpay_payment_id,
        //     payment_date: new Date(),
        //     // bundle_cost: Number(data?.finalIndPrice),
        //     bundle_cost: Number(price),
        //     payment_confirmation_status: "success",
        // })
      });
      console.log('res', response, response?.data?.data?.payment?.order_number, response?.data?.data?.payment?.transaction_number);
      if (k?.payment_confirmation_status === 'success') {
        // setMessage(response.data.message || "Error with save bundle");
        // setSeverity("success");
        // dispatch(setMessage(response.data.message || "Error with save bundle"));
        // dispatch(setSeverity("success"));
        setTransaction_no(response?.data?.data?.payment?.transaction_number);
      } else {
        // setMessage(k?.errorMessage || "Error with purchasing bundle");
        // setSeverity("error");
        // dispatch(setMessage(k?.errorMessage || "Error with purchasing bundle"));
        // dispatch(setSeverity("error"));
      }
      SendCreditRequest(k)
      // dispatch(handleClick());
    } catch (error) {
      console.log('err', error);
      setLoad(false)
      // setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle');
      // setSeverity("error");
      // dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle'));
      // dispatch(setSeverity("error"));
      // dispatch(handleClick());
    }
  }
  const TransactionApiTrigger = async (k) => {
    try {
      const response = await http.post("/master_service/invoice", JSON.stringify({ user_id: userId }));
      console.log("response tbl manage_user:", response, response?.data, response?.data?.data?.invoice_no);
      pdfInputData.invoice.num = response?.data?.data?.invoice_no
      pdfInputData.contact.name = response?.data?.data?.user_details?.user_first_name
      pdfInputData.contact.address = response?.data?.data?.user_details?.billing_address
      pdfInputData.contact.phone = formatPhoneNumber(String(response?.data?.data?.user_details?.phone_number))
      pdfInputData.contact.email = response?.data?.data?.user_details?.email
      pdfInputData.invoice.header = [{ title: "Sl No", style: { width: 17, }, }, { title: "Name", }, { title: "Applicable Credits", style: { align: "right", } }, { title: "Credits Cost", style: { align: "right", } },]
      pdfInputData.invoice.table = [[1, 'Credit Purchase', Number(Math.round(credits)) + ' Credits', GetPriceWithSymbolNoDecimal(Number(Math.round(price)))]]
      pdfInputData.invoice.additionalRows[0].col3 = String(GetPriceWithSymbolNoDecimal(Math.round(price)))
      // setTblData(response?.data?.user_table)
      // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
      GenerateInvoice({ ...k, invoice_no: response?.data?.data?.invoice_no })
    }
    catch (error) {
      console.error("Error changing status:", error)
      setLoad(false)
    }
  }
  const SendCreditRequest = async (e) => {
    setLoad(true)
    try {
      const response = await http.post(
        "/master_service/corporate_payment",
        JSON.stringify({
          user_id: userId,
          request_amt: price,
          applicable_credit: credits,
          onlinePayment: paymentMode === 'online',
        })
      );

      console.log('eee', { user_id: userId, recharge_request_id: response.data?.data?.recharge_request_id, transaction_no: e?.razorpay_payment_id, payment_date: new Date(), payment_type: 'online' }, e, response.data);
      await SendCredit({ user_id: userId, recharge_request_id: response.data?.data?.recharge_request_id, transaction_no: e?.razorpay_payment_id, payment_date: new Date(), payment_type: 'online' });
      // await Fetch_TableData();
      await Credit();
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false)
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Fetch the table data."
      // );
      setMessage('Enter the price in dollars');
      setSeverity("error");
      handleClick();
    }
  };
  const SendCredit = async (p) => {
    try {
      const response = await http.post(
        "/master_service/credit_request/update",
        JSON.stringify(p)
        // JSON.stringify({user_id:'', recharge_request_id, transaction_no, payment_date, payment_type})
      );
      const successMessage = response?.data?.message;
      const errorMessage = response?.data?.error;
      if (errorMessage) {
        console.warn("Warning:", errorMessage);
        setMessage(errorMessage);
        setSeverity("warning");
        // dispatch(setMessage(errorMessage));
        // dispatch(setSeverity("warning"));
      } else {
        //  console.log(successMessage);
        setMessage(successMessage || "Data sent successfully.");
        setSeverity("success");
        // dispatch(setMessage(successMessage || "Data sent successfully."));
        // dispatch(setSeverity("success"));
        // fetchDatasP()
      }
      await Get()
      setLoad(false)
      // dispatch(handleClick());
    } catch (error) {
      const errMsg = error?.response?.data?.error || "Error sending data.";
      console.error("Error sending data:", errMsg);
      setMessage(errMsg);
      setSeverity("error");
      // dispatch(setMessage(errMsg));
      // dispatch(setSeverity("error"));
      // dispatch(handleClick());
    }
  };
  const AfterOnlinePayment = (e) => {
    TransactionApiTrigger(e)
    // SendCreditRequest(e)
    // SendCredit({ user_id: userId, recharge_request_id: receivedData?.recharge_request_id, transaction_no: transaction_no, payment_date: new Date(), payment_type: 'online' })
  }
  const MakeOnlinePayment = async () => {
    setLoad(true)
    RazorpayPayment({ amount: price, handler: (e) => { console.log('eee', e); AfterOnlinePayment(e); }, ondismiss: () => { setLoad(false) }, onError: () => { setLoad(false) }, onPaymentFailed: () => { setLoad(false) }, })
  };
  const MakePayment = async () => {
    setLoad(true)
    try {
      const response = await http.post(
        "/master_service/corporate_payment",
        JSON.stringify({
          user_id: userId,
          request_amt: price,
          applicable_credit: credits,
          onlinePayment: paymentMode === 'online',
        })
      );

      console.log(response.data);
      setShowPaymentBanner(true)
      // await Fetch_TableData();
      setMessage(response.data.message);
      setLoad(false)
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false)
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Fetch the table data."
      // );
      setMessage('Enter the price in dollars');
      setSeverity("error");
      handleClick();
    }
  };
  const Credit = async () => {
    try {
      const res = await http.post(
        "/master_service/credit",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      console.log("resp", res, res.data);
      // setCred(res.data.data.available_credit)
      dispatch(
        updateCredit({
          available_credit: res.data.data.available_credit,
          used_credit: res.data.data.used_credit,
        })
      );
      // console.log("credi", credi);
    } catch (error) {
      console.log("credits err", error);
    }
  };

  const handlePay = () => {
    if (ValidatePayment()) {
      if (paymentMode === 'online') {
        MakeOnlinePayment()
      } else {
        MakePayment()
      }
    }
  }
  return (
    <Box
      sx={{
        border: "1px solid #797F8F80",
        borderRadius: "8px",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          my: "15px",
        }}
      >
        <FormLabel
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#000000",
          }}
        >
          Price
          <span style={{ color: "#797F8F" }}>(in dollars)</span>
        </FormLabel>
        <TextField
          placeholder="Enter Price"
          type="number"
          value={price}
          onChange={(e) => {
            const newPrice = e.target.value;
            if (newPrice >= 0) {
              setPrice(newPrice);
              setCredits(newPrice * 10);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          required
          sx={{
            color: "#AFAFAF",
            fontWeight: "500",
            fontSize: "10px",
          }}
          InputProps={{
            sx: {
              height: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#797F8F",
              },
            },
          }}
          error={!!errors.price} helperText={errors.price}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "100%" },
          my: "15px",
        }}
      >
        <FormLabel
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#000000",
          }}
        >
          Estimated Credits
        </FormLabel>
        <TextField
          type="number"
          value={credits}
          onChange={(e) => {
            const newCredits = e.target.value;
            if (newCredits >= 0) {
              setCredits(newCredits);
              setPrice(newCredits / 10);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          required
          sx={{
            color: "#AFAFAF",
          }}
          InputProps={{
            sx: {
              height: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#797F8F",
              },
            },
          }}
        />
      </Box>
      <Button
        sx={{
          height: "15",
          background: "#8A18D0",
          color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff',
          // px: 4,
          // ml: 2,
          mt: 2,
          width: "100%",
          fontSize: "12px",
          fontWeight: "600",
          textTransform: "capitalize",
          "&:hover": { background: "#B37FFC" },
        }}
        onClick={handlePay}
        disabled={load}
      // onClick={MakePayment}
      >
        Make Payment
        <Box>
          {load && (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
              <Box sx={BtnLoad} />
            </Box>
          )}
        </Box>
      </Button>
    </Box>
  );
};
