import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminDashboardCredit, ConvertManageUsers, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterList } from "@mui/icons-material";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";
import { setAllUsers } from "../../../../slice/allBundles";
import { useDispatch } from "react-redux";
import { handleClick, setMessage, setSeverity } from "../../../../slice/SnackPop";


export const ManageUsers = () => {
  const [searchTerm, setSearchTerm] = useState(null);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [tblData, setTblData] = useState([]);
  const [threedotss, setThreeDotss] = useState(false);
  const [cardDatas, setCardDatas] = useState(
    {
      totalBrandAuraUsers: 0,
      individualUsers: 0,
      companyUsers: 0,
    }
  );
  const fetchDatas = async () => {
    try {
      const response = await http.get("/master_service/admin_panel/manage_user");
      console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
      setTblData(response?.data?.user_table?.sort((a, b) => b?.user_id - a?.user_id))
      dispatch(setAllUsers(response?.data?.user_table))
      setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
    }
    catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const Deactivate = async ({ uid, status, rowData }) => {
    try {
      const response = await http.post("/master_service/admin_panel/update_status", JSON.stringify({ user_id: rowData?.user_id, user_status: status }));
      console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
      fetchDatas()
      dispatch(setMessage(response?.data?.message))
      dispatch(setSeverity('success'))
      dispatch(handleClick())
      // setTblData(response?.data?.user_table)
      // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
    }
    catch (error) {
      console.error("Error changing status:", error)
      dispatch(setMessage(error?.response?.data?.message || 'There is some issue with changing the User status'))
      dispatch(setSeverity('error'))
      dispatch(handleClick())
    }
  }
  useEffect(() => {
    fetchDatas()
  }, [])
  const tbl = ConvertManageUsers({ d: tblData })
  // console.log('tbl', tblC, tblP)
  // const filteredDataC = tblC;
  // console.log('filteredDataC', filteredDataC)
  // const filteredDataP = tblP;
  const filteredData = tbl?.filter((k) => {
    if (!k) return false;

    const search = String(searchTerm ?? '')?.toLowerCase();
    // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
    const matchesSearch = [
      k?.user_id,
      k?.name,
      k?.email,
      k?.phone_number,
      k?.company,
      k?.user_status
    ].some(field =>
      String(field ?? '').toLowerCase().includes(search)
    );
    const filtMatch = filter === null || filter === 'all' || filter === k?.verified_status?.props?.txt
    return matchesSearch && filtMatch;
  });

  useEffect(() => {
    // console.log('filterDatas', threedotss, filterDatas, fltData);
    setThreeDotss(filteredData?.[0]?.email !== null);
  }, [filteredData]);
  const btns = [
    {
      title: ({ i, rowData }) => {
        return rowData.user_status?.props?.txt !== "Active"
          ? "Active"
          : "Deactivate";
      },
      fn: ({ index, i, rowData }) => {
        console.log(
          "dec",
          rowData.user_status?.props?.txt === "Active",
          rowData.user_status,
          rowData.user_status?.props?.txt,
          rowData
        );
        Deactivate({
          uid: rowData.user_id,
          status:
            rowData.user_status?.props?.txt !== "Active"
              ? "Active"
              : "Deactivate",
          rowData: rowData
        });
      },
      cb: true,
      link: "",
    },
  ];
  const propsUser = { filteredData: filteredData, tableProps: { btnData: btns }, tableStyle: { Invoice: false, isThreeDot2: threedotss, OutBox: { borderRadius: "8px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }, threeDot2: { textAlign: "end" }, }, rowsPerPage, page, setPage }
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ width: { xs: '100%', md: '100%' } }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2, gap: 1 }}>
            <BasicCard title='Total Brand Aura Users' count={cardDatas?.totalBrandAuraUsers} width={{ xs: '100%', md: '25%' }} />
            <BasicCard title='Individual Users' count={cardDatas?.individualUsers} width={{ xs: '100%', md: '25%' }} />
            <BasicCard title='Company Users' count={cardDatas?.companyUsers} width={{ xs: '100%', md: '25%' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>User Table</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
              <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </Box>

              <Select
                displayEmpty
                size='small'
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                inputProps={{ IconComponent: () => null }}
                // startAdornment={
                // }
                sx={{
                  width: "150px",
                  height: "35px",
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: filter === null ? 'end' : 'center',
                    pr: '5px !important',
                    pl: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#D0D5DD",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#D0D5DD",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#D0D5DD",
                  },
                }}>
                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    <FilterIkn />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Verified Status</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                <MenuItem value='Verified' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Verified</MenuItem>
                <MenuItem value='Not Verified' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Not Verified</MenuItem>
              </Select>
            </Box>
          </Box>
          <CorporateNormalUserTable prop={propsUser} />
        </Box>
      </Box>
    </Box>
  )
}
