import { Box, Button, Typography } from "@mui/material";
import BG from "../../../../../assets/CTA/CTA Background.png";
import Img from "../../../../../assets/CTA/CTA img.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

export const LastPage = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
      <Box
        sx={{
          borderRadius: "8px",
          display: "flex",
          height: "518px",
          width: "90%",
          background: `url(${BG}) center/cover no-repeat`,
          backgroundColor: "#000",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 2, md: 4 },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "28px", sm: "35px", md: "48px" },
                  userSelect: "none",
                  py: "10px",
                  fontWeight: "700",
                }}
              >
                Take Your
                <Typography
                  component="span"
                  sx={{
                    background:
                      "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    ml: "10px",
                    fontSize: "inherit",
                    fontWeight: "700",
                  }}
                >
                  Analysis
                </Typography>
                <Typography component="h2" sx={{ display: "block" }} />
                to the Next Level
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "400",
                  width: '80%',
                  py: "20px",
                  textAlign: { xs: 'justify', md: 'justify' },
                  hyphens: 'none'
                }}
              >
                Harness powerful tool for Ecommerce Data Analytics and gain a competitive edge through actionable insights and real-time data.
              </Typography>
              <Box
                sx={{
                  display: { xs: "flex", md: "block" },
                  flexDirection: { xs: "column", md: "" },
                  alignItems: { xs: "center" },
                }}
              >
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to={'/reg'}
                >
                  <Button
                    sx={{
                      color: "#fff",
                      background: "#8A18D0",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "52px",
                      width: "140px",
                      mx: "10px",
                      my: "10px",
                      textTransform: "capitalize",
                      "&:hover": { background: "#8a18d099" },
                    }}
                  >
                    Get Started
                  </Button>
                </Link>
                <Link
                  onClick={() => {
                    setTimeout(() => {
                      scroller.scrollTo("main", {
                        smooth: true,
                        duration: 1500,
                      });
                    }, 50);
                  }}
                  to={"/contact"}
                >
                  <Button
                    sx={{
                      color: "inherit",
                      fontSize: "14px",
                      fontWeight: "500",
                      height: "52px",
                      width: "140px",
                      border: "1px solid #fff",
                      mx: "10px",
                      textTransform: "capitalize",
                      my: "10px",
                    }}
                  >
                    Contact Us
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <img
              alt=""
              className="h-[auto] w-[80%]"
              style={{ borderRadius: "20px" }}
              src={Img}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const LastPage2 = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
      <Box
        sx={{
          borderRadius: "8px",
          display: "flex",
          height: "518px",
          width: "90%",
          background: `url(${BG}) center/cover no-repeat`,
          backgroundColor: "#000",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 2, md: 4 },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "28px", sm: "35px", md: "48px" },
                  userSelect: "none",
                  py: "10px",
                  fontWeight: "700",
                }}
              >
                Take Your
                <Typography
                  component="span"
                  sx={{
                    background:
                      "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    ml: "10px",
                    fontSize: "inherit",
                    fontWeight: "700",
                  }}
                >
                  Analysis
                </Typography>
                <Typography component="h2" sx={{ display: "block" }} />
                to the Next Level
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "400",
                  width: '80%',
                  py: "20px",
                  textAlign: { xs: 'justify', md: 'justify' },
                  hyphens: 'none'
                }}
              >
                Unlock powerful insights and stay ahead of the competition with{" "}
                {/* <br /> */}
                our BI comparison data for power tools.
              </Typography>
              <Box
                sx={{
                  display: { xs: "flex", md: "block" },
                  flexDirection: { xs: "column", md: "" },
                  alignItems: { xs: "center" },
                }}
              >
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to={'/reg'}
                >
                  <Button
                    sx={{
                      color: "#fff",
                      background: "#8A18D0",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "52px",
                      width: "140px",
                      mx: "10px",
                      my: "10px",
                      textTransform: "capitalize",
                      "&:hover": { background: "#8a18d099" },
                    }}
                  >
                    Get Started
                  </Button>
                </Link>
                <Link
                  onClick={() => {
                    setTimeout(() => {
                      scroller.scrollTo("main", {
                        smooth: true,
                        duration: 1500,
                      });
                    }, 50);
                  }}
                  to={"/contact"}
                >
                  <Button
                    sx={{
                      color: "inherit",
                      fontSize: "14px",
                      fontWeight: "500",
                      height: "52px",
                      width: "140px",
                      border: "1px solid #fff",
                      mx: "10px",
                      textTransform: "capitalize",
                      my: "10px",
                    }}
                  >
                    Contact Us
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <img
              alt=""
              className="h-[auto] w-[80%]"
              style={{ borderRadius: "20px" }}
              src={Img}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
