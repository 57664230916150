// import Log from '../Brand aura logo 1.svg'

export const Logo = ({ cursor = 'default' }) => {
    return (
        <button style={{ cursor: cursor }} className="flex justify-around border-[2px] bg-[#fff] border-[black] p-1">
            <span className="bg-[#000] font-bold text-[#fff] px-2 py-1">BRAND</span>
            <span className="text-[#000] font-bold px-2 py-1">AURA</span>
        </button>
    )
}
