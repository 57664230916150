import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function BasicCard({ title, count, width }) {
  const titleStyles = {
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    color: "#474747",
  };

  const countStyles = {
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
    mt: "8px", // Add margin top to create spacing between title and count
  };

  return (
    <Card
      sx={{
        width,
        // height: "84px",
        minHeight: "84px",
        boxShadow: "none",
        padding: "16px",
        borderRadius: "6px",
        border: "1px solid #EAECF0",
        backgroundColor: "#FFFFFF",
      }}>
      <CardContent
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          "&:last-child": { pb: 0 },
        }}>
        <Typography gutterBottom sx={titleStyles}>
          {title}
        </Typography>
        <Typography sx={countStyles}>{count}</Typography>
      </CardContent>
    </Card>
  );
}
