import { Box, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react";
import http from '../../../../utils/http-common'
import { DataMartBundleCard } from ".";
import { SampleDataMartDatas } from "../../../../utils/data/LandingPage/Data";
import { useSelector } from "react-redux";
import { ConvertDataMartBundles } from "../../../../components/@extended/CustomFunctions";


export const DataMartMainContent = () => {
    // const [type, setType] = useState('All Bundles');
    const Get = useSelector((s) => s?.allbundles?.datamartBundles?.allDataMartBundles)
    // console.log('get', Get, Get?.length);
    const tbl = ConvertDataMartBundles(Get)
    const type = useSelector((s) => s?.allbundles?.type)
    console.log('get', tbl?.length > 0, Get, Get, tbl);

    return (
        <Box sx={{ py: 2, mt: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                {tbl?.length > 0 ?
                    <Typography sx={{ fontSize: '24px', fontWeight: '600', color: '#000000' }}>
                        {type === null ? 'All Bundles' : type}
                    </Typography>
                    :
                    null
                }

                {/* <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    displayEmpty
                    sx={{
                        p: 0, height: '36px', width: "150px", background: '#fff', borderRadius: 0, color: '#000',
                        '& MuiSelect': {
                            border: '#000',
                        },
                        '&:hover MuiSelect': {
                            border: '#000',
                        },
                        '&.Mui-focused MuiSelect': {
                            border: '#000',
                        },
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value='All Bundles'>All Bundles</MenuItem>
                    <MenuItem value='Prime Day'>Prime Day</MenuItem>
                    <MenuItem value='Black Friday'>Black Friday</MenuItem>
                    <MenuItem value='Christmas'>Christmas</MenuItem>
                    <MenuItem value='End of the Year'>End of the Year</MenuItem>
                    <MenuItem value='Republic Day'>Republic Day</MenuItem>
                </Select> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}>
                {tbl?.map((e, i) => (
                    // {SampleDataMartDatas?.map((e, i) => (
                    <DataMartBundleCard e={e} key={i} />
                ))}
            </Box>
        </Box>
    )
}