import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { Back } from "../../../../components/@extended/Button"
import { useEffect, useState } from "react"
import { UserDropDown } from "../../../../components/@extended/UserTypeDropDown";
import http from "../../../../utils/http-common";
import { useDispatch } from "react-redux";
import { setAllUsers } from "../../../../slice/allBundles";
import { handleClick, setMessage, setSeverity } from "../../../../slice/SnackPop";
// const pdf = require('html-pdf');
const { BlobServiceClient } = require('@azure/storage-blob'); // Import Azure Blob Storage SDK
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { useRef } from "react";
import autoTable from "jspdf-autotable";
import { addStyledImage, base64ToBlob, extractBase64Data, loadImage, sendData } from "../../../../components/@extended/CustomFunctions";
import { GetPriceWithSymbol } from "../../../../components/@extended/CurrencyConversion";
import { dateClnse7, StringToDate } from "../../../../components/Public/DateClnse";
import logoImg from '../../../../assets/pdf/logo.png'
import linkedinImg from '../../../../assets/pdf/1.png'
import xImg from '../../../../assets/pdf/2.png'
import instagramImg from '../../../../assets/pdf/3.png'
import { BtnLoad } from "../../../../utils/data/LandingPage/Data";
import jsPDFInvoiceTemplate from "../../../../components/pdf-generation/Invoice";
// import { Buffer } from 'buffer';

export const BrandAuraAdminCustomPlan = () => {
    const pdfRef = useRef(null);
    const [formData, setFormData] = useState({});
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [load, setLoad] = useState("");
    const dispatch = useDispatch();
    // window.Buffer = Buffer;
    const handleFieldChange = (e) => {
        // console.log('Before update:', fmDatas);
        setFormData((prevFormData) => ({ ...prevFormData, [e.target.id]: e.target.value }));
        console.log('After update:', formData, formData?.user);
    };
    const fetchDatas = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/manage_user");
            console.log("response tbl manage_user:", response?.data?.manage_user, response?.data?.user_table);
            const AdminUsers = response?.data?.user_table?.filter((e) => e?.user_role === 'Admin User')
            dispatch(setAllUsers(AdminUsers))
            // setTblData(response?.data?.user_table)
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
            dispatch(setMessage(error?.response?.data?.message || 'Error fetching user data'));
            dispatch(setSeverity('error'));
            dispatch(handleClick())
        }
    }

    const validateForm = () => {
        const newErrors = {};
        if (!user) {
            newErrors.user = "User  is required.";
        }
        if (!formData.planName) {
            newErrors.planName = "Plan Name is required.";
        }
        if (!formData.noOfAdmin || isNaN(formData.noOfAdmin) || formData.noOfAdmin <= 0) {
            newErrors.noOfAdmin = "Please enter a valid number of Admins.";
        }
        if (!formData.noOfUsers || isNaN(formData.noOfUsers) || formData.noOfUsers <= 0) {
            newErrors.noOfUsers = "Please enter a valid number of Users.";
        }
        if (!formData.creditsForThePlan || isNaN(formData.creditsForThePlan) || formData.creditsForThePlan <= 0) {
            newErrors.creditsForThePlan = "Please enter a valid number of credits.";
        }
        if (!formData.planCost || isNaN(formData.planCost) || formData.planCost <= 0) {
            newErrors.planCost = "Please enter a valid Plan Cost.";
        }
        console.log('newErrors', newErrors);

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    // async function uploadToAzureBlob(blob, quotation_id) {
    //     try {
    //         console.log('blob, quotation_id', blob, quotation_id);

    //         // Azure Storage Configuration
    //         const AZURE_STORAGE_CONNECTION_STRING = 'DefaultEndpointsProtocol=https;AccountName=baecommerceimages;AccountKey=mOmQUrNTAnGhZJD/RroEoxyPx8aTyFDIHc9soNzOpmFk7uA9mIY8gR877h2xdkbYV8mDA1wp3Ju++AStNeCxTQ==;EndpointSuffix=core.windows.net';
    //         const CONTAINER_NAME = 'quotations';
    //         const blobServiceClient = BlobServiceClient.fromConnectionString(AZURE_STORAGE_CONNECTION_STRING);
    //         const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME);

    //         // Ensure the container exists (create if it doesn't)
    //         await containerClient.createIfNotExists();

    //         const blobName = `Quotation-${quotation_id}.pdf`;
    //         const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    //         // Upload the blob to Azure Blob Storage
    //         // await blockBlobClient.uploadStream(blob.stream(), blob.size);
    //         await blockBlobClient.uploadData(blob, {
    //             blobHTTPHeaders: { blobContentType: 'application/pdf' }
    //         });

    //         console.log('blockBlobClient.url', blockBlobClient.url);

    //         // Return the URL of the uploaded blob
    //         return blockBlobClient.url;
    //     } catch (error) {
    //         console.log('Error uploading to Azure Blob Storage:', error);
    //         console.error('Error uploading to Azure Blob Storage:', error);
    //         // throw error;
    //     }
    // }


    const createPDF = async ({ prop, response }) => {
        const { Quotation_Date, Quotation_Id, Valid_Until, Plan_Name, user_id, recharge_request_id, plan_name,
            No_of_Admin,
            No_Of_User,
            credits_for_the_plan,
            Plan_cost, } = prop
        const user_name = user?.user_first_name
        const doc = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
            putOnlyUsedFonts: true,
        });

        // const [logoImg, linkedinImg, xImg, instagramImg] = [];
        // Add border around entire page content
        doc.setLineWidth(0.1);
        doc.setDrawColor(255, 255, 255);
        doc.rect(10, 10, 190, 267, "D");

        // Header
        doc.setFillColor(0, 0, 0); // Black fill
        // Draw top-left and top-right rounded corners
        doc.roundedRect(10, 10, 150, 15, 2, 5, "F");
        doc.roundedRect(10, 10, 95, 15, 2, 2, "F"); // Left part
        doc.roundedRect(105, 10, 95, 15, 2, 2, "F"); // Right part
        doc.rect(10, 22, 190, 3, "F"); // Bottom straight line to connect the two rounded parts

        // console.log('logoImg', logoImg);

        doc.addImage(logoImg, "PNG", 165, 13, 30, 10);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(255, 255, 255); // White text
        doc.setFontSize(12);
        doc.text("Quotation", 15, 20);

        // Quotation Details
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setTextColor(0, 0, 0);

        doc.text("Quotation Details", 15, 35); // Title
        doc.setFont("helvetica", "normal");

        doc.text(`Quotation Date: ${dateClnse7(StringToDate(Quotation_Date))}`, 15, 43);
        doc.text(`Quotation ID: ${Quotation_Id}`, 15, 48);
        doc.text(`Plan Name: ${plan_name}`, 15, 53);

        doc.setFont("helvetica", "bold");
        // User Details
        doc.text("User Details", 100, 35); // Title (Adjust X-coordinate for side-by-side)
        doc.setFont("helvetica", "normal");

        doc.text(`User ID: ${user_id}`, 100, 43); // Adjust X-coordinate
        doc.text(`User Name: ${user_name}`, 100, 48); // Adjust X-coordinate

        doc.setFont("helvetica", "bold");
        // doc.setTextColor(255, 255, 255); // White text
        doc.setFontSize(12);
        doc.text("Plan Details", 15, 74);

        // Quotation Details
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        // Plan Details Table
        autoTable(doc, {
            startY: 80,
            head: [["Configurations", ""]],
            body: [
                ["No of Admin", No_of_Admin],
                ["No of Users", No_Of_User],
                ["Credits for the plan (per month)", credits_for_the_plan],
                ["Plan Cost", GetPriceWithSymbol(Math.round(Plan_cost))],
            ],
            theme: "grid",
            headStyles: {
                // fillColor: [138, 24, 208, 0.1],
                fillColor: [243, 231, 250],
                textColor: 0,
                lineWidth: 0.2,
                lineColor: [174, 177, 176],
            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                lineWidth: 0.2,
                lineColor: [174, 177, 176],
            },
            alternateRowStyles: {
                fillColor: [250, 250, 250],
                lineWidth: 0.2, // Set border width to 1px
                lineColor: [174, 177, 176],
            },
            didParseCell: (data) => {
                const row = data.row.index;
                if (row >= 3) { // Last 4 rows (index 6, 7, 8, 9)
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = [243, 231, 250];
                }
            },
            // didDrawPage: (data) => {
            //     const table = data.table;
            //     if (!table) {
            //         console.error("Table data is null or undefined.");
            //         return;
            //     }

            //     const tableX = table.pageStartX;
            //     const tableY = table.pageStartY;
            //     const tableWidth = table.width;
            //     const tableHeight = table.finalY - tableY;
            //     const borderRadius = 5;
            //     const style = 'F';

            //     console.log("tableX:", tableX, "tableY:", tableY, "tableWidth:", tableWidth, "tableHeight:", tableHeight, "borderRadius:", borderRadius, "style:", style);

            //     if (typeof tableX === 'number' && typeof tableY === 'number' && typeof tableWidth === 'number' && typeof tableHeight === 'number' && typeof borderRadius === 'number' && typeof style === 'string') {
            //         doc.setFillColor(243, 231, 250);
            //         doc.roundedRect(tableX, tableY, tableWidth, tableHeight, borderRadius, borderRadius, style);
            //         doc.setFillColor(255, 255, 255);
            //         doc.rect(tableX + 1, tableY + 1, tableWidth - 2, tableHeight - 2, style);
            //     } else {
            //         console.error("Invalid data types for roundedRect.");
            //     }
            // }
        });

        const termsY = 140; // Adjusted Y position
        const termsHeight = 60; // Height of the box
        const termsWidth = 170; // Width of the box
        const termsX = 15; // X position (left side)

        // // Set border thickness and color
        // doc.setLineWidth(0.5); // Thicker border
        // doc.setDrawColor(0, 0, 0); // Black border

        // Draw a properly aligned rectangle with padding
        doc.rect(termsX, termsY, termsWidth, termsHeight);
        doc.setFontSize(8);
        doc.text("(*Tax not included)", 165, 120 + 1);

        // Title for Terms & Conditions
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Terms & Conditions", termsX + 5, termsY + 8); // Title inside box with padding

        // Reset font to normal for terms text
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        // Terms list (Bullets properly aligned)
        const terms = [
            `<b>Validity of Quote</b> - This quotation is valid for 7 working days from ${dateClnse7(StringToDate(Quotation_Date))} till ${dateClnse7(StringToDate(Valid_Until))}.`,
            "<b>Payment Terms</b> - Payment to be done before the plan is created.",
            "<b>Delivery</b> - Delivery will be completed within 72 hours, at which point the credits will be updated accordingly.",
            "<b>Changes and Revisions</b> - Changes and revisions after the quotation will lead to additional effort and cost.",
            "<b>Cancellation Policy</b> - Cancellations are not permitted once the service has been initiated.",
            "<b>Warranty/Guarantee</b> - The data provided is as-is basis only, and we do not offer any guarantee or warranty at this time.",
            "<b>Confidential</b> - All information shared between both parties will remain confidential.",
        ];

        let textY = termsY + 15; // Start position for text inside the box
        const lineSpacing = 5; // Space between each line

        // Wrap text inside the box and align properly
        terms.forEach((term, index) => {
            const bullet = `• `;
            const bulletWidth = doc.getTextWidth(bullet, { fontSize: 12 }); // Use correct fontSize

            // Split the term into bold and regular text parts
            const parts = term.split(/<b>(.*?)<\/b>/); // Split by <b>...</b> tags
            let currentX = termsX + 5 + bulletWidth;

            // Print bullet
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor('#000');
            doc.text(bullet, termsX + 5, textY);

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];
                if (i % 2 === 1) { // Bold part
                    doc.setFont('helvetica', 'bold');
                    doc.setFontSize(8);
                    doc.setTextColor('#000');
                    const wrappedBoldText = doc.splitTextToSize(part, termsWidth - currentX);
                    wrappedBoldText.forEach((line, lineIndex) => {
                        if (lineIndex > 0) {
                            textY += lineSpacing;
                            currentX = termsX + 5 + bulletWidth; // Reset X for new lines
                        }
                        doc.text(line, currentX, textY);
                        currentX += doc.getTextWidth(line, { fontSize: 8, fontWeight: 'bold' }); // Adjust X for next part
                    });

                } else { // Regular part
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(8);
                    doc.setTextColor('#000');
                    const wrappedRegularText = doc.splitTextToSize(part, termsWidth - currentX);
                    wrappedRegularText.forEach((line, lineIndex) => {
                        if (lineIndex > 0) {
                            textY += lineSpacing;
                            currentX = termsX + 5 + bulletWidth; // Reset X for new lines
                        }
                        doc.text(line, currentX, textY);
                        currentX += doc.getTextWidth(line, { fontSize: 8, fontWeight: 'normal' }); // Adjust X for next part
                    });
                }
            }
            textY += lineSpacing; // Space between terms
        });




        // Footer
        doc.setFillColor(0, 0, 0);
        // Draw bottom-left and bottom-right rounded corners
        doc.roundedRect(10, 265, 95, 12, 2, 2, "F"); // Left part
        doc.roundedRect(105, 265, 95, 12, 2, 2, "F"); // Right part
        doc.rect(10, 265, 190, 3, "F"); // Top straight line to connect the two rounded parts

        // Draw rounded rectangle in the middle
        const centerX = 10 + 190 / 2;
        const rectWidth = 50; // Adjust width as needed
        const rectHeight = 12; // Adjust height as needed
        const rectX = centerX - rectWidth / 2;
        const cornerRadiusX = 2; // Adjust corner radius X as needed
        const cornerRadiusY = 2; // Adjust corner radius Y as needed

        doc.roundedRect(rectX, 265, rectWidth, rectHeight, cornerRadiusX, cornerRadiusY, "F");

        doc.setFontSize(8);
        doc.setTextColor(255, 255, 255);

        doc.text("Follow us on:", 15, 270);
        addStyledImage(doc, linkedinImg, 35, 267, 6, 6, 1, 1);
        addStyledImage(doc, xImg, 45, 267, 6, 6, 1, 1);
        addStyledImage(doc, instagramImg, 55, 267, 6, 6, 1, 1);
        // doc.addImage(linkedinImg, 'PNG', 20, 200, 8, 8);
        // doc.addImage(xImg, 'PNG', 30, 270, 8, 8);
        // doc.addImage(xImg, 'PNG', 30, 270, 8, 8);
        // doc.addImage(instagramImg, 'PNG', 45, 270, 8, 8);
        doc.setFontSize(13);
        doc.text("©", 96, 271);
        doc.setFontSize(8);
        doc.text("Copyright 2025, All Rights Reserved by Brand Aura.", 100, 270);

        // doc.save(`Quotation-${Quotation_Id}.pdf`);
        const pdfBuffer = doc.output("blob");
        const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
        const Uint8Array2 = new Uint8Array(new ArrayBuffer(doc.output('arraybuffer')));
        const bloburl = URL.createObjectURL(blob)
        // console.log('bloburl', bloburl, blob, pdfBuffer, doc.output(), doc.output('arraybuffer'), doc.output('bloburl'), doc.output('bloburi'), doc.output('dataurl'), doc.output('datauri'));
        // const blobUrl1 = await uploadToAzureBlob(doc.output(), '007');
        // const blobUrl2 = await uploadToAzureBlob(doc.output('arraybuffer'), '007');
        // const blobUrl3 = await uploadToAzureBlob(pdfBuffer, '007');
        // const blobUrl4 = await uploadToAzureBlob(doc.output('bloburl'), '007');
        // const blobUrl5 = await uploadToAzureBlob(doc.output('bloburi'), '007');
        // const blobUrl6 = await uploadToAzureBlob(doc.output('dataurl'), '007');
        // const blobUrl7 = await uploadToAzureBlob(doc.output('datauri'), '007');
        // const blobUrl8 = await uploadToAzureBlob(bloburl, '007');
        // const blobUrl9 = await uploadToAzureBlob(Uint8Array2, '007');
        // const base64Image = extractBase64Data(pdfBuffer);
        // const blob2 = base64ToBlob(base64Image, mime);
        const mime = "application/pdf";
        const file = new File([blob], "abc.pdf", { type: mime });
        const d = { ...prop, paymentFor: 'customplan' }
        let parmUrl = sendData(d)
        console.log('paramurl', parmUrl, d);
        {/* <a href='${window.location.origin + `/click-pay?price=${encodeURIComponent(Plan_cost)}&user_id=${encodeURIComponent(user_id)}&plan_id=${encodeURIComponent(0)}`}'><button>Pay Now</button></a> */ }
        const html = `

        <body style="font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f9f9f9;">

            <div class="container"
                style="max-width: 700px; margin: 0 auto; padding: 20px; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);">
                <p style="margin-bottom: 5px;">Hi ${user_name},</p>
                <p style="margin-bottom: 5px;">Thank you for raising custom plan.</p>
                <p style="margin-bottom: 5px;">We have attached the quotation for the Custom Plan. To activate this Plan, a
                    payment of ${GetPriceWithSymbol(Math.round(Plan_cost))} is required.</p>

                <div
                    style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 20px; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <table width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="font-size: 1.5em; padding: 10px;">Quotation</td>
                            <td style="text-align: right;">
                                <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png"
                                    alt="Brand Aura Logo" style="height: 40px; margin-right: 5px;">
                            </td>
                        </tr>
                    </table>
                </div>

                <div style="width: 100%; margin-bottom: 20px;">
                    <table width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="width: 50%; padding: 10px;">
                                <h2 style="font-size: 1.1em; margin-bottom: 5px; ">Quotation Details</h2>
                                <p style="margin-bottom: 0;">Quotation Date: ${dateClnse7(StringToDate(Quotation_Date))}</p>
                                <p style="margin-bottom: 0;">Quotation ID: ${Quotation_Id}</p>
                                <p style="margin-bottom: 0;">Plan Name: ${plan_name}</p>
                            </td>
                            <td style="width: 50%; padding: 10px;">
                                <h2 style="font-size: 1.1em; margin-bottom: 5px;">User Details</h2>
                                <p style="margin-bottom: 0;">User ID: ${user_id}</p>
                                <p style="margin-bottom: 0;">User Name: ${user_name}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="plan-details" style="margin-bottom: 20px; overflow: hidden;">
                    <h2 style="font-size: 1.1em; margin-bottom: 10px;">Plan Details</h2>
                    <div style="border-radius: 6px; overflow: hidden;">
                        <table style="width: 100%; border-collapse: collapse; border-radius: 8px;">
                            <tr>
                                <td
                                    style="padding: 8px; background-color: #8A18D01A; text-align: left; font-weight: bold;">
                                    Configurations</td>
                                <td
                                    style="padding: 8px; background-color: #8A18D01A; text-align: left;">
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; text-align: left;">No. of Admin</td>
                                <td style="padding: 8px; text-align: left;">${No_of_Admin}</td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">
                                    No. of Users</td>
                                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">
                                    ${No_Of_User}</td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; text-align: left;">Credits for the plan (per
                                    month)</td>
                                <td style="padding: 8px; text-align: left;">${credits_for_the_plan}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="padding: 8px; background-color: #8A18D01A; text-align: left; font-weight: 600;">
                                    Plan Cost</td>
                                <td
                                    style="padding: 8px; background-color: #8A18D01A; text-align: left; font-weight: 600;">
                                    $ ${Plan_cost}</td>
                            </tr>
            <tr>
                <td style="font-weight: 600; font-size: 16px; color: #000;"></td>
                <td style="font-size: 11px; color: #000; text-align: end;">(*Tax not included)</td>
            </tr>
                        </table>
                    </div>
                </div>
    <a href='${parmUrl}' style="font-weight: 600; font-size: 14px; color: #8A18D0; text-decoration: underline; cursor: pointer;">Payment Link</a>
    

                <div class="terms-conditions" style="margin-bottom: 20px;">
                    <h2 style="font-size: 1.1em; margin-bottom: 10px;">Terms & Conditions</h2>
                    <ul style="padding-left: 20px; list-style-type: disc; margin: 0;">
                        <li style="margin-bottom: 5px;"><b>Validity of Quote</b> - This quotation is valid for 7 working days from
                            ${dateClnse7(StringToDate(Quotation_Date))} till ${dateClnse7(StringToDate(Valid_Until))}</li>
                        <li style="margin-bottom: 5px;"><b>Payment Terms</b> - Payment to be done before the plan is created.</li>
                        <li style="margin-bottom: 5px;"><b>Delivery</b> - Delivery will be completed within 72 hours, at which point
                            the credits will be updated accordingly.</li>
                        <li style="margin-bottom: 5px;"><b>Changes and Revisions</b> - Changes and revisions after the quotation will
                            lead to additional effort and cost.</li>
                        <li style="margin-bottom: 5px;"><b>Cancellation Policy</b> - Cancellations are not permitted once the service
                            has been initiated.</li>
                        <li style="margin-bottom: 5px;"><b>Warranty/Guarantee</b> - Please note that the data provided is as is basis
                            only, and we do not offer any guaranty or warranty at this time.</li>
                        <li style="margin-bottom: 5px;"><b>Confidential</b> - All information shared between both parties will remain
                            confidential.</li>
                    </ul>
                </div>

                <div class="footer"
                    style="background-color: #000; color: #fff; padding: 15px; border-top: 1px solid #eee; font-size: 0.8em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                    <!-- Table for Aligning Social Icons and Copyright -->
                    <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                        <tr>
                            <!-- Left Cell: Social Icons -->
                            <td style="text-align: left; vertical-align: middle;">
                                <span style="font-weight: bold;">Follow us on:</span>
                                <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
                                    <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image%20(1).png"
                                        alt="LinkedIn"
                                        style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
                                </a>
                                <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
                                    <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image%20(3).png"
                                        alt="X"
                                        style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
                                </a>
                                <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
                                    <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image.png"
                                        alt="Instagram"
                                        style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
                                </a>
                            </td>
                            <!-- Right Cell: Copyright -->
                            <td style="text-align: right; vertical-align: middle;">
                                <p style="margin: 0; font-size: 0.9em;">&copy; Copyright 2025, All Rights Reserved by Brand
                                    Aura.</p>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="thank-you" style="margin-top: 20px; ">
                    <p style="margin-bottom: 5px;">Thank you,</p>
                    <p style="margin-bottom: 0;">Brand Aura Team</p>
                </div>
            </div>

        </body>
        `
        await customPlanMailSending({
            pdf: file,
            message: response?.data?.message,
            filename: `Quotation-${response?.data?.Quotation_details?.Quotation_Id}.pdf`,
            Quotation_Id: response?.data?.Quotation_details?.Quotation_Id,
            quotationEmailSubject: `Quotation for ${response?.data?.Quotation_details?.Plan_Name} - ${response?.data?.Quotation_details?.Quotation_Id}`,
            quotationHtml: html
        })
        // console.log('blobUrl', bloburl);
        // console.log('blobUrl1', blobUrl1);
        // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
        // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
        // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);
        // console.log('blobUrl', bloburl, blobUrl1, blobUrl2, blobUrl3, blobUrl4, blobUrl5, blobUrl6, blobUrl7,);


        // return blob;
        // return file;
    };


    // const createPDF = async () => {
    //     const doc = new jsPDF({
    //         orientation: 'p',
    //         unit: 'mm',
    //         format: 'a4',
    //         putOnlyUsedFonts: true
    //     });

    //     // Preload images
    //     // const [logoImg, linkedinImg, xImg, instagramImg] = await Promise.all([
    //     //     loadImage('https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png'),
    //     //     loadImage('https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (1).png'),
    //     //     loadImage('https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (3).png'),
    //     //     loadImage('https://baecommerceimages.blob.core.windows.net/brandaura-logo/image.png')
    //     // ]);

    //     // Header
    //     doc.setFillColor(0, 0, 0);
    //     doc.rect(0, 0, 210, 30, 'F');

    //     // doc.addImage(logoImg, 'PNG', 160, 5, 40, 20);
    //     doc.setFont('helvetica', 'bold');
    //     doc.setTextColor(255, 255, 255);
    //     doc.setFontSize(14);
    //     doc.text('Quotation', 15, 15);

    //     // Plan Details
    //     doc.setFontSize(12);
    //     doc.setTextColor(0, 0, 0);
    //     doc.text('Plan Details', 15, 40);

    //     const planTable = {
    //         startY: 45,
    //         head: [['Configurations', '']],
    //         body: [
    //             ['No of Admin', '12'],
    //             ['No of Users', '12'],
    //             ['Credits for the plan (per month)', '12312'],
    //             ['Plan Cost', '$ 12321']
    //         ],
    //         theme: 'grid',
    //         headStyles: { fillColor: [249, 245, 249], textColor: 0, lineWidth: 0.5 },
    //         bodyStyles: { fillColor: [255, 255, 255], textColor: 0, lineWidth: 0.5 },
    //         alternateRowStyles: { fillColor: [249, 245, 249] }
    //     };

    //     // doc.autoTable(planTable);
    //     // autoTable(doc, { head: planTable.head, body: planTable.body })
    //     const tableData = autoTable(doc, {
    //         startY: planTable.startY,
    //         head: planTable.head,
    //         body: planTable.body,
    //         theme: planTable.theme,
    //         headStyles: planTable.headStyles,
    //         bodyStyles: planTable.bodyStyles,
    //         alternateRowStyles: planTable.alternateRowStyles
    //     });
    //     // doc.autoTable({
    //     //     startY: planTable.startY,
    //     //     head: planTable.head,
    //     //     body: planTable.body,
    //     //     theme: planTable.theme,
    //     //     headStyles: planTable.headStyles,
    //     //     bodyStyles: planTable.bodyStyles,
    //     //     alternateRowStyles: planTable.alternateRowStyles
    //     // });

    //     // console.log('tableData.finalY', doc.lastAutoTable.finalY);
    //     const tbHY = 85
    //     // const tbHY = tableData.finalY
    //     // Terms & Conditions
    //     doc.setTextColor(0, 0, 0);
    //     doc.text('Terms & Conditions', 15, tbHY + 10);

    //     const terms = [
    //         'Validity of Quote - This quotation is valid for 7 working days from 4/3/2025 till 11/3/2025',
    //         'Payment Terms - Payment to be done before the plan is created.',
    //         'Delivery - Delivery will be completed within 72 hours, at which point the credits will be updated accordingly.',
    //         'Changes and Revisions - Changes and revisions after the quotation will lead to additional effort and cost.',
    //         'Cancellation Policy - Cancellations are not permitted once the service has been initiated.',
    //         'Warranty/Guarantee - Please note that the data provided is as is basis only, and we do not offer any guaranty or warranty at this time.',
    //         'Confidential - All information shared between both parties will remain confidential.'
    //     ];

    //     terms.forEach((term, index) => {
    //         doc.text(`\u2022 ${term}`, 15, tbHY + 15 + (index * 5));
    //     });

    //     // Footer
    //     doc.setFillColor(0, 0, 0);
    //     doc.rect(0, 277, 210, 20, 'F');

    //     // Social Icons
    // doc.addImage(linkedinImg, 'PNG', 15, 280, 8, 8);
    // doc.addImage(xImg, 'PNG', 30, 280, 8, 8);
    // doc.addImage(instagramImg, 'PNG', 45, 280, 8, 8);

    //     doc.setFontSize(8);
    //     doc.setTextColor(255, 255, 255);
    //     doc.text('Follow us on:', 15, 285);

    //     doc.text('\u00A9 Copyright 2025, All Rights Reserved by Brand Aura.', 100, 285, { align: 'right' });

    //     doc.save('Quotation-Q-48222.pdf');
    // };

    // const loadImage = async (url) => {
    //     const img = new Image();
    //     img.crossOrigin = 'Anonymous';
    //     img.src = url;
    //     return new Promise(resolve => {
    //         img.onload = () => {
    //             const canvas = document.createElement('canvas');
    //             canvas.width = img.width;
    //             canvas.height = img.height;
    //             const ctx = canvas.getContext('2d');
    //             ctx.drawImage(img, 0, 0);
    //             resolve(canvas.toDataURL('image/png'));
    //         };
    //     });
    // };

    const createPDF2 = async () => {
        const pdfHTML = `<div class="header" style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 20px; border-top-left-radius: 10px; border-top-right-radius: 10px;">
        <table style="width: 100%; height: 60px; border-collapse: collapse;">
            <tr>
                <td style="text-align: left; vertical-align: middle;">
                    <div class="logo" style="font-size: 1.5em;">
                        <h2 style="font-size: 1.1em; color:#fff; margin-bottom: 5px;">Quotation</h2>
                    </div>
                </td>
                <td style="text-align: right; vertical-align: middle;">
                    <div class="brand">
                        <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png" alt="Brand Aura Logo" style="height: 40px; margin-right: 5px;">
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div style="width: 100%; margin-bottom: 20px;">
        <table width="100%" style="border-collapse: collapse;">
            <tr>
       
            </tr>
        </table>
    </div>

    <div class="plan-details" style="margin-bottom: 20px; border-radius: 10px; overflow: hidden;">
        <h2 style="font-size: 1.1em; margin-bottom: 10px;">Plan Details</h2>
        <table style="width: 100%; border-collapse: collapse;">
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;"><strong>Configurations</strong></td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;"></td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left;">No of Admin</td>
                <td style="padding: 8px; text-align: left;"></td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">No of Users</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;"></td>
            </tr>
            <tr>
                <td style="padding: 8px; text-align: left;">Credits for the plan (per month)</td>
                <td style="padding: 8px; text-align: left;"></td>
            </tr>
            <tr>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">Plan Cost</td>
                <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">$ </td>
            </tr>
        </table>
    </div>

    <div class="terms-conditions" style="margin-bottom: 20px;">
        <h2 style="font-size: 1.1em; margin-bottom: 10px;">Terms & Conditions</h2>
        <ul style="padding-left: 20px; list-style-type: disc; margin: 0;">
            <li style="margin-bottom: 5px;">Validity of Quote - This quotation is valid for 7 working days from </li>
            <li style="margin-bottom: 5px;">Payment Terms - Payment to be done before the plan is created.</li>
            <li style="margin-bottom: 5px;">Delivery - Delivery will be completed within 72 hours, at which point the credits will be updated accordingly.</li>
            <li style="margin-bottom: 5px;">Changes and Revisions - Changes and revisions after the quotation will lead to additional effort and cost.</li>
            <li style="margin-bottom: 5px;">Cancellation Policy - Cancellations are not permitted once the service has been initiated.</li>
            <li style="margin-bottom: 5px;">Warranty/Guarantee - Please note that the data provided is as is basis only, and we do not offer any guaranty or warranty at this time.</li>
            <li style="margin-bottom: 5px;">Confidential - All information shared between both parties will remain confidential.</li>
        </ul>
    </div>

    <div class="footer" style="margin-top: 20px; background-color: #000000; color: #ffffff; padding: 10px; border-top: 1px solid #eee; font-size: 0.8em; height: 60px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <table style="width: 100%; height: 100%; border-collapse: collapse;">
            <tr>
                <td style="text-align: left; vertical-align: middle;">
                    <div class="social-icons">
                        <span style="color: #ffffff; ">Follow us on:</span>
                        <!-- Replace with actual image URLs -->
                        <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (1).png" alt="LinkedIn" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                        <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (3).png" alt="X" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                        <a href="#" style="color: #777; display: inline-block; margin-right: 2px;"><img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image.png" alt="Instagram" style="width: 20px; height: 20px; background-color: white; border-radius: 3px; padding: 2px; vertical-align: middle;"></a>
                    </div>
                </td>
                <td style="text-align: right; vertical-align: middle;">
                    <div class="copyright">
                        <p style="margin: 0; color: #ffffff;">&copy; Copyright 2025, All Rights Reserved by Brand Aura.</p>
                    </div>
                </td>
            </tr>
        </table>
    </div>`;

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = pdfHTML;
        document.body.appendChild(tempDiv);
        const pdfOptions = {
            format: 'A4',
            orientation: 'portrait',
            border: '10mm',
            header: { height: '5mm' },
            footer: { height: '5mm' },
        };

        const canvas = await html2canvas(tempDiv, {
            scale: 2, // Increase resolution (optional)
        });
        const imgData = canvas.toDataURL("image/png");

        // Create a new jsPDF instance
        const pdf = new jsPDF({
            format: "a4",
            orientation: "portrait",
            unit: "mm",
        });

        // Calculate dimensions for the PDF
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Save the PDF
        pdf.save("Quotation.pdf");
        // pdf.create(pdfHTML, pdfOptions).toBuffer(async function (err, buffer) {
        //     if (err) {
        //         console.error('Error creating PDF:', err);
        //         return res.status(500).json({ error: 'Error generating PDF.' });
        //     }

        //     // Upload the PDF to Azure Blob Storage
        //     const blobUrl = await uploadToAzureBlob(buffer, 'xxx01');


        //     // const attachments = [
        //     //     {
        //     //         filename: `Quotation-${quotation_id}.pdf`,
        //     //         content: buffer,
        //     //         contentType: 'application/pdf',
        //     //     },
        //     // ];
        //     console.log('blobUrl', blobUrl)
        // })
    }

    const handleCreate = () => {
        if (validateForm()) {
            customPlan()
        }
    }
    const customPlanMailSending = async ({ pdf, message, filename, Quotation_Id, quotationEmailSubject, quotationHtml }) => {
        // const payload = {
        //     user_name: user?.user_first_name,
        //     plan_name: formData?.planName,
        //     No_of_Admin: formData?.noOfAdmin,
        //     No_Of_User: formData?.noOfUsers,
        //     credits_for_the_plan: formData?.creditsForThePlan,
        //     Plan_cost: formData?.planCost,
        //     pdf: pdf
        // }

        // const mime = "application/pdf";
        // const file = new File([pdf], "abc.pdf", { type: mime });
        const formData = new FormData();
        formData.append('mail', user?.user_email);
        formData.append('filename', filename);
        formData.append('message', message);
        formData.append('quotationEmailSubject', quotationEmailSubject);
        formData.append('quotationHtml', quotationHtml);
        formData.append('Quotation_Id', Quotation_Id);
        // formData.append('Plan_cost', formData?.planCost);
        formData.append('pdf', pdf);
        // formData.append('pdf', file);
        try {
            const response = await http.post("/master_service/admin_panel/custom_plan_mail_sending", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the correct content type
                },
            });
            console.log("response tbl manage_user:", response?.data, response?.data?.manage_user, response?.data?.user_table);
            // const pdfBuffer = createPDF({ prop: response?.data })
            // const blobUrl = await uploadToAzureBlob(pdfBuffer, '007');
            // dispatch(setAllUsers(response?.data?.user_table))
            // setTblData(response?.data?.user_table)
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            dispatch(setMessage(response?.data?.message || 'Custom Plan, Created Successfully'));
            dispatch(setSeverity('success'));
            dispatch(handleClick())
            setLoad(false)
        }
        catch (error) {
            console.error("Error creating Custom Plan:", error)
            dispatch(setMessage(error?.response?.data?.message || 'Error Creating Custom Plan'));
            dispatch(setSeverity('error'));
            dispatch(handleClick())
            setLoad(false)
        }
    }
    const customPlan = async () => {
        const payload = {
            user_email: user?.user_email,
            plan_name: formData?.planName,
            No_of_Admin: formData?.noOfAdmin,
            No_Of_User: formData?.noOfUsers,
            credits_for_the_plan: formData?.creditsForThePlan,
            Plan_cost: formData?.planCost
        }
        setLoad(true)
        try {
            const response = await http.post("/master_service/admin_panel/create_custom_plan", JSON.stringify(payload));
            console.log("response customPlan:", response?.data, response?.data?.manage_user, response?.data?.user_table);
            const d = { ...response?.data?.Plan_Details, ...response?.data?.Quotation_details, ...response?.data?.User_details, }
            const pdfBuffer = createPDF({ prop: d, response: response })
            // const { Quotation_Date, Quotation_Id, Valid_Until, Plan_Name, user_id, recharge_request_id, plan_name, user_name,
            //     No_of_Admin,
            //     No_Of_User,
            //     credits_for_the_plan,
            //     Plan_cost, } = d
            //             const html = `

            // <body style="font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f9f9f9;">

            //     <div class="container"
            //         style="max-width: 700px; margin: 0 auto; padding: 20px; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);">
            //         <p style="margin-bottom: 5px;">Hi ${user_name},</p>
            //         <p style="margin-bottom: 5px;">Thank you for raising custom plan.</p>
            //         <p style="margin-bottom: 5px;">We have attached the quotation for the Custom Plan. To activate this Plan, a
            //             payment of ${Plan_cost} is required.</p>

            //         <div
            //             style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 20px; border-top-left-radius: 10px; border-top-right-radius: 10px;">
            //             <table width="100%" style="border-collapse: collapse;">
            //                 <tr>
            //                     <td style="font-size: 1.5em; padding: 10px;">Quotation</td>
            //                     <td style="text-align: right;">
            //                         <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png"
            //                             alt="Brand Aura Logo" style="height: 40px; margin-right: 5px;">
            //                     </td>
            //                 </tr>
            //             </table>
            //         </div>

            //         <div style="width: 100%; margin-bottom: 20px;">
            //             <table width="100%" style="border-collapse: collapse;">
            //                 <tr>
            //                     <td style="width: 50%; padding: 10px;">
            //                         <h2 style="font-size: 1.1em; margin-bottom: 5px; ">Quotation Details</h2>
            //                         <p style="margin-bottom: 0;">Quotation Date: ${Quotation_Date}</p>
            //                         <p style="margin-bottom: 0;">Quotation ID: ${Quotation_Id}</p>
            //                         <p style="margin-bottom: 0;">Plan Name: ${plan_name}</p>
            //                     </td>
            //                     <td style="width: 50%; padding: 10px;">
            //                         <h2 style="font-size: 1.1em; margin-bottom: 5px;">User Details</h2>
            //                         <p style="margin-bottom: 0;">User ID: ${user_id}</p>
            //                         <p style="margin-bottom: 0;">User Name: ${user_name}</p>
            //                     </td>
            //                 </tr>
            //             </table>
            //         </div>
            //         <div class="plan-details" style="margin-bottom: 20px; overflow: hidden;">
            //             <h2 style="font-size: 1.1em; margin-bottom: 10px;">Plan Details</h2>
            //             <div style="border-radius: 6px; overflow: hidden;">
            //                 <table style="width: 100%; border-collapse: collapse; border-radius: inherit;">
            //                     <tr>
            //                         <td
            //                             style="padding: 8px; background-color: #8A18D01A; text-align: left; font-weight: bold;">
            //                             Configurations</td>
            //                         <td
            //                             style="padding: 8px; background-color: #8A18D01A; text-align: left;">
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td style="padding: 8px; text-align: left;">No. of Admin</td>
            //                         <td style="padding: 8px; text-align: left;">${No_of_Admin}</td>
            //                     </tr>
            //                     <tr>
            //                         <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">
            //                             No. of Users</td>
            //                         <td style="padding: 8px; background-color: #FAFAFA; text-align: left;">
            //                             ${No_Of_User}</td>
            //                     </tr>
            //                     <tr>
            //                         <td style="padding: 8px; text-align: left;">Credits for the plan (per
            //                             month)</td>
            //                         <td style="padding: 8px; text-align: left;">${credits_for_the_plan}
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td
            //                             style="padding: 8px; background-color: #8A18D01A; text-align: left;">
            //                             Plan Cost</td>
            //                         <td
            //                             style="padding: 8px; background-color: #8A18D01A; text-align: left;">
            //                             $ ${Plan_cost}</td>
            //                     </tr>
            //                 </table>
            //             </div>
            //         </div>

            //         <div class="terms-conditions" style="margin-bottom: 20px;">
            //             <h2 style="font-size: 1.1em; margin-bottom: 10px;">Terms & Conditions</h2>
            //             <ul style="padding-left: 20px; list-style-type: disc; margin: 0;">
            //                 <li style="margin-bottom: 5px;">Validity of Quote - This quotation is valid for 7 working days from
            //                     ${Quotation_Date} till ${Valid_Until}</li>
            //                 <li style="margin-bottom: 5px;">Payment Terms - Payment to be done before the plan is created.</li>
            //                 <li style="margin-bottom: 5px;">Delivery - Delivery will be completed within 72 hours, at which point
            //                     the credits will be updated accordingly.</li>
            //                 <li style="margin-bottom: 5px;">Changes and Revisions - Changes and revisions after the quotation will
            //                     lead to additional effort and cost.</li>
            //                 <li style="margin-bottom: 5px;">Cancellation Policy - Cancellations are not permitted once the service
            //                     has been initiated.</li>
            //                 <li style="margin-bottom: 5px;">Warranty/Guarantee - Please note that the data provided is as is basis
            //                     only, and we do not offer any guaranty or warranty at this time.</li>
            //                 <li style="margin-bottom: 5px;">Confidential - All information shared between both parties will remain
            //                     confidential.</li>
            //             </ul>
            //         </div>

            //         <div class="footer"
            //             style="background-color: #000; color: #fff; padding: 15px; border-top: 1px solid #eee; font-size: 0.8em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
            //             <!-- Table for Aligning Social Icons and Copyright -->
            //             <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
            //                 <tr>
            //                     <!-- Left Cell: Social Icons -->
            //                     <td style="text-align: left; vertical-align: middle;">
            //                         <span style="font-weight: bold;">Follow us on:</span>
            //                         <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
            //                             <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (1).png"
            //                                 alt="LinkedIn"
            //                                 style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
            //                         </a>
            //                         <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
            //                             <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image (3).png"
            //                                 alt="X"
            //                                 style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
            //                         </a>
            //                         <a href="#" style="color: #777; display: inline-block; margin-right: 10px;">
            //                             <img src="https://baecommerceimages.blob.core.windows.net/brandaura-logo/image.png"
            //                                 alt="Instagram"
            //                                 style="width: 24px; height: 24px; background-color: white; border-radius: 4px; padding: 2px; vertical-align: middle;">
            //                         </a>
            //                     </td>
            //                     <!-- Right Cell: Copyright -->
            //                     <td style="text-align: right; vertical-align: middle;">
            //                         <p style="margin: 0; font-size: 0.9em;">&copy; Copyright 2025, All Rights Reserved by Brand
            //                             Aura.</p>
            //                     </td>
            //                 </tr>
            //             </table>
            //         </div>

            //         <div class="thank-you" style="margin-top: 20px; ">
            //             <p style="margin-bottom: 5px;">Thank you,</p>
            //             <p style="margin-bottom: 0;">Brand Aura Team</p>
            //         </div>
            //     </div>

            // </body>
            // `
            // const blobUrl = await uploadToAzureBlob(pdfBuffer, '007');
            // await customPlanMailSending({
            //     pdf: pdfBuffer,
            //     filename: `Quotation-${response?.data?.Quotation_details?.Quotation_Id}.pdf`,
            //     Quotation_Id: response?.data?.Quotation_details?.Quotation_Id,
            //     quotationEmailSubject: `Quotation for ${response?.data?.Quotation_details?.Plan_Name} - ${response?.data?.Quotation_details?.Quotation_Id}`,
            //     quotationHtml: html
            // })
            // console.log('blobUrl', blobUrl, pdfBuffer)
            // dispatch(setAllUsers(response?.data?.user_table))
            // setTblData(response?.data?.user_table)
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
            // dispatch(setMessage(response?.data?.message || 'Custom Plan, Created Successfully'));
            // dispatch(setSeverity('success'));
            // dispatch(handleClick())
        }
        catch (error) {
            console.error("Error creating Custom Plan:", error)
            dispatch(setMessage(error?.response?.data?.message || 'Error Creating Custom Plan'));
            dispatch(setSeverity('error'));
            dispatch(handleClick())
            setLoad(false)
        }
    }
    useEffect(() => {
        fetchDatas()
    }, [])
    const samplePdfGenProp = {
        outputType: "save",
        returnJsPDFDocObject: true,
        fileName: "Invoice 2022",
        orientationLandscape: false,
        compress: true,
        logo: {
            src: logoImg,
            // src: "https://baecommerceimages.blob.core.windows.net/brandaura-logo/Brand%20Aura.png",
            width: 30, //aspect ratio = width/height
            height: 10,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        // stamp: {
        //     inAllPages: true,
        //     src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
        //     width: 20, //aspect ratio = width/height
        //     height: 20,
        //     margin: {
        //         top: 0, //negative or positive num, from the current position
        //         left: 0 //negative or positive num, from the current position
        //     }
        // },
        business: {
            name: "Brand Aura",
            address: "Bandapura Village Road, Bangalore,\nChandapura, Anekal Tk,\nBangalore - 562107, Karnataka, India.",
            // phone: "+91 069 11 11 111",
            email: "email@example.com",
            email_1: "info@example.al",
            website: "www.example.al",
        },
        contact: {
            label: "Invoice issued for:",
            name: "Trump",
            address: "abc street, def area, Chennai, India, 600007",
            phone: "+91 069 11 11 111",
            email: "client@website.al",
            otherInfo: "www.website.al",
        },
        invoice: {
            label: "Invoice No: ",
            num: 19,
            invDate: "Payment Date: 01/04/2025 18:12",
            invGenDate: "Invoice Date: 01/04/2025 18:12",
            headerBorder: true,
            tableBodyBorder: true,
            header: [
                {
                    title: "Sl No",
                    style: {
                        width: 10
                    }
                },
                {
                    title: "Title",
                    style: {
                        width: 30
                    }
                },
                {
                    title: "Description",
                    style: {
                        width: 80
                    }
                },
                { title: "Price" },
                { title: "Quantity" },
                { title: "Unit" },
                { title: "Total" }
            ],
            table: Array.from(Array(1), (item, index) => ([
                index + 1,
                "name",
                "description",
                200.5,
                4.5,
                "m2",
                400.5
            ])),
            additionalRows: [{
                col1: 'Total:',
                col2: '145,250.50',
                // col3: 'ALL',
                style: {
                    fontSize: 14 //optional, default 12
                }
            },
            // {
            //     col1: 'VAT:',
            //     col2: '20',
            //     col3: '%',
            //     style: {
            //         fontSize: 10 //optional, default 12
            //     }
            // },
            // {
            //     col1: 'SubTotal:',
            //     col2: '116,199.90',
            //     col3: 'ALL',
            //     style: {
            //         fontSize: 10 //optional, default 12
            //     }
            // }
            ],

            // invDescLabel: "Invoice Note",
            // invDesc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
        },
        footer: {
            text: "The invoice is created on a computer and is valid without the signature and stamp.",
        },
        pageEnable: true,
        pageLabel: "Page ",
    }
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Back prop={{ txt: 'Custom Plan' }} />
            <Box sx={{ width: '100%', }}>
                <Box sx={{ width: '100%', }}>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>User*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <UserDropDown value={user}
                                onChange={(e) => {
                                    setUser(e);
                                }}
                                required={true} sx={{ height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272", color: "#727272" }} />
                            {errors.user && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.user}</Typography>}
                        </Box>
                        {/* <Select
                            value={formData?.user}
                            id="user"
                            onChange={(event) =>
                                handleFieldChange(event)
                            }
                            displayEmpty
                            sx={{
                                height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272", color: "#727272"
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={undefined}>Select Users</MenuItem>
                        </Select>
                        <TextField
                            value={formData?.user}
                            id="user"
                            onChange={(event) =>
                                handleFieldChange(event)
                            }
                            InputProps={{ sx: { height: "31px", width: '100%',fontSize: '12px',borderColor: "#727272" } }}
                            sx={{ width: '100%', color: "#727272" }}
                        /> */}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>Plan Name*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                value={formData?.planName}
                                id="planName"
                                placeholder="Enter Plan Name"
                                onChange={(event) =>
                                    handleFieldChange(event)
                                }
                                InputProps={{ sx: { height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
                                sx={{ width: '100%', color: "#727272" }}
                            />
                            {errors.planName && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.planName}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>No Of Admin*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                value={formData?.noOfAdmin}
                                id="noOfAdmin"
                                type="number"
                                placeholder="Enter No of Admin"
                                onKeyPress={(e) => {
                                    if (e.key === "-" || e.key === "+") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    console.log('event.target.value', event.target.value);
                                    if (event.target.value >= 1 || event.target.value === '') {
                                        handleFieldChange(event)
                                    }
                                }}
                                InputProps={{ sx: { height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
                                sx={{ width: '100%', color: "#727272", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", }, "& input[type=number]": { MozAppearance: "textfield", }, }}
                            />
                            {errors.noOfAdmin && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.noOfAdmin}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>No Of Users*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                value={formData?.noOfUsers}
                                id="noOfUsers"
                                type="number"
                                placeholder="Enter No of Users"
                                onKeyPress={(e) => {
                                    if (e.key === "-" || e.key === "+") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.target.value >= 1 || event.target.value === '') {
                                        handleFieldChange(event)
                                    }
                                }}
                                InputProps={{ sx: { height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
                                sx={{ width: '100%', color: "#727272", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", }, "& input[type=number]": { MozAppearance: "textfield", }, }}
                            />
                            {errors.noOfUsers && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.noOfUsers}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>Credits for the Plan*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                value={formData?.creditsForThePlan}
                                id="creditsForThePlan"
                                placeholder="Enter Credit count"
                                type="number"
                                onKeyPress={(e) => {
                                    if (e.key === "-" || e.key === "+") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.target.value >= 1 || event.target.value === '') {
                                        handleFieldChange(event)
                                    }
                                }}
                                InputProps={{ sx: { height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
                                sx={{ width: '100%', color: "#727272", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", }, "& input[type=number]": { MozAppearance: "textfield", }, }}
                            />
                            {errors.creditsForThePlan && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.creditsForThePlan}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2, width: '100%' }}>
                        <Typography sx={{ minWidth: { xs: 'max-content', md: '200px' }, fontSize: '14px', fontWeight: '600' }}>Plan Cost*</Typography>
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                value={formData?.planCost}
                                id="planCost"
                                placeholder="Enter Plan cost"
                                type="number"
                                onKeyPress={(e) => {
                                    if (e.key === "-" || e.key === "+") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.target.value >= 1 || event.target.value === '') {
                                        handleFieldChange(event)
                                    }
                                }}
                                InputProps={{ sx: { height: "31px", width: '100%', fontSize: '12px', borderColor: "#727272" } }}
                                sx={{ width: '100%', color: "#727272", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", }, "& input[type=number]": { MozAppearance: "textfield", }, }}
                            />
                            {errors.planCost && <Typography color="error" sx={{ mt: 1, fontSize: '12px' }}>{errors.planCost}</Typography>}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    {/* <Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }} onClick={() => createPDF({ prop: {}, response: {} })}>Create PDF</Button> */}
                    {/* <Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", mx: 1, textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }} onClick={() => jsPDFInvoiceTemplate(samplePdfGenProp)}>Create PDF</Button> */}
                    <Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff', borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }} onClick={handleCreate} disabled={load}>
                        Create Plan
                        <Box>
                            {load && (
                                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                                    <Box sx={BtnLoad} />
                                </Box>
                            )}
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
