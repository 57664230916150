import { Box, Button, Fade, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import { ForwardIcon } from "../../utils/icon/AdminPage/Icon"
import { useEffect, useState } from "react"
import './NotifiCard.css'
import http from '../../utils/http-common'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { LogoutSvg, SettingsSvg, SupportSvg, ThemeSvg, UserSvg } from "../../utils/icon/IndividualPages/Icon"
import { IIcn } from "../../utils/icon/LandingPage/Icon"
import { setImage } from "../../slice/ProfilePic"
import { clearToken } from "../../slice/Token"
import Cookies from 'js-cookie';


export const SellerReviewCard = () => {
    return (
        <Box></Box>
    )
}
export const NotifiCategoryCard = ({ p }) => {
    const { cat, setCat, i, res } = p;
    const len = res?.filter((e) => e?.categoryFilter === i)
    // console.log('ii', i, len?.length)
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', p: 2, my: 1, borderRadius: '4px', background: cat === i ? '#8A18D0' : '#FAFAFA', color: cat === i ? '#fff' : '#000', border: '0.8px solid #E1E2E6' }} onClick={() => setCat(i)}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <p.ikn />
                <Box sx={{ pl: 2 }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', pb: 1, }}>{p?.text}</Typography>
                    <Typography sx={{ fontSize: '8px', fontWeight: '400', }}>{len?.length} Notification</Typography>
                </Box>
            </Box>
            <Button disableFocusRipple disableRipple disableTouchRipple sx={{ minWidth: 'max-content', width: 'max-content' }}><ForwardIcon /></Button>
        </Box>
    )
}
export const NotifyCard = ({ p, swap }) => {
    const [showDetails, setShowDetails] = useState(false);
    // console.log('p', p)

    return (
        <Box
            sx={{
                borderRadius: '8px',
                my: 1,
                background: '#FAFAFA',
                border: '0.8px solid #BCBFC766',
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    p: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* {p?.ikn} */}
                    <Box
                        sx={{
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            background: "#000",
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        {swap === 'Brand' ? p?.text?.charAt?.(0)?.toUpperCase() : p?.EcommName?.charAt?.(0)?.toUpperCase()}
                    </Box>
                    <Box sx={{ pl: 2 }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000', pb: 1 }}>{swap === 'Brand' ? p?.text : p?.EcommName}</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p?.subText}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>{p?.time}</Typography>
                    <Button disableTouchRipple disableFocusRipple disableGutters
                        sx={{
                            fontSize: '10px',
                            fontWeight: '400',
                            color: '#000',
                            textTransform: 'capitalize',
                            padding: 0,
                            '&:hover': { background: 'transparent' },
                            // minWidth: 'auto'
                        }}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {!showDetails ? 'More' : 'Less'}
                    </Button>
                </Box>
            </Box>
            {/* {showDetails && ( */}
            <Box
                className={`details ${showDetails ? 'show' : 'hide'}`}
                sx={{
                    borderTop: '2px solid #E1E2E6',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // p: 2,
                }}
            >
                <Box>
                    <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>Product Name :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000', maxWidth: '70%', textWrap: 'wrap' }}>{p?.prodName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>{swap === 'Brand' ? 'Brand' : 'E-Commerce Name'} :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{swap === 'Brand' ? p?.text : p?.EcommName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>Seller :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p?.Seller}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', textWrap: 'nowrap' }}>Product Price</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#000',
                            p: 1,
                            background: '#e3c9f2',
                            textAlign: 'center',
                            borderRadius: '3px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {p?.prodPrice}
                    </Typography>
                </Box>
            </Box>
            {/* )} */}
        </Box>
    );
};


export const ProfileCard = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const profileImage = useSelector((state) => state.profile.ProfilePic);
    // const [profileImage, setProfileImage] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = localStorage.getItem('email');
    const userName = localStorage.getItem('user_name');
    const user_id = localStorage.getItem('user_id');

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleLogout = () => {
        // Clear local storage
        localStorage.clear();

        // Clear cookies
        Object.keys(Cookies.get()).forEach((cookieName) => {
            Cookies.remove(cookieName);
        });

        // Dispatch logout action
        dispatch(clearToken());

        // Redirect to the home page
        // navigate('/');
    };
    const settings = [
        {
            title: 'Dashboard',
            icn: <UserSvg />,
            link:
                localStorage.getItem('user_type') === 'company'
                    ? localStorage.getItem('user_role') === 'Admin User'
                        ? '/admin'
                        : '/user'
                    : '/dashboard/individual-dashboard',
            active: true,
        },
        {
            title: 'Log out',
            icn: <LogoutSvg />,
            fn: handleLogout,
            link: '',
            active: true,
        },
    ];
    const fetchImage = async () => {
        try {
            const response = await http.get(`/master_service/get_image/${user_id}`);
            dispatch(setImage(response.data.imageUrl));
        } catch (error) {
            console.log("Fetching img failed", error);
        }
    };

    useEffect(() => {
        fetchImage();
    }, []);


    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
                <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}>
                    {profileImage ? (
                        <Box
                            component="img"
                            src={profileImage}
                            alt="Profile"
                            sx={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                background: "#000",
                                color: "#fff",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            {userName && userName.charAt(0).toUpperCase()}
                        </Box>
                    )}
                </IconButton>
            </Tooltip>

            <Menu
                sx={{ mt: '45px', minWidth: '200px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                TransitionComponent={Fade}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}>
                    {profileImage ? (
                        <Box
                            component="img"
                            src={profileImage}
                            alt="Profile"
                            sx={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                background: "#000",
                                color: "#fff",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            {userName && userName.charAt(0).toUpperCase()}
                        </Box>
                    )}
                    <Box sx={{ marginLeft: '10px' }}>
                        <Typography
                            sx={{ fontWeight: '500', fontSize: '16px', color: '#344054' }}>
                            {userName}
                        </Typography>
                        <Typography
                            sx={{ fontWeight: '400', fontSize: '14px', color: '#667085' }}>
                            {email}
                        </Typography>
                    </Box>
                </Box>
                {settings.map((setting, index) => (
                    <MenuItem
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 20px',
                            color: setting.active ? '#344054' : 'lightgrey',
                            cursor: setting.active ? 'pointer' : 'default',
                            pointerEvents: setting.active ? 'auto' : 'none',
                            '& svg': {
                                color: setting.active ? '#344054' : 'lightgrey',
                            },
                        }}
                        onClick={() => {
                            if (setting.fn) setting.fn();
                            if (setting.link) navigate(setting.link);
                            handleCloseUserMenu();
                        }}>
                        {setting.icn}
                        <Typography sx={{ pl: 2, fontWeight: '500', fontSize: '14px' }}>
                            {setting.title}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export const NoteComponent = ({ prop }) => {
    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', background: '#FFF6E5', p:1, borderRadius: '8px' }}>
            <IIcn />
            <Typography sx={{ color: '#040815', fontSize: '12px', fontWeight: '600', px: 1 }}>Note: </Typography>
            <Typography sx={{ color: '#474747CC', fontSize: '12px', fontWeight: '400' }}>{prop?.txt}</Typography>
        </Box>
    );
}