import { Box } from "@mui/material";
import { HeaderFooter } from "../../../../components/nav/NormalHeaderFooter";
import { Checkout } from "./components";


export const DataMartCheckout = () => {
    return (
        <HeaderFooter>
            <Checkout />
        </HeaderFooter>
    );
}