import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../../../utils/icon/IndividualPages/Icon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BasicCard from "../../../../components/card/AdminCard";
import { CorporateNormalUserTable } from "../../../../components/@extended/ModifiedTable";
import http from "../../../../utils/http-common";
import { AdminDashboardCredit, ConvertWebScrapping, useAdminCreditPlanRequest } from "../../../../components/@extended/CustomFunctions";
import { FilterList } from "@mui/icons-material";
import { FilterIkn } from "../../../../utils/icon/BrandAuraAdminPage/Icon";



export const BrandAuraAdminWebScrapping = () => {
    const [searchTermCredit, setSearchTermCredit] = useState(null);
    const [searchTermPlan, setSearchTermPlan] = useState(null);
    const [filter, setFilter] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [tblData, setTblData] = useState([]);
    const [cardDatas, setCardDatas] = useState(
        {
            totalDataScrapped: 0,
            dataScrappedToday: 0,
        }
    );
    const fetchDatas = async () => {
        try {
            const response = await http.get("/master_service/admin_panel/web_scrapping");
            console.log("response tbl manage_user:", response?.data, response?.data?.web_scrapping);
            setTblData(response?.data?.log)
            setCardDatas((p) => ({ ...p, totalDataScrapped: response?.data?.web_scrapping?.Total_data_scrapped, dataScrappedToday: response?.data?.web_scrapping?.Data_scrapped_today }))
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => {
        fetchDatas()
    }, [])
    const tblP = ConvertWebScrapping({ d: tblData })
    // console.log('tbl', tblC, tblP)
    // const filteredDataC = tblC;
    // console.log('filteredDataC', filteredDataC)
    // const filteredDataP = tblP;
    const filteredDataP = tblP?.sort((a, b) => b?.sl_no - a?.sl_no)?.filter((k) => {
        if (!k) return false;

        const searchTerm = String(searchTermPlan ?? '')?.toLowerCase();
        // const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = [
            k?.script_run_date,
            k?.country,
            k?.script_name,
            k?.data_inserted_into_DB,
            k?.scrapping_status
        ].some(field =>
            String(field ?? '').toLowerCase().includes(searchTerm)
        );
        const filtMatch = filter === null || filter === 'all' || filter === k?.scrapping_status?.props?.txt
        return matchesSearch && filtMatch;
    });
    const propsPlan = { filteredData: filteredDataP, rowsPerPage, page, setPage }
    return (
        <Box sx={{ p: 2 }}>
            <Typography sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}>Dashboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2, gap: 1 }}>
                        <BasicCard title='Total Data Scrapped' count={cardDatas?.totalDataScrapped} width={{ xs: '100%', md: '25%' }} />
                        <BasicCard title='Data Scrapped Today' count={cardDatas?.dataScrappedToday} width={{ xs: '100%', md: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ color: '#000000', fontSize: '16px', fontWeight: '600' }}>Log</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '0.8px solid #797F8F80', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '250px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px', }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, }, }} placeholder='Search...' variant='outlined' value={searchTermPlan} onChange={(e) => setSearchTermPlan(e.target.value)} />
                            </Box>

                            <Select
                                displayEmpty
                                size='small'
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                inputProps={{ IconComponent: () => null }}
                                // startAdornment={
                                // }
                                sx={{
                                    width: "150px",
                                    height: "40px",
                                    "& .MuiSelect-select": {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: filter === null ? 'end' : 'center',
                                        pr: '5px !important',
                                        pl: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#D0D5DD",
                                    },
                                }}>
                                <MenuItem sx={{ display: 'none' }} value={null} disabled>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                        <FilterIkn />
                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Filter</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value='all' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>All</MenuItem>
                                <MenuItem value='Success' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Success</MenuItem>
                                <MenuItem value='Failed' sx={{ fontSize: '12px', fontWeight: '400', color: '#797F8F' }}>Failed</MenuItem>
                            </Select>
                            {/* <Link to={'/admin/dashboard/data-mart/upload-bundles'}><Button disableElevation disableFocusRipple disableRipple sx={{ background: "#8A18D0", color: "#fff", borderRadius: "4px", textWrap: "nowrap", textTransform: "capitalize", "&:hover": { background: "#8A18D0", }, }}>Upload Bundle</Button></Link> */}
                        </Box>
                    </Box>
                    <CorporateNormalUserTable prop={propsPlan} />
                </Box>
            </Box>
        </Box>
    )
}
