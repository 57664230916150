import { Box, Button, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import http from '../../../../../utils/http-common';
import { renderIcon } from '../../../../../utils/icon/LandingPage/Icon';
import { CP_Icon } from '../../../../../utils/icon/AdminPage/Icon';
import { BtnLoad } from '../../../../../utils/data/LandingPage/Data';
import { useRazorpayPayment } from '../../../../../components/@extended/Payment';
import { GetPriceWithOutSymbol, GetPriceWithSymbolNoDecimal } from '../../../../../components/@extended/CurrencyConversion';
import { returnInvoiceGeneratingPayload } from '../../../../../utils/data/IndividualDashboard/Data';
import jsPDFInvoiceTemplate from '../../../../../components/pdf-generation/Invoice';
import { formatPhoneNumber } from '../../../../../components/@extended/CustomFunctions';

const OurPlans = ({ Plans, id = null, need = true }) => {
  const seq = localStorage.getItem('seq');
  const RazorpayPayment = useRazorpayPayment()
  const [currPlan, setCurrPlan] = useState(
    window.location.pathname === '/pricing' ? -1 : Number(seq ? seq : -1)
  );
  const [clickedPlan, setClickedPlan] = useState(
    window.location.pathname === '/pricing' ? 2 : Number(seq ? seq : 2)
  );
  console.log('curr', currPlan, seq);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(null);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  // const [paymentMode, setPaymentMode] = useState('online');
  const paymentMode = localStorage.getItem('paymentType')
  const pdfInputData = returnInvoiceGeneratingPayload({})

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCardClick = (index) => {
    setClickedPlan(index);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleMenuClose();
  };
  const navi = useNavigate();
  const userId = (id !== null && id !== undefined) ? id : localStorage.getItem('user_id');
  const PurchaseOffline = async ({ title }) => {
    try {
      const response = await http.post(
        '/master_service/select_plan',
        JSON.stringify({ user_id: userId, plan_name: title, onlinePayment: paymentMode === 'online' })
      );
      setLoad(null)
      console.log('select_plan', response.data);
      localStorage.setItem('admin_id', response.data.admin_id);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();

      setTimeout(() => {
        navi(window.location.pathname === '/pricing' ? '/login' : '/admin');
      }, 4000);
    } catch (error) {
      setLoad(null)
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  }
  const SendPlanRequest = async (e) => {
    try {
      const response = await http.post(
        "/master_service/select_plan",
        JSON.stringify({
          user_id: userId,
          plan_name: e?.title,
          onlinePayment: paymentMode === 'online',
        })
      );

      console.log('eee', { user_id: userId, recharge_request_id: response.data?.recharge_request_id, transaction_no: e?.razorpay_payment_id, payment_date: new Date(), payment_type: 'online' }, e, response.data);
      await AcceptPlan({ user_id: userId, recharge_request_id: response.data?.recharge_request_id, transaction_no: e?.razorpay_payment_id, payment_date: new Date(), payment_type: 'online' });
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(null)
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Fetch the table data."
      // );
      setMessage('Enter the price in dollars');
      setSeverity("error");
      handleClick();
    }
  };
  const AcceptPlan = async (p) => {
    try {
      const response = await http.post(
        "/master_service/credit_request/update",
        JSON.stringify(p)
        // JSON.stringify({user_id:'', recharge_request_id, transaction_no, payment_date, payment_type})
      );
      const successMessage = response?.data?.message;
      const errorMessage = response?.data?.error;
      if (errorMessage) {
        console.warn("Warning:", errorMessage);
        setMessage(errorMessage);
        setSeverity("warning");
        // dispatch(setMessage(errorMessage));
        // dispatch(setSeverity("warning"));
      } else {
        //  console.log(successMessage);
        setMessage(successMessage || "Data sent successfully.");
        setSeverity("success");
        // dispatch(setMessage(successMessage || "Data sent successfully."));
        // dispatch(setSeverity("success"));
        // fetchDatasP()
      }
      setTimeout(() => {
        navi(window.location.pathname === '/pricing' ? '/login' : '/admin');
      }, 4000);
      setLoad(null)
      // dispatch(handleClick());
    } catch (error) {
      const errMsg = error?.response?.data?.error || "Error sending data.";
      console.error("Error sending data:", errMsg);
      setMessage(errMsg);
      setSeverity("error");
      // dispatch(setMessage(errMsg));
      // dispatch(setSeverity("error"));
      // dispatch(handleClick());
    }
  };
  const GenerateInvoice = async (k) => {
    try {
      const pdf = jsPDFInvoiceTemplate(pdfInputData)
      const mime = "application/pdf";
      const file = new File([pdf?.blob], "abc.pdf", { type: mime });
      console.log('pdf', pdf);

      const formData = new FormData();
      formData.append('user_id', Number(userId));
      formData.append('ind_bundle_id', '');
      formData.append('plan_name', '');
      formData.append('payment_id', k?.id);
      formData.append('invoice_no', k?.invoice_no);
      formData.append('payment_type', 'online');
      formData.append('payment_date', new Date());
      formData.append('bundle_cost', Number(k?.price));
      formData.append('payment_confirmation_status', 'success')
      // formData.append('payment_confirmation_status', k?.payment_confirmation_status)
      formData.append('filename', 'Credit Purchase');
      formData.append('pdf', file);
      const response = await http.post(
        "master_service/payment/details", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set the correct content type
        },
        // JSON.stringify({
        //     user_id: Number(user_id),
        //     ind_bundle_id: Number(bundleId),
        //     plan_name: Number(plan_name),
        //     // payment_type: paymentType,
        //     payment_id: k?.razorpay_payment_id,
        //     payment_date: new Date(),
        //     // bundle_cost: Number(data?.finalIndPrice),
        //     bundle_cost: Number(price),
        //     payment_confirmation_status: "success",
        // })
      });
      console.log('res', response, response?.data?.data?.payment?.order_number, response?.data?.data?.payment?.transaction_number);
      if (k?.payment_confirmation_status === 'success') {
        // setMessage(response.data.message || "Error with save bundle");
        // setSeverity("success");
        // dispatch(setMessage(response.data.message || "Error with save bundle"));
        // dispatch(setSeverity("success"));
        setTransaction_no(response?.data?.data?.payment?.transaction_number);
      } else {
        // setMessage(k?.errorMessage || "Error with purchasing bundle");
        // setSeverity("error");
        // dispatch(setMessage(k?.errorMessage || "Error with purchasing bundle"));
        // dispatch(setSeverity("error"));
      }
      SendPlanRequest(k)
      // dispatch(handleClick());
    } catch (error) {
      console.log('err', error);
      setLoad(false)
      // setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle');
      // setSeverity("error");
      // dispatch(setMessage(error?.response?.data?.message || 'There is some Issue with Purchasing Bundle'));
      // dispatch(setSeverity("error"));
      // dispatch(handleClick());
    }
  }
  const TransactionApiTrigger = async (k) => {
    try {
      const response = await http.post("/master_service/invoice", JSON.stringify({ user_id: userId }));
      console.log("response tbl manage_user:", response, response?.data, response?.data?.data?.invoice_no);
      pdfInputData.invoice.additionalRows[0].col3 = String(GetPriceWithSymbolNoDecimal(Math.round(k?.price)))
      pdfInputData.invoice.num = response?.data?.data?.invoice_no
      pdfInputData.contact.name = response?.data?.data?.user_details?.user_first_name
      pdfInputData.contact.address = response?.data?.data?.user_details?.billing_address
      pdfInputData.contact.phone = formatPhoneNumber(String(response?.data?.data?.user_details?.phone_number))
      pdfInputData.contact.email = response?.data?.data?.user_details?.email
      // pdfInputData.invoice.header = [{ title: "Sl No", }, { title: "Plan Name", }, { title: "No Of Admin", }, { title: "No Of Users" }, { title: "Credits for the Plan (per month)", style: { width: 60 } }, { title: "Plan Cost" },]
      // pdfInputData.invoice.table = [[1, k?.title, Plans?.[load]?.noOfAdmins, Plans?.[load]?.noOfUsers, Plans?.[load]?.applicableCredits, GetPriceWithOutSymbol(Number(k?.price))]]
      // pdfInputData.invoice.table = [[1, k?.title, Plans?.[load]?.noOfAdmins, Plans?.[load]?.noOfUsers, Plans?.[load]?.applicableCredits, GetPriceWithOutSymbol(Number(k?.price))]]
      pdfInputData.invoice.header = [{ title: "Sl No", style: { width: 17, }, }, { title: "Plan Name", }, { title: "Plan Cost", style: { align: "right", } },]
      pdfInputData.invoice.table = [[1, k?.title, GetPriceWithSymbolNoDecimal(Number(k?.price))]]
      // pdfInputData.invoice.table = [[1, k?.title, Plans?.[load]?.noOfAdmins, Plans?.[load]?.noOfUsers, Plans?.[load]?.applicableCredits, GetPriceWithOutSymbol(Number(k?.price))]]
      // pdfInputData.invoice.table = [[1, 'Credit Purchase', Number(Math.round(credits)) + ' Credits', GetPriceWithOutSymbol(Number(Math.round(price)))]]
      // setTblData(response?.data?.user_table)
      // setCardDatas((p) => ({ ...p, totalBrandAuraUsers: response?.data?.manage_user?.[0]?.Total_Brand_Aura_User, individualUsers: response?.data?.manage_user?.[0]?.Individual_User, companyUsers: response?.data?.manage_user?.[0]?.Company_User }))
      // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
      GenerateInvoice({ ...k, invoice_no: response?.data?.data?.invoice_no })
    }
    catch (error) {
      console.error("Error changing status:", error)
      setLoad(false)
    }
  }
  const AfterOnlinePayment = (e, title, price) => {
    // SendPlanRequest(e, title)
    TransactionApiTrigger({ ...e, title, price })
    // SendCredit({ user_id: userId, recharge_request_id: receivedData?.recharge_request_id, transaction_no: transaction_no, payment_date: new Date(), payment_type: 'online' })
  }
  const MakeOnlinePayment = async ({ title, price }) => {
    RazorpayPayment({
      amount: price, handler: (e) => { console.log('eee', e); AfterOnlinePayment(e, title, price); }, onError: () => { setLoad(null) }, onPaymentFailed: () => { setLoad(null) }, ondismiss: () => { setLoad(null) }
    })
  };
  const PurchasePlan = ({ title, price }) => {
    if (paymentMode === 'online') {
      MakeOnlinePayment({ title, price })
    } else {
      PurchaseOffline({ title })
    }
  }
  const SelectPlan = async ({ title, price }) => {
    if (title === 'Custom')
      navi(
        window.location.pathname === '/pricing'
          ? '/Contact'
          : '/admin/custom-plan'
      );
    if (!userId && !id) {
      setMessage(
        'There is some issue with Credentials, Please Try Again'
      );
      setSeverity('error');
      handleClick();
      setLoad(null)
      return;
    }
    console.log('userId', userId, id);
    PurchasePlan({ title, price })
  };

  return (
    <Box
      sx={{
        background: '#fff',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pt: 8,
        pb: 0,
      }}>
      <Box sx={{ width: { xs: '100%', md: need ? '80%' : '100%' } }}>
        <Box sx={{ textAlign: 'center', mb: 5 }}>
          <Typography
            sx={{
              color: '#000',
              fontSize: { xs: '30px', md: '40px' },
              fontWeight: '600',
              pr: '10px',
              display: 'inline-block',
            }}>
            Our
          </Typography>
          <Typography
            sx={{
              color: '#8A18D0',
              fontSize: { xs: '30px', md: '40px' },
              fontWeight: '600',
              display: 'inline-block',
            }}>
            Plans
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' }, // Ensure center alignment on smaller screens
          }}>
          {Plans.map((plan, index) => (
            <Grid
              item
              xs={11}
              sm={6}
              md={3}
              key={index}>
              <Box
                onClick={() => handleCardClick(index)}
                sx={{
                  border: '1px solid #E4E4E4',
                  color: clickedPlan === index ? '#FFFFFF' : '#000000',
                  background: clickedPlan === index ? '#000000' : '',
                  p: 2,
                  borderRadius: '8px',
                  minWidth: '200px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                  height: 'auto',
                  boxSizing: 'border-box',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  transform: clickedPlan === index ? 'scale(1.05)' : 'scale(1)',
                  boxShadow:
                    clickedPlan === index
                      ? '0px 10px 20px rgba(0,0,0,0.2)'
                      : 'none',
                  cursor: 'pointer',
                  perspective: '1000px',
                  transformStyle: 'preserve-3d',
                }}>
                <Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '24px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mb: 2,
                      }}>
                      {plan.title}
                    </Typography>
                    {currPlan === index && (
                      // <Box>
                      //   <Typography>Current Plan</Typography>
                      // </Box>
                      <Box sx={{ mr: -2.2 }}>
                        <CP_Icon />
                      </Box>
                    )}
                  </Box>

                  {plan.text && (
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '15px',
                        color: '#727272',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        mb: 3,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}>
                      {plan.text}
                    </Typography>
                  )}

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                    {plan.price && (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '32px',
                          pr: '5px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}>
                        {GetPriceWithSymbolNoDecimal(plan.price)}
                      </Typography>
                    )}
                    {plan.discount && (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          background:
                            clickedPlan === index ? '#4B4B4B' : '#F2F2F2',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}>
                        {plan.discount}
                      </Typography>
                    )}
                  </Box>
                  {plan.subText && (
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '12px',
                        color: clickedPlan === index ? '#AFAFAF' : '',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        mb: 2,
                      }}>
                      {plan.subText}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: clickedPlan === index ? '#D8D8D8' : '#000',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                    }}>
                    {plan.mainSubText}
                  </Typography>
                  <Box>
                    {plan.subTextData.map((item, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '10px',
                        }}>
                        {renderIcon(clickedPlan === index)}
                        <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            fontWeight: '400',
                            pl: '7px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                          }}>
                          {item.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Button
                  sx={{
                    border:
                      clickedPlan !== index &&
                      index > currPlan &&
                      '1px solid #8A18D0',
                    textTransform: 'capitalize',
                    fontWeight: '500',
                    fontSize: '16px',
                    background:
                      index <= currPlan
                        ? '#474747'
                        : clickedPlan === index
                          ? '#8A18D0'
                          : 'transparent',
                    '&:hover': {
                      background:
                        index <= currPlan
                          ? '#47474799'
                          : clickedPlan === index && '#8a18d099',
                    },
                    // color: '#fff',
                    color: index <= currPlan
                      || clickedPlan === index ? '#fff' : '#8A18D0',
                    width: '100%',
                    mt: 2,
                    height: '40px',
                    '&.Mui-disabled': {
                      color: '#ffffff',
                      border: 'none'
                    },

                  }}
                  onClick={() => {
                    if (index === currPlan) {
                      console.log('Button is disabled');
                      setMessage('This is Currently Activated Plan, This cant be purchased again.');
                      setSeverity('warning');
                      handleClick();
                    } else if (index <= currPlan) {
                      console.log('Button is disabled');
                      setMessage('Plan downgrade not possible.');
                      setSeverity('warning');
                      handleClick();
                    } else {
                      setLoad(index); SelectPlan({ title: plan.title, price: plan.price });
                    }
                  }}
                  component={RouterLink}
                // disabled={index <= currPlan}
                // to={plan.btn_Link && plan.btn_Link}
                >
                  {plan.btn}
                  <Box>
                    {load === index && (
                      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                        <Box sx={BtnLoad} />
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "calc(100% - 16px)", sm: "100%" },
            maxWidth: "100%",
            height: { xs: "100px", sm: "60px" },
            my: "32px",
            mx: { xs: "8px", sm: "0" },
            backgroundColor: "#F3E8FA",
            borderRadius: "8px",
            px: 2,
            textAlign: "center",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontWeight: "200",
              mb: { xs: 1, sm: 0 },
            }}
          >
            Are you an individual user? We have custom plans for you as well.
          </Typography>
          <RouterLink to='/reg' style={{ textDecoration: "none" }}>
            <Typography
              onClick={handleMenuClose}
              sx={{
                color: "#8A18D0",
                display: "block",
                textAlign: "center",
                px: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Explore
            </Typography>
          </RouterLink>
        </Box> */}
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ position: 'absolute', mt: '38px' }}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant='filled'
            sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default OurPlans;
