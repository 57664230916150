import { Box, Button, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";


export const Model_1 = ({ open, setOpen, data }) => {
    const handleClose = () => setOpen(false);
    const handleButtonClick = (link, fn, cancel) => {
        if (fn) {
            fn();
        }
        if (cancel) {
            setOpen(false);
        }
        if (link) {
            navigate(link);
        }
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: '83%', md: "537px" },
        bgcolor: "#FFFFFF",
        // border: '2px solid #000',
        boxShadow: 24,
        outlineColor: "invert",
        p: 2,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "700",
                                background:
                                    "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            {data.title}
                        </Typography>
                        {data.emoji && (
                            <Typography
                                sx={{ fontSize: "20px", fontWeight: "700", pl: "7px" }}
                            >
                                {data.emoji}
                            </Typography>
                        )}
                    </Box>
                    <Button
                        disableRipple
                        disableElevation
                        sx={{ minWidth: "max-content" }}
                        onClick={handleClose}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM9.879 8.464C9.69946 8.28275 9.45743 8.17697 9.20245 8.16832C8.94748 8.15967 8.69883 8.2488 8.50742 8.41747C8.31601 8.58613 8.1963 8.82159 8.1728 9.07562C8.14929 9.32966 8.22378 9.58308 8.381 9.784L8.465 9.879L10.585 11.999L8.465 14.121C8.28375 14.3005 8.17797 14.5426 8.16932 14.7975C8.16067 15.0525 8.2498 15.3012 8.41847 15.4926C8.58713 15.684 8.82258 15.8037 9.07662 15.8272C9.33066 15.8507 9.58408 15.7762 9.785 15.619L9.879 15.536L12 13.414L14.121 15.536C14.3005 15.7173 14.5426 15.823 14.7975 15.8317C15.0525 15.8403 15.3012 15.7512 15.4926 15.5825C15.684 15.4139 15.8037 15.1784 15.8272 14.9244C15.8507 14.6703 15.7762 14.4169 15.619 14.216L15.536 14.121L13.414 12L15.536 9.879C15.7173 9.69946 15.823 9.45743 15.8317 9.20245C15.8403 8.94748 15.7512 8.69883 15.5825 8.50742C15.4139 8.31601 15.1784 8.1963 14.9244 8.1728C14.6703 8.14929 14.4169 8.22378 14.216 8.381L14.121 8.464L12 10.586L9.879 8.464Z"
                                fill="black"
                            />
                        </svg>
                    </Button>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: 'column', md: 'row' }, mb: 1 }}>
                    {/* <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000' }}>
              {data.txt}
            </Typography> */}
                    <Box>
                        {data.txt.map((item, index) =>
                            Array.isArray(item) ? (
                                <Typography sx={{ display: 'flex', textWrap: 'wrap', alignItems: 'center' }}>
                                    {
                                        item.map((part, idx) => (
                                            <Typography
                                                key={idx}
                                                sx={{
                                                    fontWeight: part.isBold ? "700" : "500",
                                                    fontSize: "12px",
                                                    mr: '3px',
                                                    color: "#000000",
                                                    textWrap: 'nowrap',
                                                }}
                                            >
                                                {part.text}
                                            </Typography>
                                        ))
                                    }
                                </Typography>
                            ) : (
                                <Typography
                                    key={index}
                                    sx={{ fontWeight: "500", fontSize: "12px", color: "#000000" }}
                                >
                                    {item}
                                </Typography>
                            )
                        )}
                    </Box>
                    {data.cl && (
                        <Box
                            sx={{
                                background: "#F3E7FA",
                                p: 2,
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#474747",
                            }}
                        >
                            Total Credits Left : {data.cl}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    {data.btn_1 && (
                        <Link
                            to={data.btn_1_Link && data.btn_1_Link}
                            style={{ minWidth: "50%", width: !data.btn_2 ? "100%" : "" }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    color: "#8A18D0",
                                    borderColor: "#8A18D0",
                                    // minWidth: "50%",
                                    // width: !data.btn_2 ? "100%" : "",
                                    width: "100%",
                                    textTransform: "capitalize",
                                }}
                                onClick={() => {
                                    if (data.btn_1_fn) {
                                        data.btn_1_fn(data.btn_1_fn_props && data.btn_1_fn_props);
                                    }
                                    if (data.btn_1_cancel) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {data.btn_1}
                            </Button>
                        </Link>
                    )}
                    {data.btn_2 && (
                        <Link
                            to={data.btn_2_Link && data.btn_2_Link}
                            style={{ minWidth: "50%", width: !data.btn_1 ? "100%" : "" }}
                        >
                            <Button
                                sx={{
                                    background: "#8A18D0",
                                    color: "#fff",
                                    ml: "5px",
                                    // minWidth: "50%",
                                    // width: !data.btn_1 ? "100%" : "",
                                    width: "100%",
                                    textTransform: "capitalize",
                                    "&:hover": { background: "#B37FFC" },
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (data.btn_2_fn) {
                                        data.btn_2_fn(data.btn_2_fn_props && data.btn_2_fn_props);
                                    }
                                    if (data.btn_2_cancel) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {data.btn_2}
                            </Button>
                        </Link>
                    )}
                </Box>
                {data.Credits && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            mt: 1,
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{ color: "#000", fontSize: "12px", fontWeight: "500", mr: 1 }}
                        >
                            Ask more credits?{" "}
                        </Typography>
                        <Link to={data.CreditsLnk && data.CreditsLnk}>
                            <Typography
                                sx={{
                                    color: "#8A18D0",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    textDecorationLine: "underline",
                                }}
                            >
                                Click Here
                            </Typography>
                        </Link>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};
